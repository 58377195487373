import { Product, Unit } from '../../model/product';
import { addUnit, getUnits } from '../../repositories/product';
import { ucFirst } from '../../utils/helper';
import { SelectOption, colourStyles } from '../../utils/style';
import { Dialog, Transition } from '@headlessui/react';
import FolderIcon from '@heroicons/react/24/outline/FolderIcon';
import { Fragment, type FC, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { SingleValue } from 'react-select';
import Creatable from 'react-select/creatable';


interface FormUnitProps {
    product: Product | null
    modalUnitOpen: boolean
    setModalUnitOpen: (arg: boolean) => void
    setInputUnitId: (arg: string) => void
    setInputUnit: (arg: SingleValue<SelectOption>) => void
    setUnits: (arg: Unit[]) => void
    inputUnitValue: number
    inputUnit: SelectOption | null | undefined
    setInputUnitValue: (arg: number) => void
    onSubmit: () => void
    unitSelect: SelectOption[]
}

const FormUnit: FC<FormUnitProps> = ({ modalUnitOpen, setModalUnitOpen, product, setInputUnitId, setInputUnit, inputUnit, inputUnitValue, setInputUnitValue, setUnits, unitSelect, onSubmit }) => {
    return (<Transition appear show={modalUnitOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setModalUnitOpen(false)}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="fixed inset-0 bg-black/25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Dialog.Panel className="w-full flex flex-col max-w-2xl transform overflow-hidden rounded-2xl bg-form-dialog p-6 text-left align-middle shadow-xl transition-all" style={{ height: 'calc(100vh - 300px)' }}>
                            <Dialog.Title
                                as="h3"
                                className="text-xl font-medium leading-6 text-gray-900"
                            >
                                Tambah Unit
                            </Dialog.Title>
                            <div className="flex-1">
                                <div className="mt-4 ">
                                    <div className="flex items-center mb-6">
                                        <div className="w-1/3">
                                            <label className="block text-gray-900 mb-0 pr-4">
                                                Unit
                                            </label>
                                        </div>
                                        <div className="w-2/3">
                                            <Creatable< SelectOption, false> styles={colourStyles} options={unitSelect} value={inputUnit}
                                                onChange={(option: SingleValue<SelectOption>): void => {
                                                    setInputUnitId(option?.value ?? "")
                                                    setInputUnit(option)
                                                }}
                                                onCreateOption={(val) => {
                                                    addUnit({ name: val }).then(v => getUnits({ page: 1, limit: 1000 })
                                                        .then(v => v.json())
                                                        .then(v => {
                                                            setUnits(v.data)
                                                        }))
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {product?.unit_id ? <div className="mt-4 ">
                                    <div className="flex items-center mb-6">
                                        <div className="w-1/3">
                                            <label className="block text-gray-900 mb-0 pr-4">
                                                Nilai {product?.unit_id ? `(${product.unit_name})` : null}
                                            </label>
                                        </div>
                                        <div className="w-2/3">
                                            <CurrencyInput
                                                className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                                id="base-price"
                                                groupSeparator="."
                                                decimalSeparator=","
                                                placeholder="Nilai"
                                                onValueChange={(value, _, values) => setInputUnitValue(values?.float ?? 0)}
                                            />
                                        </div>
                                    </div>
                                </div> : null}

                            </div>

                            <div className="mt-4">
                                <button
                                    type="button"
                                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                    onClick={onSubmit}
                                >
                                    <FolderIcon
                                        className=" h-5 w-5 mr-2"
                                        aria-hidden="true" />  Simpan
                                </button>
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </div>
        </Dialog>
    </Transition>);
}
export default FormUnit;