import { DeliveryLetter, DeliveryLetterItem } from '../model/delivery_letter';
import { parseVarian } from '../utils/helper';
import { money } from '../utils/number';
import { TrashIcon } from '@heroicons/react/16/solid';
import { PlusCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useEffect, useState, type FC } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { useNavigate } from 'react-router-dom';
import { Checkbox } from 'rsuite';
import Swal from 'sweetalert2';

interface DeliveryLetterItemRecordProps {
    deliveryLetter: DeliveryLetter
    item: DeliveryLetterItem
    orderNumber: number
    editable?: boolean
    checked: boolean
    onDelete?: () => void
    onChange?: (i: DeliveryLetterItem) => void
    onAddPackingList?: (i: DeliveryLetterItem) => void
    onChecked?: (arg: boolean) => void
}

const DeliveryLetterItemRecord: FC<DeliveryLetterItemRecordProps> = ({
    deliveryLetter,
    item,
    orderNumber,
    editable,
    onDelete,
    onChange,
    checked,
    onAddPackingList,
    onChecked,
}) => {
    const [packingList, setPackingList] = useState<number[]>([]);
    const nav = useNavigate()

    useEffect(() => {
        setPackingList(item.packing_list)
    }, [item]);

    const renderDesc = () => {
        if (deliveryLetter.type == "packing_list") {
            if (!editable) return `| ${packingList.join(" | ")} |`
            return <div className='flex flex-wrap'>
                {packingList.map((e, i) => <div className='flex mx-1 items-center border mb-1 hover:border-gray-500 rounded-sm' key={i}>
                    <div className='flex  items-center'>
                        <CurrencyInput
                            className=" max-w-10 text-center "
                            id={`qty-${item.uuid}`}
                            groupSeparator="."
                            decimalSeparator=","
                            placeholder="Qty"
                            defaultValue={e}
                            onValueChange={(value, _, values) => {
                                packingList[i] = values?.float ?? 0
                                setPackingList([...packingList])
                            }}
                            onKeyUp={(e) => {
                                if (e.key === 'Enter') {
                                    document.getElementById(`qty-${item.uuid}`)!.blur();
                                }
                            }}
                            onBlur={() => {
                                if (onChange != null) onChange({
                                    ...item,
                                    packing_list: packingList,
                                    qty_packaging: packingList.length,
                                    qty: packingList.reduce((a, b) => a + b, 0)
                                })
                            }}
                        />
                        <XMarkIcon className='w-4 text-red-400 hover:text-red-600 hover:font-medium' onClick={() => {
                            let pkg = packingList.splice(i, 1)
                            setPackingList([])
                            if (onChange != null) onChange({
                                ...item,
                                packing_list: packingList,
                                qty_packaging: packingList.length,
                                qty: packingList.reduce((a, b) => a + b, 0)
                            })
                        }} />
                    </div>

                </div>)}
                <PlusCircleIcon onClick={() => {
                    if (onAddPackingList) onAddPackingList({
                        ...item,
                        packing_list: [...item.packing_list, 0],
                        qty_packaging: item.qty_packaging + 1
                    })
                }} className='w-4 text-red-400 hover:text-red-600 hover:font-medium ' />
            </div>
        }
    }
    return (<tr>
        <td scope="col" className="px-6 py-3 w-3 ">
            <div className='flex items-center'>
                {editable && <Checkbox defaultChecked={checked} onChange={(value, checked) => {
                    if (onChecked != null) {
                        onChecked(checked)
                    }
                }} />}
                <span>
                    {money(orderNumber)}

                </span>
            </div>
        </td>
        <td scope="col" className="px-6 py-3 ">
            <div className=' hover:font-medium' onClick={() => {
                if (item.product_id) {
                    nav(`/product/${item.product_id}`)
                }
            }}>
                {item.description}
            </div>

        </td>
        <td scope="col" className="px-6 py-3 text-right">
            {money(item.qty_packaging)} {item.packaging_name}
        </td>
        <td scope="col" className="px-6 py-3 text-right">
            {money(item.qty)} {item.unit_name}
        </td>
        <td scope="col" className="px-6 py-3 max-w-[200px]  ">
            <div className='flex w-full flex-wrap'>
                {renderDesc()}
            </div>
        </td>
        <td scope="col" className="px-6 py-3 text-right">
            {editable ? <TrashIcon
                className=" h-5 w-5 text-red-400 hover:text-red-600"
                aria-hidden="true"
                onClick={() => {

                    Swal.fire({
                        title: "Anda Yakin",
                        text: "Anda tidak akan dapat mengembalikan proses ini!",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Ya, Hapus!",
                        cancelButtonText: "Batal"
                    }).then((result) => {
                        if (result.isConfirmed) {
                            if (onDelete) onDelete()
                        }

                    });
                }}
            /> : null}
        </td>
    </tr>);
}
export default DeliveryLetterItemRecord;