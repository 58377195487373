import { PaginationReq } from "../model/pagination";
import { TransactionReq } from "../model/transaction";
import { customFetch } from "../utils/helper";
import moment from "moment";
import { DateRange } from "rsuite/esm/DateRangePicker";


export let getTransactions = async (pagination: PaginationReq, dateRange?: DateRange | null,accountId?: string | null, ) => {
    var params: Record<string, string> = {
        page: pagination.page.toString(),
        limit: pagination.limit.toString(),
        search: pagination.search ?? "",

    };

    if (pagination.type) {
        params["type"] = pagination.type
    }
    if (accountId) {
        params["account_id"] = accountId
    }

    if (dateRange) {
        params["start_date"] = moment(dateRange[0]).format("YYYY-MM-DD")
        params["end_date"] = moment(dateRange[1]).format("YYYY-MM-DD")
    }

    return await customFetch(`Transaction/?${new URLSearchParams(params)}`)
}
export let getTransactionDetail = async (uuid: string) => {

    return await customFetch(`Transaction/${uuid}`)
}

export let deleteTransaction = async (uuid: string) => {
    return await customFetch(`Transaction/${uuid}`, {
        method: "DELETE",
    })
}
export let addFileTransaction = async (uuid: string, path: string) => {
    
    return await customFetch(`Transaction/${uuid}/AddFile`, {
        method: "POST",
        body: JSON.stringify({path})
    })
}
export let addTransaction = async (req: TransactionReq) => {
    return await customFetch(`Transaction/`, {
        method: "POST",
        body: JSON.stringify(req)
    })
}
export let editTransaction = async (uuid: string, req: TransactionReq) => {
    return await customFetch(`Transaction/${uuid}`, {
        method: "PUT",
        body: JSON.stringify(req)
    })
}
