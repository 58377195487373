import { Company, CompanyCategory, CompanyReq, JarayaTradingSetting } from '../../model/company';
import { LoadingContext } from '../../objects/loading_context';
import { getCompanyCategory, getCompanyDetail, updateCompany, updateProductAddOnSetting } from '../../repositories/company';
import { fileUpload } from '../../utils/file';
import { randomStr, setNullString } from '../../utils/helper';
import { FolderIcon } from '@heroicons/react/24/outline';
import AvatarIcon from '@rsuite/icons/legacy/Avatar';
import { useContext, useEffect, useRef, useState, type FC } from 'react';
import { Panel } from 'rsuite';
import Swal from 'sweetalert2';


interface CompanyProps {
    permissions: string[]
    onUpdate?: () => void

}
const CompanySetting: FC<CompanyProps> = ({
    permissions,
    onUpdate
}) => {
    let { isLoading, setIsLoading } = useContext(LoadingContext);
    const [mounted, setMounted] = useState(false);
    const [name, setName] = useState("")
    const [logo, setLogo] = useState("")
    const [cover, setCover] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [fax, setFax] = useState("")
    const [address, setAddress] = useState("")
    const [contactPerson, setContactPerson] = useState("")
    const [contactPersonPosition, setContactPersonPosition] = useState("")
    const [categoryCompanyId, setCategoryCompanyId] = useState("")
    const [apiSecret, setApiSecret] = useState("")
    const [taxPayerNumber, setTaxPayerNumber] = useState("")
    const [categories, setCategories] = useState<CompanyCategory[]>([]);
    const [uploading, setUploading] = useState(false);
    const logoRef = useRef<HTMLInputElement>(null)
    const coverRef = useRef<HTMLInputElement>(null)
    const [settingLoaded, setSettingLoaded] = useState(false);

    const [company, setCompany] = useState<Company | null>(null);






    useEffect(() => {
        setMounted(true)
        getCompanyData(false)
    }, []);

    useEffect(() => {
        if (!mounted) return
        getCompanyCategory()
            .then(v => v.json())
            .then(v => {
                setCategories(v.data)
            })
    }, [mounted]);


    useEffect(() => {
        if (!company) return

        if (company?.product_add_on_setting == null) {
            
            
            setupDefaultSetting()
        } else if (
            company!.product_add_on_setting!.jaraya_trading_setting.bill.number_format == "" &&
            company!.product_add_on_setting!.jaraya_trading_setting.invoice.number_format == "" &&
            company!.product_add_on_setting!.jaraya_trading_setting.purchase_order.number_format == "" &&
            company!.product_add_on_setting!.jaraya_trading_setting.quotation.number_format == "" 

        ) {
            setupDefaultSetting()
            return
        }
    }, [company]);


    const setupDefaultSetting = async () => {
        let jaraya_trading_setting: JarayaTradingSetting = {
            delivery_letter: {
                static_character: "DO",
                number_format: "{static-character}-{auto-numeric}/{month-mmm}/{year-yyyy}",
                auto_numeric_length: 5,
                random_numeric_length: 5,
                random_character_length: 5
            },
            invoice: {
                title: "INVOICE",
                source_id: "",
                destination_id: "",
                tax_id: "",
                tax_method: "add",
                secondary_tax_id: "",
                secondary_tax_method: "add",
                notes: "",
                is_auto_stock: true,
                static_character: "INV",
                number_format: "{static-character}-{auto-numeric}/{month-mmm}/{year-yyyy}",
                auto_numeric_length: 5,
                random_numeric_length: 5,
                random_character_length: 5
            },
            bill: {
                title: "BILL",
                source_id: "",
                destination_id: "",
                tax_id: "",
                tax_method: "add",
                secondary_tax_id: "",
                secondary_tax_method: "add",
                notes: "",
                is_auto_stock: true,
                static_character: "BILL",
                number_format: "{static-character}-{auto-numeric}/{month-mmm}/{year-yyyy}",
                auto_numeric_length: 5,
                random_numeric_length: 5,
                random_character_length: 5
            },
            quotation: {
                title: "QUOTATION",
                source_id: "",
                destination_id: "",
                tax_id: "",
                tax_method: "add",
                secondary_tax_id: "",
                secondary_tax_method: "add",
                notes: "",
                is_auto_stock: true,
                static_character: "QO",
                number_format: "{static-character}-{auto-numeric}/{month-mmm}/{year-yyyy}",
                auto_numeric_length: 5,
                random_numeric_length: 5,
                random_character_length: 5
            },
            purchase_order: {
                title: "PURCHASE ORDER",
                source_id: "",
                destination_id: "",
                tax_id: "",
                tax_method: "",
                secondary_tax_id: "",
                secondary_tax_method: "",
                notes: "",
                is_auto_stock: true,
                static_character: "PO",
                number_format: "{static-character}-{auto-numeric}/{month-mmm}/{year-yyyy}",
                auto_numeric_length: 5,
                random_numeric_length: 5,
                random_character_length: 5
            },
            varian_parameters: ["warna"],
            user_packaging_stock: false,
            delivery_letter_template: "regular"
        }

        try {
            setIsLoading(true)
            var resp = await updateProductAddOnSetting({
                jaraya_trading_setting,
            })
            var respJson = await resp.json()
            getCompanyData(false)
        } catch (error) {
            Swal.fire("Perhatian", `${error}`, "error")
        } finally {
            setIsLoading(false)
        }
    }

    const getCompanyData = async (forceUpdate: boolean) => {
        if (settingLoaded && !forceUpdate) return
        try {
            setIsLoading(true)
            var resp = await getCompanyDetail()
            var respJson = await resp.json()
            setCompany(respJson.data)
            setSettingLoaded(true)
        } catch (error) {
            Swal.fire("Perhatian", `${error}`, "error")
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (!company) return
        setName(company!.name)
        setLogo(company!.logo)
        setCover(company!.cover)
        setEmail(company!.email)
        setPhone(company!.phone)
        setCategoryCompanyId(company!.category_company_id)
        setFax(company!.fax)
        setAddress(company!.address)
        setContactPerson(company!.contact_person)
        setContactPersonPosition(company!.contact_person_position)
        setApiSecret(company!.api_secret)
        setTaxPayerNumber(company!.tax_payer_number)

        

    }, [company]);

    const companyUpdate = async ({ logo, cover }: { logo?: string, cover?: string }) => {
        try {
            setIsLoading(true)
            let req: CompanyReq = {
                name,
                email: setNullString(email),
                phone: setNullString(phone),
                category_company_id: setNullString(categoryCompanyId),
                fax: setNullString(fax),
                address: setNullString(address),
                contact_person: setNullString(contactPerson),
                contact_person_position: setNullString(contactPersonPosition),
                api_secret: apiSecret,
                tax_payer_number: taxPayerNumber,
                is_active: company!.is_active,
                is_professional: company!.is_professional,
                is_default: company!.is_default,
            }
            if (logo) {
                req.logo = logo
            }
            if (cover) {
                req.cover = cover
            }
            var resp = await updateCompany(req)
            setSettingLoaded(false)
            setCompany(null)
            getCompanyData(true)
            


        } catch (error) {
            Swal.fire("Perhatian", `${error}`, "error")
        } finally {
            setIsLoading(false)
        }
    }




    return (<div className="flex-1 overflow-y-auto mb-5 col-span-1 mt-5 rounded-xl py-6 px-6 transition-all hover:shadow-lg sm:p-4 lg:px-4 xl:px-6 bg-white cursor-pointer">
        <div className='grid gap-x-8 gap-y-4 sm:gap-y-0 xl:grid-cols-4 lg:grid-cols-4 sm:grid-cols-2 md:grid-cols-2'>
            <div className='col-span-2'>
                <Panel header="Info Perusahaan" bordered>
                    <div className="flex items-center mb-6">
                        <div className="w-1/3">
                            <label className="block text-gray-900 mb-0 pr-4">Nama Perusahaan</label>
                        </div>
                        <div className="w-2/3">
                            <input
                                disabled={!permissions.includes('update_company')}
                                className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                type="text"
                                placeholder={"Nama Perusahaan"}
                                value={name}
                                onChange={(el) => setName(el.target.value)}
                            />
                        </div>
                    </div>
                    <div className="flex items-center mb-6">
                        <div className="w-1/3">
                            <label className="block text-gray-900 mb-0 pr-4">Kategori Perusahaan</label>
                        </div>
                        <div className="w-2/3">
                            <select
                                disabled={!permissions.includes('update_company')}
                                className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                value={categoryCompanyId}
                                onChange={(el) => setCategoryCompanyId(el.target.value)}
                            >{categories.map(e => <option key={e.uuid} value={e.uuid}>{e.name}</option>)}
                            </select>
                        </div>
                    </div>
                    <div className="flex items-center mb-6">
                        <div className="w-1/3">
                            <label className="block text-gray-900 mb-0 pr-4">Email</label>
                        </div>
                        <div className="w-2/3">
                            <input
                                disabled={!permissions.includes('update_company')}
                                className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                type="text"
                                placeholder={"Email"}
                                value={email}
                                onChange={(el) => setEmail(el.target.value)}
                            />
                        </div>
                    </div>
                    <div className="flex items-center mb-6">
                        <div className="w-1/3">
                            <label className="block text-gray-900 mb-0 pr-4">Telepon</label>
                        </div>
                        <div className="w-2/3">
                            <input
                                disabled={!permissions.includes('update_company')}
                                className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                type="text"
                                placeholder={"Telepon"}
                                value={phone}
                                onChange={(el) => setPhone(el.target.value)}
                            />
                        </div>
                    </div>
                    <div className="flex items-center mb-6">
                        <div className="w-1/3">
                            <label className="block text-gray-900 mb-0 pr-4">Fax</label>
                        </div>
                        <div className="w-2/3">
                            <input
                                disabled={!permissions.includes('update_company')}
                                className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                type="text"
                                placeholder={"Fax"}
                                value={fax}
                                onChange={(el) => setFax(el.target.value)}
                            />
                        </div>
                    </div>
                    <div className="flex  mb-6">
                        <div className="w-1/3">
                            <label className="block text-gray-900 mb-0 pr-4">Alamat</label>
                        </div>
                        <div className="w-2/3">
                            <textarea
                                className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                rows={9}
                                placeholder={"Alamat"}
                                value={address}
                                onChange={(el) => setAddress(el.target.value)}
                            />
                        </div>
                    </div>
                    <div className="flex items-center mb-6">
                        <div className="w-1/3">
                            <label className="block text-gray-900 mb-0 pr-4">Penanggung Jawab</label>
                        </div>
                        <div className="w-2/3">
                            <input
                                disabled={!permissions.includes('update_company')}
                                className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                type="text"
                                placeholder={"Penanggung Jawab"}
                                value={contactPerson}
                                onChange={(el) => setContactPerson(el.target.value)}
                            />
                        </div>
                    </div>
                    <div className="flex items-center mb-6">
                        <div className="w-1/3">
                            <label className="block text-gray-900 mb-0 pr-4">Jabatan</label>
                        </div>
                        <div className="w-2/3">
                            <input
                                disabled={!permissions.includes('update_company')}
                                className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                type="text"
                                placeholder={"Jabatan"}
                                value={contactPersonPosition}
                                onChange={(el) => setContactPersonPosition(el.target.value)}
                            />
                        </div>
                    </div>
                    <div className="mt-4">
                        <button
                            disabled={!permissions.includes('update_company')}
                            type="button"
                            className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                            onClick={() => companyUpdate({})}
                        >
                            <FolderIcon
                                className=" h-5 w-5 mr-2"
                                aria-hidden="true" />  Simpan
                        </button>
                    </div>
                </Panel>
            </div>
            <div className='col-span-2'>
                <Panel header="Logo & Cover" bordered className='mb-8'>
                    <div className="flex mb-6">
                        <div className="w-1/3">
                            <label className="block text-gray-900 mb-0 pr-4">Logo</label>
                        </div>
                        <div className="w-2/3">
                            <input value={""} type='file' id='file' accept='image/*' ref={logoRef} style={{ display: 'none' }} onChange={async (e) => {
                                try {
                                    setIsLoading(true)
                                    var resp = await fileUpload(`File/ImageUpload`, {
                                        image: e.target.files![0],
                                        dir: 'product',
                                    })

                                    let respJson = await resp.json()

                                    companyUpdate({ logo: respJson.data.path })

                                } catch (error) {
                                    Swal.fire("Perhatian", `${error}`, "error")
                                } finally {
                                    setIsLoading(false)
                                }
                            }} />
                            <div onClick={() => {
                                logoRef.current?.click()
                            }}>
                                {company?.logo ? <img src={company?.logo ?? ""} alt="" className=' object-cover w-full h-80 rounded-lg' /> : <AvatarIcon />}
                            </div>
                        </div>
                    </div>
                    <div className="flex mb-6">
                        <div className="w-1/3">
                            <label className="block text-gray-900 mb-0 pr-4">Cover</label>
                        </div>
                        <div className="w-2/3">
                            <input value={""} type='file' id='file' accept='image/*' ref={coverRef} style={{ display: 'none' }} onChange={async (e) => {
                                 try {
                                    setIsLoading(true)
                                    var resp = await fileUpload(`File/ImageUpload`, {
                                        image: e.target.files![0],
                                        dir: 'product',
                                    })

                                    let respJson = await resp.json()

                                    companyUpdate({ cover: respJson.data.path })

                                } catch (error) {
                                    Swal.fire("Perhatian", `${error}`, "error")
                                } finally {
                                    setIsLoading(false)
                                }
                            }} />
                            <div onClick={() => {
                                coverRef.current?.click()
                            }}>
                                {company?.logo ? <img src={company?.cover ?? ""} alt="" className=' object-cover w-full h-80 rounded-lg' /> : <AvatarIcon />}
                            </div>
                        </div>
                    </div>
                </Panel>
                <Panel header="Akses Publik" bordered >
                    <div className="flex items-center mb-6">
                        <div className="w-1/3">
                            <label className="block text-gray-900 mb-0 pr-4">API KEY</label>
                        </div>
                        <div className="w-2/3">
                            {company?.uuid}

                        </div>
                    </div>
                    <div className="flex items-center mb-6">
                        <div className="w-1/3">
                            <label className="block text-gray-900 mb-0 pr-4">API SECRET</label>
                        </div>
                        <div className="w-2/3">
                            {apiSecret}
                        </div>
                    </div>
                    <div className="mt-4">
                        <button
                            disabled={true}
                            type="button"
                            className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                            onClick={() => {
                                Swal.fire({
                                    title: "Anda Yakin",
                                    text: "API SECRET digunakan untuk integrasi aplikasi lain ke jaraya, dengan membuat API Secret baru memungkinkan Aplikasi yang telah terhubung tidak berfungsi",
                                    icon: "warning",
                                    showCancelButton: true,
                                    confirmButtonColor: "#3085d6",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: "Ya, Generate!",
                                    cancelButtonText: "Batal"
                                }).then((result) => {
                                    setApiSecret(randomStr(20))
                                    setTimeout(() => {
                                        companyUpdate({})
                                    }, 1000);
                                });
                            }}
                        >
                            <FolderIcon
                                className=" h-5 w-5 mr-2"
                                aria-hidden="true" />  Generate API Secret Baru
                        </button>
                    </div>
                </Panel>
            </div>
        </div>
    </div>);
}
export default CompanySetting;