import { Account } from '../../model/account';
import { Pagination } from '../../model/pagination';
import { LoadingContext } from '../../objects/loading_context';
import { deleteAccount, getAccounts } from '../../repositories/account';
import { useState, type FC, useContext, useEffect } from 'react';
import Swal from 'sweetalert2';
import CustomTable, { TableRecord } from '../custom_table';
import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import { ACCOUNT_LABELS } from '../../objects/constants';
import ModalAccount from '../modals/modal_account';
import FormAccount from '../modals/form_account';
import ModalAccountTemplate from '../modals/modal_account_template';

interface AccountProps {

}

const AccountSetting: FC<AccountProps> = ({

}) => {

  const [accounts, setAccounts] = useState<Account[]>([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [pagination, setPagination] = useState<Pagination | null>(null);
  const [search, setSearch] = useState("");
  const [mounted, setMounted] = useState(false);
  const [settingLoaded, setSettingLoaded] = useState(false);
  let { isLoading, setIsLoading } = useContext(LoadingContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalTemplateOpen, setModalTemplateOpen] = useState(false);

  useEffect(() => {
    setMounted(true)
  }, []);

  useEffect(() => {
    if (!mounted) return
    getAllAccounts()
  }, [mounted]);

  useEffect(() => {
    setPage(1)
    getAllAccounts(1)
  }, [search]);
  useEffect(() => {
    getAllAccounts()
  }, [limit, page]);

  const getAllAccounts = async (p?: number) => {
    try {
      setIsLoading(true)
      var resp = await getAccounts({ page: p ?? page, limit, search })
      var respJson = await resp.json()
      setAccounts(respJson.data)
      setPagination(respJson.meta)
    } catch (error) {
      Swal.fire("Perhatian", `${error}`, "error")
    } finally {
      setIsLoading(false)
    }
  }




  return (<div className="flex-1 mb-5 col-span-1 mt-5 rounded-xl py-6 px-6 transition-all hover:shadow-lg sm:p-4 lg:px-4 xl:px-6 bg-white cursor-pointer">
    <h1 className=' text-3xl mb-4'>Akun</h1>
    <CustomTable
      searchPlaceholder='Cari Kategori'
      onSearch={(val) => {
        setSearch(val)
      }}
      pagination
      total={pagination?.total_records}
      limit={limit}
      activePage={page}
      setActivePage={(val) => {
        setPage(val)
      }}
      searchHeader={
        <div className='flex'>
          <button
            type="button"
            className="mr-2 inline-flex justify-center items-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-xs font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
            onClick={() => setModalOpen(true)}
          >
            <PlusIcon
              className=" h-3 w-3 mr-1"
              aria-hidden="true" />  Tambah Akun
          </button>
          <button
            type="button"
            className="mr-2 inline-flex justify-center items-center rounded-md border border-transparent bg-orange-100 px-2 py-1 text-xs font-medium text-orange-900 hover:bg-orange-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-orange-500 focus-visible:ring-offset-2"
            onClick={() => setModalTemplateOpen(true)}
          >
            <PlusIcon
              className=" h-3 w-3 mr-1"
              aria-hidden="true" />  Dari Template
          </button>
        </div>
      }
      headers={["#", "Nama", "Tipe", "Kategori/Grup", ""]} headerClasses={["w-10", "", "w-64", "w-64", "w-10"]} datasets={accounts.map(e => {
        let data: TableRecord = {
          cells: [
            { data: ((page - 1) * limit) + (accounts.indexOf(e) + 1) },
            { data: e.name, },
            { data: ACCOUNT_LABELS[e.type], },
            {
              data: <div>

                <dl className='text-xs'>
                  <dt >Kategori</dt>
                  <dd>{e.category}</dd>
                  <dt >Grup Arus Kas</dt>
                  <dd>{e.cashflow_group_label}</dd>
                  <dt >Subgrup Arus Kas</dt>
                  <dd>{e.cashflow_subgroup_label}</dd>
                </dl>
              </div>,
            },
            {
              data: e.is_deletable ? <TrashIcon className="w-5 text-red-600" onClick={() => {
                Swal.fire({
                  title: "Anda Yakin",
                  text: "Anda tidak akan dapat mengembalikan proses ini!",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Ya, Hapus!",
                  cancelButtonText: "Batal"
                }).then((result) => {
                  if (result.isConfirmed) {
                    setIsLoading(true)
                    deleteAccount(e.uuid)
                      .then(v => getAllAccounts(1))
                      .catch(error => Swal.fire("Perhatian", `${error}`, "error"))
                      .finally(() => setIsLoading(false))
                  }
                });
              }} /> : null, className: 'w-4 text-right'
            },
          ],
          className: "last:border-0 hover:bg-gray-50"
        }
        return data
      })} />
    <FormAccount isOpen={modalOpen} setIsOpen={setModalOpen} onSubmit={() => {
      setModalOpen(false)
      getAllAccounts()
    }} />
    <ModalAccountTemplate isOpen={modalTemplateOpen} setIsOpen={setModalTemplateOpen}  onSubmit={() => {
      setModalTemplateOpen(false)
      getAllAccounts()
    }} />
  </div>);
}
export default AccountSetting;