import { EditReturnReq, ReturnItem, ReturnItemReq, ReturnReq } from "../model/return";
import { PaginationReq } from "../model/pagination";
import { customFetch } from "../utils/helper";
import moment from "moment";
import { DateRange } from "rsuite/esm/DateRangePicker";



export let deleteReturn = async (uuid: string) => {
    return await customFetch(`Return/${uuid}`, {
        method: "DELETE",
    })
}
export let addReturn = async (req: ReturnReq) => {
    return await customFetch(`Return/`, {
        method: "POST",
        body: JSON.stringify(req)
    })
}
export let editReturn = async (uuid: string, req: EditReturnReq) => {
    return await customFetch(`Return/${uuid}`, {
        method: "PUT",
        body: JSON.stringify(req)
    })
}
export let addItemReturn = async (uuid: string, req: ReturnItemReq) => {
    return await customFetch(`Return/${uuid}/AddItem`, {
        method: "PUT",
        body: JSON.stringify(req)
    })
}
export let processReturn = async (uuid: string, remarks: string) => {
    return await customFetch(`Return/${uuid}/Process`, {
        method: "PUT",
        body: JSON.stringify({ remarks })
    })
}
export let processRemarks = async (uuid: string, remarks: string) => {
    return await customFetch(`Return/${uuid}/Remarks`, {
        method: "PUT",
        body: JSON.stringify({ remarks })
    })
}
export let processDelivery = async (uuid: string, remarks: string) => {
    return await customFetch(`Return/${uuid}/Delivery`, {
        method: "PUT",
        body: JSON.stringify({ remarks })
    })
}
export let processSettlement = async (uuid: string, remarks: string, account_return_id: string, account_asset_id: string, return_method: string) => {
    return await customFetch(`Return/${uuid}/Settlement`, {
        method: "PUT",
        body: JSON.stringify({ remarks, account_return_id, account_asset_id, return_method })
    })
}
export let deleteItemReturn = async (uuid: string, itemId: string) => {
    return await customFetch(`Return/${uuid}/DeleteItem/${itemId}`, {
        method: "DELETE",
    })
}
export let getReturnDetail = async (uuid: string) => {
    return await customFetch(`Return/${uuid}`, {
    })
}

export let getReturns = async (pagination: PaginationReq) => {
    var params: Record<string, string> = {
        page: pagination.page.toString(),
        limit: pagination.limit.toString(),

    };
    if (pagination.search) {
        params["search"] = pagination.search
    }

    if (pagination.type) {
        params["type"] = pagination.type
    }

    return await customFetch(`Return/?${new URLSearchParams(params)}`)
}