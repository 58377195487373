import PDFView from '../components/pdf_view';
import { Invoice } from '../model/invoice';
import { Company, CompanySimple } from '../model/company';
import { Merchant } from '../model/merchant';
import { getInvoiceDetail } from '../repositories/invoice';
import { getCompanyDetail } from '../repositories/company';
import { getMerchantDetail } from '../repositories/merchant';
import { money } from '../utils/number';
import { Font, StyleSheet, View, Text } from '@react-pdf/renderer';
import moment from 'moment';
import { useEffect, useState, type FC } from 'react';
import Moment from 'react-moment';
import { useParams, useSearchParams } from 'react-router-dom';

interface SalePrintProps {}

const SalePrint: FC<SalePrintProps> = ({}) => {
    const { saleId } = useParams()
    const [searchParams] = useSearchParams()
    const [invoice, setInvoice] = useState<Invoice | null>(null);
    const [merchant, setMerchant] = useState<Merchant | null>(null);
    const [company, setCompany] = useState<Company | null>(null);
    const params = useParams<{ uuid: string; }>()
    // const searchParams = useSearchParams()
    const address = searchParams.get('address')
    const payment = searchParams.get('payment')
    const header = searchParams.get('header')
    const notes = searchParams.get('notes')
    const print = searchParams.get('print')
    const download = searchParams.get('download')


    const styles = StyleSheet.create({
        defaultFont: {
            fontFamily: 'dot',
            letterSpacing: 0.8,
            fontSize: 9,
        },
        cellStyle: {
            border: 'solid',
            borderWidth: 0.4,
            paddingVertical: 4,
            paddingHorizontal: 8,
            borderColor: "black"
        }
    });

    useEffect(() => {
        getDetail()
        getCompanyDetail()
            .then(v => v.json())
            .then(v => setCompany(v.data))
        getMerchantDetail()
            .then(v => v.json())
            .then(v => setMerchant(v.data))

    }, []);

    const getDetail = () => {
        getInvoiceDetail(saleId!)
            .then(v => v.json())
            .then(v => setInvoice(v.data))
    }

    const renderHeader = () => {
        if (!header) return <View></View>
        if (header == "merchant") {
            return (<View style={{ display: 'flex', flexDirection: 'column', paddingHorizontal: 20, marginBottom: 10, paddingBottom: 5 }}>
                <Text style={{ ...styles.defaultFont, textAlign: 'left', fontSize: 11 }}>{merchant!.name}</Text>
                <Text style={{ ...styles.defaultFont, textAlign: 'left', fontSize: 10  }}>{merchant?.address ?? ""} {merchant?.phone}</Text>
            </View>)
        }

        return (<View style={{ display: 'flex', flexDirection: 'column', paddingHorizontal: 20, marginBottom: 10, paddingBottom: 5 }}>
            <Text style={{ ...styles.defaultFont, textAlign: 'left', fontSize: 11  }}>{company!.name}</Text>
            <Text style={{ ...styles.defaultFont, textAlign: 'left', fontSize: 10  }}>{company?.address ?? ""} {company?.phone}</Text>
        </View>)
    }


    return (invoice && <PDFView title={invoice?.invoice_number} print={print ? true : false} download={download ? true : false}>
        {renderHeader()}

        <View style={{ display: 'flex', flexDirection: 'column', paddingHorizontal: 20, marginBottom: 10, paddingBottom: 5 }}>
            <Text style={{ ...styles.defaultFont, fontSize: 12, textAlign: 'center', textDecoration: 'underline' }}>Faktur</Text>
            <Text style={{ ...styles.defaultFont, textAlign: 'center' }}>{invoice.customer_name}</Text>
            {address &&
                <Text style={{ ...styles.defaultFont, textAlign: 'center', }}>{invoice.customer_address} {invoice.customer_phone}</Text>
            }
        </View>
        <View style={{ display: 'flex', flexDirection: 'row', paddingHorizontal: 20, justifyContent: 'space-between' }}>
            <View style={{ display: "flex", flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                <Text style={{ ...styles.defaultFont, }}>Tgl : {moment(invoice.invoice_date).format("DD/MM/YYYY")}</Text>
            </View>
            <View style={{ display: "flex", flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', minWidth: 160  }}>
                <Text style={{ ...styles.defaultFont, }}>Jatuh Tempo : {moment(invoice.payment_due).format("DD/MM/YYYY")}</Text>
            </View>
        </View>
        <View style={{ display: 'flex', flexDirection: 'row', paddingHorizontal: 20, justifyContent: 'space-between' }}>
            <View style={{ display: "flex", flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                <Text style={{ ...styles.defaultFont, }}>No Faktur : {invoice.invoice_number}</Text>
            </View>
            <View style={{ display: "flex", flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', minWidth: 160 }}>
                <Text style={{ ...styles.defaultFont, }}>No PO. : {invoice.po_number}</Text>
            </View>
        </View>
        <View style={{ display: 'flex', flexDirection: 'row', paddingHorizontal: 20, justifyContent: 'space-between' }}>
            <View style={{ display: "flex", flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <Text style={{ ...styles.defaultFont, }}>Ref Surat Jalan : {invoice.ref_number}</Text>

            </View>
            
        </View>
        <View style={{ display: 'flex', flexDirection: 'column', paddingHorizontal: 20, marginBottom: 1, }}>
            <View style={{ borderBottomStyle: "solid", borderBottomWidth: 0.4, borderBottomColor: "black", marginTop: 4, paddingBottom: 5, marginBottom: 2 }}></View>
        </View>
        <View style={{ display: 'flex', flexDirection: 'column', paddingHorizontal: 20, marginBottom: 10 }}>
        <View style={{ display: 'flex', flexDirection: 'row' }}>
                <View style={{ ...styles.cellStyle, width: '5%', borderLeft: "none" }}><Text style={{ ...styles.defaultFont, }}>No</Text></View>
                <View style={{ ...styles.cellStyle, width: '30%' }}><Text style={{ ...styles.defaultFont, }}>Keterangan</Text></View>
                <View style={{ ...styles.cellStyle, width: '12%' }}><Text style={{ ...styles.defaultFont, textAlign: 'right' }}>Pkg</Text></View>
                <View style={{ ...styles.cellStyle, width: '13%' }}><Text style={{ ...styles.defaultFont, textAlign: 'right' }}>Qty</Text></View>
                <View style={{ ...styles.cellStyle, width: '20%' }}><Text style={{ ...styles.defaultFont, textAlign: 'right' }}>Harga</Text></View>
                <View style={{ ...styles.cellStyle, width: '20%', borderRight: "none" }}><Text style={{ ...styles.defaultFont, textAlign: 'right' }}>Jumlah</Text></View>
            </View>
            {invoice.items.map(e => <View key={e.uuid} style={{ display: 'flex', flexDirection: 'row', }}>
                <View style={{ ...styles.cellStyle, width: '5%', borderLeft: "none" }}><Text style={{ ...styles.defaultFont, }}>{invoice.items.indexOf(e) + 1}</Text></View>
                <View style={{ ...styles.cellStyle, width: '30%' }}><Text style={{ ...styles.defaultFont, }}>{e.description}</Text></View>
                <View style={{ ...styles.cellStyle, width: '12%' }}><Text style={{ ...styles.defaultFont, textAlign: 'right' }}>{money(e.qty_packaging)} {e.packaging_name}</Text></View>
                <View style={{ ...styles.cellStyle, width: '13%' }}><Text style={{ ...styles.defaultFont, textAlign: 'right' }}>{money(e.qty)} {e.unit_name}</Text></View>
                <View style={{ ...styles.cellStyle, width: '20%' }}><Text style={{ ...styles.defaultFont, textAlign: 'right' }}>{money(e.price)}</Text></View>
                <View style={{ ...styles.cellStyle, width: '20%', borderRight: "none" }}><Text style={{ ...styles.defaultFont, textAlign: 'right' }}>{money(e.amount)}</Text></View>
            </View>)}
            <View style={{ borderBottomStyle: "solid", borderBottomWidth: 0.4, borderBottomColor: "black", paddingBottom: 5, marginBottom: 2 }}></View>
            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginBottom: 5 }}>
                <Text style={{ ...styles.defaultFont, textAlign: 'right' }}>Total</Text>
                <Text style={{ ...styles.defaultFont, textAlign: 'right', marginLeft: 10 }}>{money(invoice.grand_total)}</Text>
            </View>
            {payment &&
                <View>
                    {invoice.payments.map(e => <View key={e.uuid} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <View style={{ display: "flex", flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                            <Text style={{ ...styles.defaultFont }}>{e.description}</Text>
                            <Text style={{ ...styles.defaultFont, marginLeft: 10 }}>({moment(e.date).format("DD/MM/YYYY")})</Text>
                        </View>
                        <View style={{ display: "flex", flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>

                            <Text style={{ ...styles.defaultFont, textAlign: 'right', marginLeft: 10 }}>{money(e.amount)}</Text>
                        </View>
                    </View>)}

                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <View style={{ display: "flex", flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                            <Text style={{ ...styles.defaultFont }}>Sisa Pembayaran</Text>

                        </View>
                        <View style={{ display: "flex", flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                            <Text style={{ ...styles.defaultFont, textAlign: 'right', marginLeft: 10, fontSize: 11 }}>{money(invoice.balance)}</Text>
                        </View>
                    </View>
                </View>
            }

            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 20 }}>
                <View style={{ paddingBottom: 50, borderBottom: 1, borderBottomColor: 'black', paddingHorizontal: 30 }}>
                    <Text style={{ ...styles.defaultFont, textAlign: 'right' }}>Tanda Terima</Text>
                </View>
                <View style={{ paddingBottom: 50, borderBottom: 1, borderBottomColor: 'black', paddingHorizontal: 30 }}>
                    <Text style={{ ...styles.defaultFont, textAlign: 'right' }}>Mengetahui</Text>
                </View>
            </View>
            {notes &&
                <View style={{ display: 'flex', flexDirection: 'row', marginTop: 40 }}>
                    <View style={{ paddingBottom: 50, }}>
                        <Text style={{ ...styles.defaultFont, fontSize: 10 }}>Catatan</Text>
                        <Text style={{ ...styles.defaultFont, fontSize: 8 }}>{invoice.notes.replace(/<\/?[^>]+(>|$)/g, "")}</Text>
                    </View>

                </View>
            }
        </View>

    </PDFView>);
}
export default SalePrint;