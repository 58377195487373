import { useContext, useEffect, useState, type FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingContext } from '../objects/loading_context';
import { Invoice, toRequest } from '../model/invoice';
import { Customer } from '../model/customer';
import useRoveFocus from '../hooks/useRaveFocus';
import { Company } from '../model/company';
import { SelectOption, colourStyles } from '../utils/style';
import { Item, ItemReq } from '../model/item';
import { Account } from '../model/account';
import { getPermissions, parseVarian, setNullString } from '../utils/helper';
import { getCompanyDetail } from '../repositories/company';
import { getAccounts, getCashflowSubGroup } from '../repositories/account';
import { addInvoicePayment, addItemInvoice, deleteItemInvoice, downloadInvoice, getInvoiceDetail, getReceipt, releaseInvoice, unreleaseInvoice, updateInvoice, updateItemInvoice } from '../repositories/invoice';
import Swal from 'sweetalert2';
import ModalSearch from '../components/modals/modal_search';
import { getCustomers } from '../repositories/customer';
import Avatar from '../components/avatar';
import { COLORS, TAX_METHODS } from '../objects/constants';
import Moment from 'react-moment';
import moment from 'moment';
import { Badge, Checkbox, DatePicker, Drawer, Tooltip, Whisper } from 'rsuite';
import { Bars3BottomRightIcon, CloudArrowDownIcon, FolderIcon, InformationCircleIcon, LockClosedIcon, LockOpenIcon, PaperAirplaneIcon, PlusIcon, PrinterIcon, TrashIcon, WalletIcon } from '@heroicons/react/24/outline';
import RecordItem from '../components/record_item';
import { money } from '../utils/number';
import { Product, ProductStoreStock } from '../model/product';
import { Store } from '../model/store';
import DashboardLayout from '../components/dashboard';
import Back from '../components/back';
import ModalAccount from '../components/modals/modal_account';
import ModalProduct from '../components/modals/modal_product';
import ModalEditor from '../components/modals/modal_editor';
import ModalCustom from '../components/modals/modal_custom';
import InlineForm from '../components/inline_form';
import CurrencyInput from 'react-currency-input-field';
import Select, { SingleValue } from 'react-select';
import ModalTax from '../components/modals/modal_tax';
import { Switch } from '@headlessui/react';
import { printReciept } from '../utils/print';
import { TfiReceipt } from "react-icons/tfi";


interface SaleDetailProps {}

const SaleDetail: FC<SaleDetailProps> = ({}) => {
    const nav = useNavigate()
    const { saleId } = useParams()
    let { isLoading, setIsLoading } = useContext(LoadingContext);
    const [invoice, setInvoice] = useState<Invoice | null>(null);
    const [mounted, setMounted] = useState(false);
    const [permissions, setPermissions] = useState<string[]>([]);
    const [modalCustomerOpen, setModalCustomerOpen] = useState(false);
    const [customers, setCustomers] = useState<Customer[]>([]);
    const [focus, setFocus] = useRoveFocus(customers.length ?? 0);
    const [accountSourceOpen, setAccountSourceOpen] = useState(false);
    const [accountDestinationOpen, setAccountDestinationOpen] = useState(false);
    const [editorOpen, setEditorOpen] = useState(false);
    const [content, setContent] = useState("");
    const [editing, setEditing] = useState(false);
    const [editable, setEditable] = useState(false);
    const [modalProductOpen, setModalProductOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState<Item | null>(null);
    const [selectedItems, setSelectedItems] = useState<Item[]>([]);
    const [invoiceItems, setInvoiceItems] = useState<Item[]>([]);
    const [openWithHeader, setOpenWithHeader] = useState(false);
    const [modalPaymentOpen, setModalPaymentOpen] = useState(false);
    const [assetAccounts, setAssetAccounts] = useState<Account[]>([]);
    const [date, setDate] = useState<Date>(new Date());
    const [inputAmountPayment, setInputAmountPayment] = useState(0);
    const [mountedInputPayment, setMountedInputPayment] = useState(true);
    const [inputDescription, setInputDescription] = useState("");
    const [assetSelect, setAssetSelect] = useState<SelectOption[]>([]);
    const [inputAssetId, setInputAssetId] = useState("");
    const [inputAsset, setInputAsset] = useState<SelectOption | null>();
    const [modalPassword, setModalPassword] = useState(false);
    const [password, setPassword] = useState("");
    const [modalDownloadOpen, setModalDownloadOpen] = useState(false);
    const [hideTax, setHideTax] = useState(false);
    const [hideSecondaryTax, setHideSecondaryTax] = useState(false);
    const [hideTotalBeforeTax, setHideTotalBeforeTax] = useState(false);
    const [modalTaxOpen, setModalTaxOpen] = useState(false);
    const [modalTaxSecondaryOpen, setModalTaxSecondaryOpen] = useState(false);
    const [company, setCompany] = useState<Company | null>(null);
    const [printHeader, setPrintHeader] = useState("");
    const [printShowPayment, setPrintShowPayment] = useState(false);
    const [printShowAddress, setPrintShowAddress] = useState(false);
    const [printShowNotes, setPrintShowNotes] = useState(false);
    const [printDownload, setPrintDownload] = useState(false);

    const [modalDiscountOpen, setModalDiscountOpen] = useState(false);
    const [inputDiscountPercent, setInputDiscountPercent] = useState(0);
    const [inputDiscountAmount, setInputDiscountAmount] = useState(0);
    const [discType, setDiscType] = useState("percent");
    const [modalItemNoteEditor, setModalItemNoteEditor] = useState(false);





    useEffect(() => {
        getPermissions().then(v => {
            setPermissions(v)
        })
        
        setMounted(true)
        getCompanyDetail()
            .then(v => v.json())
            .then(v => setCompany(v.data))
    }, []);

    useEffect(() => {
        if (!mounted) return
        getDetail()
        getCashflowSubGroup()
        getAccounts({ page: 1, limit: 10, type: "Asset", cashflowGroup: "current_asset" })
            .then(v => v.json())
            .then(v => {
                setAssetAccounts(v.data)
            })


    }, [mounted]);

    useEffect(() => {
        let v: { value: string; label: string; }[] = []
        for (let index = 0; index < assetAccounts.length; index++) {
            const element = assetAccounts[index];
            v.push({ value: element.uuid!, label: element.name })
        }
        setAssetSelect(v)
    }, [assetAccounts]);

    useEffect(() => {

    }, [inputAmountPayment]);


    useEffect(() => {
        if (!invoice) return
        setEditable(!((invoice?.locked ?? false) || (invoice.balance == 0 && invoice?.grand_total > 0)))
        setInvoiceItems(invoice.items)
    }, [invoice]);


    const getDetail = async () => {
        try {
            setIsLoading(true)
            var resp = await getInvoiceDetail(saleId!)
            var respJson = await resp.json()

            setInvoice(respJson.data)


        } catch (error) {
            Swal.fire("Perhatian", `${error}`, "error")
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {

    }, []);

    const update = async () => {
        try {

            setIsLoading(true)
            await updateInvoice(invoice!.uuid, toRequest(invoice!))
            setEditing(false)
            getDetail()
        } catch (error) {
            Swal.fire("Perhatian", `${error}`, "error")
        } finally {
            setIsLoading(false)
        }
    }

    const modalCustomer = () => (
        <ModalSearch isOpen={modalCustomerOpen} setIsOpen={setModalCustomerOpen} onChange={(val) => {
            getCustomers({ page: 1, limit: 10, search: val })
                .then(v => v.json())
                .then(v => {
                    setCustomers(v.data)

                })
        }} onClose={() => {
            console.log("ON CLOSE")
        }} >
            <ul role="list" className="divide-y divide-gray-100">
                {customers.map((v, index) => (
                    <li key={v.uuid} onClick={() => {
                        let change = {
                            ...invoice!,
                            customer_id: v.uuid,
                            customer_name: v.name,
                            customer_address: v.address,
                            customer_phone: v.phone,
                            customer_fax: v.fax,
                            customer_email: v.email,
                            customer_contact_person: v.contact_person,
                        }
                        setInvoice(change)

                        setModalCustomerOpen(false)
                        setEditing(true)
                    }}
                        className="flex items-center gap-x-4 px-3 py-5 hover:bg-gray-600 outline-none cursor-pointer text-gray-900 hover:text-white"
                    >
                        <Avatar marginRight={0} name={v.name} url={v.logo ?? ''} style={{ backgroundColor: COLORS[Math.floor(Math.random() * COLORS.length)].color }} />
                        <div className="min-w-0">
                            <p className="text-sm font-semibold leading-6   ">{v.name}</p>
                            {v.address.length > 0 && <p className="-mt-0.5 truncate text-xs leading-5  " >{v.address}</p>}
                            {v.phone.length > 0 && <p className="-mt-0.5 truncate text-xs leading-5  " >Telp. {v.phone}</p>}
                            {v.email.length > 0 && <p className="-mt-0.5 truncate text-xs leading-5  " >Email. {v.email}</p>}
                        </div>
                    </li>
                ))}
            </ul>
        </ModalSearch>

    )

    const invoiceDetail = () => (<div className='flex flex-col col-span-3'>
        <div className="mb-5  flex flex-col col-span-3 rounded-xl py-6 px-6 transition-all hover:shadow-lg sm:p-4 lg:px-4 xl:px-6 bg-white cursor-pointer">
            <div className='flex mb-4'>
                <div className='w-1/2 flex-col flex mr-2'>
                    <p className=' text-gray-500  '>No. Faktur</p>
                    <input disabled={!editable} className='editable  text-2xl w-full ' value={invoice?.invoice_number ?? ""} onChange={(val) => {
                        let change = {
                            ...invoice!,
                            invoice_number: val.target.value
                        }
                        setInvoice(change)
                        setEditing(true)

                    }} />
                </div>
                <div className='w-1/2 flex-col flex'>
                    <p className=' text-gray-500  '>No. PO</p>
                    <input disabled={!editable} className='editable  text-xl w-full ' value={invoice?.po_number ?? "-"} onChange={(val) => {
                        let change = {
                            ...invoice!,
                            po_number: val.target.value
                        }
                        setInvoice(change)
                        setEditing(true)
                    }} />
                </div>
            </div>
            <div className='flex mb-4'>
                <div className='w-1/2 flex-col flex mr-2'>
                    <p className=' text-gray-500  '>Tgl. Faktur</p>
                    {!editable ? <Moment className="text-lg" format="DD MMM YYYY">{new Date(invoice?.invoice_date ?? new Date())}</Moment> : <DatePicker disabled={!editable} as={"div"} className='input-flat' value={new Date(invoice?.invoice_date ?? new Date())} format="dd MMM yyyy" onChangeCalendarDate={(date) => {
                        let change = {
                            ...invoice!,
                            invoice_date: moment(date).format("YYYY-MM-DD")
                        }
                        setInvoice(change)

                    }} />}

                </div>
                <div className='w-1/2 flex-col flex'>
                    <p className=' text-gray-500  '>Jatuh Tempo</p>
                    {!editable ? <Moment className="text-lg" format="DD MMM YYYY">{new Date(invoice?.payment_due ?? new Date())}</Moment> : <DatePicker disabled={!editable} as={"div"} className='input-flat' value={new Date(invoice?.payment_due ?? new Date())} format="dd MMM yyyy" onChangeCalendarDate={(date) => {
                        let change = {
                            ...invoice!,
                            payment_due: moment(date).format("YYYY-MM-DD")
                        }
                        setInvoice(change)

                    }} />}

                </div>
            </div>
            {invoice != null ? <div className='flex mb-4'>
                <div className='w-1/2 flex-col  mr-2'>
                    <p className=' text-gray-500  '>Status Pembayaran</p>
                    {new Date() > new Date(invoice!.payment_due) && invoice!.balance > 0 ? <Badge className="py-1 px-4 text-center" content="Terlambat" color="red" /> : null}
                    {new Date() < new Date(invoice!.payment_due) && invoice!.balance > 0 && invoice!.balance == invoice!.grand_total ? <Badge className="py-1 px-4 text-center" content="Belum Dibayar" color="orange" /> : null}
                    {new Date() < new Date(invoice!.payment_due) && invoice!.balance > 0 && invoice!.balance != invoice!.grand_total ? <Badge className="py-1 px-4 text-center" content="Dibayar Sebagian" color="violet" /> : null}
                    {invoice!.paid && invoice!.balance == 0 ? <Badge className="py-1 px-4 text-center" content="Lunas" color="green" /> : null}

                </div>
                <div className='w-1/2 flex-col '>
                    <p className=' text-gray-500  '>Status Faktur</p>
                    {(invoice?.locked ?? false) ? <Badge className="py-1 px-4 text-center" content={
                        <div className='flex items-center'>
                            <LockClosedIcon className='text-white mr-1 w-3' />
                            <span>Dirilis</span>
                        </div>
                    } color="blue" /> : <Badge className="py-1 px-4 text-center" content={
                        <div className='flex items-center'>
                            <LockOpenIcon className='text-white mr-1 w-3' />
                            <span>Belum Dirilis</span>
                        </div>
                    } color="cyan" />}
                </div>
            </div> : null}
            <div className='flex mb-2 items-center mt-8 justify-between border-b py-2'>
                <p className='text-xl'>Detail Faktur</p>
                <div>
                    {editable &&
                        <button
                            type="button"
                            className="inline-flex items-center justify-center rounded-md border bg-white  px-4 py-2 text-sm font-medium text-gray-400 hover:text-gray-900 hover:bg-gray-100 "
                            onClick={() => setModalProductOpen(true)}>
                            <PlusIcon
                                className=" h-3 w-3 mr-2"
                                aria-hidden="true" />  Tambah
                        </button>
                    }
                    {selectedItems.length > 0 &&
                        <button
                            type="button"
                            className="inline-flex ml-2 items-center justify-center rounded-md border bg-white  px-4 py-2 text-sm font-medium text-red-400 hover:text-red-900 hover:bg-red-100 "
                            onClick={async () => {
                                Swal.fire({
                                    title: "Anda Yakin",
                                    text: "Anda tidak akan dapat mengembalikan proses ini!",
                                    icon: "warning",
                                    showCancelButton: true,
                                    confirmButtonColor: "#3085d6",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: "Ya, Hapus!",
                                    cancelButtonText: "Batal"
                                }).then(async (result) => {

                                    if (result.isConfirmed) {
                                        for (const key in selectedItems) {
                                            if (Object.prototype.hasOwnProperty.call(selectedItems, key)) {
                                                const element = selectedItems[key];
                                                await deleteItemInvoice(invoice!.uuid, element.uuid)
                                            }
                                        }
                                        getDetail()
                                        setSelectedItems([])
                                    }
                                });

                            }}>
                            <TrashIcon
                                className=" h-3 w-3 mr-2"
                                aria-hidden="true" />  Hapus
                        </button>
                    }
                </div>
            </div>
            {invoice?.items.length == 0 ? <div className='flex justify-center text-base text-center py-5'>
                Belum ada item
            </div> :
                <div className=" overflow-x-auto">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-900 ">
                        <thead className="text-xs text-gray-700 uppercase border-t border-b ">
                            <tr>
                                <th scope="col" className="px-6 py-3 w-3">
                                    #
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Keterangan
                                </th>
                                <th scope="col" className="px-6 py-3 text-right">
                                    Qty
                                </th>
                                <th scope="col" className="px-6 py-3 ">
                                    Unit
                                </th>
                                {company?.product_add_on_setting?.jaraya_trading_setting?.user_packaging_stock && <th scope="col" className="px-6 py-3 text-right">
                                    Kemasan
                                </th>}
                                <th scope="col" className="px-6 py-3 text-right">
                                    Harga
                                </th>


                                <th scope="col" className="px-6 py-3 text-right">
                                    Jumlah
                                </th>
                                <th scope="col" className="px-6 py-3 text-right">
                                </th>
                            </tr>
                        </thead>
                        <tbody >
                            {invoiceItems.map(e => <RecordItem
                                userPackagingStock={company?.product_add_on_setting?.jaraya_trading_setting?.user_packaging_stock ?? false}
                                permissions={permissions}
                                checked={selectedItems.includes(e)}
                                onChecked={(val) => {
                                    if (!val) {
                                        setSelectedItems([...selectedItems.filter((i) => i != e)])
                                    } else {
                                        setSelectedItems([...selectedItems, e])
                                    }
                                }}
                                onChange={(item) => {
                                    updateItem(item)
                                }}
                                // onClickDesc={() => {
                                //     setSelectedItem(e)
                                //     setModalProductOpen(true)
                                // }}
                                addNotes={() => {
                                    setSelectedItem(e)
                                    setModalItemNoteEditor(true)
                                }}
                                addDiscount={() => {
                                    setSelectedItem(e)
                                    setInputDiscountAmount(e.discount_amount)
                                    setInputDiscountPercent(e.discount_percent)
                                    if (e.discount_percent > 0 || (e.discount_percent == e.discount_amount && e.discount_percent == 0)) setDiscType("percent")
                                    if (e.discount_amount > 0) setDiscType("amount")
                                    setModalDiscountOpen(true)
                                }}
                                onDelete={() => {
                                    deleteItemInvoice(invoice!.uuid, e.uuid).then(v => getDetail())
                                }}
                                parentId={invoice?.uuid}
                                locked={invoice?.locked ?? false}
                                key={e.uuid}
                                item={e}
                                no={invoiceItems.indexOf(e) + 1} />)}

                        </tbody>
                        <tfoot className="text-xs text-gray-700 uppercase border-t border-b ">
                            {(invoice?.total_before_tax ?? 0) != (invoice?.grand_total ?? 0) &&
                                <tr className='border-b'>
                                    <th scope="col" colSpan={company?.product_add_on_setting?.jaraya_trading_setting?.user_packaging_stock ? 6 : 5} className="px-6 font-normal text-sm py-3 text-left">
                                        Sub Total
                                    </th>
                                    <th className='px-6 py-3 text-right text-base'>
                                        {money(invoice?.total_before_tax ?? 0)}
                                    </th>
                                    <th className='px-6 py-3'>

                                    </th>
                                </tr>
                            }
                            {(invoice?.total_discount_amount ?? 0) > 0 &&
                                <tr className='border-b'>
                                    <th scope="col" colSpan={company?.product_add_on_setting?.jaraya_trading_setting?.user_packaging_stock ? 6 : 5} className="px-6 font-normal text-sm py-3 text-left">
                                        Total Diskon
                                    </th>
                                    <th className='px-6 py-3 text-right text-base'>
                                        {money(invoice?.total_discount_amount ?? 0)}
                                    </th>
                                    <th className='px-6 py-3'>

                                    </th>
                                </tr>
                            }
                            {(invoice?.total_tax_amount ?? 0) > 0 &&
                                <tr className='border-b'>
                                    <th scope="col" colSpan={company?.product_add_on_setting?.jaraya_trading_setting?.user_packaging_stock ? 6 : 5} className="px-6 font-normal text-sm py-3 text-left">
                                        {invoice?.tax_name}
                                    </th>
                                    <th className='px-6 py-3 text-right text-base'>
                                        {money(invoice?.total_tax_amount ?? 0)}
                                    </th>
                                    <th className='px-6 py-3'>

                                    </th>
                                </tr>
                            }

                            {(invoice?.total_secondary_tax_amount ?? 0) > 0 &&
                                <tr className='border-b'>
                                    <th scope="col" colSpan={company?.product_add_on_setting?.jaraya_trading_setting?.user_packaging_stock ? 6 : 5} className="px-6 font-normal text-sm py-3 text-left">
                                        {invoice?.secondary_tax_name}
                                    </th>
                                    <th className='px-6 py-3 text-right text-base'>
                                        {money(invoice?.total_secondary_tax_amount ?? 0)}
                                    </th>
                                    <th className='px-6 py-3'>

                                    </th>
                                </tr>
                            }
                            <tr className='border-b'>
                                <th scope="col" colSpan={company?.product_add_on_setting?.jaraya_trading_setting?.user_packaging_stock ? 6 : 5} className="px-6 py-3 text-left text-base">
                                    Grand Total
                                </th>
                                <th className='px-6 py-3 text-right text-base'>
                                    {money(invoice?.grand_total ?? 0)}
                                </th>
                                <th className='px-6 py-3'>

                                </th>
                            </tr>
                            <tr className='border-b'>
                                <th scope="col" colSpan={company?.product_add_on_setting?.jaraya_trading_setting?.user_packaging_stock ? 6 : 5} className="px-6 font-normal text-sm py-3 text-left">
                                    Pembayaran
                                </th>
                                <th className='px-6 py-3 text-right text-base'>
                                    {money(invoice?.paid ?? 0)}
                                </th>
                                <th className='px-6 py-3'>

                                </th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            }

        </div>
        {(invoice?.payments ?? []).length > 0 &&
            <div className="mb-5  flex flex-col col-span-3 rounded-xl py-6 px-6 transition-all hover:shadow-lg sm:p-4 lg:px-4 xl:px-6 bg-white cursor-pointer">
                <div className=' flex-col'>
                    <div className='mb-3 flex  flex-col' >
                        <p className=' text-gray-900 text-lg'>Riwayat Pembayaran</p>

                    </div>
                    <table className="w-full text-sm text-left rtl:text-right text-gray-900">
                        <thead className="text-xs text-gray-700 uppercase border-t border-b ">
                            <tr>
                                <th scope="col" className="px-6 py-3 w-3">
                                    #
                                </th>
                                <th scope="col" className="px-6 py-3  w-32">Tgl</th>
                                <th scope="col" className="px-6 py-3">
                                    Keterangan
                                </th>

                                <th scope="col" className="px-6 py-3 text-right">
                                    Jumlah
                                </th>

                                <th scope="col" className="px-6 py-3 text-right w-20">
                                </th>
                            </tr>
                        </thead>
                        <tbody >
                            {(invoice?.payments ?? []).map(e => <tr key={e.uuid} className=' hover:bg-gray-50' >
                                <td className="px-6 py-4 flex items-center">
                                    {(invoice?.payments ?? []).indexOf(e) + 1}
                                </td>
                                <td className="px-6 py-4 ">
                                    <Moment format='DD MMM YYYY'>{e.date}</Moment>
                                </td>
                                <td className="px-6 py-4">
                                    {e.description}
                                </td>
                                <td className="px-6 py-4 text-right">
                                    {money(e.amount)}
                                </td>
                                <td className="px-6 py-4 text-right ">
                                    <CloudArrowDownIcon className='w-5 text-blue-300 hover:text-blue-600' onClick={() => {
                                        getReceipt(invoice!.uuid!, e.uuid)
                                            .then(v => v.blob())
                                            .then(v => {
                                                console.log(v)
                                                var fileURL = window.URL.createObjectURL(v);
                                                window.open(fileURL);

                                            })
                                    }} />
                                </td>
                            </tr>)}

                        </tbody>

                    </table>
                </div>
            </div>
        }
    </div>)
    const invoiceInfo = () => (
        <div>
            {(invoice?.grand_total ?? 0) > 0 && (!(invoice!.paid && invoice!.balance == 0)) &&
                <div className="mb-5 col-span-1 rounded-xl py-6 px-6 transition-all hover:shadow-lg sm:p-4 lg:px-4 xl:px-6 bg-white cursor-pointer">
                    <div className=' flex-col'>
                        {(invoice?.balance ?? 0) > 0 &&
                            <div className=' flex flex-col'>
                                <p className=' m-0 text-gray-500'>Sisa Pembayaran</p>
                                <p className=' m-0 text-4xl font-medium'>{money(invoice?.balance)}</p>
                                <p className=' m-0 text-gray-500 text-xl leading-6'>{invoice?.counted}</p>
                                <button
                                    type="button"
                                    className="inline-flex transition-all mt-4 duration-500 justify-center rounded-md border border-transparent bg-orange-600 px-4 py-2 text-sm font-medium text-white hover:bg-orange-900 focus:outline-none focus-visible:ring-2 focus-visible:ring-orange-500 focus-visible:ring-offset-2"
                                    onClick={() => {
                                        if (invoice?.locked ?? false) {

                                            setModalPaymentOpen(true)
                                        } else {
                                            Swal.fire({
                                                title: "Anda Yakin",
                                                text: "Faktur ini belum dirilis, anda yakin akan menambahkan pembayaran?",
                                                icon: "warning",
                                                showCancelButton: true,
                                                confirmButtonColor: "#3085d6",
                                                cancelButtonColor: "#d33",
                                                confirmButtonText: "Ya, Tampilkan Pembayaran!",
                                                cancelButtonText: "Batal"
                                            }).then(async (result) => {

                                                if (result.isConfirmed) {
                                                    setModalPaymentOpen(true)
                                                }
                                            });
                                        }

                                    }
                                    }>
                                    <WalletIcon
                                        className=" h-5 w-5 mr-2"
                                        aria-hidden="true" />  Pembayaran
                                </button>
                            </div>
                        }

                    </div>
                </div>
            }

            <div className="mb-5 col-span-1 rounded-xl py-6 px-6 transition-all hover:shadow-lg sm:p-4 lg:px-4 xl:px-6 bg-white cursor-pointer">
                <div className=' flex-col'>
                    <div className='mb-3 flex  flex-col  hover:bg-gray-50' onClick={!editable ? () => { } : () => {
                        getCustomers({ page: 1, limit: 10, search: "" })
                            .then(v => v.json())
                            .then(v => {
                                setCustomers(v.data)
                            })
                        setModalCustomerOpen(true)
                    }}>
                        <p className=' text-gray-500'>Konsumen</p>
                        <div className=' text-lg  w-full  -mt-1' >
                            {invoice?.customer_name ?? "-"}
                        </div>
                        <p className=' w-5/6 leading-5'>{invoice?.customer_address} {invoice?.customer_phone ? `Telp. ${invoice?.customer_phone}` : ''}</p>
                        {invoice?.customer_email ? <p className=' -mt-0.5 w-5/6 leading-4'>  email: {invoice?.customer_email}</p> : null}
                        {invoice?.customer_fax ? <p className=' -mt-0.5 w-5/6 leading-4'>  fax: {invoice?.customer_fax}</p> : null}
                        {invoice?.customer_name.length == 0 && <div className='block h-5'>No Data</div>}
                    </div>


                </div>
            </div>

            <div className="mb-5 col-span-1 rounded-xl py-6 px-6 transition-all hover:shadow-lg sm:p-4 lg:px-4 xl:px-6 bg-white cursor-pointer">
                <div className=' flex-col'>
                    <div className='mb-3 flex  flex-col  hover:bg-gray-50' onClick={!editable ? () => { } : () => setAccountSourceOpen(true)}>
                        <p className=' text-gray-500 '>Akun Pendapatan</p>
                        <div className=' text-lg  w-full -mt-1 ' >
                            {invoice?.account_income?.name ?? "-"}
                        </div>
                        {invoice?.account_income?.name.length == 0 && <div className='block h-5'>No Data</div>}
                    </div>

                    <div className='mb-3 flex  flex-col  hover:bg-gray-50' onClick={!editable ? () => { } : () => setAccountDestinationOpen(true)}>
                        <p className=' text-gray-500 '>Akun Aset / Piutang</p>
                        <div className=' text-lg  w-full -mt-1 ' >
                            {invoice?.account_receivable?.name ?? "-"}
                        </div>
                        {invoice?.account_receivable?.name.length == 0 && <div className='block h-5'>No Data</div>}
                    </div>

                    <div className='mb-3 flex  flex-col  hover:bg-gray-50' onClick={!editable ? () => { } : () => setModalTaxOpen(true)}>
                        <p className=' text-gray-500 '>Pajak</p>
                        <div className=' text-lg  w-full -mt-1 ' >
                            {invoice?.tax_name ?? "-"}
                        </div>
                        {invoice?.tax_name.length ? <div><p className=' text-gray-500 '>Metode</p>
                            <div className=' text-sm  w-full -mt-1 ' >
                                {TAX_METHODS.find(e => e.method == invoice?.tax_method)?.description ?? ""}
                            </div>
                        </div> : null
                        }

                    </div>
                    {invoice?.tax_id &&
                        <div className='mb-3 flex  flex-col  hover:bg-gray-50' onClick={!editable ? () => { } : () => setModalTaxSecondaryOpen(true)}>
                            <p className=' text-gray-500 '>Pajak #2</p>
                            <div className=' text-lg  w-full -mt-1 ' >
                                {invoice?.secondary_tax_name ?? "-"}
                            </div>
                            {invoice?.secondary_tax_name.length ? <div><p className=' text-gray-500 '>Metode</p>
                                <div className=' text-sm  w-full -mt-1 ' >
                                    {TAX_METHODS.find(e => e.method == invoice?.secondary_tax_method)?.description ?? ""}
                                </div></div> : null}

                        </div>
                    }

                    <div className='mb-3 flex  flex-col  hover:bg-gray-50'>
                        <p className=' text-gray-500 ' onClick={!editable ? () => { } : () => setEditorOpen(true)}>Catatan</p>
                        <div dangerouslySetInnerHTML={{ __html: invoice?.notes ?? "-" }}></div>
                    </div>
                </div>
            </div>
        </div>)

    const addProduct = async (product: Product, store: Store, varians: ProductStoreStock[]) => {
        if (varians.length == 0) {
            addItem({
                price: (product.prices ?? []).length ? product.prices[0].amount : 1000,
                qty: 1,
                qty_packaging: 0,
                discount_percent: 0,
                discount_amount: 0,
                description: product.name,
                notes: '',
                product_id: setNullString(product.uuid),
                varian_id: setNullString(""),
                store_id: setNullString(store.uuid),
                service_id: setNullString(""),
                product_serial_number_id: setNullString(""),
                unit_id: setNullString(product.unit_id),
                is_product: true,
                is_service: false,
                account_income_id: setNullString(invoice?.account_income_id)
            })
        } else {
            for (const key in varians) {
                if (Object.prototype.hasOwnProperty.call(varians, key)) {
                    const element = varians[key];
                    await addItem({
                        price: product.prices.length ? product.prices[0].amount : 1000,
                        qty: 1,
                        qty_packaging: 0,
                        discount_percent: 0,
                        discount_amount: 0,
                        description: `${product.name}${element.varian_id == "" ? '' : ' ' + parseVarian(element.varian_data)}`,
                        notes: '',
                        product_id: setNullString(product.uuid),
                        varian_id: setNullString(element.varian_id),
                        store_id: setNullString(store.uuid),
                        service_id: setNullString(""),
                        product_serial_number_id: setNullString(""),
                        unit_id: setNullString(product.unit_id),
                        is_product: true,
                        is_service: false,
                        account_income_id: setNullString(invoice?.account_income_id)
                    })

                }
            }
        }

    }
    const addNonProduct = (val: string) => {
        addItem({
            price: 1000,
            qty: 1,
            qty_packaging: 0,
            discount_percent: 0,
            discount_amount: 0,
            description: val,
            notes: '',
            product_id: setNullString(""),
            varian_id: setNullString(""),
            store_id: setNullString(""),
            service_id: setNullString(""),
            product_serial_number_id: setNullString(""),
            unit_id: setNullString(""),
            is_product: false,
            is_service: false,
            account_income_id: setNullString(invoice?.account_income_id)
        })
    }

    const addItem = async (item: ItemReq) => {
        // console.log(item)
        // return
        try {
            setIsLoading(true)
            var resp = await addItemInvoice(saleId!, item)
            var respJson = await resp.json()
            await getDetail()
        } catch (error) {
            Swal.fire("Perhatian", `${error}`, "error")
        } finally {
            setIsLoading(false)
        }
    }
    const updateItem = async (item: Item) => {
        try {
            setIsLoading(true)
            var resp = await updateItemInvoice(saleId!, item.uuid, {
                price: item.price,
                qty: item.qty,
                qty_packaging: item.qty_packaging,
                discount_percent: item.discount_percent,
                discount_amount: item.discount_amount,
                description: item.description,
                notes: item.notes,
                product_id: setNullString(item.product_id),
                varian_id: setNullString(item.varian_id),
                store_id: setNullString(item.store_id),
                service_id: setNullString(item.service_id),
                product_serial_number_id: setNullString(item.product_serial_number_id),
                unit_id: setNullString(item.unit_id),
                is_product: item.is_product,
                is_service: item.is_service,
                account_income_id: setNullString(item.account_income_id)
            })
            var respJson = await resp.json()
            await getDetail()
        } catch (error) {
            Swal.fire("Perhatian", `${error}`, "error")
        } finally {
            setIsLoading(false)
        }
    }

    const savePayment = async () => {
        if ((invoice?.balance ?? 0) - inputAmountPayment < 0) {
            Swal.fire("Perhatian", `Pembayaran melebihi sisa`, "error")
            return
        }
        if (inputAssetId == "") {
            Swal.fire("Perhatian", `Akun kas belum di isi`, "error")
            return
        }
        if (inputDescription == "") {
            Swal.fire("Perhatian", `Keterangan belum di isi`, "error")
            return
        }

        try {
            setIsLoading(true)
            var resp = await addInvoicePayment(saleId!, {
                description: inputDescription,
                notes: '',
                credit: inputAmountPayment,
                debit: 0,
                amount: inputAmountPayment,
                date: date.toISOString(),
                is_income: true,
                is_expense: false,
                account_source_id: setNullString(invoice?.account_receivable_id),
                account_destination_id: setNullString(inputAssetId),
                invoice_id: setNullString(invoice?.uuid),
                bill_id: setNullString(""),
                is_invoice_payment: true,
                is_bill_payment: false,
            })
            var respJson = await resp.json()
            setModalPaymentOpen(false)
            await getDetail()
        } catch (error) {
            Swal.fire("Perhatian", `${error}`, "error")
        } finally {
            setIsLoading(false)
        }
    }

    return (<DashboardLayout permission="read_invoice">
        <div className="p-6">
            <div className='flex justify-between items-center'>
                <h1 className="text-4xl flex"> <Back className="mr-2" onClick={() => nav(-1)} /> {invoice?.invoice_title}</h1>
                <div className='flex'>
                    {editing && <div className="">
                        <button
                            type="button"
                            className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                            onClick={update}>
                            <FolderIcon
                                className=" h-5 w-5 mr-2"
                                aria-hidden="true" />  Simpan
                        </button>
                    </div>}
                    <Bars3BottomRightIcon className='w-10 ml-2 text-gray-400 cursor-pointer hover:text-gray-600' onClick={() => setOpenWithHeader(true)} />
                </div>

            </div>
            <div className="mt-6 grid gap-x-8 gap-y-4 sm:gap-y-0 xl:grid-cols-4 lg:grid-cols-4 sm:grid-cols-2 md:grid-cols-2 ">
                {invoiceDetail()}
                {invoiceInfo()}
            </div>
        </div>
        {modalCustomer()}
        <ModalAccount accountType='Income' isOpen={accountSourceOpen} setIsOpen={setAccountSourceOpen} onClick={(account) => {
            let change = {
                ...invoice!,
                account_income: account,
                account_income_id: account.uuid,
            }
            setInvoice(change)

        }} onClose={() => {
            setEditing(true)
        }} />
        <ModalAccount accountType='Receivable,Asset' cashflowGroup='current_asset,operating' isOpen={accountDestinationOpen} setIsOpen={setAccountDestinationOpen} onClick={(account) => {
            let change = {
                ...invoice!,
                account_receivable: account,
                account_receivable_id: account.uuid,
            }
            setInvoice(change)

        }} onClose={() => {
            setEditing(true)
        }} />
        <ModalProduct disableCreate isSell={true} isBuy={false} selectStore={true} isOpen={modalProductOpen} setIsOpen={setModalProductOpen} onClick={(product, store, varians) => {
            if (selectedItem == null) {
                addProduct(product, store!, varians ?? [])
                setModalProductOpen(false)
            }

        }} onClose={() => {
            setEditing(true)
            setSelectedItem(null)
        }}
            onSubmit={(val) => {
                if (selectedItem == null) {
                    addNonProduct(val)
                    setModalProductOpen(false)
                }
            }}
        />
        <ModalEditor isOpen={editorOpen} setIsOpen={setEditorOpen} value={invoice?.notes ?? ""} onEditorChange={(val) => {
            let change = {
                ...invoice!,
                notes: val,
            }
            setInvoice(change)
            setEditing(true)

        }}
            setContent={setContent}
            onClose={(val) => {
                console.log(val)
            }}

        />
        <ModalEditor isOpen={modalItemNoteEditor} setIsOpen={setModalItemNoteEditor} value={selectedItem?.notes ?? ""} onEditorChange={(val) => {
            setSelectedItem({ ...selectedItem!, notes: val })

        }}
            setContent={setContent}
            onClose={(val) => {
                updateItem(selectedItem!)
            }}

        />
        <ModalCustom title='Tambah Diskon' isOpen={modalDiscountOpen} setIsOpen={setModalDiscountOpen} onChange={() => { }}
            footer={
                <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={() => {
                        selectedItem!.discount_amount = inputDiscountAmount
                        selectedItem!.discount_percent = inputDiscountPercent
                        updateItem(selectedItem!)
                        setModalDiscountOpen(false)
                        setSelectedItem(null)
                    }}>
                    <FolderIcon
                        className=" h-5 w-5 mr-2"
                        aria-hidden="true" />  Simpan
                </button>
            }
        >
            <InlineForm title="Tipe Diskon" className="mt-4">
                <select className='bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500'
                    value={discType} onChange={(val) => {
                        setDiscType(val.target.value)
                        setInputDiscountPercent(0)
                        setInputDiscountAmount(0)
                    }} >
                    <option value={"percent"}>Diskon (%)</option>
                    <option value={"amount"}>Diskon Nominal</option>
                </select>
            </InlineForm>
            {discType == "amount" && mountedInputPayment &&
                <InlineForm title="Diskon Nominal">
                    <CurrencyInput
                        className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                        groupSeparator="."
                        decimalSeparator=","
                        placeholder="Diskon Nominal"
                        defaultValue={inputDiscountAmount}
                        max={100}
                        onValueChange={(value, _, values) => {
                            setInputDiscountAmount(values?.float ?? 0)
                        }}
                    />
                </InlineForm>
            }
            {discType == "percent" && mountedInputPayment &&
                <InlineForm title="Diskon (%)">
                    <CurrencyInput
                        className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                        groupSeparator="."
                        decimalSeparator=","
                        placeholder="Diskon (%)"
                        defaultValue={inputDiscountPercent}
                        max={100}
                        onValueChange={(value, _, values) => {
                            if (values?.float! > 100) {
                                setInputDiscountPercent(100)
                                setMountedInputPayment(false)
                                setTimeout(() => {
                                    setMountedInputPayment(true)

                                }, 30);
                            } else {
                                setInputDiscountPercent(values?.float ?? 0)
                            }
                        }}
                    />
                </InlineForm>
            }
            {selectedItem != null &&
                <InlineForm title="Total Harga">
                    {money(discType == "amount" ? (selectedItem!.qty * selectedItem!.price) - inputDiscountAmount : (selectedItem!.price * (1 - inputDiscountPercent / 100)) * selectedItem!.qty)}
                </InlineForm>
            }
        </ModalCustom>
        <ModalCustom title='Tambah Pembayaran' isOpen={modalPaymentOpen} setIsOpen={setModalPaymentOpen} onChange={() => {

        }} onClose={() => {
            setModalPaymentOpen(false)
            setInputAmountPayment(0)
            setInputDescription("")
            setInputAsset(null)
            setInputAssetId('')
        }}
            footer={
                <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent bg-orange-100 px-4 py-2 text-sm font-medium text-orange-900 hover:bg-orange-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-orange-500 focus-visible:ring-offset-2"
                    onClick={savePayment}>
                    <PaperAirplaneIcon
                        className=" h-5 w-5 mr-2"
                        aria-hidden="true" />  Kirim Pembayaran
                </button>
            }
        >

            <div className="mt-4 ">
                <div className="flex items-center mb-6">
                    <div className="w-1/3">
                        <label
                            className="block text-gray-900 mb-0 pr-4"
                            htmlFor="date"
                        >
                            Tanggal
                        </label>
                    </div>
                    <div className="w-2/3">
                        <DatePicker value={date} block format="dd/MM/yyyy HH:mm" onChangeCalendarDate={(date) => {
                            setDate(date)
                        }} />
                    </div>
                </div>
                <div className="flex mb-6">
                    <div className="w-1/3">
                        <label
                            className="block text-gray-900 mb-0 pr-4"

                        >
                            Jumlah
                        </label>
                    </div>
                    <div className="w-2/3">
                        {mountedInputPayment ?
                            <CurrencyInput
                                className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"

                                groupSeparator="."
                                decimalSeparator=","
                                placeholder="Jumlah"
                                defaultValue={inputAmountPayment}
                                max={invoice?.balance ?? 0}

                                onValueChange={(value, _, values) => {
                                    setInputAmountPayment(values?.float ?? 0)


                                }}
                            />
                            : <input disabled className='bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500' />}
                        <Checkbox onChange={(val, checked) => {
                            setMountedInputPayment(false)
                            if (checked) {
                                setInputAmountPayment(invoice?.balance ?? 0)
                            } else {
                                setInputAmountPayment(0)
                            }
                            setTimeout(() => {
                                setMountedInputPayment(true)

                            }, 100);
                        }} >Bayar Semua sisa Pembayaran</Checkbox>
                    </div>
                </div>
                <div className="flex  mb-6">
                    <div className="w-1/3">
                        <label className="block text-gray-900 mb-0 pr-4">
                            Akun Kas
                        </label>
                    </div>
                    <div className="w-2/3">
                        <Select< SelectOption, false> styles={colourStyles} options={assetSelect} value={inputAsset}
                            onChange={(option: SingleValue<SelectOption>): void => {
                                setInputAssetId(option?.value ?? "")
                                setInputAsset(option)
                                // let selectedVar = storeProduct?.varians.filter(e => e.uuid == option?.value ?? "")
                                // if (selectedVar) {
                                //     setSelectedSourceVarian(selectedVar[0])
                                // }
                            }}
                            onInputChange={(val) => {
                                getAccounts({ page: 1, limit: 10, type: "Asset", search: val, cashflowGroup: "current_asset" })
                                    .then(v => v.json())
                                    .then(v => {
                                        setAssetAccounts(v.data)
                                    })

                            }}
                        />
                        <small className='text-gray-400'>{(invoice?.balance ?? 0) > (invoice?.balance ?? 0) - inputAmountPayment ? `Sisa Pembayaran : ${money((invoice?.balance ?? 0) - inputAmountPayment)}` : null}</small>
                    </div>
                </div>
                <div className="flex items-start mb-6">
                    <div className="w-1/3">
                        <label
                            className="block text-gray-900 mb-0 pr-4"
                            htmlFor="desc"
                        >
                            Keterangan
                        </label>
                    </div>
                    <div className="w-2/3">
                        <textarea
                            className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                            id="desc"
                            rows={5}
                            placeholder="Keterangan"
                            value={inputDescription}
                            onChange={(e) => setInputDescription(e.target.value)}
                        />
                    </div>
                </div>
            </div>

        </ModalCustom>

        <ModalCustom
            height={300}
            isOpen={modalPassword}
            setIsOpen={setModalPassword}
            onClose={() => {
                setPassword("")
                setModalPassword(false)
            }
            }
            footer={<button
                type="button"
                className="inline-flex justify-center rounded-md border border-transparent bg-orange-100 px-4 py-2 text-sm font-medium text-orange-900 hover:bg-orange-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-orange-500 focus-visible:ring-offset-2"
                onClick={() => {
                    if (password) {
                        setIsLoading(true)
                        unreleaseInvoice(invoice?.uuid!, password)
                            .then(v => getDetail())
                            .catch(error => Swal.fire("Perhatian", `${error}`, "error"))
                            .finally(() => setIsLoading(false))
                        setModalPassword(false)
                    }
                }}>
                <PaperAirplaneIcon
                    className=" h-5 w-5 mr-2"
                    aria-hidden="true" />  Kirim
            </button>}
            title='Masukan Password'>
            <small>Untuk membuka rilis faktur, silakan masukan password login anda</small>
            <div className="w-full mt-4">
                <input
                    className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                    id="product-name"
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />

            </div>
        </ModalCustom>
        <ModalTax taxType="Liability" isOpen={modalTaxOpen} setIsOpen={setModalTaxOpen} onChange={() => {
        }} onClose={() => {
            setEditing(true)
        }} onClick={(tax, method) => {
            let change = {
                ...invoice!,
                tax_id: tax.uuid,
                tax_method: method,
                tax_name: tax.name,
            }
            setInvoice({ ...change })
            setModalTaxOpen(false)
            setEditing(true)
        }} />

        <ModalTax taxType="Liability" isOpen={modalTaxSecondaryOpen} setIsOpen={setModalTaxSecondaryOpen} onChange={() => {
        }} onClose={() => {
            setEditing(true)
        }} onClick={(tax, method) => {
            let change = {
                ...invoice!,
                secondary_tax_id: tax.uuid,
                secondary_tax_method: method,
                secondary_tax_name: tax.name
            }
            setInvoice({ ...change })
            setModalTaxSecondaryOpen(false)
            setEditing(true)
        }} />

        <ModalCustom
            height={300}
            isOpen={modalDownloadOpen}
            setIsOpen={setModalDownloadOpen}
            onClose={() => {

                setModalDownloadOpen(false)
            }
            }
            footer={<button
                type="button"
                className="inline-flex justify-center rounded-md border border-transparent bg-orange-100 px-4 py-2 text-sm font-medium text-orange-900 hover:bg-orange-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-orange-500 focus-visible:ring-offset-2"
                onClick={() => {
                    setIsLoading(true)
                    setModalDownloadOpen(false)
                    downloadInvoice(invoice!.uuid, { hide_tax: hideTax ? "1" : "0", hide_secondary_tax: hideSecondaryTax ? "1" : "0", hide_total_before_tax: hideSecondaryTax ? "1" : "0" })
                        .then(v => v.blob())
                        .then(v => {
                            console.log(v)
                            var fileURL = window.URL.createObjectURL(v);
                            window.open(fileURL);

                        })
                        .catch(error => Swal.fire("Perhatian", `${error}`, "error"))
                        .finally(() => setIsLoading(false))
                }}>
                <PaperAirplaneIcon
                    className=" h-5 w-5 mr-2"
                    aria-hidden="true" />  Kirim
            </button>}
            title='Download Faktur'>
            <ul className='mt-4'>
                <li><Checkbox checked={hideTax} onChange={(_, checked) => setHideTax(checked)} >Sembunyikan Pajak</Checkbox></li>
                <li><Checkbox checked={hideSecondaryTax} onChange={(_, checked) => setHideSecondaryTax(checked)}>Sembunyikan Pajak Ke #2</Checkbox></li>
                <li><Checkbox checked={hideTotalBeforeTax} onChange={(_, checked) => setHideTotalBeforeTax(checked)}>Sembunyikan Total Sebelum Pajak</Checkbox></li>
            </ul>
        </ModalCustom>

        <Drawer open={openWithHeader} onClose={() => setOpenWithHeader(false)}>
            <Drawer.Header>
                <Drawer.Title>Menu Faktur</Drawer.Title>
                <Drawer.Actions>
                    {/* <Button onClick={() => {
                        //   setDateRange(null)
                        //   setInputProduct(null)
                        //   setInputDestination(null)
                        //   setOpenWithHeader(false)
                        //   setProduct(null)
                        //   setStore(null)
                        //   setProducts([])
                    }}><XMarkIcon className="w-5" /> Clear Filter</Button> */}
                </Drawer.Actions>
            </Drawer.Header>
            <Drawer.Body>
                <div className='flex flex-col h-full'>
                    <div className='flex-1'>
                        <h3 className=' font-medium text-2xl mb-4'>Pengaturan Faktur</h3>
                        <div className="flex items-start mb-6">
                            <div className="w-1/3">
                                <label
                                    className="flex items-center  text-gray-900 mb-0 pr-4"
                                    htmlFor="desc"
                                >
                                    Rilis Faktur
                                    <Whisper followCursor speaker={<Tooltip>Faktur yang dirilis, terkunci dan tidak dapat diedit</Tooltip>}>

                                        <InformationCircleIcon className='w-4 ml-2 text-gray-400 hover:text-gray-700' />
                                    </Whisper>
                                </label>
                            </div>
                            <div className="w-2/3">
                                <Switch
                                    checked={invoice?.locked ?? false}
                                    onChange={async (val) => {
                                        if (val) {
                                            if (!permissions.includes("invoice_release")) {
                                                Swal.fire("Perhatian", `Anda tidak mempunyai akses untuk merilis faktur`, "error")
                                                return
                                            }
                                            releaseInvoice(invoice?.uuid!)
                                                .then(v => getDetail())
                                        } else {
                                            if (!permissions.includes("invoice_release")) {
                                                Swal.fire("Perhatian", `Anda tidak mempunyai akses untuk membuka rilis faktur`, "error")
                                                return
                                            }


                                            setModalPassword(true)
                                            setOpenWithHeader(false)
                                        }
                                    }}
                                    className={`${(invoice?.locked ?? false) ? 'bg-blue-600' : 'bg-gray-200'
                                        } relative inline-flex h-6 w-11 items-center rounded-full`}
                                >
                                    <span className="sr-only"> Rilis Faktur</span>
                                    <span
                                        aria-hidden="true"
                                        className={`${(invoice?.locked ?? false) ? 'translate-x-6' : 'translate-x-1'
                                            } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                    />
                                </Switch>
                            </div>
                        </div>
                        <h3 className=' font-medium text-2xl mb-4'>Pengaturan Print</h3>
                        <div className="mb-6">
                            <InlineForm title="Header">
                                <select className='bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500'
                                    value={printHeader} onChange={(val) => setPrintHeader(val.target.value)} >
                                    <option value={""}>Tanpa Header</option>
                                    <option value={"merchant"}>Merchant</option>
                                    <option value={"company"}>Perusahaan</option>
                                </select>
                            </InlineForm>
                            <InlineForm title="Pembayaran">
                                <Switch
                                    checked={printShowAddress}
                                    onChange={async (val) => {
                                        setPrintShowPayment(val)
                                    }}
                                    className={`${printShowPayment ? 'bg-blue-600' : 'bg-gray-200'
                                        } relative inline-flex h-6 w-11 items-center rounded-full`}
                                >
                                    <span className="sr-only"> Pembayaran </span>
                                    <span
                                        aria-hidden="true"
                                        className={`${printShowPayment ? 'translate-x-6' : 'translate-x-1'
                                            } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                    />
                                </Switch>
                            </InlineForm>
                            <InlineForm title="Alamat">
                                <Switch
                                    checked={printShowAddress}
                                    onChange={async (val) => {
                                        setPrintShowAddress(val)
                                    }}
                                    className={`${printShowAddress ? 'bg-blue-600' : 'bg-gray-200'
                                        } relative inline-flex h-6 w-11 items-center rounded-full`}
                                >
                                    <span className="sr-only"> Alamat </span>
                                    <span
                                        aria-hidden="true"
                                        className={`${printShowAddress ? 'translate-x-6' : 'translate-x-1'
                                            } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                    />
                                </Switch>
                            </InlineForm>
                            <InlineForm title="Catatan">
                                <Switch
                                    checked={printShowNotes}
                                    onChange={async (val) => {
                                        setPrintShowNotes(val)
                                    }}
                                    className={`${printShowNotes ? 'bg-blue-600' : 'bg-gray-200'
                                        } relative inline-flex h-6 w-11 items-center rounded-full`}
                                >
                                    <span className="sr-only"> Catatan </span>
                                    <span
                                        aria-hidden="true"
                                        className={`${printShowNotes ? 'translate-x-6' : 'translate-x-1'
                                            } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                    />
                                </Switch>
                            </InlineForm>
                            <InlineForm title="Download">
                                <Switch
                                    checked={printDownload}
                                    onChange={async (val) => {
                                        setPrintDownload(val)
                                    }}
                                    className={`${printDownload ? 'bg-blue-600' : 'bg-gray-200'
                                        } relative inline-flex h-6 w-11 items-center rounded-full`}
                                >
                                    <span className="sr-only"> Download </span>
                                    <span
                                        aria-hidden="true"
                                        className={`${printDownload ? 'translate-x-6' : 'translate-x-1'
                                            } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                    />
                                </Switch>
                            </InlineForm>
                            <button
                                type="button"
                                className="flex w-full transition-all mt-4 duration-500 justify-center rounded-md border border-transparent bg-orange-600 px-4 py-2 text-sm font-medium text-white hover:bg-orange-900 focus:outline-none focus-visible:ring-2 focus-visible:ring-orange-500 focus-visible:ring-offset-2"
                                onClick={() => {
                                    let params: Record<string, string> = {}
                                    if (printHeader) {
                                        params['header'] = printHeader
                                    }
                                    if (printShowPayment) {
                                        params['payment'] = printShowPayment ? "1" : "0"
                                    }
                                    if (printShowAddress) {
                                        params['address'] = printShowAddress ? "1" : "0"
                                    }
                                    if (printShowNotes) {
                                        params['notes'] = printShowNotes ? "1" : "0"
                                    }
                                    if (printDownload) {
                                        params['download'] = printDownload ? "1" : "0"
                                    }
                                    let url = `/sale/${invoice?.uuid}/print?${new URLSearchParams(params)}`
                                    window.open(url)


                                }}>
                                <PrinterIcon
                                    className=" h-5 w-5 mr-2"
                                    aria-hidden="true" />  Print
                            </button>
                            <button
                                type="button"
                                className="flex w-full transition-all mt-4 duration-500 justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-900 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                onClick={() => {
                                    printReciept(invoice!, company!)
                                }}>
                                    <TfiReceipt className=" h-5 w-5 mr-2"/>
                                    Print Receipt
                               </button>
                        </div>
                    </div>


                    {/* <div>
                        <Whisper followCursor speaker={<Tooltip>Download Faktur</Tooltip>}>
                            <AvatarRS circle color='orange' className=' cursor-pointer' onClick={() => {
                                setModalDownloadOpen(true)
                                setOpenWithHeader(false)
                            }} >
                                <DocumentArrowDownIcon className='w-6' />
                            </AvatarRS>
                        </Whisper>
                    </div> */}

                </div>

            </Drawer.Body>
        </Drawer>
    </DashboardLayout>);
}
export default SaleDetail;