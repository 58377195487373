import { ContactReq } from "../model/company";
import { EmployeeReq } from "../model/employee";
import { PaginationReq } from "../model/pagination";
import { customFetch } from "../utils/helper";

export let getEmployees = async (pagination: PaginationReq) => {
    var params: Record<string, string> = {
        page: pagination.page.toString(),
        limit: pagination.limit.toString(),

    };
    if (pagination.search && pagination.search != "") {
        params["search"] = pagination.search
    }
    return await customFetch(`Trading/Employee/?${new URLSearchParams(params)}`)
}

export let updateEmployee = async (uuid:string, req: EmployeeReq) => {
    return await customFetch(`Trading/Employee/${uuid}`, {
        method: "PUT",
        body: JSON.stringify(req)
    })
}
export let addEmployee = async (req: EmployeeReq) => {

    return await customFetch(`Trading/Employee/`, {
        method: "POST",
        body: JSON.stringify(req)
    })
}

export let deleteEmployee = async (uuid: string) => {
    return await customFetch(`Trading/Employee/${uuid}`, {
        method: "DELETE",
    })
}