import { NullString } from "../objects/null_string"
import { Account } from "./account"
import { Item } from "./item"
import { Tax, TaxTransaction } from "./tax"
import { setNullString } from "../utils/helper"
import { Transaction } from "./transaction"
import moment from "moment"

export interface Invoice {
    uuid: string
    customer_id: string
    customer_name: string
    customer_address: string
    customer_phone: string
    customer_fax: string
    customer_email: string
    customer_contact_person: string
    customer_tax_payer_number: string
    invoice_title: string
    invoice_running_number: number
    invoice_number: string
    ref_number: string
    po_number: string
    invoice_date: string
    payment_due: string
    is_over_due: boolean
    tax_id: string
    tax: TaxTransaction
    secondary_tax: TaxTransaction
    account_receivable: Account
    account_income: Account
    account_receivable_id: string
    account_income_id: string
    quotation_id: string
    flag: boolean
    locked: boolean
    updated_stock: boolean
    notes: string
    balance: number
    total_before_discount: number
    total_after_discount: number
    total_discount_amount: number
    paid: number
    items: Item[]
    payments: Transaction[]
    total_tax_amount: number
    total_before_tax: number
    grand_total: number
    tax_method: string
    tax_name: string
    tax_amount: number
    tax_payment_id: string
    withholding_certificate: string
    is_tax_paid: boolean
    is_auto_stock: boolean
    is_cash: boolean
    secondary_tax_id: string
    secondary_tax_method: string
    secondary_tax_name: string
    secondary_tax_amount: number
    total_secondary_tax_amount: number
    counted: string
}



export interface InvoiceReq {
    customer_id: NullString
    customer_name?: string,
    customer_address?: string,
    customer_phone?: string,
    customer_fax?: string,
    customer_email?: string,
    customer_contact_person?: string,
    invoice_date: string
    payment_due: string
    invoice_title: string
    invoice_number: string
    ref_number: string
    po_number?: string
    account_receivable_id: NullString
    account_income_id: NullString
    is_auto_stock: boolean
    tax_id: NullString
    tax_method: NullString
    secondary_tax_id: NullString
    secondary_tax_method: NullString
    notes: string
}
export interface InvoiceEditReq {
    customer_id: NullString
    invoice_date: string
    payment_due: string
    invoice_title: string
    invoice_number: string
    account_receivable_id: NullString
    account_income_id: NullString
    is_auto_stock: boolean
    tax_id: NullString
    tax_method: NullString
    secondary_tax_id: NullString
    secondary_tax_method: NullString
    notes: string
}


export let toRequest = (input: Invoice): InvoiceReq => {
    return {
        customer_id: setNullString(input.customer_id),
        customer_name: input.customer_name,
        customer_address: input.customer_address,
        customer_phone: input.customer_phone,
        customer_fax: input.customer_fax,
        customer_email: input.customer_email,
        customer_contact_person: input.customer_contact_person,
        invoice_date: moment(input.invoice_date).toISOString(),
        payment_due: moment(input.payment_due).toISOString(),
        po_number: input.po_number,
        ref_number: input.ref_number,
        invoice_title: input.invoice_title,
        invoice_number: input.invoice_number,
        account_receivable_id: setNullString(input.account_receivable_id),
        account_income_id: setNullString(input.account_income_id),
        is_auto_stock: input.is_auto_stock,
        tax_id: setNullString(input.tax_id),
        tax_method: setNullString(input.tax_method),
        secondary_tax_id: setNullString(input.secondary_tax_id),
        secondary_tax_method: setNullString(input.secondary_tax_method),
        notes: input.notes,
    }
}