import { useContext, useEffect, useRef, useState, type FC } from 'react';
import { GroupedStock, Product, ProductAddReq, ProductCategory } from '../model/product';
import { Pagination } from '../model/pagination';
import { SelectOption, colourStyles, multiColourStyles } from '../utils/style';
import { LoadingContext } from '../objects/loading_context';
import { useNavigate } from 'react-router-dom';
import { TopProduct } from '../model/dashboard';
import { Store } from '../model/store';
import { Company } from '../model/company';
import { getPermissions, randomStr, setNullString, sumMatrixStock } from '../utils/helper';
import { getCompanyDetail, getPackagingByName, getPriceCategoryByName, getProductBySKU, getProductCategoryByName, getStoreByName, getUnitByName } from '../repositories/company';
import { addPackaging, addPriceCategory, addProduct, addProductCategory, addProductPackaging, addProductPriceCategory, addProductUnit, addUnit, addVarian, deleteProduct, getProductCategories, getProducts } from '../repositories/product';
import { getTopProducts } from '../repositories/dashboard';
import { addStore, getStores } from '../repositories/store';
import Swal from 'sweetalert2';
import { CustomPie } from '../components/custom_pie';
import { Avatar, AvatarGroup, DatePicker, Message, Progress, toaster } from 'rsuite';
import { XlsData } from '../objects/xls';
import { addCirculation } from '../repositories/circulation';
import moment from 'moment';
import DashboardLayout from '../components/dashboard';
import CustomTable, { TableRecord } from '../components/custom_table';
import Badge from '../components/badge';
import { money } from '../utils/number';
import { PrinterIcon, TrashIcon } from '@heroicons/react/24/outline';
import Select, { MultiValue, SingleValue } from 'react-select';
import Creatable from 'react-select/creatable';
import saveAs from 'file-saver';
import { Switch } from '@headlessui/react';
import FileUploadIcon from '@rsuite/icons/FileUpload';
import * as XLSX from "xlsx";
import FormProduct from '../components/modals/form_product';

interface ProductPageProps {}

const ProductPage: FC<ProductPageProps> = ({}) => {
    const fileRef = useRef<HTMLInputElement>(null)
    const [products, setProducts] = useState<Product[]>([]);
    const [productCategories, setProductCategories] = useState<ProductCategory[]>([]);
    const [mounted, setMounted] = useState(false);
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(20);
    const [pagination, setPagination] = useState<Pagination | null>(null);
    const [categories, setCategories] = useState<SelectOption[]>([]);
    let { isLoading, setIsLoading } = useContext(LoadingContext);
    const [permissions, setPermissions] = useState<string[]>([]);
    let [isOpen, setIsOpen] = useState(false)
    const nav = useNavigate()
    const [inputName, setInputName] = useState("");
    const [inputPrice, setInputPrice] = useState(0);
    const [inputProductCategoryID, setInputProductCategoryID] = useState("");
    const [inputProductCategory, setInputProductCategory] = useState<SelectOption | null>(null);
    const [inputIsSell, setInputIsSell] = useState(false);
    const [inputIsBuy, setInputIsBuy] = useState(false);
    const [topProducts, setTopProducts] = useState<TopProduct[]>([]);
    const [filterCategories, setFilterCategories] = useState<ProductCategory[]>([]);
    const [selectedCategories, setSelectedCategories] = useState<MultiValue<SelectOption>>([]);
    const [groupedStock, setGroupedStock] = useState<GroupedStock[]>([]);
    const [stores, setStores] = useState<Store[]>([]);
    const [selectedStore, setSelectedStore] = useState<SelectOption>({ value: "", label: "Pilih Gudang" });
    const [date, setDate] = useState<Date>(new Date());
    const [isEarlyStock, setIsEarlyStock] = useState(false);
    const [description, setDescription] = useState("Update Stok");
    const [company, setCompany] = useState<Company | null>(null);

    useEffect(() => {
        getPermissions().then(v => {
            setPermissions(v)
            setMounted(true)
        })
        
    }, []);

    useEffect(() => {
        if (!mounted) return
        getCompanyDetail()
            .then(v => v.json())
            .then(v => setCompany(v.data))
        getAllProducts()
        getProductCategories({ page: 1, limit: 1000 })
            .then(v => v.json())
            .then(v => setProductCategories(v.data))

        getFilterCategories("")

        getTopProducts()
            .then(v => v.json())
            .then(v => {
                let products: TopProduct[] = []
                for (let index = 0; index < v.data.length; index++) {
                    const element: TopProduct = v.data[index];
                    products.push(element)
                }
                let total = products.map(e => e.sold).reduce((a, b) => a + b, 0)

                products = products.map(e => {
                    e.percent = Math.round(e.sold / total * 100)
                    return e
                })
                setTopProducts([...products])
            })

        getAllStores("")
    }, [mounted]);

    const getFilterCategories = (s: string) => {
        getProductCategories({ page: 1, limit: 5, search: s })
            .then(v => v.json())
            .then(v => setFilterCategories(v.data))
    }

    const getAllStores = (s: string) => {
        getStores({ page: 1, limit: 5, search: s })
            .then(v => v.json())
            .then(v => setStores(v.data))
    }

    useEffect(() => {
        let cat: { value: string; label: string; }[] = []
        for (let index = 0; index < productCategories.length; index++) {
            const element = productCategories[index];
            cat.push({ value: element.uuid, label: element.name })
        }
        setCategories(cat)
    }, [productCategories]);


    useEffect(() => {
        if (!mounted) return
        getAllProducts()
    }, [search, page, limit, selectedCategories, selectedStore]);

    const getAllProducts = async () => {
        try {
            setIsLoading(true)
            var resp = await getProducts({ page, limit, search, isSell: true, isBuy: true }, selectedCategories.map(e => e.value), selectedStore.value)
            var respJson = await resp.json()
            setProducts(respJson.data)
            setPagination(respJson.meta)
            setGroupedStock(sumMatrixStock(selectedCategories, respJson.data) as GroupedStock[])
        } catch (error) {
            Swal.fire("Perhatian", `${error}`, "error")
        } finally {
            setIsLoading(false)
        }

    }


    const productPieCard = () => (
        <div className="mb-5 rounded-xl py-6 px-6 transition-all hover:shadow-lg sm:p-4 lg:px-4 xl:px-6 bg-white cursor-pointer">
            <h3 className=" text-2xl mb-4">Produk Terlaris</h3>
            <div className=" w-full">
                <CustomPie labels={topProducts.map(e => e.name)} datasets={[
                    {
                        label: '# of Transaksi',
                        data: topProducts.map(e => e.sold),
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.7)',
                            'rgba(54, 162, 235, 0.7)',
                            'rgba(255, 206, 86, 0.7)',
                            'rgba(75, 192, 192, 0.7)',
                            'rgba(153, 102, 255, 0.7)',
                            'rgba(255, 159, 64, 0.7)',
                        ],

                    },
                ]} />

            </div>

            <div className="mt-4">
                <ul>
                    {topProducts.map(e => <li key={e.uuid} className="flex w-full items-center mb-2">
                        <span className="text-bold text-5xl mr-2">{topProducts.indexOf(e) + 1}</span>
                        <div className="flex flex-col flex-1">
                            <span className="text-bold text-lg px-2">{e.name}</span>
                            <Progress.Line percent={e.percent} strokeColor={["blue", "red", "orange", "lime", "slate"][topProducts.indexOf(e)]} />
                        </div>

                    </li>)}
                </ul>
                <p className="text-sm text-gray-400 text-right">Produk berdasarkan perjualan 5 teratas</p>
            </div>
        </div>
    )


    const processFileXls = async (records: XlsData[]) => {
        for (let index = 0; index < records.length; index++) {
            const record = records[index];
            let storeId = ""
            let catId = ""
            let unitId = ""
            let packagingId = ""
            let priceCatId = ""
            if (record["Produk Jual"] == 0 && record["Produk Beli"] == 0) {
                continue
            }
            // console.log(record)
            if (record.Kategori) {
                try {
                    var cat = await getProductCategoryByName(record.Kategori)
                    var catJson = await cat.json()
                    catId = catJson.data
                } catch (error) {
                    await addProductCategory({ name: record.Kategori })
                        .then(v => v.json()).then(v => catId = v.data)
                }

            }
            if (record.Gudang) {
                try {
                    var store = await getStoreByName(record.Gudang)
                    var storeJson = await store.json()
                    storeId = storeJson.data
                } catch (error) {
                    await addStore({ name: record.Gudang })
                        .then(v => v.json()).then(v => storeId = v.data)
                }

            }
            if (record.Unit) {
                try {
                    var unit = await getUnitByName(record.Unit)
                    var unitJson = await unit.json()
                    unitId = unitJson.data
                } catch (error) {
                    await addUnit({ name: record.Unit })
                        .then(v => v.json()).then(v => unitId = v.data)
                }
            }
            if (record.Packaging) {
                try {
                    var packaging = await getPackagingByName(record.Packaging)
                    var packagingJson = await packaging.json()
                    packagingId = packagingJson.data
                } catch (error) {
                    await addPackaging({ name: record.Packaging })
                        .then(v => v.json()).then(v => packagingId = v.data)
                }
            }
            let productId = ""
            if (record.SKU) {
                try {
                    var prod = await getProductBySKU(record.SKU)
                    var prodJson = await prod.json()
                    productId = prodJson.data.uuid
                } catch (error) {
                    let dataProduct: ProductAddReq = {
                        name: record["Nama Produk"],
                        price: record["Harga Dasar"],
                        description: record.Keterangan ?? "",
                        sku: `${record.SKU}`,
                        barcode: `${record.Barcode}`,
                        product_category_id: {
                            Valid: catId.length != 0,
                            String: catId,
                        },
                        is_buy: record["Produk Beli"] == 1 ? true : false,
                        is_sell: record["Produk Jual"] == 1 ? true : false,
                        is_material: false,
                        varian_parameters: record.Varian ? JSON.stringify(record.Varian.split(",")) : "[]"
                    }
                    let respProd = await addProduct(dataProduct)
                    let respProdJson = await respProd.json()
                    productId = respProdJson.data
                }
            }


            // console.log(dataProduct)

            try {

                // ADD PRICE CATEGORI
                if (record["Harga Jual"]) {
                    try {
                        var priceCat = await getPriceCategoryByName("Harga Jual")
                        var priceCatJson = await priceCat.json()
                        priceCatId = priceCatJson.data
                    } catch (error) {
                        let pc = await addPriceCategory({
                            name: "Harga Jual"
                        })
                        let pcJson = await pc.json()
                        priceCatId = pcJson.data.last_id
                    }


                    await addProductPriceCategory(productId, {
                        product_id: setNullString(productId),
                        price_category_id: setNullString(priceCatId),
                        amount: record["Harga Jual"],
                        price_level: setNullString(""),
                    })
                }

                // ADD UNIT
                if (unitId) {
                    await addProductUnit(productId, {
                        unit_id: setNullString(unitId),
                        product_id: setNullString(productId),
                        is_default: true,
                        value: 1
                    })

                    // ADD PACKAGING
                    if (packagingId) {
                        await addProductPackaging(productId, {
                            unit_id: setNullString(unitId),
                            packaging_id: setNullString(packagingId),
                            netto: 1
                        })
                    }

                }
                const varianIds: { name: string, id: string }[] = []
                try {
                    if (record.Varian && record.__EMPTY) {
                        for (let i = 0; i < record.__EMPTY.split(",").length; i++) {
                            const varRecord = record.__EMPTY.split(",")[i];
                            const varRecordData = varRecord.split("|")
                            let varData: Record<string, string> = {}

                            for (let j = 0; j < record.Varian.split(",").length; j++) {
                                const key = record.Varian.split(",")[j];
                                varData[key] = varRecordData[j]
                            }
                            var res = await addVarian(productId, JSON.stringify(varData))
                            var resJson = await res.json()
                            varianIds.push({
                                name: varRecord,
                                id: resJson.data
                            })
                        }

                    }
                } catch (error) {
                    console.log(error)
                }

                // console.log(varianIds)
                // ADD CIRCULATION
                if (record["Stok Awal"] && storeId) {
                    try {
                        if (varianIds.length == 0) {
                            await addCirculation({
                                in: parseFloat(record["Stok Awal"]) ?? 0,
                                out: 0,
                                packaging_in: parseFloat(record["Stok Awal Packaging"]) ?? 0,
                                packaging_out: 0,
                                description: isEarlyStock ? "Stok Awal" : description,
                                product_id: setNullString(productId),
                                source_id: setNullString(""),
                                destination_id: setNullString(storeId),
                                date: isEarlyStock ? moment.unix(1000).toISOString() : date.toISOString(),
                                varian_id: setNullString(""),
                                unit_id: setNullString(unitId),
                                is_initial: true,
                            })
                        } else {
                            let stocks = `${record["Stok Awal"]}`.split("|")
                            let stockPackagings = `${record["Stok Awal Packaging"]}`.split("|")
                            for (let index = 0; index < varianIds.length; index++) {
                                const v = varianIds[index];
                                await addCirculation({
                                    in: parseFloat(stocks[index] ?? stocks[0]) ?? 0,
                                    out: 0,
                                    packaging_in: parseFloat(stockPackagings[index] ?? stockPackagings[0]) ?? 0,
                                    packaging_out: 0,
                                    description: isEarlyStock ? "Stok Awal" : description,
                                    product_id: setNullString(productId),
                                    source_id: setNullString(""),
                                    destination_id: setNullString(storeId),
                                    date: isEarlyStock ? moment.unix(1000).toISOString() : date.toISOString(),
                                    varian_id: setNullString(v.id),
                                    unit_id: setNullString(unitId),
                                    is_initial: true,
                                })

                            }
                        }
                    } catch (error) {
                        console.log("ERROR", error)
                    }


                }
                // ADD VARIAN


            } catch (error) {
                toaster.push(<Message showIcon type={"warning"} closable>
                    {`${error}`}
                </Message>, { placement: "bottomStart", duration: 3000 })
            }

        }
        getAllProducts()
    }


    const delProduct = async (item: Product) => {
        try {
            setIsLoading(true)
            const resp = await deleteProduct(item.uuid)
            getAllProducts()
        } catch (error) {
            Swal.fire("Perhatian", `${error}`, "error")
        } finally {
            Swal.fire({
                title: "Deleted!",
                text: "Your file has been deleted.",
                icon: "success"
            });
            setIsLoading(false)
        }
    }
    return (
        <DashboardLayout permission="read_product" >
            <div className="p-6">
                <h1 className="text-4xl">Produk & Bahan Baku</h1>
                <div className="mt-6 grid gap-x-8 gap-y-4 sm:gap-y-0 xl:grid-cols-4 lg:grid-cols-4 sm:grid-cols-2 md:grid-cols-2 ">
                    <div className="mb-5 col-span-3 rounded-xl py-6 px-6 transition-all hover:shadow-lg sm:p-4 lg:px-4 xl:px-6 bg-white cursor-pointer" >
                        <CustomTable className="mt-4"
                            onSearch={(val) => {
                                setSearch(val)
                            }}
                            switchHeader
                            pagination
                            total={pagination?.total_records}
                            limit={limit}
                            activePage={page}
                            setActivePage={(val) => {
                                setPage(val)
                            }}
                            searchHeader={
                                <div className="flex">

                                    <button
                                        onClick={() => { setIsOpen(true) }}
                                        className=" text-white bg-orange-600 hover:bg-orange-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-full text-sm px-5 py-2.5 text-center "
                                    >
                                        Tambah Produk
                                    </button>
                                </div>

                            }
                            changeLimit={(val) => setLimit(val)}
                            limitPaginations={[10, 20, 50, 100, 1000]}
                            headers={["No", "Nama Produk", "Kategori", "Stok", "Harga", ""]}
                            footer={groupedStock.map(e => ({
                                cells: [
                                    { data: "" },
                                    { data: groupedStock.indexOf(e) == 0 ? "Total Stok" : null, className: "font-semibold text-sm" },
                                    { data: e.label, className: "font-semibold text-sm" },
                                    {
                                        data: <ul className={e.stocks.length > 1 ? `disc` : ''}>
                                            {e.stocks.map(e => <li key={randomStr(3)}>{e}</li>)}
                                        </ul>, className: " text-sm"
                                    },
                                ],
                                className: " border-t border-t-gray-200"
                            }))}
                            headerClasses={[]} datasets={products.map(e => {
                                let data: TableRecord = {
                                    cells: [
                                        { data: ((page - 1) * limit) + (products.indexOf(e) + 1) },

                                        {
                                            data: <div className="flex justify-between items-center" style={{ width: "100%" }} onClick={() => nav(`/product/${e.uuid}`)} >
                                                <div className="flex flex-col">
                                                    {e.sku ? <small>{e.sku}</small> : null}
                                                    <p className="text-md  hover:font-medium">{e.name}</p>
                                                </div>

                                                <AvatarGroup stack>
                                                    {e.pictures
                                                        .filter((pic, i) => i < 4)
                                                        .map(pic => (
                                                            <Avatar bordered circle key={pic} src={pic} alt={pic} />
                                                        ))}
                                                    {e.pictures.length - 4 > 0 ? <Avatar bordered circle style={{ background: '#111' }}>
                                                        +{e.pictures.length - 4}
                                                    </Avatar> : null}

                                                </AvatarGroup>
                                            </div>
                                        },
                                        {
                                            data: <div className="flex flex-col">
                                                <span>{e.product_category}</span>
                                                <span className="flex mt-1">
                                                    {e.is_buy ? <Badge label="Produk Beli" className="mr-2" /> : null}
                                                    {e.is_sell ? <Badge label="Produk Jual" className="mr-2" color="green" /> : null}
                                                </span>
                                            </div>
                                        },
                                        {
                                            data: <div className="flex flex-col">
                                                <div>
                                                    {money(e.total_stock)} {e.unit_name}

                                                </div>
                                                {company?.product_add_on_setting?.jaraya_trading_setting?.user_packaging_stock &&
                                                    <div>
                                                        {money(e.total_packaging_stock)} {e.packaging_name}
                                                    </div>
                                                }
                                            </div>
                                        },
                                        {
                                            data: (
                                                <div>
                                                    {permissions.includes('hide_base_price') ? '' : money(e.price)}
                                                    {permissions.includes('hide_sale_price') || !e.prices ? '' : <ul>
                                                        {e.prices.map(p =>
                                                            <li key={p.id} className="text-xs " style={{ fontSize: 10 }}>
                                                                <span className="font-medium text-blue-700">{p.category_name} :</span>
                                                                <span className="font-normal ml-2">{money(p.amount)}</span>
                                                            </li>
                                                        )}

                                                    </ul>}
                                                </div>
                                            )
                                        },

                                        {
                                            data: <div className="flex justify-end">
                                                <TrashIcon
                                                    className=" h-5 w-5 text-red-400 hover:text-red-600"
                                                    aria-hidden="true"
                                                    onClick={() => {
                                                        Swal.fire({
                                                            title: "Anda Yakin",
                                                            text: "Anda tidak akan dapat mengembalikan proses ini!",
                                                            icon: "warning",
                                                            showCancelButton: true,
                                                            confirmButtonColor: "#3085d6",
                                                            cancelButtonColor: "#d33",
                                                            confirmButtonText: "Ya, Hapus!",
                                                            cancelButtonText: "Batal"
                                                        }).then((result) => {
                                                            if (result.isConfirmed) {
                                                                delProduct(e)
                                                            }
                                                        });
                                                    }}
                                                />

                                            </div>, className: 'w-4 text-right'
                                        },
                                    ],
                                    className: "last:border-0 hover:bg-gray-50"
                                }
                                return data
                            })} />

                    </div>
                    <div>
                        <div className="mb-5 rounded-xl py-6 px-6 transition-all hover:shadow-lg sm:p-4 lg:px-4 xl:px-6 bg-white cursor-pointer">
                            <h3 className=" text-xl mb-4">Filter </h3>
                            <Select<SelectOption, true> isMulti styles={multiColourStyles}
                                options={filterCategories.map(e => ({ value: e.uuid, label: e.name }))} value={selectedCategories}
                                className="w-full mb-4" placeholder="Pilih Kategori"
                                onChange={(option: MultiValue<SelectOption>): void => {
                                    setSelectedCategories(option!)
                                }}
                                onInputChange={(val) => {
                                    getFilterCategories(val)
                                }}
                            />
                            <Creatable<SelectOption, false> styles={colourStyles}
                                options={[{ value: "", label: "Pilih Gudang" }, ...stores.map(e => ({ value: e.uuid!, label: e.name }))]} value={selectedStore}
                                className="w-full mb-4" placeholder="Pilih Kategori"
                                onChange={(option: SingleValue<SelectOption>): void => {
                                    setSelectedStore(option!)
                                }}
                                onInputChange={(val) => {
                                    getAllStores(val)
                                }}
                                onCreateOption={(val) => {
                                    addStore({
                                        name: val,
                                    }).then((v => getAllStores("")))
                                }}
                            />

                            <button
                                type="button"
                                className="flex w-full transition-all mt-4 duration-500 justify-center rounded-md border border-transparent bg-orange-600 px-4 py-2 text-sm font-medium text-white hover:bg-orange-900 focus:outline-none focus-visible:ring-2 focus-visible:ring-orange-500 focus-visible:ring-offset-2"
                                onClick={() => {
                                    let params: Record<string, string> = {
                                        "page": page.toString(),
                                        "limit": limit.toString(),
                                        "categoryIds": selectedCategories.map(e => e.value).join(","),
                                        "storeId": selectedStore.value
                                    }

                                    let url = `/product/summary?${new URLSearchParams(params)}`
                                    window.open(url)


                                }}>
                                <PrinterIcon
                                    className=" h-5 w-5 mr-2"
                                    aria-hidden="true" />  Print Summary
                            </button>

                        </div>
                        <div className="mb-5 rounded-xl py-6 px-6 transition-all hover:shadow-lg sm:p-4 lg:px-4 xl:px-6 bg-white cursor-pointer">
                            <h3 className=" text-2xl mb-4">Import Data</h3>
                            <p>Anda dapat menambahkan data produk dari file excel, silahkan download terlebih dahulu template file xls disini <span className="font-semibold hover:font-medium hover:text-blue-700" onClick={() => {
                                fetch("/Sample Data Nyaga.xlsx")
                                    .then(v => v.blob())
                                    .then((v) => {
                                        saveAs(v, "Sample Data Nyaga.xlsx")
                                    })
                            }}>Download Disini</span></p>
                            {!isEarlyStock &&
                                <h3 className=" font-semibold text-base mt-4 mb-2">Tgl Sirkulasi</h3>
                            }
                            {!isEarlyStock &&
                                <DatePicker value={date} block format="dd/MM/yyyy" onChangeCalendarDate={(date) => {
                                    setDate(date)
                                }} />
                            }
                            {!isEarlyStock &&
                                <h3 className=" font-semibold text-base mt-4 mb-2">Keterangan</h3>
                            }
                            {!isEarlyStock &&
                                <textarea
                                    className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                    placeholder={"Keterangan"}
                                    value={description}
                                    rows={3}
                                    onChange={(el) => setDescription(el.target.value)}
                                />
                            }
                            <h3 className=" font-semibold text-base mt-4 mb-2">Stok Awal</h3>
                            <Switch
                                checked={isEarlyStock}
                                onChange={async (val) => {
                                    setIsEarlyStock(val)
                                }}
                                className={`${(isEarlyStock) ? 'bg-blue-600' : 'bg-gray-200'
                                    } relative inline-flex h-6 w-11 items-center rounded-full`}
                            >
                                <span className="sr-only"> Stok Awal</span>
                                <span
                                    aria-hidden="true"
                                    className={`${(isEarlyStock) ? 'translate-x-6' : 'translate-x-1'
                                        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                />
                            </Switch>
                            <button
                                type="button"
                                className="inline-flex justify-center items-center mt-4 w-full rounded-md border border-transparent bg-orange-200 px-4 py-2 text-sm font-medium text-orange-900 hover:bg-orange-400 cursor-pointer focus:outline-none focus-visible:ring-2 focus-visible:ring-orange-500 focus-visible:ring-offset-2"
                                onClick={() => fileRef.current?.click()}
                            >

                                <FileUploadIcon className="w-10" /> Upload File Xls
                            </button>
                            <input value={""} type='file' id='file' accept=".xls,.xlsx" ref={fileRef} style={{ display: 'none' }} onChange={async (e) => {
                                if (e.target.files) {
                                    let file = e.target.files[0]
                                    const reader = new FileReader();
                                    reader.onload = (e) => {
                                        try {
                                            const data = e.target!.result;
                                            const workbook = XLSX.read(data, { type: "binary" });
                                            const sheetName = workbook.SheetNames[0];
                                            const worksheet = workbook.Sheets[sheetName];
                                            const json = XLSX.utils.sheet_to_json(worksheet);
                                            json.shift()

                                            processFileXls(json.map(e => e as XlsData))
                                        } catch (error) {
                                            Swal.fire("Perhatian", `${error}`, "error")
                                        }


                                    };
                                    reader.readAsBinaryString(file);
                                }
                            }} />
                        </div>
                        {productPieCard()}
                    </div>
                </div>
            </div>
            <FormProduct isOpen={isOpen} setIsOpen={setIsOpen} onSubmit={(uuid) => nav(`/product/${uuid}`)} />
        </DashboardLayout>
    )

}
export default ProductPage;