import { Fragment, type FC } from 'react';
import { Editor as TinyMCEEditor } from 'tinymce';
import { Editor } from '@tinymce/tinymce-react';
import { Dialog, Transition } from '@headlessui/react';


interface ModalEditorProps {
    isOpen: boolean
    setIsOpen: (arg: boolean) => void
    value: string
    imagesUploadHandler?: UploadHandler
    onEditorChange: (a: string, editor: TinyMCEEditor) => void
    onClose?: (arg:string) => void
    setContent: (arg: string) => void
}

const ModalEditor: FC<ModalEditorProps> = ({
    value,
    imagesUploadHandler,
    onEditorChange,
    setContent,
    isOpen,
    onClose,
    setIsOpen
}) => {
    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={(val: boolean) => {
                setIsOpen(val)
                if (onClose != null) onClose(value)
            }} >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full flex flex-col max-w-2xl transform overflow-hidden rounded-2xl bg-form-dialog p-6 text-left align-middle shadow-xl transition-all" >
                                <Dialog.Title
                                    as="h3"
                                    className="text-xl font-medium leading-6 text-gray-900"
                                >
                                </Dialog.Title>
                                <Editor
                                    apiKey={process.env.REACT_APP_EDITOR_KEY} // your api key here
                                    onEditorChange={onEditorChange}
                                    onInit={(evt, editor) => setContent(editor.getContent({ format: "text" }))}
                                    
                                    value={value}
                                    init={{
                                        image_caption: true,
                                        images_upload_handler: imagesUploadHandler,
                                        height: 500,
                                        menubar: false,
                                        plugins: 'advlist autolink lists link image charmap preview anchor table ' +
                                            'searchreplace visualblocks code fullscreen ' +
                                            'insertdatetime media table code help wordcount fullscreen'
                                        ,
                                        toolbar: 'undo redo | styles | ' +
                                            'bold italic backcolor forecolor | alignleft aligncenter ' +
                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                            'table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol' +
                                            'removeformat | image imagetools | fullscreen help ',
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                        custom_elements: 'picture',
                                        valid_children: 'picture[source|img]',
                                        formats: {
                                            'custom_picture': { block: 'picture', selector: 'picture', merge_siblings: true, wrapper: true },
                                        }
                                    }}
                                />
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>);
}
export default ModalEditor;


interface BlobInfo {
    id: () => string;
    name: () => string;
    filename: () => string;
    blob: () => Blob;
    base64: () => string;
    blobUri: () => string;
    uri: () => string | undefined;
}
type ProgressFn = (percent: number) => void;
type UploadHandler = (blobInfo: BlobInfo, progress: ProgressFn) => Promise<string>;