import { LoadingContext } from "../../objects/loading_context";
import { Product, ProductCategory, Unit } from '../../model/product';
import { addProduct, addProductCategory, getProductCategories, getUnits } from '../../repositories/product';
import { SelectOption, colourStyles } from '../../utils/style';
import { Dialog, Switch, Transition } from '@headlessui/react';
import FolderIcon from '@heroicons/react/24/outline/FolderIcon';
import { Fragment, useContext, useState, type FC, useEffect } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { SingleValue } from 'react-select';
import Creatable from 'react-select/creatable';
import Swal from 'sweetalert2';
import { Company, JarayaTradingSetting } from "../../model/company";
import { getCompanyDetail } from "../../repositories/company";

interface FormProductProps {
    isOpen: boolean
    setIsOpen: (arg: boolean) => void
    onSubmit?: (arg: string) => void
    initialProductName?: string | null
}

const FormProduct: FC<FormProductProps> = ({
    isOpen,
    setIsOpen,
    onSubmit,
    initialProductName,
}) => {
    const [productCategories, setProductCategories] = useState<ProductCategory[]>([]);
    const [categories, setCategories] = useState<SelectOption[]>([]);
    let { isLoading, setIsLoading } = useContext(LoadingContext);
    const [inputName, setInputName] = useState("");
    const [inputPrice, setInputPrice] = useState(0);
    const [inputProductCategoryID, setInputProductCategoryID] = useState("");
    const [inputProductCategory, setInputProductCategory] = useState<SelectOption | null>(null);
    const [inputIsSell, setInputIsSell] = useState(false);
    const [inputIsBuy, setInputIsBuy] = useState(false);
    const [inputIsMaterial, setInputIsMaterial] = useState(false);
    const [mounted, setMounted] = useState(false);
    const [company, setCompany] = useState<Company | null>(null);
    const [jarayaTradingSetting, setJarayaTradingSetting] = useState<JarayaTradingSetting | null>(null);






    useEffect(() => {
        setInputName(initialProductName!)
        getCompanyDetail()
            .then(v => v.json())
            .then(v => setCompany(v.data))


        setMounted(true)
    }, []);

    useEffect(() => {
        setJarayaTradingSetting(company?.product_add_on_setting?.jaraya_trading_setting ?? null)

    }, [company]);

    useEffect(() => {
        if (!mounted) return
        getAllCategories()

    }, [mounted]);

    const getAllCategories = () => {
        getProductCategories({ page: 1, limit: 1000 })
            .then(v => v.json())
            .then(v => setProductCategories(v.data))
    }



    useEffect(() => {
        let cat: { value: string; label: string; }[] = []
        for (let index = 0; index < productCategories.length; index++) {
            const element = productCategories[index];
            cat.push({ value: element.uuid, label: element.name })
        }
        setCategories(cat)
    }, [productCategories]);

    const saveAddProductCategory = async (name: string) => {
        try {
            setIsLoading(true)
            const resp = await addProductCategory({
                name: name,
            })
            await resp.json()
            getAllCategories()

        } catch (error) {
            Swal.fire("Perhatian", `${error}`, "error")
        } finally {
            setIsLoading(false)
        }
    }

    const saveAddProduct = async () => {
        if (inputName.length == 0) {
            Swal.fire("Perhatian", `Name Produk tidak boleh kosong`, "error")
            return
        }

        if (!inputIsBuy && !inputIsSell &&!inputIsMaterial) {
            Swal.fire("Perhatian", `Pilih Produk Jual / Beli`, "error")
            return
        }

        try {
            setIsLoading(true)
            setIsOpen(false)
            const resp = await addProduct({
                name: inputName,
                price: inputPrice,
                product_category_id: {
                    Valid: inputProductCategoryID.length != 0,
                    String: inputProductCategoryID,
                },
                is_buy: inputIsBuy,
                is_sell: inputIsSell,
                is_material: inputIsMaterial,
                varian_parameters: JSON.stringify(jarayaTradingSetting?.varian_parameters ?? [])
            })
            const respJson = await resp.json()
            if (onSubmit != null) {
                onSubmit(respJson.data)
            }
            //   router.push(`/product/${respJson.data}`)
        } catch (error) {
            Swal.fire("Perhatian", `${error}`, "error")
        } finally {
            setIsLoading(false)
        }

        // setIsOpen(false)
    }



    return (<Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setIsOpen(false)}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="fixed inset-0 bg-black/25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Dialog.Panel className="w-full max-w-2xl  max-h-screen transform overflow-hidden rounded-2xl bg-form-dialog p-6 text-left align-middle shadow-xl transition-all">
                            <Dialog.Title
                                as="h3"
                                className="text-xl font-medium leading-6 text-gray-900"
                            >
                                Tambah Produk
                            </Dialog.Title>
                            <div className="mt-4">
                                <div className="flex items-center mb-6">
                                    <div className="w-1/3">
                                        <label
                                            className="block text-gray-900 mb-0 pr-4"
                                        >
                                            Nama Produk
                                        </label>
                                    </div>
                                    <div className="w-2/3">
                                        <input
                                            className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                            type="text"
                                            placeholder="Nama Produk"
                                            value={inputName ?? ""}
                                            onChange={(e) => setInputName(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="flex items-center mb-6">
                                    <div className="w-1/3">
                                        <label
                                            className="block text-gray-900 mb-0 pr-4"
                                            htmlFor="product-category"
                                        >
                                            Kategori
                                        </label>
                                    </div>
                                    <div className="w-2/3">
                                        <Creatable< SelectOption, false> styles={colourStyles} options={categories} value={inputProductCategory}
                                            onChange={(option: SingleValue<SelectOption>): void => {
                                                setInputProductCategoryID(option?.value ?? "")
                                                setInputProductCategory(option)
                                            }}
                                            onCreateOption={(val) => {
                                                saveAddProductCategory(val)
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="flex items-center mb-6">
                                    <div className="w-1/3">
                                        <label
                                            className="block text-gray-900 mb-0 pr-4"
                                            htmlFor="base-price"
                                        >
                                            Harga Dasar
                                        </label>
                                    </div>
                                    <div className="w-2/3">
                                        <CurrencyInput
                                            className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                            id="base-price"
                                            groupSeparator="."
                                            decimalSeparator=","
                                            placeholder="Harga Dasar"
                                            onValueChange={(value, _, values) => setInputPrice(values?.float ?? 0)}
                                        />
                                    </div>
                                </div>
                                <div className="flex items-center mb-6">
                                    <div className="w-1/3">
                                        <label
                                            className="block text-gray-900 mb-0 pr-4"
                                            htmlFor="base-price"
                                        >
                                            Produk Jual
                                        </label>
                                    </div>
                                    <div className="w-2/3">
                                        <Switch
                                            checked={inputIsSell}
                                            onChange={setInputIsSell}
                                            className={`${inputIsSell ? 'bg-blue-600' : 'bg-gray-200'
                                                } relative inline-flex h-6 w-11 items-center rounded-full`}
                                        >
                                            <span className="sr-only">Produk Jual</span>
                                            <span
                                                aria-hidden="true"
                                                className={`${inputIsSell ? 'translate-x-6' : 'translate-x-1'
                                                    } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                            />
                                        </Switch>
                                    </div>
                                </div>
                                <div className="flex items-center mb-6">
                                    <div className="w-1/3">
                                        <label
                                            className="block text-gray-900 mb-0 pr-4"
                                            htmlFor="base-price"
                                        >
                                            Produk Beli
                                        </label>
                                    </div>
                                    <div className="w-2/3">
                                        <Switch
                                            checked={inputIsBuy}
                                            onChange={setInputIsBuy}
                                            className={`${inputIsBuy ? 'bg-blue-600' : 'bg-gray-200'
                                                } relative inline-flex h-6 w-11 items-center rounded-full`}
                                        >
                                            <span className="sr-only">Produk Beli</span>
                                            <span
                                                aria-hidden="true"
                                                className={`${inputIsBuy ? 'translate-x-6' : 'translate-x-1'
                                                    } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                            />
                                        </Switch>
                                    </div>
                                </div>
                                <div className="flex items-center mb-6">
                                    <div className="w-1/3">
                                        <label
                                            className="block text-gray-900 mb-0 pr-4"
                                            htmlFor="base-price"
                                        >
                                            Bahan Baku
                                        </label>
                                    </div>
                                    <div className="w-2/3">
                                        <Switch
                                            checked={inputIsMaterial}
                                            onChange={setInputIsMaterial}
                                            className={`${inputIsMaterial ? 'bg-blue-600' : 'bg-gray-200'
                                                } relative inline-flex h-6 w-11 items-center rounded-full`}
                                        >
                                            <span className="sr-only">Bahan Baku</span>
                                            <span
                                                aria-hidden="true"
                                                className={`${inputIsMaterial ? 'translate-x-6' : 'translate-x-1'
                                                    } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                            />
                                        </Switch>
                                    </div>
                                </div>


                            </div>

                            <div className="mt-4">
                                <button
                                    type="button"
                                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                    onClick={saveAddProduct}
                                >
                                    <FolderIcon
                                        className=" h-5 w-5 mr-2"
                                        aria-hidden="true" />  Simpan
                                </button>
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </div>
        </Dialog>
    </Transition>);
}
export default FormProduct;