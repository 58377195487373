import { Fragment, type FC, useEffect, useState, useContext } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CompanyRole, Permission } from '../../model/company';
import { createRole, getCompanyRolePermissions, updateRole } from '../../repositories/company';
import { ucFirstWord } from '../../utils/helper';
import { Button, ButtonGroup, ButtonToolbar, Tooltip, Whisper } from 'rsuite';
import { FolderIcon, ListBulletIcon, MagnifyingGlassIcon, TrashIcon } from '@heroicons/react/24/outline';
import { LoadingContext } from '../../objects/loading_context';
import Swal from 'sweetalert2';


interface ModalPermissionsProps {
    role?: CompanyRole | null
    isOpen: boolean
    setIsOpen: (arg: boolean) => void
    onClose?: () => void
    onSubmit?: () => void
}

const ModalPermissions: FC<ModalPermissionsProps> = ({
    role,
    isOpen,
    setIsOpen,
    onClose,
    onSubmit,
}) => {
    let { isLoading, setIsLoading } = useContext(LoadingContext);

    const [userPermissions, setUserPermissions] = useState<Permission[]>([]);
    const [groupPermissions, setGroupPermissions] = useState<Record<string, Permission[]>>({});
    const [mounted, setMounted] = useState(false);
    const [inputName, setInputName] = useState("");
    const [inputDescription, setInputDescription] = useState("");
    const [search, setSearch] = useState("");


    useEffect(() => {
        setMounted(true)
    }, []);

    useEffect(() => {
        if (!mounted) return
        setInputName("")
        setInputDescription("")
        setSearch("")
        setUserPermissions([])
        getAllRole()

    }, [mounted]);
    useEffect(() => {
        if (!mounted) return
        setInputName("")
        setInputDescription("")
        setSearch("")
        setUserPermissions([])
        getAllRole()


    }, [role]);

    const getAllRole = () => {
        getCompanyRolePermissions({ search: "", roleId: role?.uu_id ?? "" })
            .then(v => v.json())
            .then(v => {
                setUserPermissions(v.data)
                if (role != null) {
                    setInputName(role?.name)
                    setInputDescription(role?.description)
                }
                // console.log(v.data)
            }).catch(console.log)
    }

    useEffect(() => {
        let result = userPermissions.reduce(function (r, a) {
            r[a.group] = r[a.group] || [];
            r[a.group].push(a);
            return r;
        }, Object.create(null));
        setGroupPermissions(result)
    }, [userPermissions]);

    const save = async () => {
        try {
            setIsLoading(true)
            if (role) {
                await updateRole({
                    name: inputName,
                    description: inputDescription,
                    permissions: JSON.stringify(userPermissions.filter(e => e.checked).map(e => e.key))
                }, role!.uu_id)
            } else {
                await createRole({
                    name: inputName,
                    description: inputDescription,
                    permissions: JSON.stringify(userPermissions.filter(e => e.checked).map(e => e.key))
                })
            }

            if (onSubmit != null) {
                onSubmit()
            }

            //   router.push(`/product/${respJson.data}`)
        } catch (error) {
            Swal.fire("Perhatian", `${error}`, "error")
        } finally {
            setIsLoading(false)
        }
    }


    return (<Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={(val: boolean) => {
            setIsOpen(val)


            if (onClose != null) onClose()
        }} >
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="fixed inset-0 bg-black/25" />
            </Transition.Child>

            <div className="fixed inset-0 ">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Dialog.Panel className="w-full flex flex-col  transform overflow-hidden rounded-2xl bg-form-dialog p-6 text-left align-middle shadow-xl transition-all" style={{ width: 'calc(100vw - 200px)', height: 'calc(100vh - 200px)' }}>
                            <Dialog.Title
                                as="h3"
                                className="text-xl font-medium leading-6 text-gray-900"
                            >
                                <div className=' flex justify-between'>
                                    <span>
                                        Form Hak Akses
                                    </span>
                                    <form className=" w-60 ">
                                        <div className="relative">
                                            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                                <MagnifyingGlassIcon
                                                    className=" h-5 w-5 text-violet-200 hover:text-violet-100"
                                                    aria-hidden="true"
                                                />
                                            </div>
                                            <input
                                                type="search"
                                                id="default-search"
                                                className="block w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-full bg-white focus:ring-blue-500 focus:border-blue-500   "
                                                placeholder="Search..."
                                                onChange={(val) => {
                                                    setSearch(val.target.value)
                                                }}
                                            />
                                        </div>
                                    </form>

                                </div>
                            </Dialog.Title>
                            <div className="flex items-center mb-6 mt-4">
                                <div className="w-1/3">
                                    <label
                                        className="block text-gray-900 mb-0 pr-4"
                                        htmlFor="product-name"
                                    >
                                        Nama Hak Akses
                                    </label>
                                </div>
                                <div className="w-2/3">
                                    <input
                                        className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                        id="product-name"
                                        type="text"
                                        placeholder="Nama Hak Akses"
                                        value={inputName}
                                        onChange={(e) => setInputName(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="flex items-start mb-6 ">
                                <div className="w-1/3">
                                    <label
                                        className="block text-gray-900 mb-0 pr-4"
                                        htmlFor="desc"
                                    >
                                        Keterangan
                                    </label>
                                </div>
                                <div className="w-2/3">
                                    <textarea
                                        className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                        id="desc"
                                        rows={5}
                                        placeholder="Keterangan"
                                        value={inputDescription}
                                        onChange={(e) => setInputDescription(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className="flex-1 overflow-y-auto ">
                                {Object.keys(groupPermissions).map(e => <div key={e} className="flex items-center mb-6 border-b py-5">
                                    <div className="w-1/3">
                                        <label
                                            className="block font-medium text-gray-900 mb-0 pr-4"
                                            htmlFor="product-name"
                                        >
                                            {ucFirstWord(e)}
                                        </label>
                                        <div className='flex '>

                                            <span onClick={() => {
                                                setUserPermissions([...userPermissions.map(u => {
                                                    if (u.group == e) {
                                                        u.checked = true
                                                    }
                                                    return u
                                                })])
                                            }} className='mr-3 hover:font-medium text-green-800 cursor-pointer flex items-center text-xs'><ListBulletIcon
                                                    className=" h-3 w-3 mr-1"
                                                    aria-hidden="true" />  Pilih Semua</span>
                                            <span onClick={() => {
                                                setUserPermissions([...userPermissions.map(u => {
                                                    if (u.group == e) {
                                                        u.checked = false
                                                    }
                                                    return u
                                                })])
                                            }} className='mr-3 hover:font-medium text-red-800 cursor-pointer flex items-center text-xs'><TrashIcon
                                                    className=" h-3 w-3 mr-1"
                                                    aria-hidden="true" />  Hapus Semua</span>
                                        </div>
                                    </div>
                                    <div className="w-2/3">
                                        <ButtonToolbar>
                                            {groupPermissions[e].filter(s => s.name.toLowerCase().includes(search.toLowerCase())).map(i =>
                                                <Whisper key={i.key} followCursor speaker={<Tooltip>{i.description}</Tooltip>}>
                                                    <Button onClick={() => {

                                                        setUserPermissions([...userPermissions.map(u => {
                                                            if (u.key == i.key) {
                                                                u.checked = !i.checked
                                                            }
                                                            return u
                                                        })])

                                                    }} color="violet" className=' rounded-full' key={i.key} appearance={i.checked ? `primary` : `ghost`}>{i.name}</Button>
                                                </Whisper>
                                            )}
                                        </ButtonToolbar>

                                    </div>
                                </div>)}

                            </div>
                            <div className='flex mt-4'>


                                <button
                                    type="button"
                                    className="inline-flex justify-center items-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-xs font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                    onClick={save}
                                >
                                    <FolderIcon
                                        className=" h-3 w-3 mr-1"
                                        aria-hidden="true" />  Simpan
                                </button>
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </div>
        </Dialog></Transition>);
}
export default ModalPermissions;