import { Vendor } from '../../model/vendor';
import { useEffect, useState, type FC } from 'react';
import ModalSearch from './modal_search';
import { getVendors } from '../../repositories/vendor';
import Avatar from '../avatar';
import { COLORS } from '../../objects/constants';

interface ModalVendorProps {
    isOpen: boolean
    setIsOpen: (a: boolean) => void
    onChange?: (a: Vendor) => void
    onClick: (a: Vendor) => void
}

const ModalVendor: FC<ModalVendorProps> = ({
    isOpen,
    setIsOpen,
    onChange,
    onClick,
}) => {

    const [vendors, setVendors] = useState<Vendor[]>([]);

    useEffect(() => {
        getVendors({ page: 1, limit: 10, search: "" })
            .then(v => v.json())
            .then(v => {
                setVendors(v.data)
            })
    }, []);
    return (<ModalSearch isOpen={isOpen} setIsOpen={setIsOpen} onChange={(val) => {
        getVendors({ page: 1, limit: 10, search: val })
            .then(v => v.json())
            .then(v => {
                setVendors(v.data)

            })
    }} onClose={() => {
        setIsOpen(false)
    }} >
        <ul role="list" className="divide-y divide-gray-100">
            {vendors.map((v, index) => (
                <li key={v.uuid} onClick={() => {
                    if (onClick) onClick(v)
                    setIsOpen(false)
                }}
                    className="flex items-center gap-x-4 px-3 py-5 hover:bg-gray-600 outline-none cursor-pointer text-gray-900 hover:text-white"
                >
                    <Avatar marginRight={0} name={v.name} url={v.logo ?? ''} style={{ backgroundColor: COLORS[Math.floor(Math.random() * COLORS.length)].color }} />
                    <div className="min-w-0">
                        <p className="text-sm font-semibold leading-6   ">{v.name}</p>
                        {v.address.length > 0 && <p className="-mt-0.5 truncate text-xs leading-5  " >{v.address}</p>}
                        {v.phone.length > 0 && <p className="-mt-0.5 truncate text-xs leading-5  " >Telp. {v.phone}</p>}
                        {v.email.length > 0 && <p className="-mt-0.5 truncate text-xs leading-5  " >Email. {v.email}</p>}
                    </div>
                </li>
            ))}
        </ul>
    </ModalSearch>);
}
export default ModalVendor;