import { LoadingContext } from "../../objects/loading_context";
import { Dialog, Transition } from '@headlessui/react';
import FolderIcon from '@heroicons/react/24/outline/FolderIcon';
import { Fragment, useContext, useState, type FC } from 'react';
import Swal from 'sweetalert2';
import Badge from '../badge';
import { addStore } from '../../repositories/store';
import { setNullString } from '../../utils/helper';

interface FormStoreProps {
    isOpen: boolean
    setIsOpen: (arg: boolean) => void
    onSubmit?: () => void
}

const FormStore: FC<FormStoreProps> = ({
    isOpen,
    setIsOpen,
    onSubmit
}) => {
    const [name, setName] = useState("");
    const [code, setCode] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [fax, setFax] = useState("");
    const [address, setAddress] = useState("");
    const [contactPerson, setContactPerson] = useState("");
    const [contactPersonPosition, setContactPersonPosition] = useState("");
    const [advanceMode, setAdvanceMode] = useState(false);
    const [more, setMore] = useState(false);
    let { isLoading, setIsLoading } = useContext(LoadingContext);

    const save = async () => {
        try {
            setIsLoading(true)

            await addStore({
                name,
                code,
                email: setNullString(email),
                phone: setNullString(phone),
                fax: setNullString(fax),
                address: setNullString(address),
                contact_person: setNullString(contactPerson),
                contact_person_position: setNullString(contactPersonPosition),
                advance_mode: advanceMode,
            })
            if (onSubmit != null) {
                onSubmit()
            }

        } catch (error) {
            Swal.fire("Perhatian", `${error}`, "error")
        } finally {
            setIsLoading(false)
        }

    }

    const clear = () => {
        setName("")
        setCode("")
        setEmail("")
        setPhone("")
        setFax("")
        setAddress("")
        setContactPerson("")
        setContactPersonPosition("")
        setAdvanceMode(false)
        setMore(false)
    }


    return (<Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setIsOpen(false)}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="fixed inset-0 bg-black/25" />
            </Transition.Child>

            <div className="fixed inset-0 ">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Dialog.Panel className="w-full flex flex-col max-w-2xl transform overflow-hidden rounded-2xl bg-form-dialog p-6 text-left align-middle shadow-xl transition-all" style={{ height: 'calc(100vh - 300px)' }}>
                            <Dialog.Title
                                as="h3"
                                className="text-xl font-medium leading-6 text-gray-900"
                            >
                                Tambah Gudang
                            </Dialog.Title>
                            <div className="flex-1 overflow-y-auto">
                                <div className="mt-4 ">
                                    <div className="flex items-center mb-6">
                                        <div className="w-1/3">
                                            <label className="block text-gray-900 mb-0 pr-4">
                                                Nama Gudang
                                            </label>
                                        </div>
                                        <div className="w-2/3">
                                            <input
                                                className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                                
                                                type="text"
                                                placeholder="Nama Gudang"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-4 ">
                                    <div className="flex items-center mb-6">
                                        <div className="w-1/3">
                                            <label className="block text-gray-900 mb-0 pr-4">
                                                Kode
                                            </label>
                                        </div>
                                        <div className="w-2/3">
                                            <input
                                                className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                                type="text"
                                                placeholder="Kode"
                                                value={code}
                                                onChange={(e) => setCode(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-4 ">
                                    <div className="flex  mb-6">
                                        <div className="w-1/3">
                                            <label className="block text-gray-900 mb-0 pr-4">
                                                Alamat
                                            </label>
                                        </div>
                                        <div className="w-2/3">
                                            <textarea
                                                className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                                rows={5}
                                                placeholder="Alamat"
                                                value={address}
                                                onChange={(e) => setAddress(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {!more &&
                                    <div className='flex justify-center'>
                                        <Badge className='py-2 px-4 cursor-pointer' label='Selengkapnya' color='gray' onClick={() => {
                                            setMore(true)
                                        }} />
                                    </div>
                                }
                                {more && <div>
                                    <div className="mt-4 ">
                                        <div className="flex items-center mb-6">
                                            <div className="w-1/3">
                                                <label className="block text-gray-900 mb-0 pr-4">
                                                    Email
                                                </label>
                                            </div>
                                            <div className="w-2/3">
                                                <input
                                                    className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                                    type="text"
                                                    placeholder="Email"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-4 ">
                                        <div className="flex items-center mb-6">
                                            <div className="w-1/3">
                                                <label className="block text-gray-900 mb-0 pr-4">
                                                    Telp
                                                </label>
                                            </div>
                                            <div className="w-2/3">
                                                <input
                                                    className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                                    type="text"
                                                    placeholder="Telp"
                                                    value={phone}
                                                    onChange={(e) => setPhone(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-4 ">
                                        <div className="flex items-center mb-6">
                                            <div className="w-1/3">
                                                <label className="block text-gray-900 mb-0 pr-4">
                                                    Fax
                                                </label>
                                            </div>
                                            <div className="w-2/3">
                                                <input
                                                    className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                                    type="text"
                                                    placeholder="Fax"
                                                    value={fax}
                                                    onChange={(e) => setFax(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-4 ">
                                        <div className="flex items-center mb-6">
                                            <div className="w-1/3">
                                                <label className="block text-gray-900 mb-0 pr-4">
                                                    Penanggung Jawab
                                                </label>
                                            </div>
                                            <div className="w-2/3">
                                                <input
                                                    className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                                    type="text"
                                                    placeholder="Penanggung Jawab"
                                                    value={contactPerson}
                                                    onChange={(e) => setContactPerson(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="flex items-center mb-6">
                                            <div className="w-1/3">
                                                <label className="block text-gray-900 mb-0 pr-4">
                                                    Jabatan
                                                </label>
                                            </div>
                                            <div className="w-2/3">
                                                <input
                                                    className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                                    type="text"
                                                    placeholder="Jabatan"
                                                    value={contactPersonPosition}
                                                    onChange={(e) => setContactPersonPosition(e.target.value)}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>}

                            </div>

                            <div className="mt-4">
                                <button
                                    type="button"
                                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                    onClick={save}
                                >
                                    <FolderIcon
                                        className=" h-5 w-5 mr-2"
                                        aria-hidden="true" />  Simpan
                                </button>
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </div>
        </Dialog>
    </Transition>);
}
export default FormStore;