import { CompanyMember, CompanyRole } from '../../model/company';
import { deleteMember, deleteRole, getCompanyMembers, getCompanyRoles, getMyPermissions, inviteMember, updateMemberRole } from '../../repositories/company';
import { useState, type FC, useEffect, useContext } from 'react';
import { Button, ButtonGroup, Checkbox, Dropdown, Panel, Stack } from 'rsuite';
import CustomTable, { TableRecord } from '../custom_table';
import { Pagination } from '../../model/pagination';
import { ChevronDownIcon, FolderIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import Swal from 'sweetalert2';
import { setMyPermissions, setNullString } from '../../utils/helper';
import ModalCustom from '../modals/modal_custom';
import { LoadingContext } from '../../objects/loading_context';
import ModalPermissions from '../modals/modal_permissions';

interface UserProps {

}

const UserSetting: FC<UserProps> = ({

}) => {
  let { isLoading, setIsLoading } = useContext(LoadingContext);
  const [mounted, setMounted] = useState(false);
  const [roles, setRoles] = useState<CompanyRole[]>([]);

  const [members, setMembers] = useState<CompanyMember[]>([]);
  const [pageMember, setPageMember] = useState(1);
  const [limitMember, setLimitMember] = useState(20);
  const [searchMember, setSearchMember] = useState("");
  const [paginationMember, setPaginationMember] = useState<Pagination | null>(null);
  const [modalMemberOpen, setModalMemberOpen] = useState(false);
  const [inputName, setInputName] = useState("");
  const [inputEmail, setInputEmail] = useState("");
  const [role, setRole] = useState<CompanyRole | null>(null);
  const [modalPermissionsOpen, setModalPermissionsOpen] = useState(false);



  useEffect(() => {
    setMounted(true)
  }, []);

  useEffect(() => {
    if (!mounted) return
    geAllCompanyMember()
    getCompanyRoles()
      .then(v => v.json())
      .then(v => {
        setRoles(v.data)

      })
      getMyAllPermissions()
  }, [mounted]);

  const geAllCompanyMember = (): void => {
    getCompanyMembers()
      .then(v => v.json())
      .then(v => {
        setMembers(v.data)
        setPaginationMember(v.meta)
      })
  }
 
  const getMyAllPermissions = () => {
    getMyPermissions()
    .then(v => v.json())
    .then(v => {
      setMyPermissions(v.data)
    })
  }

  const inviteNewMember = async () => {
    try {
      setModalMemberOpen(false)
      setIsLoading(true)
      var resp = await inviteMember({
        email: inputEmail,
        name: inputName,
        role_id: setNullString(role?.uu_id)
      })
      var respJson = await resp.json()
      geAllCompanyMember()
      Swal.fire("Perhatian", `Silakan cek email yang anda daftarkan`, "success")
      setInputName("")
      setInputEmail("")
      setRole(null)

    } catch (error) {
      Swal.fire("Perhatian", `${error}`, "error")
    } finally {
      setIsLoading(false)
    }
  }


  return (<div className="flex-1 mb-5 col-span-1 mt-5 rounded-xl py-6 px-6 transition-all hover:shadow-lg sm:p-4 lg:px-4 xl:px-6 bg-white cursor-pointer">
    <div className='  grid gap-x-8 gap-y-4 sm:gap-y-0 xl:grid-cols-4 lg:grid-cols-4 sm:grid-cols-2 md:grid-cols-2'>
      <div className='col-span-2'>
        <Panel className='' bordered
          header={
            <Stack justifyContent="space-between">
              <span>Pengguna</span>
              <button
                type="button"
                className="mr-2 inline-flex justify-center items-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-xs font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                onClick={() => setModalMemberOpen(true)}
              >
                <PlusIcon
                  className=" h-3 w-3 mr-1"
                  aria-hidden="true" />  Tambah Member
              </button>
            </Stack>
          }
        >

          <CustomTable headers={["#", "Nama", "Email", "Hak Akses", ""]}

            headerClasses={[]} datasets={members.map(e => {
              let data: TableRecord = {
                cells: [
                  { data: ((pageMember - 1) * limitMember) + (members.indexOf(e) + 1) },
                  {
                    data: <div className=' hover:font-medium ' onClick={() => {

                    }}>
                      {e.full_name}
                    </div>,
                  },
                  { data: e.email, },
                  {
                    data: <div>
                      <select
                        className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                        value={e.company_role_id}
                        onChange={(el) => {
                          updateMemberRole(e.uuid, {
                            role_id: setNullString(el.target.value),
                            is_receive_notif: e.is_receive_notif
                          }).then(v => geAllCompanyMember())

                        }} >
                        {(roles ?? []).map(role =>
                          <option key={role.uu_id} value={role.uu_id}>{role.name}</option>)}
                      </select>

                    </div>,
                  },
                  {
                    data: <div className='flex '>
                      <Checkbox checked={e.is_receive_notif} onChange={(val, checked) => {

                      }} />
                      <TrashIcon className="w-5 text-red-600" onClick={() => {
                        Swal.fire({
                          title: "Anda Yakin",
                          text: "Anda tidak akan dapat mengembalikan proses ini!",
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: "#3085d6",
                          cancelButtonColor: "#d33",
                          confirmButtonText: "Ya, Hapus!",
                          cancelButtonText: "Batal"
                        }).then((result) => {
                          if (result.isConfirmed) {
                            deleteMember(e.uuid)
                              .then(v => geAllCompanyMember())
                          }
                        });
                      }} /></div>, className: 'w-4 text-right'
                  },
                ],
                className: "last:border-0 hover:bg-gray-50"
              }
              return data
            })} />
        </Panel>
      </div>
      <div className='col-span-2'>
        <Panel className=' min-h-[300px]' bordered
          header={
            <Stack justifyContent="space-between">
              <span>Hak Akses</span>
              <button
                type="button"
                className="mr-2 inline-flex justify-center items-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-xs font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                onClick={() => {
                  setRole(null)
                  setTimeout(() => {
                    setModalPermissionsOpen(true)
                  }, 300);
                }}
              >
                <PlusIcon
                  className=" h-3 w-3 mr-1"
                  aria-hidden="true" />  Tambah Hak Akses
              </button>
            </Stack>
          }
        >
          <CustomTable headers={["#", "Hak Akses", "Keterangan", ""]} headerClasses={[]} datasets={roles.map(e => {
            let data: TableRecord = {
              cells: [
                { data: (roles.indexOf(e) + 1) },
                {
                  data: <div className=' hover:font-medium ' onClick={() => {
                    setRole(e)
                    setTimeout(() => {
                      setModalPermissionsOpen(true)
                    }, 300);
                  }}>
                    {e.name}
                  </div>,
                },
                { data: e.description, },

                {
                  data: <div className='flex '>

                    <TrashIcon className="w-5 text-red-600" onClick={() => {
                      Swal.fire({
                        title: "Anda Yakin",
                        text: "Anda tidak akan dapat mengembalikan proses ini!",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Ya, Hapus!",
                        cancelButtonText: "Batal"
                      }).then((result) => {
                        if (result.isConfirmed) {
                          deleteRole(e.uu_id)
                            .then(v => {
                              getCompanyRoles()
                                .then(v => v.json())
                                .then(v => {
                                  setRoles(v.data)
                                })
                            })
                        }
                      });
                    }} /></div>, className: 'w-4 text-right'
                },
              ],
              className: "last:border-0 hover:bg-gray-50"
            }
            return data
          })} />
        </Panel>
      </div>
    </div>
    <ModalCustom
      footer={
        <div className='flex'>
          <button
            type="button"
            className="inline-flex justify-center items-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-xs font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
            onClick={inviteNewMember}
          >
            <FolderIcon
              className=" h-3 w-3 mr-1"
              aria-hidden="true" />  Simpan
          </button></div>}
      isOpen={modalMemberOpen} setIsOpen={setModalMemberOpen} title='Tambah Member' onClose={() => {
        setModalMemberOpen(false)
        setInputName("")
        setInputEmail("")
        setRole(null)
      }} >
      <div className='mt-4'>
        <div className="flex items-center mb-6">
          <div className="w-1/3">
            <label
              className="block text-gray-900 mb-0 pr-4"
            >
              Nama Lengkap
            </label>
          </div>
          <div className="w-2/3">
            <input
              className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              type="text"
              placeholder="Nama Lengkap"
              value={inputName}
              onChange={(e) => setInputName(e.target.value)}
            />
          </div>
        </div>
        <div className="flex items-center mb-6">
          <div className="w-1/3">
            <label
              className="block text-gray-900 mb-0 pr-4"
            >
              Email
            </label>
          </div>
          <div className="w-2/3">
            <input
              className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              type="email"
              placeholder="Email"
              value={inputEmail}
              onChange={(e) => setInputEmail(e.target.value)}
            />
          </div>
        </div>
        <div className="flex items-center mb-6">
          <div className="w-1/3">
            <label
              className="block text-gray-900 mb-0 pr-4"
            >
              Hak Akses
            </label>
          </div>
          <div className="w-2/3">
            <select
              className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              value={role?.uu_id}
              onChange={(el) => {
                let selected = (roles ?? []).find(e => e.uu_id == el.target.value)
                if (selected) setRole(selected)

              }} >
              {(roles ?? []).map(role =>
                <option key={role.uu_id} value={role.uu_id}>{role.name}</option>)}
            </select>
          </div>
        </div>
      </div>
    </ModalCustom>
    <ModalPermissions role={role} isOpen={modalPermissionsOpen} setIsOpen={setModalPermissionsOpen} onClose={() => {
      setModalPermissionsOpen(false)
      getCompanyRoles()


    }} onSubmit={() => {
      setModalPermissionsOpen(false)
      getCompanyRoles()
        .then(v => v.json())
        .then(v => {
          setRoles(v.data)
          Swal.fire("Perhatian", `Hak Akses Berhasil di update`, "success")
          getMyAllPermissions()
        })

    }} />
  </div>);
}
export default UserSetting;


