import { Fragment, useContext, useEffect, useState, type FC } from 'react';
import { LoadingContext } from '../objects/loading_context';
import { Pagination } from '../model/pagination';
import { useNavigate, useParams } from 'react-router-dom';
import { ProductRequest, Store, StoreProduct, StoreUser } from '../model/store';
import { CompanyMember } from '../model/company';
import { SelectOption, colourStyles } from '../utils/style';
import { getPermissions, randomStr, setNullString, ucFirstWord } from '../utils/helper';
import { getCompanyMembers, getSpecialPermissions } from '../repositories/company';
import { addStore, addStoreRequest, addStoreUser, deleteStoreUser, getStoreDetail, getStorePermissions, getStoreProducts, getStoreRequests, getStoreUsers, getStores, updateStore } from '../repositories/store';
import Swal from 'sweetalert2';
import Moment from 'react-moment';
import DataTable from 'react-data-table-component';
import Badge from '../components/badge';
import { Dialog, Switch, Transition } from '@headlessui/react';
import { Checkbox, DatePicker } from 'rsuite';
import Select, { SingleValue } from 'react-select';
import Creatable from 'react-select/creatable';
import { EyeIcon, FolderIcon, TrashIcon } from '@heroicons/react/24/outline';
import { money } from '../utils/number';
import DashboardLayout from '../components/dashboard';
import Back from '../components/back';

interface StoreDetailProps {}

const StoreDetail: FC<StoreDetailProps> = ({}) => {

    const [mounted, setMounted] = useState(false);
    let { isLoading, setIsLoading } = useContext(LoadingContext);
    const { storeId } = useParams()
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(20);
    const [search, setSearch] = useState("");
    const [pagination, setPagination] = useState<Pagination | null>(null);

    const [data, setData] = useState<Store | null>(null);
    const nav = useNavigate()
    const [name, setName] = useState<string>("");
    const [code, setCode] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [phone, setPhone] = useState<string>("");
    const [fax, setFax] = useState<string>("");
    const [address, setAddress] = useState<string>("");
    const [contactPerson, setContactPerson] = useState<string>("");
    const [contactPersonPosition, setContactPersonPosition] = useState<string>("");
    const [advanceMode, setAdvanceMode] = useState(false);
    const [more, setMore] = useState(false);
    const [products, setProducts] = useState<StoreProduct[]>([]);

    const [permissions, setPermissions] = useState<string[]>([]);
    const [storePermissions, setStorePermissions] = useState<string[]>([]);
    const [members, setMembers] = useState<CompanyMember[]>([]);
    const [users, setUsers] = useState<StoreUser[]>([]);


    const [pageUsers, setPageUsers] = useState(1);
    const [limitUsers, setLimitUsers] = useState(20);
    const [searchUsers, setSearchUsers] = useState("");
    const [paginationUsers, setPaginationUsers] = useState<Pagination | null>(null);

    const [productRequest, setProductRequest] = useState<ProductRequest[]>([]);

    const [pageRequest, setPageRequest] = useState(1);
    const [limitRequest, setLimitRequest] = useState(20);
    const [searchRequest, setSearchRequest] = useState("");
    const [paginationRequest, setPaginationRequest] = useState<Pagination | null>(null);

    const [specialPermissionList, setSpecialPermissionList] = useState<{ key: string, name: string }[]>([]);
    const [modalUserOpen, setModalUserOpen] = useState(false);

    const [memberSelect, setMemberSelect] = useState<SelectOption[]>([]);
    const [inputMemberId, setInputMemberId] = useState("");
    const [inputMember, setInputMember] = useState<SelectOption | null>();
    const [inputSpecialPermissions, setInputSpecialPermissions] = useState<string[]>([]);
    const [date, setDate] = useState<Date>(new Date());
    const [modalRequestOpen, setModalRequestOpen] = useState(false);
    const [requestNumber, setRequestNumber] = useState("");

    const [inputDestinationId, setInputDestinationId] = useState("");
    const [inputDestination, setInputDestination] = useState<SelectOption | null>();
    const [storeSelect, setStoreSelect] = useState<SelectOption[]>([]);
    const [typeSelect, setTypeSelect] = useState<SelectOption[]>([{ value: "DEMAND", label: "Pengambilan Barang" }, { value: "RETURN", label: "Pengembalian Barang" }]);

    const [stores, setStores] = useState<Store[]>([]);

    const [inputType, setInputType] = useState<SelectOption | null>();
    const [inputNotes, setInputNotes] = useState("");


    useEffect(() => {
        setMounted(true)
    }, []);

    useEffect(() => {
        initData()
    }, [mounted]);

    const initData = async () => {
        getDetail()
        setPermissions(await getPermissions())
        getCompanyMembers()
            .then(v => v.json())
            .then(v => {
                setMembers(v.data)
            })
        getStorePermissions(storeId!)
            .then(v => v.json())
            .then(v => {
                setStorePermissions(v.data)
            })
        getAllProductRequest()
        getSpecialPermissions("store")
            .then(v => v.json())
            .then(v => {
                setSpecialPermissionList(v.data)
            })
        getStores({ page: 1, limit: 1000 })
            .then(v => v.json())
            .then(v => {
                setStores(v.data)
            })
        getUsers()
    }

    useEffect(() => {
        let v: { value: string; label: string; }[] = []
        for (let index = 0; index < members.length; index++) {
            const element = members[index];
            v.push({ value: element.uuid!, label: element.full_name })
        }

        setMemberSelect(v)
    }, [members]);

    useEffect(() => {
        let u: { value: string; label: string; }[] = []
        for (let index = 0; index < stores.filter(e => e.uuid != storeId!).length; index++) {
            const element = stores.filter(e => e.uuid != storeId!)[index];
            u.push({ value: element.uuid!, label: element.name })
        }
        setStoreSelect(u)
    }, [stores]);

    useEffect(() => {
        if (!data) return

        setName(data?.name ?? "")
        setCode(data?.code ?? "")
        setEmail(data?.email ?? "")
        setPhone(data?.phone ?? "")
        setFax(data?.fax ?? "")
        setAddress(data?.address ?? "")
        setContactPerson(data?.contact_person ?? "")
        setContactPersonPosition(data?.contact_person_position ?? "")
        setAdvanceMode(data.advance_mode ?? false)
    }, [data]);



    useEffect(() => {
        if (!mounted) return
        getProducts()
    }, [search, page, limit]);

    useEffect(() => {
        if (!mounted) return
        getUsers()
    }, [searchUsers, pageUsers, limitUsers]);

    const getUsers = () => {
        getStoreUsers(storeId!, { page: pageUsers, limit: limitUsers, search: searchUsers })
            .then(v => v.json())
            .then(v => {
                setUsers(v.data)
                setPaginationUsers(v.meta)
            })
    }

    const getProducts = async () => {
        try {
            setIsLoading(true)
            var resp = await getStoreProducts(storeId!!, { page, limit, search })
            var respJson = await resp.json()
            setProducts(respJson.data)
            setPagination(respJson.meta)
        } catch (error) {
            Swal.fire("Perhatian", `${error}`, "error")
        } finally {
            setIsLoading(false)
        }
    }
    const getDetail = async () => {
        try {
            setIsLoading(true)
            var resp = await getStoreDetail(storeId!!)
            var respJson = await resp.json()

            setData(respJson.data)
            getProducts()
        } catch (error) {
            Swal.fire("Perhatian", `${error}`, "error")
        } finally {
            setIsLoading(false)
        }
    }


    const getAllProductRequest = async () => {
        getStoreRequests(storeId!, { page: pageRequest, limit: limitRequest })
            .then(v => v.json())
            .then(v => {
                setProductRequest(v.data)
                setPaginationRequest(v.meta)
            })
    }

    const requestRender = () => (
        <div className={`mb-5 h-auto overflow-y-auto ${storePermissions.includes("product_store_request") ? 'col-span-2' : 'col-span-4'} rounded-xl py-6 px-6 transition-all hover:shadow-lg sm:p-4 lg:px-4 xl:px-6 bg-white cursor-pointer`} >
            <div className="flex flex-col">
                <div className='flex  justify-between items-center mb-2'>
                    <h3 className=" text-2xl">Permintaan Produk</h3>
                    <button
                        onClick={() => {
                            setModalRequestOpen(true)
                        }}
                        className=" text-white bg-orange-600 hover:bg-orange-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-full text-sm px-5 py-1.5 text-center "
                    >
                        Tambah Permintaan
                    </button>
                </div>
                <DataTable
                    pagination
                    paginationServer
                    paginationTotalRows={paginationUsers?.total_records ?? 0}
                    dense
                    striped
                    columns={[
                        {
                            name: 'No.',
                            selector: (row: ProductRequest) => (limit * (page - 1)) + (productRequest.indexOf(row) + 1),
                            width: '100px',
                        },
                        {
                            name: 'Keterangan',
                            selector: (row: ProductRequest) => row.created_by,
                            cell: (row: ProductRequest) =>
                                <dl onClick={() => nav(`/store/${storeId!}/request/${row.uuid}`)}>
                                    <dt><strong>Tgl: </strong></dt>
                                    <dd> <Moment format='DD/MM/YYYY'>{row.product_request_date}</Moment></dd>
                                    <dt><strong>Kode: </strong></dt>
                                    <dd>{row.product_request_number}</dd>
                                    <dt><strong>Diajukan: </strong></dt>
                                    <dd>{row.created_by}</dd>
                                    <dt><strong>Tujuan: </strong></dt>
                                    <dd>{row.source_name}</dd>
                                </dl>
                            ,
                            width: '40%'

                        },
                        {
                            name: 'Tipe',
                            selector: (row: ProductRequest) => row.product_request_type,
                            cell: (row: ProductRequest) => <Badge label={row.product_request_type == 'DEMAND' ? 'PENGAMBILAN' : 'PENGEMBALIAN'} color={row.product_request_type == 'DEMAND' ? 'green' : 'red'} />,

                        },
                        {
                            name: 'Status',
                            selector: (row: ProductRequest) => row.status,
                            cell: (row: ProductRequest) => {
                                let color = "gray"
                                switch (row.status) {
                                    case "PROCESSING":
                                        color = "blue"
                                        break;
                                    case "REJECTED":
                                        color = "red"
                                        break;
                                    case "FINISHED":
                                        color = "green"
                                        break;

                                    default:
                                        color = "gray"
                                        break;
                                }
                                return <Badge label={row.status} color={color} />;
                            },

                        },



                    ]}
                    data={productRequest}
                    onRowClicked={(val) => {
                    }}
                    paginationPerPage={limitUsers}
                    paginationRowsPerPageOptions={[5, 10, 20, 50, 100, 500, 1000]}

                    pointerOnHover={true}
                    customStyles={{
                        headCells: {
                            style: {
                                paddingTop: '20px', // override the cell padding for head cells
                                paddingBottom: '20px', // override the cell padding for head cells
                                fontWeight: "600",
                                fontSize: "12pt"
                            },
                        },
                        cells: {
                            style: {
                                padding: '16px', // override the cell padding for head cells
                            },
                        },
                    }}
                    onChangeRowsPerPage={(v) => {
                        setLimitRequest(v)
                    }}
                    onChangePage={(v) => {
                        if (v != page) {
                            setPageRequest(v)
                        }
                    }}
                />
            </div>
        </div>
    )

    const modalRequest = () => (<Transition appear show={modalRequestOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => {
            // setSelectedVarian(null)
            setModalRequestOpen(false)
            // setInputVarian(null)
            // setInputVarianId("")
        }}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="fixed inset-0 bg-black/25" />
            </Transition.Child>

            <div className="fixed inset-0 ">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Dialog.Panel className="w-full flex flex-col max-w-2xl transform overflow-hidden rounded-2xl bg-form-dialog p-6 text-left align-middle shadow-xl transition-all" style={{ height: 'calc(100vh - 300px)' }}>
                            <Dialog.Title
                                as="h3"
                                className="text-xl mb-4 font-medium leading-6 text-gray-900"
                            >
                                Tambah Permintaan
                            </Dialog.Title>
                            <div className="flex-1 overflow-y-auto">
                                <div className="flex items-center mb-6">
                                    <div className="w-1/3">
                                        <label
                                            className="block text-gray-900 mb-0 pr-4"
                                            htmlFor="date"
                                        >
                                            Tanggal
                                        </label>
                                    </div>
                                    <div className="w-2/3">
                                        <DatePicker value={date} block format="dd/MM/yyyy HH:mm" onChangeCalendarDate={(date) => {
                                            setDate(date)
                                        }} />
                                    </div>
                                </div>

                                <div className="flex items-center mb-6">
                                    <div className="w-1/3">
                                        <label
                                            className="block text-gray-900 mb-0 pr-4"
                                            htmlFor="number-request"
                                        >
                                            No Permintaan
                                        </label>
                                    </div>
                                    <div className="w-2/3">
                                        <input
                                            className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                            id="number-request"
                                            type="text"
                                            placeholder="No Permintaan"
                                            value={requestNumber}
                                            onChange={(e) => setRequestNumber(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className="flex items-center mb-6">
                                    <div className="w-1/3">
                                        <label className="block text-gray-900 mb-0 pr-4">
                                            Gudang Tujuan
                                        </label>
                                    </div>
                                    <div className="w-2/3">
                                        <Creatable< SelectOption, false> styles={colourStyles} options={storeSelect} value={inputDestination}
                                            onChange={(option: SingleValue<SelectOption>): void => {
                                                setInputDestinationId(option?.value ?? "")
                                                setInputDestination(option)
                                            }}
                                            onCreateOption={(val) => {
                                                addStore({ name: val }).then(v => getStores({ page: 1, limit: 1000 })
                                                    .then(v => v.json())
                                                    .then(v => {
                                                        setStores(v.data)
                                                    }))
                                            }}
                                        />

                                    </div>
                                </div>
                                <div className="flex items-center mb-6">
                                    <div className="w-1/3">
                                        <label className="block text-gray-900 mb-0 pr-4">
                                            Jenis Permintaan
                                        </label>
                                    </div>
                                    <div className="w-2/3">
                                        <Select< SelectOption, false> styles={colourStyles} options={typeSelect} value={inputType}
                                            onChange={(option: SingleValue<SelectOption>): void => {
                                                setInputType(option)
                                            }}

                                        />

                                    </div>
                                </div>




                            </div>

                            <div className="mt-4">
                                <button
                                    type="button"
                                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                    onClick={saveRequest}
                                >
                                    <FolderIcon
                                        className=" h-5 w-5 mr-2"
                                        aria-hidden="true" />  Simpan
                                </button>
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </div>
        </Dialog>
    </Transition>)

    const userModal = () => (<Transition appear show={modalUserOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setModalUserOpen(false)}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="fixed inset-0 bg-black/25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Dialog.Panel className="w-full flex flex-col max-w-2xl transform overflow-hidden rounded-2xl bg-form-dialog p-6 text-left align-middle shadow-xl transition-all" style={{ height: 'calc(100vh - 300px)' }}>
                            <Dialog.Title
                                as="h3"
                                className="text-xl font-medium leading-6 text-gray-900"
                            >
                                Tambah User
                            </Dialog.Title>
                            <div className="flex-1">
                                <div className="mt-4 ">
                                    <div className="flex items-center mb-6">
                                        <div className="w-1/3">
                                            <label className="block text-gray-900 mb-0 pr-4">
                                                User
                                            </label>
                                        </div>
                                        <div className="w-2/3">
                                            <Select< SelectOption, false> styles={colourStyles} options={memberSelect} value={inputMember}
                                                onChange={(option: SingleValue<SelectOption>): void => {
                                                    setInputMemberId(option?.value ?? "")
                                                    setInputMember(option)
                                                }}

                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-4 ">
                                    <div className="flex  mb-6">
                                        <div className="w-1/3">
                                            <label className="block text-gray-900 mb-0 pr-4">
                                                Hak Akses
                                            </label>
                                        </div>
                                        <div className="w-2/3">
                                            <ul>
                                                {specialPermissionList.map(e => <li key={e.key}>
                                                    <Checkbox checked={inputSpecialPermissions.includes(e.key)} value={e.key} onChange={(value) => {
                                                        let checked = [...inputSpecialPermissions]

                                                        if (checked.includes(`${value}`)) {
                                                            checked = checked.filter(v => v !== `${value}`);
                                                        } else {
                                                            checked.push(`${value}`)
                                                        }
                                                        setInputSpecialPermissions(checked)
                                                    }}>{e.name}</Checkbox>
                                                </li>)}
                                            </ul>
                                        </div>
                                    </div>
                                </div>


                            </div>

                            <div className="mt-4">
                                <button
                                    type="button"
                                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                    onClick={() => {
                                        setIsLoading(true)
                                        addStoreUser(storeId!, inputMemberId, inputSpecialPermissions).then(e => {
                                            getUsers()
                                            setModalUserOpen(false)
                                            setInputSpecialPermissions([])
                                            setInputMemberId("")
                                            setInputMember(null)

                                        })
                                            .catch(error => {
                                                Swal.fire("Perhatian", `${error.includes('Duplicate entry') ? 'User sudah ditambahkan' : error}`, "error")
                                            })
                                            .finally(() => {
                                                setIsLoading(false)

                                            })
                                    }}
                                >
                                    <FolderIcon
                                        className=" h-5 w-5 mr-2"
                                        aria-hidden="true" />  Simpan
                                </button>
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </div>
        </Dialog>
    </Transition>)
    const usersRender = () => (
        <div className={`mb-5 h-auto overflow-y-auto ${storePermissions.includes("product_store_request") ? 'col-span-2' : 'col-span-4'} rounded-xl py-6 px-6 transition-all hover:shadow-lg sm:p-4 lg:px-4 xl:px-6 bg-white cursor-pointer`} >
            <div className="flex flex-col">
                <div className='flex  justify-between items-center mb-2'>
                    <h3 className=" text-2xl">User</h3>
                    <button
                        onClick={() => {
                            setModalUserOpen(true)
                        }}
                        className=" text-white bg-orange-600 hover:bg-orange-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-full text-sm px-5 py-1.5 text-center "
                    >
                        Tambah User
                    </button>
                </div>
                <DataTable
                    pagination
                    paginationServer
                    paginationTotalRows={paginationUsers?.total_records ?? 0}
                    dense
                    striped
                    columns={[
                        {
                            name: 'No.',
                            selector: (row: StoreUser) => (limit * (page - 1)) + (users.indexOf(row) + 1),
                            width: '100px',
                        },
                        {
                            name: 'User',
                            selector: (row: StoreUser) => row.full_name,
                            cell: (row: StoreUser) => <div className=' hover:font-medium'>
                                {row.full_name}
                            </div>,
                            width: '200px',
                        },
                        {
                            name: 'Hak Akses',
                            selector: (row: StoreUser) => `${JSON.stringify(row.permissions)}`,
                            cell: (row: StoreUser) => <div className="flex flex-wrap">
                                {row.permissions.map(e => <Badge className='mr-2 mb-2' key={randomStr(20)} label={ucFirstWord(e)} color='green' />)}
                            </div>,
                            style: { textAlign: 'right' },

                        },
                        {
                            name: '',
                            selector: (row: StoreUser) => row.full_name,
                            cell: (row: StoreUser) => <div className="flex justify-end">
                                <TrashIcon
                                    className=" h-5 w-5 text-red-400 hover:text-red-600"
                                    aria-hidden="true"
                                    onClick={() => {
                                        Swal.fire({
                                            title: "Anda Yakin",
                                            text: "Anda tidak akan dapat mengembalikan proses ini!",
                                            icon: "warning",
                                            showCancelButton: true,
                                            confirmButtonColor: "#3085d6",
                                            cancelButtonColor: "#d33",
                                            confirmButtonText: "Ya, Hapus!",
                                            cancelButtonText: "Batal"
                                        }).then((result) => {
                                            if (result.isConfirmed) {
                                                deleteStoreUser(storeId!, row.uuid).then(v => getUsers())
                                            }
                                        });
                                    }}
                                />
                            </div>,
                            width: '100px',
                        },

                    ]}
                    data={users}
                    onRowClicked={(val) => {
                    }}
                    paginationPerPage={limitUsers}
                    paginationRowsPerPageOptions={[5, 10, 20, 50, 100, 500, 1000]}

                    pointerOnHover={true}
                    customStyles={{
                        headCells: {
                            style: {
                                paddingTop: '20px', // override the cell padding for head cells
                                paddingBottom: '20px', // override the cell padding for head cells
                                fontWeight: "600",
                                fontSize: "12pt"
                            },
                        },
                        cells: {
                            style: {
                                padding: '16px', // override the cell padding for head cells
                            },
                        },
                    }}
                    onChangeRowsPerPage={(v) => {
                        setLimitUsers(v)
                    }}
                    onChangePage={(v) => {
                        if (v != page) {
                            setPageUsers(v)
                        }
                    }}
                />
            </div>
        </div>
    )

    const productsRender = () => (
        <div className="mb-5 h-auto overflow-y-auto col-span-2 rounded-xl py-6 px-6 transition-all hover:shadow-lg sm:p-4 lg:px-4 xl:px-6 bg-white cursor-pointer" >
            <div className="flex flex-col">
                <div className='flex  justify-between items-center mb-4'>
                    <h3 className=" text-2xl">Produk</h3>

                </div>
                <DataTable
                    pagination
                    paginationServer
                    paginationTotalRows={pagination?.total_records ?? 0}
                    dense
                    striped
                    columns={[
                        {
                            name: 'No.',
                            selector: (row: StoreProduct) => (limit * (page - 1)) + (products.indexOf(row) + 1),
                            width: '100px',
                        },
                        {
                            name: 'Produk',
                            selector: (row: StoreProduct) => row.product_name,
                            cell: (row: StoreProduct) => <div className=' hover:font-medium' onClick={() => {
                                nav(`/product/${row.uuid}`)
                            }}>
                                {row.product_name}
                            </div>,
                        },
                        {
                            name: 'Total Stok',
                            selector: (row: StoreProduct) => `${money(row.stock)}`,
                            cell: (row: StoreProduct) => <div className="flex justify-end">{money(row.stock)} {row.unit_name}</div>,
                            style: { textAlign: 'right' },
                            width: '200px',

                        },
                        {
                            name: '',
                            selector: (row: StoreProduct) => row.stock,
                            cell: (row: StoreProduct) => {
                                return <EyeIcon className='w-5 text-blue-500 hover:text-blue-800' onClick={() => nav(`/store/${storeId!}/product/${row.uuid}`)} />;
                            },
                            width: '100px',

                        },

                    ]}
                    data={products}
                    onRowClicked={(val) => {
                        nav(`/product/${val.uuid}`)
                    }}
                    paginationPerPage={limit}
                    paginationRowsPerPageOptions={[5, 10, 20, 50, 100, 500, 1000]}

                    pointerOnHover={true}
                    customStyles={{
                        headCells: {
                            style: {
                                paddingTop: '20px', // override the cell padding for head cells
                                paddingBottom: '20px', // override the cell padding for head cells
                                fontWeight: "600",
                                fontSize: "12pt"
                            },
                        },
                        cells: {
                            style: {
                                padding: '16px', // override the cell padding for head cells
                            },
                        },
                    }}
                    onChangeRowsPerPage={(v) => {
                        setLimit(v)
                    }}
                    onChangePage={(v) => {
                        if (v != page) {
                            setPage(v)
                        }
                    }}
                />
            </div>
        </div>
    )
    const info = () => (
        <div className="mb-5 h-auto overflow-y-auto col-span-2 rounded-xl py-6 px-6 transition-all hover:shadow-lg sm:p-4 lg:px-4 xl:px-6 bg-white cursor-pointer" >
            <div className="flex flex-col">
                <div className='flex  justify-between items-center mb-4'>
                    <h3 className=" text-2xl">Info</h3>
                    {saveButton()}
                </div>

                <div className="flex-1 overflow-y-auto">
                    <div className="mt-4 ">
                        <div className="flex items-center mb-6">
                            <div className="w-1/3">
                                <label className="block text-gray-900 mb-0 pr-4">
                                    Nama Gudang
                                </label>
                            </div>
                            <div className="w-2/3">
                                <input
                                    className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                    
                                    type="text"
                                    placeholder="Nama Gudang"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="mt-4 ">
                        <div className="flex items-center mb-6">
                            <div className="w-1/3">
                                <label className="block text-gray-900 mb-0 pr-4">
                                    Kode
                                </label>
                            </div>
                            <div className="w-2/3">
                                <input
                                    className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                    type="text"
                                    placeholder="Kode"
                                    value={code}
                                    onChange={(e) => setCode(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="mt-4 ">
                        <div className="flex  mb-6">
                            <div className="w-1/3">
                                <label className="block text-gray-900 mb-0 pr-4">
                                    Alamat
                                </label>
                            </div>
                            <div className="w-2/3">
                                <textarea
                                    className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                    rows={5}
                                    placeholder="Alamat"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="mt-4 ">
                            <div className="flex items-center mb-6">
                                <div className="w-1/3">
                                    <label className="block text-gray-900 mb-0 pr-4">
                                        Email
                                    </label>
                                </div>
                                <div className="w-2/3">
                                    <input
                                        className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                        type="text"
                                        placeholder="Email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mt-4 ">
                            <div className="flex items-center mb-6">
                                <div className="w-1/3">
                                    <label className="block text-gray-900 mb-0 pr-4">
                                        Telp
                                    </label>
                                </div>
                                <div className="w-2/3">
                                    <input
                                        className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                        type="text"
                                        placeholder="Telp"
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mt-4 ">
                            <div className="flex items-center mb-6">
                                <div className="w-1/3">
                                    <label className="block text-gray-900 mb-0 pr-4">
                                        Fax
                                    </label>
                                </div>
                                <div className="w-2/3">
                                    <input
                                        className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                        type="text"
                                        placeholder="Fax"
                                        value={fax}
                                        onChange={(e) => setFax(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mt-4 ">
                            <div className="flex items-center mb-6">
                                <div className="w-1/3">
                                    <label className="block text-gray-900 mb-0 pr-4">
                                        Penanggung Jawab
                                    </label>
                                </div>
                                <div className="w-2/3">
                                    <input
                                        className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                        type="text"
                                        placeholder="Penanggung Jawab"
                                        value={contactPerson}
                                        onChange={(e) => setContactPerson(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="flex items-center mb-6">
                                <div className="w-1/3">
                                    <label className="block text-gray-900 mb-0 pr-4">
                                        Jabatan
                                    </label>
                                </div>
                                <div className="w-2/3">
                                    <input
                                        className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                        type="text"
                                        placeholder="Jabatan"
                                        value={contactPersonPosition}
                                        onChange={(e) => setContactPersonPosition(e.target.value)}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>
        </div>
    )

    const saveRequest = async () => {
        try {
            setIsLoading(true)
            var resp = await addStoreRequest({
                product_request_date: date.toISOString(),
                product_request_type: inputType?.value ?? "DEMAND",
                destination_id: { Valid: true, String: inputDestinationId },
                product_request_number: requestNumber,
                notes: inputNotes,
                source_id: { Valid: true, String: storeId! }
            })
            var respJson = await resp.json()
            nav(`/store/${storeId!}/request/${respJson.data.last_id}`)
            setModalRequestOpen(false)
        } catch (error) {
            Swal.fire("Perhatian", `${error}`, "error")
        } finally {
            setIsLoading(false)

        }
    }


    const saveButton = () => (
        <button
            type="button"
            className="inline-flex justify-center items-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-xs font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
            onClick={() => update(true)}
        >
            <FolderIcon
                className=" h-3 w-3 mr-1"
                aria-hidden="true" />  Simpan
        </button>
    )



    const update = async (showAlert: boolean) => {
        try {

            console.log("UPDATE")
            setIsLoading(true)
            await updateStore(storeId!, {
                name,
                code,
                email: setNullString(email),
                phone: setNullString(phone),
                fax: setNullString(fax),
                address: setNullString(address),
                contact_person: setNullString(contactPerson),
                contact_person_position: setNullString(contactPersonPosition),
                advance_mode: advanceMode,
            })
            if (showAlert) {
                Swal.fire({
                    title: "Perhatian!",
                    text: `${name} berhasil di update`,
                    icon: "success"
                });
            }

            getDetail()
        } catch (error) {
            Swal.fire("Perhatian", `${error}`, "error")
        } finally {
            setIsLoading(false)
        }
    }


    return (
        <DashboardLayout >
            <div className="p-6">
                <div className='flex justify-between'>
                    <h1 className="text-4xl flex">
                        <Back className="mr-2" onClick={() => nav(-1)} /> {data?.name}
                    </h1>
                    {permissions.includes("update_advance_store") && <div className="text-xl flex items-center">
                        <span className='mr-2'>
                            Advance Mode
                        </span>
                        <Switch

                            checked={advanceMode}
                            onChange={(val: boolean) => {
                                setAdvanceMode(val)
                                updateStore(storeId!, {
                                    name,
                                    code,
                                    email: setNullString(email),
                                    phone: setNullString(phone),
                                    fax: setNullString(fax),
                                    address: setNullString(address),
                                    contact_person: setNullString(contactPerson),
                                    contact_person_position: setNullString(contactPersonPosition),
                                    advance_mode: val,
                                }).then(e => {
                                    getDetail()
                                })
                            }}
                            className={`${advanceMode ? 'bg-blue-600' : 'bg-gray-200'
                                } relative inline-flex h-[32px] w-[68px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
                        >
                            <span className="sr-only">Advance Mode</span>
                            <span
                                aria-hidden="true"
                                className={`${advanceMode ? 'translate-x-9' : 'translate-x-0'}
                                pointer-events-none inline-block h-[28px] w-[28px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                            />
                        </Switch>

                    </div>}


                </div>
                <div className="mt-6 grid gap-x-8 gap-y-4 sm:gap-y-0 xl:grid-cols-4 lg:grid-cols-4 sm:grid-cols-2 md:grid-cols-2 ">
                    {info()}
                    {productsRender()}
                </div>
                {advanceMode && <div className='mt-2'>
                    <h1 className="text-2xl flex">Advance Mode</h1>
                </div>}
                {advanceMode &&
                    <div className="mt-6 grid gap-x-8 gap-y-4 sm:gap-y-0 xl:grid-cols-4 lg:grid-cols-4 sm:grid-cols-2 md:grid-cols-2 ">
                        {usersRender()}
                        {storePermissions.includes("product_store_request") && requestRender()}
                    </div>
                }
            </div>
            {userModal()}
            {modalRequest()}
        </DashboardLayout>
    );
}
export default StoreDetail;