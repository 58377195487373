import { LoadingContext } from "../../objects/loading_context";
import { Product, ProductCategory, Unit } from '../../model/product';
import { addProduct, addProductCategory, getProductCategories, getUnits } from '../../repositories/product';
import { SelectOption, colourStyles } from '../../utils/style';
import { Dialog, Switch, Transition } from '@headlessui/react';
import FolderIcon from '@heroicons/react/24/outline/FolderIcon';
import { Fragment, useContext, useState, useEffect } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { SingleValue } from 'react-select';
import Creatable from 'react-select/creatable';
import Swal from 'sweetalert2';
import { addAccount, getCashflowSubGroup, getType } from "../../repositories/account";
import { AccountType, CashflowGroup, CashflowSubGroup } from "../../model/account";
import type { FC } from 'react';



interface FormAccountProps {
    isOpen: boolean
    setIsOpen: (arg: boolean) => void
    onSubmit?: (arg: string) => void
}

const FormAccount: FC<FormAccountProps> = ({
    isOpen,
    setIsOpen,
    onSubmit,
}) => {
    const [productCategories, setProductCategories] = useState<ProductCategory[]>([]);
    let { isLoading, setIsLoading } = useContext(LoadingContext);
    const [inputName, setInputName] = useState("");
    const [inputCode, setInputCode] = useState("");
    const [inputType, setInputType] = useState("");
    const [inputCategory, setInputCategory] = useState("");
    const [cashflowGroup, setCashflowGroup] = useState("");
    const [cashflowSubgroup, setCashflowSubgroup] = useState("");
    const [accountTypes, setAccountTypes] = useState<AccountType[]>([]);
    const [accountGroups, setAccountGroups] = useState<CashflowGroup[]>([]);
    const [categories, setCategories] = useState<string[]>([]);
    const [subGroups, setSubGroups] = useState<CashflowSubGroup[]>([]);
    const [isTax, setIsTax] = useState(false);

    const [mounted, setMounted] = useState(false);


    useEffect(() => {
        getType()
            .then(v => v.json())
            .then(v => {
                setAccountTypes(v.data)
            })
        getCashflowSubGroup()
            .then(v => v.json())
            .then(v => {
                setAccountGroups(v.data)
            })

        setMounted(true)
    }, []);

    useEffect(() => {
        if (!mounted) return

    }, [mounted]);

    const clearForm = () => {
        setInputName("")
        setInputCode("")
        setInputType("")
        setInputCategory("")
        setCashflowGroup("")
        setCashflowSubgroup("")
        setSubGroups([])
    }
    const saveAddAccount = async () => {
        if (inputName.length == 0) {
            Swal.fire("Perhatian", `Nama Akun tidak boleh kosong`, "error")
            return
        }
        if (inputType.length == 0) {
            Swal.fire("Perhatian", `Tipe tidak boleh kosong`, "error")
            return
        }
        if (inputCategory.length == 0) {
            Swal.fire("Perhatian", `Kategori tidak boleh kosong`, "error")
            return
        }
        if (cashflowGroup.length == 0) {
            Swal.fire("Perhatian", `Grup Arus Kas tidak boleh kosong`, "error")
            return
        }
        if (cashflowSubgroup.length == 0) {
            Swal.fire("Perhatian", `Sub Grup Arus Kas tidak boleh kosong`, "error")
            return
        }

        try {
            setIsLoading(true)
            setIsOpen(false)
            const resp = await addAccount({
                name: inputName,
                type: inputType,
                category: inputCategory,
                cashflow_group: cashflowGroup,
                cashflow_subgroup: cashflowSubgroup,
                code: inputCode,
                is_tax: isTax,
            })
            const respJson = await resp.json()
            clearForm()
            if (onSubmit != null) {
                onSubmit(respJson.data)
            }
            //   router.push(`/product/${respJson.data}`)
        } catch (error) {
            Swal.fire("Perhatian", `${error}`, "error")
        } finally {
            setIsLoading(false)
        }

        // setIsOpen(false)
    }


    
    return ( <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => {
            setIsOpen(false)
            clearForm()
        }}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="fixed inset-0 bg-black/25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Dialog.Panel className="w-full max-w-2xl  max-h-screen transform overflow-hidden rounded-2xl bg-form-dialog p-6 text-left align-middle shadow-xl transition-all">
                            <Dialog.Title
                                as="h3"
                                className="text-xl font-medium leading-6 text-gray-900"
                            >
                                Tambah Akun
                            </Dialog.Title>
                            <div className="mt-4">
                                <div className="flex items-center mb-6">
                                    <div className="w-1/3">
                                        <label
                                            className="block text-gray-900 mb-0 pr-4"
                                            htmlFor="product-name"
                                        >
                                            Nama Akun
                                        </label>
                                    </div>
                                    <div className="w-2/3">
                                        <input
                                            className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                            id="product-name"
                                            type="text"
                                            placeholder="Nama Akun"
                                            value={inputName}
                                            onChange={(e) => setInputName(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="flex items-center mb-6">
                                    <div className="w-1/3">
                                        <label
                                            className="block text-gray-900 mb-0 pr-4"
                                            htmlFor="product-name"
                                        >
                                            Kode
                                        </label>
                                    </div>
                                    <div className="w-2/3">
                                        <input
                                            className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                            id="product-name"
                                            type="text"
                                            placeholder="Kode"
                                            value={inputCode}
                                            onChange={(e) => setInputCode(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="flex items-center mb-6">
                                    <div className="w-1/3">
                                        <label
                                            className="block text-gray-900 mb-0 pr-4"
                                            htmlFor="product-category"
                                        >
                                            Tipe
                                        </label>
                                    </div>
                                    <div className="w-2/3">
                                        <select
                                            className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                            id="product-name"
                                            value={inputType}
                                            onChange={(e) => {
                                                setInputType(e.target.value)
                                                let selected = accountTypes.find(a => a.value == e.target.value)
                                                if (selected) {
                                                    setCategories(selected.category)
                                                    if (selected.category.length > 0) {
                                                        setInputCategory(selected.category[0])
                                                        let selected2 = accountGroups.find(a => a.name == selected!.category[0])
                                                        if (selected2) {
                                                            setCashflowGroup(selected2.type)
                                                        }

                                                        if (selected.category[0] == "Modal") {
                                                            setCashflowGroup("financing")
                                                            let selected3 = accountGroups.find(a => a.type == "financing")
                                                            if (selected3) {
                                                                setSubGroups(selected3.sub_group)
                                                            }
                                                        }
                                                    }
                                                }


                                            }}
                                        >
                                            <option value={""}>Pilih Tipe</option>
                                            {accountTypes.map(e => <option key={e.value} value={e.value}>{e.text}</option>)}
                                        </select>
                                    </div>
                                </div>
                                <div className="flex items-center mb-6">
                                    <div className="w-1/3">
                                        <label
                                            className="block text-gray-900 mb-0 pr-4"
                                            htmlFor="product-category"
                                        >
                                            Kategori
                                        </label>
                                    </div>
                                    <div className="w-2/3">
                                        <select
                                            className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                            id="product-name"
                                            value={inputCategory}
                                            onChange={(e) => {
                                                setInputCategory(e.target.value)
                                                let selected = accountGroups.find(a => a.name == e.target.value)
                                                if (selected) {
                                                    setCashflowGroup(selected.type)
                                                    setSubGroups(selected.sub_group)
                                                } else if (e.target.value == "Modal") {
                                                    setCashflowGroup("financing")
                                                    let selected2 = accountGroups.find(a => a.type == "financing")
                                                    if (selected2) {
                                                        setSubGroups(selected2.sub_group)
                                                    }
                                                } else {
                                                    setCashflowGroup("")
                                                    setCashflowSubgroup("")
                                                    setSubGroups([])
                                                }

                                            }}
                                        >
                                            <option value={""}>Pilih Kategori</option>
                                            {categories.map(e => <option key={e} value={e}>{e}</option>)}
                                        </select>
                                    </div>
                                </div>
                                <div className="flex items-center mb-6">
                                    <div className="w-1/3">
                                        <label
                                            className="block text-gray-900 mb-0 pr-4"
                                            htmlFor="product-category"
                                        >
                                            Grup Arus Kas
                                        </label>
                                    </div>
                                    <div className="w-2/3">
                                        <select
                                            className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                            id="product-name"
                                            value={cashflowGroup}
                                            onChange={(e) => {
                                                setCashflowGroup(e.target.value)
                                                let selected = accountGroups.find(a => a.type == e.target.value)
                                                if (selected) {
                                                    setSubGroups(selected.sub_group)
                                                }

                                            }}
                                        >
                                            <option value={""}>Pilih Grup</option>
                                            {accountGroups.map(e => <option key={e.type} value={e.type}>{e.name}</option>)}
                                        </select>
                                    </div>
                                </div>
                                <div className="flex items-center mb-6">
                                    <div className="w-1/3">
                                        <label
                                            className="block text-gray-900 mb-0 pr-4"
                                            htmlFor="product-category"
                                        >
                                            Sub Grup Arus Kas
                                        </label>
                                    </div>
                                    <div className="w-2/3">
                                        <select
                                            className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                            id="product-name"
                                            value={cashflowSubgroup}
                                            onChange={(e) => {
                                                setCashflowSubgroup(e.target.value)

                                            }}
                                        >
                                            <option value={""}>Pilih Sub Grup</option>
                                            {subGroups.map(e => <option key={e.value} value={e.value}>{e.text}</option>)}
                                        </select>
                                    </div>
                                </div>
                                <div className="flex items-center mb-6">
                                    <div className="w-1/3">
                                        <label
                                            className="block text-gray-900 mb-0 pr-4"
                                            htmlFor="product-category"
                                        >
                                            Akun Pajak
                                        </label>
                                    </div>
                                    <div className="w-2/3">
                                        <Switch
                                            checked={isTax}
                                            onChange={setIsTax}
                                            className={`${isTax ? 'bg-blue-600' : 'bg-gray-200'
                                                } relative inline-flex h-6 w-11 items-center rounded-full`}
                                        >
                                            <span className="sr-only">Akun Pajak</span>
                                            <span
                                                aria-hidden="true"
                                                className={`${isTax ? 'translate-x-6' : 'translate-x-1'
                                                    } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                            />
                                        </Switch>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-4">
                                <button
                                    type="button"
                                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                    onClick={saveAddAccount}
                                >
                                    <FolderIcon
                                        className=" h-5 w-5 mr-2"
                                        aria-hidden="true" />  Simpan
                                </button>
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </div>
        </Dialog>
    </Transition>);
}
export default FormAccount;