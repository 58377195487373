import { NullString } from "../objects/null_string"
import { Account } from "./account"
import { Item } from "./item"
import { Tax, TaxTransaction } from "./tax"
import { setNullString } from "../utils/helper"
import { Transaction } from "./transaction"
import moment from "moment"

export interface Bill {
    uuid: string
    vendor_id: string
    vendor_name: string
    vendor_address: string
    vendor_phone: string
    vendor_fax: string
    vendor_email: string
    vendor_contact_person: string
    vendor_tax_payer_number: string
    bill_title: string
    bill_running_number: number
    bill_number: string
    ref_number: string
    po_number: string
    bill_date: string
    payment_due: string
    is_over_due: boolean
    tax_id: string
    tax: TaxTransaction
    secondary_tax: TaxTransaction
    account_payable: Account
    account_expense: Account
    account_payable_id: string
    account_expense_id: string
    quotation_id: string
    flag: boolean
    locked: boolean
    updated_stock: boolean
    notes: string
    balance: number
    total_before_discount: number
    total_after_discount: number
    total_discount_amount: number
    paid: number
    items: Item[]
    payments: Transaction[]
    total_tax_amount: number
    total_before_tax: number
    grand_total: number
    tax_method: string
    tax_name: string
    tax_amount: number
    tax_payment_id: string
    withholding_certificate: string
    is_tax_paid: boolean
    is_auto_stock: boolean
    is_cash: boolean
    secondary_tax_id: string
    secondary_tax_method: string
    secondary_tax_name: string
    secondary_tax_amount: number
    total_secondary_tax_amount: number
    counted: string
}



export interface BillReq {
    vendor_id: NullString
    vendor_name?: string,
    vendor_address?: string,
    vendor_phone?: string,
    vendor_fax?: string,
    vendor_email?: string,
    vendor_contact_person?: string,
    bill_date: string
    payment_due: string
    bill_title: string
    ref_number: string
    po_number: string
    bill_number: string
    account_payable_id: NullString
    account_expense_id: NullString
    is_auto_stock: boolean
    tax_id: NullString
    tax_method: NullString
    secondary_tax_id: NullString
    secondary_tax_method: NullString
    notes: string
}
export interface BillEditReq {
    vendor_id: NullString
    bill_date: string
    payment_due: string
    bill_title: string
    bill_number: string
    account_payable_id: NullString
    account_expense_id: NullString
    is_auto_stock: boolean
    tax_id: NullString
    tax_method: NullString
    secondary_tax_id: NullString
    secondary_tax_method: NullString
    notes: string
}


export let toRequest = (input: Bill): BillReq => {
    return {
        vendor_id: setNullString(input.vendor_id),
        vendor_name: input.vendor_name,
        vendor_address: input.vendor_address,
        vendor_phone: input.vendor_phone,
        vendor_fax: input.vendor_fax,
        vendor_email: input.vendor_email,
        vendor_contact_person: input.vendor_contact_person,
        bill_date: moment(input.bill_date).toISOString(),
        payment_due: moment(input.payment_due).toISOString(),
        po_number: input.po_number,
        ref_number: input.ref_number,
        bill_title: input.bill_title,
        bill_number: input.bill_number,
        account_payable_id: setNullString(input.account_payable_id),
        account_expense_id: setNullString(input.account_expense_id),
        is_auto_stock: input.is_auto_stock,
        tax_id: setNullString(input.tax_id),
        tax_method: setNullString(input.tax_method),
        secondary_tax_id: setNullString(input.secondary_tax_id),
        secondary_tax_method: setNullString(input.secondary_tax_method),
        notes: input.notes,
    }
}