import { Fragment, type FC, useContext, useState, useEffect } from 'react';
import { Dialog, Switch, Transition } from '@headlessui/react';
import { LoadingContext } from '../../objects/loading_context';
import { addAccount, getAccountTemplates, getType } from '../../repositories/account';
import { Account, AccountTemplate, AccountType } from '../../model/account';
import Avatar from '../avatar';
import { CheckCircleIcon, FolderIcon, FolderPlusIcon, ListBulletIcon, MagnifyingGlassIcon, TrashIcon } from '@heroicons/react/24/outline';
import { COLORS } from '../../objects/constants';
import Swal from 'sweetalert2';

interface ModalAccountTemplateProps {
    isOpen: boolean
    setIsOpen: (arg: boolean) => void
    onSubmit?: () => void
}

const ModalAccountTemplate: FC<ModalAccountTemplateProps> = ({
    isOpen,
    setIsOpen,
    onSubmit,
}) => {
    let { isLoading, setIsLoading } = useContext(LoadingContext);
    const [mounted, setMounted] = useState(false);
    const [accountTemplates, setAccountTemplates] = useState<AccountTemplate[]>([]);
    const [selectedTemplate, setSelectedTemplate] = useState("");
    const [accounts, setAccounts] = useState<Account[]>([]);
    const [desc, setDesc] = useState("");
    const [inputType, setInputType] = useState("");
    const [accountTypes, setAccountTypes] = useState<AccountType[]>([]);
    const [selectedAccounts, setSelectedAccounts] = useState<Account[]>([]);
    const [search, setSearch] = useState("");


    useEffect(() => {
        setMounted(true)
    }, []);

    useEffect(() => {
        getAccountTemplates()
            .then(v => v.json())
            .then(v => {

                setAccountTemplates(v.data)
            })
        getType()
            .then(v => v.json())
            .then(v => {
                setAccountTypes(v.data)
            })
    }, [mounted]);


    return (<Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => {
            setIsOpen(false)
            // clearForm()
        }}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="fixed inset-0 bg-black/25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Dialog.Panel className="flex flex-col w-full max-w-2xl  max-h-screen transform overflow-hidden rounded-2xl bg-form-dialog p-6 text-left align-middle shadow-xl transition-all" style={{ height: 'calc(100vh - 200px)' }}>
                            <Dialog.Title
                                as="h3"
                                className="text-xl font-medium leading-6 text-gray-900"
                            >
                                <div className=' flex justify-between '>
                                    <span>
                                        Templat Akun
                                    </span>
                                    <form className=" w-60 ">
                                        <div className="relative">
                                            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                                <MagnifyingGlassIcon
                                                    className=" h-5 w-5 text-violet-200 hover:text-violet-100"
                                                    aria-hidden="true"
                                                />
                                            </div>
                                            <input
                                                type="search"
                                                id="default-search"
                                                className="block w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-full bg-white focus:ring-blue-500 focus:border-blue-500   "
                                                placeholder="Search..."
                                                onChange={(val) => {
                                                    setSearch(val.target.value)
                                                }}
                                            />
                                        </div>
                                    </form>

                                </div>
                            </Dialog.Title>

                            <div className="mt-4">
                                <div className="flex mb-6">
                                    <div className="w-1/3">
                                        <label
                                            className="block text-gray-900 mb-0 pr-4"
                                            htmlFor="product-category"
                                        >
                                            Templat
                                        </label>
                                    </div>
                                    <div className="w-2/3">
                                        <select
                                            className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                            id="product-name"
                                            value={selectedTemplate}
                                            onChange={(el) => {
                                                setSelectedTemplate(el.target.value)
                                                const selected = accountTemplates.find(e => e.name == el.target.value)
                                                if (selected) {
                                                    setAccounts(selected.accounts)
                                                    setDesc(selected.description)
                                                }
                                            }}
                                        >
                                            <option value="">Pilih Templat</option>
                                            {accountTemplates.map(e => <option key={e.name} value={e.name}>{e.name}</option>)}
                                        </select>
                                        <div className='px-2 py-1'>
                                            <small className=' leading-2'>{desc}</small>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex items-center mb-6">
                                    <div className="w-1/3">
                                        <label
                                            className="block text-gray-900 mb-0 pr-4"
                                            htmlFor="product-category"
                                        >
                                            Tipe
                                        </label>
                                    </div>
                                    <div className="w-2/3">
                                        <select
                                            className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                            id="product-name"
                                            value={inputType}
                                            onChange={(e) => {
                                                setInputType(e.target.value)



                                            }}
                                        >
                                            <option value={""}>Semua</option>
                                            {accountTypes.map(e => <option key={e.value} value={e.value}>{e.text}</option>)}
                                        </select>
                                    </div>
                                </div>


                            </div>
                            <ul className='flex-1 overflow-y-auto'>
                                {accounts.filter((e) => {
                                    if (inputType == "" && search == "") return true
                                    if (inputType == "" && search != "") return e.name.toLowerCase().includes(search.toLowerCase())
                                    return e.type == inputType && e.name.toLowerCase().includes(search.toLowerCase())
                                }).map(e => {
                                    let color = "gray"
                                    switch (e.type) {
                                        case "Asset":
                                            color = COLORS.filter(e => e.name == "DeepSkyBlue")[0].name
                                            break;
                                        case "Equity":
                                            color = COLORS.filter(e => e.name == "Azure")[0].name
                                            break;
                                        case "Income":
                                            color = COLORS.filter(e => e.name == "BlueViolet")[0].name
                                            break;
                                        case "Expense":
                                            color = COLORS.filter(e => e.name == "Brown")[0].name
                                            break;
                                        case "Receivable":
                                            color = COLORS.filter(e => e.name == "Chocolate")[0].name
                                            break;
                                        case "Cost":
                                            color = COLORS.filter(e => e.name == "Gold")[0].name
                                            break;
                                        case "Liability":
                                        case "Payable":
                                            color = COLORS.filter(e => e.name == "DeepPink")[0].name
                                            break;

                                        default:
                                            break;
                                    }
                                    return <li onClick={() => {

                                        if (selectedAccounts.includes(e)) {
                                            setSelectedAccounts([...selectedAccounts.filter(varian => varian != e)]);
                                        } else {
                                            setSelectedAccounts([...selectedAccounts, e]);
                                        }

                                    }} key={e.uuid} className="flex rounded-2xl items-center justify-between gap-x-4 px-3 py-5 hover:bg-gray-600 outline-none cursor-pointer text-gray-900 hover:text-white">
                                        <div className='flex items-center mr-2'>
                                            <Avatar marginRight={20} name={e.name} url={""} style={{ backgroundColor: color }} />
                                            <div className="min-w-0">
                                                <p className="text-sm font-semibold leading-6   ">{e.name}</p>
                                                {e.type.length > 0 && <p className="-mt-0.5 truncate text-xs leading-5  ">{e.type}</p>}
                                                {e.cashflow_group_label.length > 0 && <p className="-mt-0.5 truncate text-xs leading-5  ">{e.cashflow_group_label}</p>}
                                                {e.cashflow_subgroup_label.length > 0 && <p className="-mt-0.5 truncate text-xs leading-5  ">{e.cashflow_subgroup_label}</p>}
                                            </div>
                                        </div>
                                        {selectedAccounts.includes(e) && <CheckCircleIcon className='w-7 text-green-600' />}
                                    </li>;
                                })}
                            </ul>

                            <div className='flex justify-between mt-4'>
                                <div>
                                    <button
                                        type="button"
                                        className="inline-flex justify-center items-center rounded-md border border-transparent bg-green-100 px-2 py-1 text-xs font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                                        onClick={() => {
                                            setSelectedAccounts(accounts)
                                        }}
                                    > <ListBulletIcon
                                            className=" h-3 w-3 mr-1"
                                            aria-hidden="true" />  Pilih Semua </button>
                                    <button
                                        type="button"
                                        className="inline-flex justify-center items-center rounded-md border border-transparent bg-red-100 px-2 py-1 text-xs font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                                        onClick={() => {
                                            setSelectedAccounts([])
                                        }}
                                    > <TrashIcon
                                            className=" h-3 w-3 mr-1"
                                            aria-hidden="true" />  Hapus Semua </button>
                                </div>


                                <button
                                    type="button"
                                    className="inline-flex justify-center items-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-xs font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                    onClick={async () => {
                                        try {
                                            setIsLoading(true)
                                            for (const key in selectedAccounts) {
                                                if (Object.prototype.hasOwnProperty.call(selectedAccounts, key)) {
                                                    const element = selectedAccounts[key];
                                                    await addAccount({
                                                        name: element.name,
                                                        type: element.type,
                                                        category: element.category,
                                                        cashflow_group: element.cashflow_group,
                                                        cashflow_subgroup: element.cashflow_subgroup,
                                                        code: element.code,
                                                        is_tax: element.is_tax,
                                                    })
                                                }
                                            }
                                            if (onSubmit != null) {
                                                onSubmit()
                                            }
                                        } catch (error) {
                                            Swal.fire("Perhatian", `${error}`, "error")
                                        } finally {
                                            setIsLoading(false)
                                        }

                                    }}
                                > <FolderPlusIcon
                                        className=" h-3 w-3 mr-1"
                                        aria-hidden="true" />  Tambahkan Akun </button>

                            </div>

                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </div>
        </Dialog>
    </Transition>);
}
export default ModalAccountTemplate;