import { useState, type FC, useEffect } from 'react';
import ModalSearch from './modal_search';
import { Product, ProductStoreStock, VarianStock } from '../../model/product';
import { getProducts } from '../../repositories/product';
import Avatar from '../avatar';
import { COLORS } from '../../objects/constants';
import { Store } from '../../model/store';
import { getStores } from '../../repositories/store';
import { getCirculationStock } from '../../repositories/circulation';
import { money } from '../../utils/number';
import { parseVarian } from '../../utils/helper';
import { CheckCircleIcon, ChevronLeftIcon, FolderIcon } from '@heroicons/react/24/outline';



interface ModalProductProps {
    children?: React.ReactNode
    search?: string
    isOpen: boolean
    selectStore: boolean
    isSell: boolean
    isBuy: boolean
    isMaterial?: boolean | null
    disableCreate?: boolean
    serviceEnable?: boolean | null
    setIsOpen: (arg: boolean) => void
    onClose?: () => void
    onSubmit?: (arg: string) => void
    onClick: (product: Product , store?: Store, varians?: ProductStoreStock[]) => void
}

const ModalProduct: FC<ModalProductProps> = ({
    children,
    isOpen,
    setIsOpen,
    onClose,
    onClick,
    onSubmit,
    isSell,
    isBuy,
    isMaterial,
    disableCreate,
    selectStore,
    serviceEnable,
}) => {
    const [mounted, setMounted] = useState(false);
    const [search, setSearch] = useState("");
    const [products, setProducts] = useState<Product[]>([]);
    const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
    const [stores, setStores] = useState<Store[]>([]);
    const [selectedStore, setSelectedStore] = useState<Store | null>(null);
    const [selectedVarians, setSelectedVarians] = useState<ProductStoreStock[]>([]);

    useEffect(() => {
        setMounted(true)
    }, []);

    useEffect(() => {
        if (mounted) return
        getAllProducts()

    }, [mounted]);

    useEffect(() => {

        // console.log(selectedVarians)
    }, [selectedVarians]);
    useEffect(() => {
        getProductStores()
    }, [selectedProduct]);

    const getProductStores = async () => {
        if (selectStore && selectedProduct) {
            var resp = await getStores({ page: 1, limit: 1000 })
            var respJson = await resp.json()


            const newStores: Store[] = []
            for (let index = 0; index < respJson.data.length; index++) {
                const element = respJson.data[index];
                var respStore = await getCirculationStock(selectedProduct!.uuid, element.uuid)
                var respStoreJson = await respStore.json()
                newStores.push({
                    ...element,
                    product_stock: respStoreJson.data
                })
            }
            setStores(newStores)
        }
    }
    useEffect(() => {
        if (!selectedProduct) return
        if (!selectedStore) return
        if ((selectedProduct.varians ?? []).length == 0 || (selectedStore.product_stock??[]).length == 0) {
            onClick(selectedProduct, selectedStore, []);
            setIsOpen(false);
            setSelectedProduct(null)
            setSelectedStore(null)
            if (onClose != null) onClose()
        }
    }, [selectedStore]);

    useEffect(() => {
        getAllProducts()
       
    }, [search]);

    const getAllProducts = async () => {
        getProducts({
            page: 1,
            limit: 10,
            search,
            isBuy,
            isSell,
            isMaterial,
        })
            .then(v => v.json())
            .then((v) => {
                setProducts(v.data)
            })
    }
    
    const clearForm = () => {
        setSearch("")
        getAllProducts()
        setSelectedProduct(null)
        setSelectedStore(null)
        setStores([])
    }

    return ( <ModalSearch
        placeholder="Cari product ..."
        isOpen={isOpen} onClose={() => {

            clearForm()
            if (onClose != null) onClose()
        }}
        setIsOpen={setIsOpen}
        onChange={(val) => setSearch(val)}
        onKeyUp={(e) => {
            if (e.key === 'Enter') {
                if (disableCreate) return
                if (onSubmit != null) onSubmit(search)
            }
        }}
        footer={<div className='p-4'>
            {selectedProduct != null && selectedStore == null ? <button
                type="button"
                className="inline-flex justify-center items-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-xs font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                onClick={() => {
                    setSelectedProduct(null)
                }}
            > <ChevronLeftIcon
                    className=" h-3 w-3 mr-1"
                    aria-hidden="true" />  Kembali </button>
                : null}
            {selectedProduct != null && selectedStore != null ? <div className='flex justify-between'>
                <button
                    type="button"
                    className="inline-flex justify-center items-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-xs font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={() => {

                        setSelectedStore(null)
                        setSelectedVarians([])
                    }}
                > <ChevronLeftIcon
                        className=" h-3 w-3 mr-1"
                        aria-hidden="true" />  Kembali </button>
                <button
                    type="button"
                    className="inline-flex justify-center items-center rounded-md border border-transparent bg-green-100 px-2 py-1 text-xs font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                    onClick={() => {
                        onClick(selectedProduct, selectedStore, selectedVarians);
                        if (onClose != null) onClose()
                        setSelectedProduct(null)
                        setSelectedStore(null)
                        setSelectedVarians([])
                        setIsOpen(false);

                    }}
                > <FolderIcon
                        className=" h-3 w-3 mr-1"
                        aria-hidden="true" />  Simpan </button>
            </div>
                : null}
        </div>}
    >
        <div className='px-4'>
            {search.length > 0 && !disableCreate && <small className='text-gray-400'>Tekan enter untuk menambahkan item non produk</small>}
        </div>
        {selectedProduct == null ? <ul role="list" className="divide-y divide-gray-100">
            {products.map((v, index) => {
                return (
                    <li key={v.uuid} onClick={async () => {
                        if (selectStore) {
                            setSelectedProduct(v)
                            if (selectStore) {
                                var resp = await getStores({ page: 1, limit: 1000 })
                                var respJson = await resp.json()


                                const newStores: Store[] = []
                                for (let index = 0; index < respJson.data.length; index++) {
                                    const element = respJson.data[index];
                                    var respStore = await getCirculationStock(v.uuid, element.uuid)
                                    var respStoreJson = await respStore.json()
                                    newStores.push({
                                        ...element,
                                        product_stock: respStoreJson.data
                                    })
                                }
                                setStores(newStores)
                            }
                            return
                        }

                        onClick(v);
                        setIsOpen(false);
                        if (onClose != null) onClose()
                    }}
                        className="flex items-center gap-x-4 px-3 py-5 hover:bg-gray-600 outline-none cursor-pointer text-gray-900 hover:text-white"
                    >
                        <Avatar marginRight={0} name={v.name} url={v.default_picture ?? ""} />
                        <div className="min-w-0">
                            <p className="text-sm font-semibold leading-6   ">{v.name}</p>
                            {v.product_category.length > 0 && <p className="-mt-0.5 truncate text-xs leading-5  ">{v.product_category}</p>}
                        </div>
                    </li>
                );
            })}
           
        </ul> : null}
        {selectedProduct != null && selectedStore == null ? <ul role="list" className="divide-y divide-gray-100">
            {stores.map(e => <li onClick={() => {
                setSelectedStore(e)
                
            }} key={e.uuid} className="flex items-center gap-x-4 px-3 py-5 hover:bg-gray-600 outline-none cursor-pointer text-gray-900 hover:text-white">
                <Avatar marginRight={0} name={e.name} url={""} />
                <div className="min-w-0">
                    <p className="text-sm font-semibold leading-6   ">{e.name}</p>
                    {e.product_stock!.length > 0 && <p className="-mt-0.5 truncate text-xs leading-5  "><strong>Total Stok:</strong> {money(e.product_stock!.map(e => e.amount).reduce((a, b) => a + b))} {selectedProduct?.unit_name} </p>}
                </div>
            </li>)}
        </ul> : null}
        {selectedProduct != null && selectedStore != null ? <ul role="list" className="divide-y divide-gray-100">
            {(selectedProduct.varians??[]).map(e => {
                let varianStock = selectedStore.product_stock?.find(ps => ps.varian_id == e.uuid)
                if (!varianStock) {
                    varianStock = {
                        product_id: e.uuid,
                        product_name: selectedProduct.name,
                        varian_id: e.uuid,
                        varian_data: e.data,
                        amount: 0
                    }
                }
                return <li onClick={() => {
                    if (selectedVarians.filter(v => v.varian_id == varianStock!.varian_id).length > 0) {
                        setSelectedVarians([...selectedVarians.filter(varian => varian != varianStock)])
                    } else {
                        console.log("KESINI")
                        setSelectedVarians([...selectedVarians, varianStock!])
                    }
                }} key={e.uuid} className="flex items-center justify-between gap-x-4 px-3 py-5 hover:bg-gray-600 outline-none cursor-pointer text-gray-900 hover:text-white">
                    <div className='flex items-center mr-2'>
                        <Avatar marginRight={20} name={parseVarian(e.data)} url={""} />
                        <div className="min-w-0">
                            <p className="text-sm font-semibold leading-6   ">{parseVarian(varianStock.varian_data)}</p>
                            <p><strong>Total Stok:</strong> {money(varianStock.amount)} {selectedProduct?.unit_name} </p>
                        </div>
                    </div>
                    {selectedVarians.filter(v => v.varian_id == varianStock!.varian_id).length > 0 ? <CheckCircleIcon className='w-7 text-green-600' /> : null}

                </li>;
            })}
        </ul> : null}

    </ModalSearch>);
}
export default ModalProduct;