import { Company } from "../model/company";
import { Invoice } from "../model/invoice";
import moment from "moment";
import { Br, Cut, Line, Printer, Row, Text, render } from 'react-thermal-printer';
import { money } from "./number";
import { sleep } from "./helper";
import { errorToast } from "./helper-ui";
export const renderReceiptData = async (invoice: Invoice, company: Company, paperWidth: number): Promise<Uint8Array> => {
    const receipt = (
        <Printer type="epson" width={paperWidth} debug={true}>
            <Text align="center" size={{ width: 2, height: 2 }}>{company.name}</Text>
            <Text align="center">{company.address}{company.phone && ` - ${company.phone}`}</Text>
            <Line />
            <Row left={<Text>No.</Text>} right={<Text>{invoice.invoice_number}</Text>} />
            <Row left={<Text>Tgl Inv</Text>} right={<Text>{moment(invoice.invoice_date).format('DD MMM YYYY')}</Text>} />
            <Row left={<Text>Tgl Cetak</Text>} right={<Text>{moment().format('DD MMM YYYY')}</Text>} />
            <Row left={<Text>Kons.</Text>} right={<Text>{invoice.customer_name}</Text>} />
            <Line />
            <Row left={<Text bold >Item</Text>} right={<Text bold >Jumlah</Text>} />
            <Line />
            {(invoice.items ?? []).map((item) => {
                let desc = `${item.qty} x ${item.description}`
                return <Row gap={1} key={item.uuid} left={desc} right={money(item.amount)} />;
            })}
            <Line />
            {invoice.total_before_discount != invoice.grand_total &&
            <Row left={<Text bold>Sub Total</Text>} right={<Text>{money(invoice.total_before_discount)}</Text>} />
            }
            <Row left={<Text bold>Total</Text>} right={<Text>{money(invoice.grand_total)}</Text>} />
            {invoice.total_discount_amount > 0 && <Row left={<Text bold>Disk.</Text>} right={<Text>{money(invoice.total_discount_amount)}</Text>} />}
            {(invoice.total_tax_amount > 0 && invoice.tax_method == "add") && <Row left={<Text bold>Pajak</Text>} right={<Text>{money(invoice.total_tax_amount)}</Text>} />}
            <Row left={<Text bold>Terbayar</Text>} right={<Text>{money(invoice.grand_total - invoice.balance)}</Text>} />
            {invoice.balance > 0 && <Row left={<Text bold>Sisa</Text>} right={<Text>{money(invoice.balance)}</Text>} />}
            <Br />
            <Text align="center">{invoice.notes.replace(/(<([^>]+)>)/gi, "")}`</Text>
            <Br />
            <Br />
        </Printer>
    );

    return await render(receipt);
}

export   const printReciept = async (invoice: Invoice, company: Company) => {
    try {
        let mobileNavigatorObject: any = window.navigator;


        if (mobileNavigatorObject && mobileNavigatorObject.bluetooth) {
            const device = await mobileNavigatorObject.bluetooth.requestDevice({
                filters: [
                    { services: ['000018f0-0000-1000-8000-00805f9b34fb'] },
                ],
            })

            const server = await device.gatt.connect();
            const service = await server.getPrimaryService(
                "000018f0-0000-1000-8000-00805f9b34fb"
            );

            const characteristic: BluetoothRemoteGATTCharacteristic = await service.getCharacteristic(
                "00002af1-0000-1000-8000-00805f9b34fb"
            );
     

            const bytes = await renderReceiptData(invoice!, company!, 32);
            const MAX_LENGTH = 64;
            for (let i = 0; i < bytes.byteLength; i += MAX_LENGTH) {
                const end = (i + MAX_LENGTH > bytes.byteLength) ? bytes.byteLength : i + MAX_LENGTH;
                const chunk = bytes.slice(i, end);
                await characteristic.writeValueWithoutResponse(chunk);
                await sleep(50);
            }


        }

    } catch (error) {
        errorToast(`${error}`)
    }

    // const device = await window.navigator.bluetooth({ filters: [{ name: 'Printer' }] });
}
