import { useContext, useEffect, useRef, useState, type FC } from 'react';
import { Packaging, PriceCategory, PriceLevelData, Product, ProductCategory, ProductStock, Unit } from '../model/product';
import { LoadingContext } from '../objects/loading_context';
import { SelectOption, colourStyles, multiColourStyles } from '../utils/style';
import { useNavigate, useParams } from 'react-router-dom';
import { Circulation } from '../model/circulation';
import { Store } from '../model/store';
import { getPermissions, randomStr, setNullString, ucFirst } from '../utils/helper';
import { addMaterial, addProductCategory, addProductPackaging, addProductPriceCategory, addProductUnit, addVarian, deleteProductPackaging, deleteProductPriceCategory, deleteProductUnit, editProduct, getPackagings, getPriceCategories, getProductCategories, getProductCirculations, getProductDetail, getProductStocks, getProducts, getUnits } from '../repositories/product';
import { getStores } from '../repositories/store';
import Swal from 'sweetalert2';
import { ArrowPathRoundedSquareIcon, FolderIcon, InformationCircleIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import Barcode from "react-barcode";
import Select, { MultiValue, SingleValue } from 'react-select';
import Creatable from 'react-select/creatable';
import CurrencyInput from 'react-currency-input-field';
import { Switch } from '@headlessui/react';
import { Avatar, Tooltip, Whisper } from 'rsuite';
import { money } from '../utils/number';
import Moment from 'react-moment';
import { fileUpload } from '../utils/file';
import Badge from '../components/badge';
import DashboardLayout from '../components/dashboard';
import Back from '../components/back';
import FormVarian from '../components/modals/form_varian';
import FormUnit from '../components/modals/form_unit';
import FormPriceCategory from '../components/modals/form_price_category';
import FormCirculation from '../components/modals/form_circulation';
import ModalCustom from '../components/modals/modal_custom';
import InlineForm from '../components/inline_form';
import FormPackaging from '../components/modals/form_packaging';

interface ProductDetailProps {}

const ProductDetail: FC<ProductDetailProps> = ({}) => {
    const [productCategories, setProductCategories] = useState<ProductCategory[]>([]);
    const [mounted, setMounted] = useState(false);
    const [permissions, setPermissions] = useState<string[]>([]);
    let { isLoading, setIsLoading } = useContext(LoadingContext);
    const [product, setProduct] = useState<Product | null>(null);
    const [categories, setCategories] = useState<SelectOption[]>([]);
    const [unitSelect, setUnitSelect] = useState<SelectOption[]>([]);
    const [packagingSelect, setPackagingSelect] = useState<SelectOption[]>([]);
    const [priceCategorySelect, setPriceCategorySelect] = useState<SelectOption[]>([]);
    const nav = useNavigate()
    const { productId } = useParams()
    const [inputName, setInputName] = useState("");
    const [inputSKU, setInputSKU] = useState("");
    const [inputBarcode, setInputBarcode] = useState("");
    const [inputPrice, setInputPrice] = useState(0);
    const [inputProductCategoryID, setInputProductCategoryID] = useState("");
    const [inputProductCategory, setInputProductCategory] = useState<SelectOption | null>(null);
    const [inputVarianParameters, setInputVarianParameters] = useState<MultiValue<SelectOption>>([]);
    const [inputIsSell, setInputIsSell] = useState(false);
    const [inputIsBuy, setInputIsBuy] = useState(false);
    const inputFile = useRef<HTMLInputElement>(null)
    const [varianParameters, setVarianParameters] = useState<string[]>([]);
    const [modalVarianOpen, setModalVarianOpen] = useState(false);
    const [inputNewVarian, setInputNewVarian] = useState<Record<string, string>>({});
    const [modalUnitOpen, setModalUnitOpen] = useState(false);
    const [units, setUnits] = useState<Unit[]>([]);
    const [inputUnitId, setInputUnitId] = useState("");
    const [inputUnit, setInputUnit] = useState<SelectOption | null>();
    const [inputUnitValue, setInputUnitValue] = useState(0);
    const [inputDescription, setInputDescription] = useState("");
  
    const [modalPackagingOpen, setModalPackagingOpen] = useState(false);
    const [packagings, setPackagings] = useState<Packaging[]>([]);
    const [inputPackagingId, setInputPackagingId] = useState("");
    const [inputPackaging, setInputPackaging] = useState<SelectOption | null>();
    const [inputPackagingValue, setInputPackagingValue] = useState(0);
  
  
    const [priceSelectedIds, setPriceSelectedIds] = useState<string[]>([]);
    const [modalPriceCategoryOpen, setModalPriceCategoryOpen] = useState(false);
    const [priceCategories, setPriceCategories] = useState<PriceCategory[]>([]);
    const [inputPriceCategoryId, setInputPriceCategoryId] = useState("");
    const [inputPriceCategory, setInputPriceCategory] = useState<SelectOption | null>();
    const [inputAmountValue, setInputAmountValue] = useState(0);
    const [priceLevel, setPriceLevel] = useState(false);
    const [priceLevelValues, setPriceLevelValues] = useState<PriceLevelData[]>([]);
  
    const [productStocks, setProductStocks] = useState<ProductStock[]>([]);
    const [circulations, setCirculations] = useState<Circulation[]>([]);
  
    const [stores, setStores] = useState<Store[]>([]);
    const [modalCirculationOpen, setModalCirculationOpen] = useState(false);
    const [circulationStore, setCirculationStore] = useState<Store | null>(null);
    const [inputHasStock, setInputHasStock] = useState(false);
    const [inputIsMaterial, setInputIsMaterial] = useState(false);
    const [modalMaterialOpen, setModalMaterialOpen] = useState(false);
    const [materialList, setMaterialList] = useState<Product[]>([]);
    const [selectedMaterial, setSelectedMaterial] = useState<Product | null>(null);
    const [materialQty, setMaterialQty] = useState(0);
    const [materialUnit, setMaterialUnit] = useState<Unit | null>(null);
    const [mountedPrice, setMountedPrice] = useState(true);
  
  
  
    useEffect(() => {
        getPermissions().then(v => {

            setPermissions(v)
        })
      setMounted(true)
    }, []);
    useEffect(() => {
      if (!mounted) return
  
      getDetail()
      getProductCategories({ page: 1, limit: 1000 })
        .then(v => v.json())
        .then(v => {
          setProductCategories(v.data)
  
        })
      getUnits({ page: 1, limit: 1000 })
        .then(v => v.json())
        .then(v => {
          setUnits(v.data)
        })
      getPackagings({ page: 1, limit: 1000 })
        .then(v => v.json())
        .then(v => {
          setPackagings(v.data)
        })
      getPriceCategories({ page: 1, limit: 1000 })
        .then(v => v.json())
        .then(v => {
          setPriceCategories(v.data)
        })
      getStores({ page: 1, limit: 1000 })
        .then(v => v.json())
        .then(v => {
          setStores(v.data)
        })
  
  
    }, [mounted]);
  
    useEffect(() => {
      let cat: { value: string; label: string; }[] = []
      for (let index = 0; index < productCategories.length; index++) {
        const element = productCategories[index];
        cat.push({ value: element.uuid, label: element.name })
      }
      setCategories(cat)
    }, [productCategories]);
  
    useEffect(() => {
      if (priceLevel) {
        setInputAmountValue(0)
        setPriceLevelValues([{ min: 0, amount: 0 }])
      } else {
        setPriceLevelValues([])
      }
    }, [priceLevel]);
  
    useEffect(() => {
      let pc: { value: string; label: string; }[] = []
      for (let index = 0; index < priceCategories.length; index++) {
        const element = priceCategories[index];
        pc.push({ value: element.uuid, label: element.name })
      }
      setPriceCategorySelect(pc)
    }, [priceCategories]);
  
    useEffect(() => {
      if (circulationStore != null) {
        setModalCirculationOpen(true)
      }
    }, [circulationStore]);
  
    useEffect(() => {
      let u: { value: string; label: string; }[] = []
      for (let index = 0; index < units.length; index++) {
        const element = units[index];
        u.push({ value: element.uuid, label: element.name })
      }
      setUnitSelect(u)
    }, [units]);
  
    useEffect(() => {
      let u: { value: string; label: string; }[] = []
      for (let index = 0; index < packagings.length; index++) {
        const element = packagings[index];
        u.push({ value: element.uuid, label: element.name })
      }
      setPackagingSelect(u)
    }, [packagings]);
  
    useEffect(() => {
  
      if (!product) return
  
      setInputName(product.name)
      setInputSKU(product.sku)
      setInputBarcode(product.barcode)
      setInputProductCategoryID(product.product_category_id)
      setInputProductCategory({ value: product.product_category_id, label: product.product_category })
      setInputPrice(product.price)
      setInputIsBuy(product.is_buy)
      setInputIsSell(product.is_sell)
      setVarianParameters(product?.varian_parameters ?? [])
      setPriceSelectedIds(product?.prices.map(e => e.price_category_id))
      setInputDescription(product?.description)
      setInputHasStock(product?.has_stock)
      setInputIsMaterial(product?.is_material)
  
      getProductStocks(product?.uuid ?? "")
        .then(v => v.json())
        .then(v => {
          setProductStocks(v.data)
        })
      getProductCirculations(product?.uuid ?? "", { page: 1, limit: 5 })
        .then(v => v.json())
        .then(v => {
          setCirculations(v.data)
        })
  
  
    }, [product]);
  
    useEffect(() => {
  
      let varParams: { value: string; label: string; }[] = []
      for (let index = 0; index < varianParameters.length; index++) {
        const element = varianParameters[index];
        varParams.push({ value: element, label: element })
      }
  
  
      setInputVarianParameters(varParams)
  
    }, [varianParameters]);
  
  
    const getDetail = async () => {
      try {
        setIsLoading(true)
        var resp = await getProductDetail(productId!)
        var respJson = await resp.json()
        if (!respJson.data["varian_parameters"]) {
          respJson.data["varian_parameters"] = []
        }
        setProduct(respJson.data)
  
      } catch (error) {
        Swal.fire("Perhatian", `${error}`, "error")
      } finally {
        setIsLoading(false)
      }
  
    }
  
    const addNewVarian = async (colors: string[]) => {
      try {
        setIsLoading(true)
        if (colors.length > 0) {
          console.log()
          if (Object.keys(inputNewVarian).includes("warna") || Object.keys(inputNewVarian).includes("color" || Object.keys(inputNewVarian).includes("colors"))) {
            for (const key in colors) {
              if (Object.prototype.hasOwnProperty.call(colors, key)) {
                const element = colors[key];
                await addVarian(product!.uuid, JSON.stringify({ "warna": element }))
              }
            }
          }
  
        } else {
          await addVarian(product!.uuid, JSON.stringify(inputNewVarian))
        }
        setModalVarianOpen(false)
        getDetail()
        setInputNewVarian({})
  
      } catch (error) {
        Swal.fire("Perhatian", `${error}`, "error")
      } finally {
        setIsLoading(false)
  
      }
    }
  
    const addNewPrice = async () => {
      try {
        setIsLoading(true)
        await addProductPriceCategory(product!.uuid, {
          product_id: { Valid: true, String: product!.uuid },
          price_category_id: { Valid: true, String: inputPriceCategoryId },
          amount: priceLevelValues.length > 0 ? priceLevelValues[0].amount : inputAmountValue,
          price_level: { Valid: priceLevelValues.length > 0, String: JSON.stringify(priceLevelValues) },
        })
        setModalPriceCategoryOpen(false)
        getDetail()
        setInputPriceCategory(null)
        setInputPriceCategoryId("")
        setInputAmountValue(0)
        setPriceLevel(false)
      } catch (error) {
        Swal.fire("Perhatian", `${error}`, "error")
      } finally {
        setIsLoading(false)
  
      }
    }
    const deleteUnit = async (unit: Unit) => {
      try {
        setIsLoading(true)
        await deleteProductUnit(product!.uuid, unit.uuid)
        getDetail()
      } catch (error) {
        Swal.fire("Perhatian", `${error}`, "error")
      } finally {
        setIsLoading(false)
  
      }
    }
    const deletePackaging = async (uuid: string) => {
      try {
        setIsLoading(true)
        await deleteProductPackaging(product!.uuid, uuid)
        getDetail()
      } catch (error) {
        Swal.fire("Perhatian", `${error}`, "error")
      } finally {
        setIsLoading(false)
  
      }
    }
    const deletePrice = async (uuid: string) => {
      try {
        setIsLoading(true)
        await deleteProductPriceCategory(product!.uuid, uuid)
        getDetail()
      } catch (error) {
        Swal.fire("Perhatian", `${error}`, "error")
      } finally {
        setIsLoading(false)
  
      }
    }
    const addNewUnit = async () => {
      try {
        setIsLoading(true)
        await addProductUnit(product!.uuid, {
          product_id: { Valid: true, String: product!.uuid },
          unit_id: { Valid: true, String: inputUnitId },
          value: product!.units.length == 0 ? 1 : inputUnitValue,
          is_default: product!.units.length == 0
        })
        setModalUnitOpen(false)
        getDetail()
        setInputUnitId("")
        setInputUnitValue(0)
        setInputUnit(null)
      } catch (error) {
        Swal.fire("Perhatian", `${error}`, "error")
      } finally {
        setIsLoading(false)
  
      }
    }
    const addNewPackaging = async () => {
      try {
        setIsLoading(true)
        await addProductPackaging(product!.uuid, {
          packaging_id: { Valid: true, String: inputPackagingId },
          unit_id: { Valid: true, String: product?.unit_id ?? "" },
          netto: inputPackagingValue,
        })
        setModalPackagingOpen(false)
        getDetail()
        setInputPackagingId("")
        setInputPackagingValue(0)
        setInputPackaging(null)
      } catch (error) {
        Swal.fire("Perhatian", `${error}`, "error")
      } finally {
        setIsLoading(false)
  
      }
    }
  
    const updateProduct = async (price?: number | null) => {
      try {
        setIsLoading(true)
        await editProduct(product!.uuid, {
          name: inputName,
          sku: inputSKU,
          barcode: inputBarcode,
          price: price ?? inputPrice,
          product_category_id: { Valid: inputProductCategoryID != "", String: inputProductCategoryID },
          description: product!.name,
          varian_parameters: JSON.stringify(varianParameters),
          is_sell: inputIsSell,
          is_buy: inputIsBuy,
          is_material: product!.is_material,
          is_manufacture: product!.is_manufacture,
          has_sn: product!.has_sn,
          has_stock: inputHasStock,
        })
        Swal.fire({
          title: "Perhatian!",
          text: `${inputName} berhasil di update`,
          icon: "success"
        });
        getDetail()
        setMountedPrice(false)
        setTimeout(() => {
          setMountedPrice(true)
        }, 300);
      } catch (error) {
        Swal.fire("Perhatian", `${error}`, "error")
      } finally {
        setIsLoading(false)
      }
    }
  
    const saveAddProductCategory = async (name: string) => {
      try {
        setIsLoading(true)
        const resp = await addProductCategory({
          name: name,
        })
        await resp.json()
        getProductCategories({ page: 1, limit: 1000 })
          .then(v => v.json())
          .then(v => setProductCategories(v.data))
      } catch (error) {
        Swal.fire("Perhatian", `${error}`, "error")
      } finally {
        setIsLoading(false)
      }
    }
  
    const saveButton = () => (
      <button
        type="button"
        className="inline-flex justify-center items-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-xs font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
        onClick={() => updateProduct()}
      >
        <FolderIcon
          className=" h-3 w-3 mr-1"
          aria-hidden="true" />  Simpan
      </button>
    )
  
    const productInfo = () => (
      <div className="mb-5 h-auto overflow-y-auto col-span-2 rounded-xl py-6 px-6 transition-all hover:shadow-lg sm:p-4 lg:px-4 xl:px-6 bg-white cursor-pointer" >
        <div className="flex justify-between items-center mb-4">
          <h3 className=" text-2xl">Info</h3>
          {saveButton()}
        </div>
        <div className="">
          <div className="flex items-center mb-6">
            <div className="w-1/3">
              <label
                className="block text-gray-900 mb-0 pr-4"
                htmlFor="sku"
              >
                SKU
              </label>
            </div>
            <div className="w-2/3">
              <input
                className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                id="sku"
                type="text"
                placeholder="SKU"
                value={inputSKU}
                onChange={(e) => setInputSKU(e.target.value)}
              />
            </div>
          </div>
          <div className="flex  mb-6">
            <div className="w-1/3">
              <label
                className="block text-gray-900 mb-0 pr-4"
                htmlFor="barcode"
              >
                Barcode
              </label>
            </div>
            <div className="w-2/3">
              <input
                className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                id="barcode"
                type="text"
                placeholder="Barcode"
                value={inputBarcode}
                onChange={(e) => setInputBarcode(e.target.value)}
              />
              {inputBarcode.length ? <Barcode height={60} value={inputBarcode} /> : null}
  
            </div>
          </div>
          <div className="flex items-center mb-6">
            <div className="w-1/3">
              <label
                className="block text-gray-900 mb-0 pr-4"
                htmlFor="product-name"
              >
                Nama Produk
              </label>
            </div>
            <div className="w-2/3">
              <input
                className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                id="product-name"
                type="text"
                placeholder="Nama Produk"
                value={inputName}
                onChange={(e) => setInputName(e.target.value)}
              />
            </div>
          </div>
  
          <div className="flex items-center mb-6">
            <div className="w-1/3">
              <label
                className="block text-gray-900 mb-0 pr-4"
                htmlFor="product-category"
              >
                Kategori
              </label>
            </div>
            <div className="w-2/3">
              <Creatable< SelectOption, false> styles={colourStyles} options={categories} value={inputProductCategory}
                onChange={(option: SingleValue<SelectOption>): void => {
                  setInputProductCategoryID(option?.value ?? "")
                  setInputProductCategory(option)
                }}
                onCreateOption={(val) => {
                  saveAddProductCategory(val)
                }}
              />
  
            </div>
          </div>
          <div className="flex items-center mb-6">
            <div className="w-1/3">
              <label
                className="block text-gray-900 mb-0 pr-4"
                htmlFor="base-price"
              >
                Harga Dasar
              </label>
            </div>
            <div className="w-2/3">
              {product?.price && mountedPrice &&
                <CurrencyInput
                  className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                  id="base-price"
                  groupSeparator="."
                  decimalSeparator=","
                  placeholder="Harga Dasar"
                  defaultValue={product!.price!}
                  onValueChange={(value, _, values) => setInputPrice(values?.float ?? 0)}
                />
              }
  
            </div>
          </div>
          <div className="flex items-center mb-6">
            <div className="w-1/3">
              <label
                className="block text-gray-900 mb-0 pr-4"
                htmlFor="base-price"
              >
                Produk Jual
              </label>
            </div>
            <div className="w-2/3">
              <Switch
                checked={inputIsSell}
                onChange={setInputIsSell}
                className={`${inputIsSell ? 'bg-blue-600' : 'bg-gray-200'
                  } relative inline-flex h-6 w-11 items-center rounded-full`}
              >
                <span className="sr-only">Produk Jual</span>
                <span
                  aria-hidden="true"
                  className={`${inputIsSell ? 'translate-x-6' : 'translate-x-1'
                    } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                />
              </Switch>
            </div>
          </div>
  
          <div className="flex items-center mb-6">
            <div className="w-1/3">
              <label
                className="block text-gray-900 mb-0 pr-4"
                htmlFor="base-price"
              >
                Produk Beli
              </label>
            </div>
            <div className="w-2/3">
              <Switch
                checked={inputIsBuy}
                onChange={setInputIsBuy}
                className={`${inputIsBuy ? 'bg-blue-600' : 'bg-gray-200'
                  } relative inline-flex h-6 w-11 items-center rounded-full`}
              >
                <span className="sr-only">Produk Beli</span>
                <span
                  aria-hidden="true"
                  className={`${inputIsBuy ? 'translate-x-6' : 'translate-x-1'
                    } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                />
              </Switch>
            </div>
          </div>
  
          <div className="flex items-center mb-6">
            <div className="w-1/3">
              <label
                className="block text-gray-900 mb-0 pr-4"
                htmlFor="base-price"
              >
                Bahan Baku
              </label>
            </div>
            <div className="w-2/3">
              <Switch
                checked={inputIsMaterial}
                onChange={setInputIsMaterial}
                className={`${inputIsMaterial ? 'bg-blue-600' : 'bg-gray-200'
                  } relative inline-flex h-6 w-11 items-center rounded-full`}
              >
                <span className="sr-only">Bahan Baku</span>
                <span
                  aria-hidden="true"
                  className={`${inputIsMaterial ? 'translate-x-6' : 'translate-x-1'
                    } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                />
              </Switch>
            </div>
          </div>
  
          <div className="flex items-center mb-6">
            <div className="w-1/3">
              <label
                className=" text-gray-900 mb-0 pr-4 flex"
                htmlFor="base-price"
              >
                <span>
  
                  Stok Aktif
                </span>
                <Whisper followCursor speaker={<Tooltip>Stok yang tidak aktif, tidak akan tercatat di sirkulasi gudang, matikan stok jika produk ini mengandung bahan baku, misalkan Kopi Cappucino, karena yang akan dihitung dalam sirkulasi adalah bahan baku dari produk tsb</Tooltip>}>
                  <div>
                    <InformationCircleIcon className="w-4 text-gray-500 ml-2" />
  
                  </div>
  
                </Whisper>
              </label>
            </div>
            <div className="w-2/3">
              <Switch
                checked={inputHasStock}
                onChange={setInputHasStock}
                className={`${inputHasStock ? 'bg-blue-600' : 'bg-gray-200'
                  } relative inline-flex h-6 w-11 items-center rounded-full`}
              >
                <span className="sr-only">Stok Aktif</span>
                <span
                  aria-hidden="true"
                  className={`${inputHasStock ? 'translate-x-6' : 'translate-x-1'
                    } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                />
              </Switch>
            </div>
          </div>
          <div className="flex  mb-6">
            <div className="w-1/3">
              <label
                className="block text-gray-900 mb-0 pr-4"
              >
                Keterangan
              </label>
            </div>
            <div className="w-2/3">
              <textarea
                className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                id="sku"
                rows={5}
                placeholder="Keterangan"
                value={inputDescription}
                onChange={(e) => setInputDescription(e.target.value)}
              />
            </div>
          </div>
  
  
        </div>
      </div>
    )
    const varians = () => (
      <div className=" mb-5 h-auto flex flex-col">
        {/* {product?.has_stock ? <div className="py-6 px-6 sm:p-4 mb-4 lg:px-4 xl:px-6 overflow-y-auto  rounded-xl transition-all hover:shadow-lg  bg-white cursor-pointer" >
                  <h3 className=" text-2xl">Stok</h3>
                  <div className="flex flex-col items-end">
                      <h1 className="text-6xl -mb-2 tracking-tighter flex items-center">{money(product?.total_stock ?? 0)}</h1>
                      <small className="text-red-600 flex">{product?.unit_name} </small>
                  </div>
                  {product?.packaging_id && product?.total_packaging_stock > 0 ? <small className="text-gray-400">perkiraan total stok dalam kemasan: <span className="text-gray-800">{money(product?.total_packaging_stock ?? 0)} {product?.packaging_name}</span> </small> : null}
              </div> : null} */}
  
        <div className="py-6 px-6 sm:p-4 flex-1  lg:px-4 xl:px-6 overflow-y-auto  rounded-xl transition-all hover:shadow-lg  bg-white cursor-pointer" >
          <div className="flex justify-between items-center mb-4">
            <h3 className=" text-2xl">Varian</h3>
            {saveButton()}
          </div>
          <Creatable<SelectOption, true> isMulti styles={multiColourStyles} value={inputVarianParameters} options={inputVarianParameters}
            onCreateOption={(val) => {
              setVarianParameters([...varianParameters, val])
            }}
          />
          <div className="mt-4">
  
            <ul>
              {varianParameters.length > 0 && <li className="flex justify-end text-xs text-blue-500 hover:text-blue-800" onClick={() => setModalVarianOpen(true)}>
                + Tambah Varian
              </li>}
  
              {product?.varians.map(e => <li className="flex mb-2" key={e.uuid}><h1 className="mr-2 mb-0 text-3xl text-gray-400 hover:text-black">{product?.varians.indexOf(e) + 1}</h1> {Object.keys(e.data).map((item) => (
                <div key={randomStr(12)} className="flex flex-col mr-4 w-28 ">
                  <small className="mb-0 text-gray-400">{ucFirst(item)}</small>
                  <span className="-mt-2"> {`${e.data[item]}`}</span>
                </div>
              ))}
                <div className="flex flex-col mr-4 w-28 ">
                  <small className="mb-0 text-gray-400">Stok</small>
                  <span className="-mt-2">{money(e.stock)} {product.unit_name}</span>
                  <span className="-mt-2">{money(e.stock_packaging)} {product.packaging_name}</span>
                </div>
              </li>)}
  
            </ul>
          </div>
        </div>
      </div>
  
    )
    const materials = () => (product != null && <div className=" mb-5 h-auto flex flex-col">
      <div className="flex-1 overflow-y-auto  rounded-xl py-6 px-6 transition-all hover:shadow-lg sm:p-4 lg:px-4 xl:px-6 bg-white cursor-pointer" >
        <div className="flex justify-between items-center">
          <h3 className=" text-2xl">Bahan Baku</h3>
          <div className=" text-xs text-blue-500 hover:text-blue-800" onClick={() => setModalMaterialOpen(true)}>
            + Tambah Bahan Baku
          </div>
        </div>
  
        <p className="text-xs mt-2">Produk ini tidak mempunyai stok, silahkan tambahkan bahan baku</p>
        <ul className="mt-4">
          {product.materials.map(e => <li key={e.uuid} className="mb-3">
            <div className="flex  justify-between items-center">
              <div className="flex">
                <Avatar alt={e.name} src={e.default_picture} circle bordered />
                <div className="ml-4  flex flex-col">
                  <span className="text-lg font-semibold" onClick={() => nav(`/product/${e.uuid}`)}>
                    {e.name}
                  </span>
                  <small className="-mt-1">{money(e.amount)} {e.unit_name}</small>
                </div>
              </div>
              <div className="">
                {money(e.amount * e.price)}
              </div>
            </div>
          </li>)}
        </ul>
        <div className="flex mt-4 justify-between items-center">
          <p className=" font-semibold">Total HPP</p>
          <p className=" m-0">{money(product.materials.map(e => e.amount * e.price).reduce((a, b) => a + b, 0))}</p>
        </div>
        <div className="flex justify-between items-center">
          <p className=" font-semibold">Harga Dasar</p>
          <p className=" m-0">{money(product.price)}</p>
        </div>
        {(product.materials.map(e => e.amount * e.price).reduce((a, b) => a + b, 0)) != product.price && <div className="mt-4 flex flex-col"> <button
          type="button"
          className="inline-flex w-full justify-center items-center rounded-md border border-transparent bg-orange-100 px-2 py-1 text-xs font-medium text-orange-900 hover:bg-orange-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-orange-500 focus-visible:ring-offset-2"
          onClick={() => {
            updateProduct(product.materials.map(e => e.amount * e.price).reduce((a, b) => a + b, 0))
  
          }}
        >
          <FolderIcon
            className=" h-3 w-3 mr-1"
            aria-hidden="true" />  Update Harga Dasar
        </button>
          <p className="text-xs mt-2">Total HPP tidak sama, silakan update harga dasar</p>
        </div>}
  
      </div>
    </div>)
    const inventory = () => (product != null &&
      <div className=" mb-5 h-auto flex flex-col">
        {product?.has_stock ? <div className="py-6 px-6 sm:p-4 mb-4 lg:px-4 xl:px-6 overflow-y-auto  rounded-xl transition-all hover:shadow-lg  bg-white cursor-pointer" >
          <h3 className=" text-2xl">Stok</h3>
          <div className="flex flex-col items-end">
            <h1 className="text-6xl -mb-2 tracking-tighter flex items-center">{money(product?.total_stock ?? 0)}</h1>
            <small className="text-red-600 flex">{product?.unit_name} </small>
            <h1 className="text-2xl -mb-2 tracking-tighter flex items-center">{money(product?.total_packaging_stock ?? 0)}</h1>
            <small className="text-red-600 flex">{product?.packaging_name} </small>
          </div>
          {/* {product?.packaging_id && product?.total_packaging_stock > 0 ? <small className="text-gray-400">perkiraan total stok dalam kemasan: <span className="text-gray-800">{money(product?.total_packaging_stock ?? 0)} {product?.packaging_name}</span> </small> : null} */}
        </div> : null}
        <div className="flex-1 overflow-y-auto  rounded-xl py-6 px-6 transition-all hover:shadow-lg sm:p-4 lg:px-4 xl:px-6 bg-white cursor-pointer" >
          <h3 className=" text-2xl">Inventory</h3>
          <ul>
            {productStocks.map(e =>
              <li key={e.uuid} className="flex  justify-between   hover:bg-gray-50 p-2 rounded-md">
                <div className="flex-col" onClick={() => nav(`/store/${e.uuid}`)}>
                  <small className=" font-medium" style={{ fontSize: 16 }}>{e.name}</small >
                  <div className="flex  items-center justify-start">
                    <small className="text-right mr-1">{money(e.stock)} {product.unit_name}</small>
  
                    {e.stock_packaging > 0 ? <small className=" text-xs text-left">({money(e.stock_packaging)} {product.packaging_name})</small> : null}
                  </div>
                </div>
                <ArrowPathRoundedSquareIcon className="w-4" onClick={() => {
                  setCirculationStore({ uuid: e.uuid, name: e.name })
  
                }} />
              </li>
            )}
          </ul>
          <hr className="mt-2" />
          <h3 className=" text-xl mt-4">Sirkulasi</h3>
          <ul>
            {circulations.map(e => <li className="text-sm flex flex-col  hover:bg-gray-50 p-2 rounded-md " key={e.uuid}>
              <Moment className="text-xs font-medium text-red-400" format="DD/MM/YYYY">{e.date}</Moment>
              <span className="text-left">
                {e.description}
              </span>
              <span className="text-xs text-gray-600">
                {`${money(e.amount_in - e.amount_out)} ${e.unit_name}`}
                {' '}
                {e.destination_name != "" ? `ke ${e.destination_name}` : null}
                {e.source_name != "" ? `dari ${e.source_name}` : null}
  
              </span>
            </li>)}
            {circulations.length > 0 ?
              <li className="flex justify-center mt-2"><Badge onClick={() => nav(`/store?product_id=${product?.uuid}`)} label="Selengkapnya" /></li>
              : null}
  
          </ul>
          <small className="text-gray-600">{circulations.length == 0 ? 'Tidak ada sirkulasi' : null} </small>
        </div>
      </div>
    )
    const photos = () => (product != null &&
      <div className="mb-5 h-auto overflow-y-auto  rounded-xl py-6 px-6 transition-all hover:shadow-lg sm:p-4 lg:px-4 xl:px-6 bg-white cursor-pointer" >
        <h3 className=" text-2xl mb-4">Foto</h3>
        <div className="flex flex-wrap">
          <input type='file' id='file' accept='image/*' ref={inputFile} style={{ display: 'none' }} onChange={async (e) => {
            try {
              setIsLoading(true)
              await fileUpload(`Product/${product.uuid}/AddImage`, {
                image: e.target.files![0],
                dir: 'product',
              })
              getDetail()
            } catch (error) {
              Swal.fire("Perhatian", `${error}`, "error")
            } finally {
              setIsLoading(false)
            }
  
          }} />
          {product.pictures.map(e => <div key={e}>
            <img className="w-24 h-24 mr-2 mb-2 object-cover rounded-lg " src={e} alt={product.name} />
          </div>)}
          <div className="w-24 h-24 mr-2 mb-2 flex justify-center items-center rounded-lg bg-gray-200 hover:bg-gray-400" onClick={() => inputFile.current?.click()}>
            <PlusIcon
              className=" h-7 w-7 text-white "
              aria-hidden="true" />
          </div>
        </div>
      </div>
    )
  
  
  
  
    const prices = () => (<div className="mb-5 h-auto  flex flex-col col-span-2 rounded-xl py-6 px-6 transition-all hover:shadow-lg sm:p-4 lg:px-4 xl:px-6 bg-white cursor-pointer">
      <div className="flex justify-between items-center mb-4">
        <h3 className=" text-2xl">Harga Jual</h3>
        <div className=" text-xs text-blue-500 hover:text-blue-800" onClick={() => setModalPriceCategoryOpen(true)}>
          + Tambah Harga
        </div>
  
      </div>
      <div className="flex-1 relative overflow-x-auto overflow-y-auto">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
            <tr>
              <th scope="col" className="px-6 py-3">
                Kategori
              </th>
              <th scope="col" className="px-6 py-3">
                Qty
              </th>
              <th scope="col" className="px-6 py-3 text-right">
                Harga
              </th>
              <th scope="col" className="px-6 py-3 text-right">
                Margin
              </th>
              <th scope="col" className="px-6 py-3 text-right">
  
              </th>
            </tr>
          </thead>
          <tbody >
            {product?.prices.map(e => e.price_level_data.map(level => {
              let index = e.price_level_data.indexOf(level)
              return <tr key={randomStr(10)} className={`bg-white  last:border-b-0 
                          ${e.price_level_data.length - 1 == index ? 'border-b' : 'border-b-0'}`}>
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                >
                  {index == 0 ? e.category_name : null}
                </th>
                <td className="px-6 py-4">{money(index == 0 ? 1 : `>${level.min}`)}</td>
                <td className="px-6 py-4 text-right">{money(index == 0 ? e.amount : level.amount)}</td>
                <td className="px-6 py-4 text-right">{money((index == 0 ? e.amount : level.amount) - (product?.price ?? 0))}</td>
                <td className="px-6 py-4 text-right w-10">
                  {index == 0 ? <TrashIcon className="w-5 text-red-600" onClick={() => {
                    Swal.fire({
                      title: "Anda Yakin",
                      text: "Anda tidak akan dapat mengembalikan proses ini!",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Ya, Hapus!",
                      cancelButtonText: "Batal"
                    }).then((result) => {
                      if (result.isConfirmed) {
                        deletePrice(e.price_category_id);
                      }
                    });
                  }} /> : null}
  
                </td>
              </tr>;
            }))}
          </tbody>
        </table>
      </div>
    </div>)
    const unitPackaging = () => (<div className="mb-5 h-auto overflow-y-auto  rounded-xl py-6 px-6 transition-all hover:shadow-lg sm:p-4 lg:px-4 xl:px-6 bg-white cursor-pointer" >
      <div className="flex justify-between items-center mb-4">
  
        <h3 className=" text-2xl">Unit & Packaging</h3>
  
  
      </div>
      {product?.unit_id ? <div> <div className="flex justify-end">
        <div className="flex flex-col items-end">
          <small className="text-red-600 flex">Default Unit </small>
          <h1 className="text-6xl -mt-2 tracking-tighter flex items-center">{product?.unit_name}  {product.units.length == 1 ? <TrashIcon className="w-4 ml-3 text-red-600" onClick={() => {
            Swal.fire({
              title: "Anda Yakin",
              text: "Anda tidak akan dapat mengembalikan proses ini!",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Ya, Hapus!",
              cancelButtonText: "Batal"
            }).then((result) => {
  
              if (result.isConfirmed) {
                deleteUnit({ uuid: product.unit_id, name: product.unit_name, value: product.unit_value, description: "", is_default: true })
              }
            });
          }} /> : null}</h1>
        </div>
  
      </div>
        <div className="flex justify-between mt-4">
          {product?.units.length > 1 ?
            <h3 className="mb-0">Unit Lainnya</h3>
            : <div></div>}
          <div className=" text-xs text-blue-500 hover:text-blue-800" onClick={() => setModalUnitOpen(true)}>
            + Tambah Unit
          </div>
  
        </div>
        <ul>
          {product.units.filter(e => !e.is_default).map(e => <li key={e.uuid} className="flex hover:font-medium justify-between">
            <small className=" ">{e.name}</small>
            <div className="flex">
              <small>{money(e.value)} {product.unit_name}</small>
              <TrashIcon className="w-3 text-red-600 ml-1 cursor-pointer" onClick={() => {
                Swal.fire({
                  title: "Anda Yakin",
                  text: "Anda tidak akan dapat mengembalikan proses ini!",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Ya, Hapus!",
                  cancelButtonText: "Batal"
                }).then((result) => {
  
                  if (result.isConfirmed) {
                    deleteUnit(e)
                  }
                });
  
              }} />
            </div>
          </li>)}
        </ul>
  
      </div> :
        <div className="flex justify-between items-center">
          <h1>Tidak Ada Unit</h1>
          <div className=" text-xs text-blue-500 hover:text-blue-800" onClick={() => setModalUnitOpen(true)}>
            + Tambah Unit
          </div>
  
        </div>
      }
      {product?.packaging_id ? <div className="flex justify-start mt-4">
        <div className="flex flex-col items-start">
          <small className="text-gray-600 flex text-xs">Packaging</small>
          <div className="flex">
            <h1 className="text-4xl -mt-1 -mb-1 tracking-tighter flex items-center" onClick={() => setModalPackagingOpen(true)}>{product?.packaging_name} </h1>
            <TrashIcon className="w-3 text-red-600 ml-1 cursor-pointer" onClick={() => {
              Swal.fire({
                title: "Anda Yakin",
                text: "Anda tidak akan dapat mengembalikan proses ini!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Ya, Hapus!",
                cancelButtonText: "Batal"
              }).then((result) => {
  
                if (result.isConfirmed) {
                  deletePackaging(product!.packaging_id)
                }
              });
  
            }} />
          </div>
          {/* <div className="flex items-end justify-end w-full">
            <small>({money(product?.packagings[0].netto)} {product?.unit_name})</small>
          </div> */}
        </div>
      </div>
  
        : <div className="mt-4  " >
          <h1 className="text-2xl -mt-1 -mb-1 tracking-tighter flex items-center">No Packaging </h1>
          <span className="text-xs text-blue-500 hover:text-blue-800" onClick={() => setModalPackagingOpen(true)}>
            + Tambah Packaging
  
          </span>
        </div>}
  
  
    </div>)
  
  
  
    return (
      <DashboardLayout permission="read_product">
        <div className="p-6">
          <h1 className="text-4xl flex"> <Back className="mr-2" onClick={() => nav(-1)} /> {product?.name}</h1>
          <div className="mt-6 grid gap-x-8 gap-y-4 sm:gap-y-0 xl:grid-cols-4 lg:grid-cols-4 sm:grid-cols-2 md:grid-cols-2 ">
            {productInfo()}
            {photos()}
            {(product?.has_stock ?? false) ? inventory() : materials()}
  
          </div>
          <div className="mt-2  grid gap-x-8 gap-y-4 sm:gap-y-0 xl:grid-cols-4 lg:grid-cols-4 sm:grid-cols-2 md:grid-cols-2 " style={{ maxHeight: 400 }}>
            {unitPackaging()}
            {prices()}
            {varians()}
          </div>
          <div className="mt-2 mb-10 grid gap-x-8 gap-y-4 sm:gap-y-0 xl:grid-cols-4 lg:grid-cols-4 sm:grid-cols-2 md:grid-cols-2 " style={{ maxHeight: 400 }}>
  
          </div>
        </div>
        <FormVarian
          inputNewVarian={{ ...inputNewVarian }}
          setInputNewVarian={setInputNewVarian}
          varianParameters={varianParameters}
          modalVarianOpen={modalVarianOpen}
          setModalVarianOpen={setModalVarianOpen}
          onSubmit={(colors) => addNewVarian(colors)}
          onChange={(colors, key) => {
            let input: Record<string, string> = {}
            input[key] = colors.join(",")
            setInputNewVarian({ ...input })
          }}
        />
        <FormUnit product={product}
          modalUnitOpen={modalUnitOpen}
          setModalUnitOpen={setModalUnitOpen}
          setInputUnitId={setInputUnitId}
          setInputUnit={setInputUnit}
          setUnits={setUnits}
          inputUnitValue={inputUnitValue}
          inputUnit={inputUnit} setInputUnitValue={setInputUnitValue} onSubmit={addNewUnit} unitSelect={unitSelect} />
        <FormPackaging
          product={product}
          packagingSelect={packagingSelect}
          modalPackagingOpen={modalPackagingOpen}
          setModalPackagingOpen={setModalPackagingOpen}
          inputPackagingId={inputPackagingId}
          setInputPackagingId={setInputPackagingId}
          inputPackaging={inputPackaging}
          setInputPackaging={setInputPackaging}
          onSubmit={addNewPackaging}
          inputPackagingValue={inputPackagingValue}
          setInputPackagingValue={setInputPackagingValue}
          setPackagings={setPackagings} />
        <FormPriceCategory
          product={product}
          modalPriceCategoryOpen={modalPriceCategoryOpen}
          setModalPriceCategoryOpen={setModalPriceCategoryOpen}
          priceCategorySelect={priceCategorySelect}
          priceSelectedIds={priceSelectedIds}
          setPriceCategorySelect={setPriceCategorySelect}
          inputPriceCategory={inputPriceCategory}
          inputPriceCategoryId={inputPriceCategoryId}
          setInputPriceCategoryId={setInputPriceCategoryId}
          setInputPriceCategory={setInputPriceCategory}
          setPriceCategories={setPriceCategories}
          priceLevel={priceLevel}
          setPriceLevel={setPriceLevel}
          inputAmountValue={inputAmountValue}
          setInputAmountValue={setInputAmountValue}
          priceLevelValues={priceLevelValues}
          setPriceLevelValues={setPriceLevelValues}
          onSubmit={addNewPrice} />
        <FormCirculation
          initialStore={circulationStore}
          modalCirculationOpen={modalCirculationOpen}
          setModalCirculationOpen={setModalCirculationOpen}
          initialProduct={product}
          onProductModalOpen={() => setModalCirculationOpen(false)}
          onProductModalSubmit={() => setModalCirculationOpen(true)}
          onSubmit={() => {
            setModalCirculationOpen(false)
            getDetail()
          }}
        />
  
        <ModalCustom
          footer={<button
            type="button"
            className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
            onClick={async () => {
              try {
                if (!materialUnit) throw new Error("Isi material terlebih dahulu")
                if (!materialQty) throw new Error("Isi qty  terlebih dahulu")
                setIsLoading(true)
  
                await addMaterial(product!.uuid, {
                  product_id: setNullString(selectedMaterial!.uuid),
                  unit_id: setNullString(materialUnit.uuid),
                  unit_name: materialUnit.name,
                  unit_value: materialUnit.value,
                  amount: materialQty,
                })
                setMaterialUnit(null)
                setMaterialQty(0)
                setMaterialList([])
                setModalMaterialOpen(false)
                setSelectedMaterial(null)
                getDetail()
              } catch (error) {
                Swal.fire("Perhatian", `${error}`, "error")
              } finally {
                setIsLoading(false)
              }
            }}
          >
            <FolderIcon
              className=" h-5 w-5 mr-2"
              aria-hidden="true" />  Simpan
          </button>}
          title="Tambahkan Bahan Baku" isOpen={modalMaterialOpen} setIsOpen={setModalMaterialOpen}  >
          <InlineForm title={'Bahan Baku'} className="mt-4">
            <Select< SelectOption, false> styles={colourStyles} options={materialList.map(e => ({ value: e.uuid, label: e.name }))} value={selectedMaterial ? {
              value: selectedMaterial.uuid, label: selectedMaterial.
                name
            } : null}
              onChange={(option: SingleValue<SelectOption>): void => {
                setSelectedMaterial(materialList.find(e => e.uuid == option?.value) ?? null)
                // }
              }}
              onInputChange={(val) => {
                getProducts({ page: 1, limit: 10, search: val, isMaterial: true })
                  .then(v => v.json())
                  .then(v => setMaterialList(v.data))
              }}
            />
          </InlineForm>
          <InlineForm title={'Qty'}>
            <CurrencyInput
              className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="base-price"
              groupSeparator="."
              decimalSeparator=","
              placeholder="Qty"
              defaultValue={materialQty}
              onValueChange={(value, _, values) => setMaterialQty(values?.float ?? 0)}
            />
          </InlineForm>
          <InlineForm title={'Unit'}>
            <Select< SelectOption, false> styles={colourStyles} options={(selectedMaterial?.units ?? []).map(e => ({ value: e.uuid, label: e.name }))} value={materialUnit ? {
              value: materialUnit.uuid, label: materialUnit.
                name
            } : null}
              onChange={(option: SingleValue<SelectOption>): void => {
                setMaterialUnit((selectedMaterial?.units ?? []).find(e => e.uuid == option?.value) ?? null)
                // }
              }}
  
            />
          </InlineForm>
          <InlineForm title={'Biaya Pokok'}>
            {money((selectedMaterial?.price ?? 0) * materialQty)}
          </InlineForm>
        </ModalCustom>
      </DashboardLayout>
    )
}
export default ProductDetail;