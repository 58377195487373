
import { AutoNumberSetting, BusinessSetting, CompanyReq, InviteReq, MemberRoleReq, PermissionReq, ProductAddOnSetting } from "../model/company";
import { SELECTED_COMPANY_ID } from "../objects/constants";
import { asyncLocalStorage, customFetch } from "../utils/helper";


export let getCompanyMembers = async () => {
    const companyID = await asyncLocalStorage.getItem(SELECTED_COMPANY_ID)

    return await customFetch(`Company/${companyID}/Members`)
}
export let getCompanyRoles = async () => {
    const companyID = await asyncLocalStorage.getItem(SELECTED_COMPANY_ID)

    return await customFetch(`Company/${companyID}/Roles`)
}
export let getFileImageUpload = async () => {
    return await customFetch(`File/ImageUpload`)
}

export let getCompanyDetail = async () => {
    const companyID = await asyncLocalStorage.getItem(SELECTED_COMPANY_ID)

    return await customFetch(`Company/${companyID}`)
}
export let getCompanyRolePermissions = async ({ roleId, search }: { roleId?: string, search: string }) => {
    const companyID = await asyncLocalStorage.getItem(SELECTED_COMPANY_ID)
    var params: Record<string, string> = {
        search,
    };

    if (roleId) {
        params["role_id"] = roleId
    }
    return await customFetch(`Permission/?${new URLSearchParams(params)}`)
}
export let updateCompany = async (req: CompanyReq) => {
    const companyID = await asyncLocalStorage.getItem(SELECTED_COMPANY_ID)

    return await customFetch(`Company/${companyID}`, {
        method: "PUT",
        body: JSON.stringify(req)
    })
}
export let createRole = async (req: PermissionReq) => {
    const companyID = await asyncLocalStorage.getItem(SELECTED_COMPANY_ID)

    return await customFetch(`Company/${companyID}/CreateRolePermission`, {
        method: "POST",
        body: JSON.stringify(req)
    })
}

export let updateRole = async (req: PermissionReq, roleId: string) => {
    const companyID = await asyncLocalStorage.getItem(SELECTED_COMPANY_ID)

    return await customFetch(`Company/${companyID}/UpdateRolePermission/${roleId}`, {
        method: "PUT",
        body: JSON.stringify(req)
    })
}

export let deleteRole = async (roleId: string) => {
    const companyID = await asyncLocalStorage.getItem(SELECTED_COMPANY_ID)

    return await customFetch(`Company/${companyID}/DeleteRolePermission/${roleId}`, {
        method: "DELETE",
    })
}

export let deleteMember = async (userId: string) => {
    const companyID = await asyncLocalStorage.getItem(SELECTED_COMPANY_ID)

    return await customFetch(`Company/${companyID}/DeleteMember/${userId}`, {
        method: "DELETE",
    })
}
export let inviteMember = async (req: InviteReq) => {
    const companyID = await asyncLocalStorage.getItem(SELECTED_COMPANY_ID)
    return await customFetch(`Company/${companyID}/InviteMember`, {
        method: "POST",
        body: JSON.stringify(req)
    })
}
export let updateMemberRole = async (userId: string, req: MemberRoleReq) => {
    const companyID = await asyncLocalStorage.getItem(SELECTED_COMPANY_ID)
    return await customFetch(`Company/${companyID}/UpdateRole/${userId}`, {
        method: "PUT",
        body: JSON.stringify(req)
    })
}
export let getCompanyCategory = async () => {
    return await customFetch(`CategoryCompany/`)
}
export let updateProductAddOnSetting = async (req: ProductAddOnSetting) => {
    const companyID = await asyncLocalStorage.getItem(SELECTED_COMPANY_ID)

    return await customFetch(`Trading/Company/${companyID}/UpdateProductAddOnSetting`, {
        method: "PUT",
        body: JSON.stringify(req)
    })
}
export let getSpecialPermissions = async (permission: string) => {
    return await customFetch(`Permission/Special?group=${permission}`)
}
export let getCustomerServices = async () => {
    return await customFetch(`CustomerService`)
}
export let getMyPermissions = async () => {
    return await customFetch(`My/Permissions`)
}
export let getProductCategoryByName = async (name: string) => {
    return await customFetch(`Trading/ProductCategoryByName/${name}`)
}
export let getPriceCategoryByName = async (name: string) => {
    return await customFetch(`Trading/PriceCategoryByName/${name}`)
}
export let getStoreByName = async (name: string) => {
    return await customFetch(`Trading/StoreByName/${name}`)
}
export let getUnitByName = async (name: string) => {
    return await customFetch(`Trading/UnitByName/${name}`)
}
export let getPackagingByName = async (name: string) => {
    return await customFetch(`Trading/PackagingByName/${name}`)
}
export let getProductBySKU = async (name: string) => {
    return await customFetch(`Trading/Product/BySKU/${name}`)
}
export let generateNumber = async (setting: BusinessSetting | AutoNumberSetting, featureType: string) => {
    return await customFetch(`Trading/Company/GenerateNumber?type=${featureType}`, {
        method: "POST",
        body: JSON.stringify(setting)
    })
}


