// 

import { Pagination, PaginationReq } from "../model/pagination"
import { MerchantSaleReportReq, MerchantSaleReq } from "../model/pos"
import { SELECTED_MERCHANT_ID } from "../objects/constants"
import { PosReq } from "../objects/pos"
import { asyncLocalStorage, customFetch } from "../utils/helper"
import moment from "moment"
import { DateRange } from "rsuite/esm/DateRangePicker"


export let getPosProducts = async (req: PosReq) => {
    var params: Record<string, string> = {}
    if (req.is_pinned) {
        params["is_pinned"] = req.is_pinned ? "1" : "0"
    }
    if (req.product_category_id) {
        params["product_category_id"] = req.product_category_id
    }
    if (req.search) {
        params["search"] = req.search
    }
    if (req.barcode) {
        params["barcode"] = req.barcode
    }
    const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID)
    return await customFetch(`Pos/${merchantId}/Product?${new URLSearchParams(params)}`)
}
export let getPosProductCategories = async () => {
    const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID)
    return await customFetch(`Pos/${merchantId}/ProductCategory`)
}
export let getPosProductByBarcode= async (barcode: string) => {
    const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID)
    return await customFetch(`Merchant/${merchantId}/Product?barcode=${barcode}`)
}
export let getPosSaleDetail = async (saleId: string) => {
    const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID)
    return await customFetch(`Pos/${merchantId}/Sale/${saleId}`)
}
export let getPosDiscounts = async () => {
    const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID)
    return await customFetch(`Pos/${merchantId}/Discount`)
}
export let getSales = async (range: DateRange, req: PaginationReq, isReported?: boolean | null) => {
    const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID)
    return await customFetch(`Pos/${merchantId}/Sale?${isReported != null ? `is_reported=${isReported ? '1':'0'}` : ''}&page=${req.page}&limit=${req.limit}&search=${req.search}&start_date=${moment(range[0].toLocaleString()).format("YYYY-MM-DD")}&end_date=${moment(range[1].toLocaleString()).format("YYYY-MM-DD")}`)
}
export let getReports = async (range: DateRange, req: PaginationReq) => {
    const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID)
    return await customFetch(`Pos/${merchantId}/Report?page=${req.page}&limit=${req.limit}&search=${req.search}&start_date=${moment(range[0].toLocaleString()).format("YYYY-MM-DD")}&end_date=${moment(range[1].toLocaleString()).format("YYYY-MM-DD")}`)
}
export let getReportDownload = async (reportId: string) => {
    const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID)
    return await customFetch(`Pos/${merchantId}/Report/${reportId}/Download`)
}
export let getReportDetail = async (reportId: string, selectedMerchantId?: string|null) => {
    const merchantId = selectedMerchantId ?? await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID)
    return await customFetch(`Pos/${merchantId}/Report/${reportId}`)
}


export let sendPosSale = async (req: MerchantSaleReq) => {
    const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID)
    return await customFetch(`Pos/${merchantId}/Sale`, {
        method: "POST",
        body: JSON.stringify(req)
    })
}
export let sendPosReport = async (req: MerchantSaleReportReq) => {
    const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID)
    return await customFetch(`Pos/${merchantId}/Report`, {
        method: "POST",
        body: JSON.stringify(req)
    })
}