import * as CryptoJS from 'crypto-js';
import { PERMISSIONS, SELECTED_COMPANY_ID, SELECTED_COMPANY, TOKEN, SELECTED_MERCHANT_ID, SELECTED_MERCHANT, PROFILE, COMPANIES, SELECTED_MACHINE, DATERANGE } from "../objects/constants";
import Swal from "sweetalert2";


export async function customFetch(...args) {
    let [resource, config, multipart, fullUrl] = args;
    const token = await asyncLocalStorage.getItem(TOKEN)
    const companyID = await asyncLocalStorage.getItem(SELECTED_COMPANY_ID)
    const merchantID = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID)

    if (!config) {
        config = {
            headers: {
                authorization: `Bearer ${token ?? null}`
            }
        }
    } else {
        config["headers"] = {
            authorization: `Bearer ${token ?? null}`
        }
    }


    if (!multipart) {
        config["headers"]["Content-Type"] = "application/json"
    }
    if (companyID) {
        config["headers"]["ID-Company"] = companyID
    }
    if (companyID) {
        config["headers"]["ID-Merchant"] = merchantID
    }
    config["headers"]["App"] = "jaraya-trading"

    try {
        // request interceptor here
        const response = await fetch(fullUrl ? resource : `${process.env.REACT_APP_API_URL}/${resource}`, config);

        if (response.status !== 200) {
            var respJson = await response.json()
            throw (respJson.message)
        }

        // response interceptor here
        return response;
    } catch (error) {
        if (error == "no product add on package exists") {
            Swal.fire("Perhatian", "Anda tidak mempunyai akses ke aplikasi ini", "warning")
                .then(() => {
                })
        }
        // console.log(error)
        if (error == "Token is expired") {
            await clearStorage()
            window.location.href = "/login"
        }
        return Promise.reject(error)
    }


}

export async function clearStorage() {
    await asyncLocalStorage.removeItem(TOKEN);
    await asyncLocalStorage.removeItem(PERMISSIONS);
    await asyncLocalStorage.removeItem(SELECTED_COMPANY_ID);
    await asyncLocalStorage.removeItem(SELECTED_COMPANY);
    await asyncLocalStorage.removeItem(SELECTED_MERCHANT_ID);
    await asyncLocalStorage.removeItem(SELECTED_MERCHANT);
}

export async function setStorageMachine(machine) {
    await asyncLocalStorage.setItem(SELECTED_MACHINE, JSON.stringify(machine));
}
export async function getStorageMachine() {
    let machine = await asyncLocalStorage.getItem(SELECTED_MACHINE)
    if (machine) return JSON.parse(machine)
    return null
}

export async function deleteStorageMachine() {
    return await asyncLocalStorage.removeItem(SELECTED_MACHINE);
}
export async function setStorageDateRange(dateRange) {
    return await asyncLocalStorage.setItem(DATERANGE, JSON.stringify([dateRange[0], dateRange[1]]));
}
export async function getStorageDateRange() {
    let dr = await asyncLocalStorage.getItem(DATERANGE)
    if (dr) return JSON.parse(dr)
    return null;
}

export async function setStorage({ token, permissions, company }) {
    await asyncLocalStorage.setItem(TOKEN, token);
    await asyncLocalStorage.setItem(PERMISSIONS, JSON.stringify(permissions));
    await asyncLocalStorage.setItem(SELECTED_COMPANY, JSON.stringify(company));
    await asyncLocalStorage.setItem(SELECTED_COMPANY_ID, company.uuid);
}
export async function setCompanyStorage(company) {
    await asyncLocalStorage.setItem(SELECTED_COMPANY, JSON.stringify(company));
    await asyncLocalStorage.setItem(SELECTED_COMPANY_ID, company.uuid);
}
export async function deleteCompanyStorage() {
    await asyncLocalStorage.removeItem(SELECTED_COMPANY);
    await asyncLocalStorage.removeItem(SELECTED_COMPANY_ID);
}
export async function setMyPermissions(permissions) {
    await asyncLocalStorage.setItem(PERMISSIONS, JSON.stringify(permissions));
}
export async function setMyCompanies(companies) {
    await asyncLocalStorage.setItem(COMPANIES, JSON.stringify(companies));
}
export async function getMyCompanies() {
    let companies = await asyncLocalStorage.getItem(COMPANIES)
    if (companies) return JSON.parse(companies)
    return []
}

export async function setProfileStorage(profile) {
    await asyncLocalStorage.setItem(PROFILE, JSON.stringify(profile));
}
export async function getProfileStorage() {
    let profile = await asyncLocalStorage.getItem(PROFILE);
    if (profile) return JSON.parse(profile)
    return null
}

export async function setActiveMerchant(merchant) {
    await asyncLocalStorage.setItem(SELECTED_MERCHANT_ID, merchant.uuid);
    await asyncLocalStorage.setItem(SELECTED_MERCHANT, JSON.stringify(merchant));
}
export async function deleteMerchant() {
    await asyncLocalStorage.removeItem(SELECTED_MERCHANT_ID);
    await asyncLocalStorage.removeItem(SELECTED_MERCHANT);
}

export async function getCompany() {
    let company = await asyncLocalStorage.getItem(SELECTED_COMPANY)
    if (company) return JSON.parse(company)
    return null
}

export async function getPermissions() {
    let permissions = await asyncLocalStorage.getItem(PERMISSIONS)
    if (permissions) return JSON.parse(permissions)
    return []
}

export async function getSelectedMerchantID() {
    return await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID)
}
export async function getSelectedMerchant() {
    let merchant = await asyncLocalStorage.getItem(SELECTED_MERCHANT)
    if (merchant) return JSON.parse(merchant)
    return null
}

export function initials(name) {
    if (!name) return ""
    return name.split(" ").map((n) => `${n[0]}`.toUpperCase()).join("");
}

export function ucFirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
export function ucFirstWord(string) {
    return string.replaceAll("_", " ").split(" ").map(e => e.charAt(0).toUpperCase() + e.slice(1)).join(" ");
}
export function parseVarian(val, valueOnly) {
    if (!val) return ""
    let keys = Object.keys(val)
    let values = Object.values(val)
    let parsed = []
    for (const i in values) {
        if (Object.hasOwnProperty.call(values, i)) {
            const element = values[i];
            if (valueOnly) {
                parsed.push(`${element}`)
            } else {
                parsed.push(`${keys[i]} : ${element}`)
            }
        }
    }
    return parsed.join(", ")
}

export function setNullString(val) {
    return { Valid: val != null && val != "", String: val }
}
export function randomStr(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}



export function nl2br(str, is_xhtml) {
    if (typeof str === 'undefined' || str === null) {
        return '';
    }
    var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
}


export const asyncLocalStorage = {
    async setItem(key, value) {
        return Promise.resolve().then(function () {
            localStorage.setItem(key, encrypt(value));
        });
    },
    async getItem(key) {
        return Promise.resolve().then(function () {
            let data = localStorage.getItem(key) || "";
            return decrypt(data) || null;
        });
    },
    async removeItem(key) {
        return Promise.resolve().then(function () {
            return localStorage.removeItem(key);
        });
    }
};

function encrypt(txt) {
    return CryptoJS.AES.encrypt(txt, process.env.REACT_APP_SECRET_KEY).toString();
}

function decrypt(txtToDecrypt) {
    return CryptoJS.AES.decrypt(txtToDecrypt, process.env.REACT_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8);
}


export const deliveryLetterStatus = (status) => {
    switch (status) {
        case 'CREATED':
            return ''
        case 'ONPROGRESS':
            return 'Dalam Perjalanan'
            break;
        case 'CANCELED':
            return 'Telah Dibatalkan'
            break;
        case 'FINISHED':
            return 'Selesai Dikirim'
    }
}

export function reverseArr(input) {
    var ret = new Array;
    for (var i = input.length - 1; i >= 0; i--) {
        ret.push(input[i]);
    }
    return ret;
}


export function confirmDelete(confirmed, title, desc, confirmText) {
    Swal.fire({
        title: title ?? "Anda Yakin",
        text: desc ?? "Anda tidak akan dapat mengembalikan proses ini!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: confirmText ?? "Ya, Hapus!",
        cancelButtonText: "Batal"
    }).then((result) => {
        if (result.isConfirmed) {
            confirmed()
        }
    });
}

export function sumMatrixStock(categories, products) {
    if (categories.length == 0) return []
    // console.log(products.map(e => ({ uuid: e.uuid, total_stock: e.total_stock, product_category_id: e.product_category_id, unit_id: e.unit_id, unit_name: e.unit_name, unit_value: e.unit_name })))
    var newCat = []
    var j = 0
    for (const cat of categories) {
        var productByCat = products.filter(e => e.product_category_id == cat.value)
        var grouped = Object.groupBy(productByCat, ({ unit_name }) => unit_name)
        cat.stocks = []
        var i = 0
        for (const key of Object.keys(grouped)) {
            cat.stocks[i] = `${grouped[key].map(g => g.total_stock * g.unit_value).reduce((a, b) => a + b, 0)} ${key}`
            i++
        }
        if (cat.stocks.length)
            newCat[j] = cat
        j++
    }
    return newCat
}

export const zeroPad = (num, places) => String(num).padStart(places, '0')


export async function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}