import { useContext, useEffect, useState, type FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingContext } from '../objects/loading_context';
import { Profile } from '../model/profile';
import { AvatarGroup, Badge, Drawer, Message, Tooltip, Whisper, useToaster, Avatar as RsAvatar } from 'rsuite';
import { PriceLevelData, Product, ProductCategory, ProductStock, ProductStoreStock, VarianStock } from '../model/product';
import { MerchantSale, MerchantSaleItem, merchantSaleToReq } from '../model/pos';
import { Machine, Merchant } from '../model/merchant';
import { Tax } from '../model/tax';
import { Customer } from '../model/customer';
import { Discount } from '../model/discount';
import { getPosDiscounts, getPosProductByBarcode, getPosProductCategories, getPosProducts, getPosSaleDetail, sendPosSale } from '../repositories/pos';
import { getCirculationStock } from '../repositories/circulation';
import { getTaxDetail } from '../repositories/tax';
import { getProfileStorage, getStorageMachine, parseVarian, randomStr, reverseArr, setStorageMachine } from '../utils/helper';
import { getMerchantDetail, getMerchantMachines } from '../repositories/merchant';
import { delSale, getSales, saveSale } from '../utils/pos_helper';
import { errorToast } from '../utils/helper-ui';
import moment from 'moment';
import Swal from 'sweetalert2';
import DashboardLayout from '../components/dashboard';
import { CheckCircleIcon, MagnifyingGlassIcon, MinusCircleIcon, PlusCircleIcon, QrCodeIcon, TrashIcon, WalletIcon } from '@heroicons/react/24/outline';
import { getProductStocks } from '../repositories/product';
import { money } from '../utils/number';
import { FaChartLine, FaRegFloppyDisk } from 'react-icons/fa6';
import CurrencyInput from 'react-currency-input-field';
import { FaRegMoneyBillAlt } from 'react-icons/fa';
import ModalSearch from '../components/modals/modal_search';
import Avatar from '../components/avatar';
import ModalCustomer from '../components/modals/model_customer';
import { paymentMethodsData, paymentProvidersData } from '../objects/pos';
import ModalCustom from '../components/modals/modal_custom';
import ModalReceipt from '../components/modals/modal_receipt';

interface CashierPageProps { }

const CashierPage: FC<CashierPageProps> = ({ }) => {
    const nav = useNavigate()
    let { isLoading, setIsLoading } = useContext(LoadingContext);
    const [profile, setProfile] = useState<Profile | null>(null);
    const toaster = useToaster();
    const [categoryId, setCategoryId] = useState("");
    const [mounted, setMounted] = useState(false);
    const [productCategories, setProductCategories] = useState<ProductCategory[]>([]);
    const [products, setProducts] = useState<Product[]>([]);
    const [modalSearchOpen, setModalSearchOpen] = useState(false);
    const [salesItems, setSalesItems] = useState<MerchantSaleItem[]>([]);
    const [mountQty, setMountQty] = useState(true);
    const [searchProducts, setSearchProducts] = useState<Product[]>([]);
    const [search, setSearch] = useState("");
    const [inputQty, setInputQty] = useState(0);
    const [merchant, setMerchant] = useState<Merchant | null>(null);
    const [modalVarianOpen, setModalVarianOpen] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
    const [selectedVarians, setSelectedVarians] = useState<VarianStock[]>([]);
    const [varianStocks, setVarianStocks] = useState<ProductStoreStock[]>([]);
    const [tax, setTax] = useState<Tax | null>(null);
    const [sidebarPaymentOpen, setSidebarPaymentOpen] = useState(false);
    const [machines, setMachines] = useState<Machine[]>([]);
    const [selectedMachine, setSelectedMachine] = useState<Machine | null>(null);
    const [paymentMethod, setPaymentMethod] = useState("CASH");
    const [paymentProviderGroup, setPaymentProviderGroup] = useState("");
    const [paymentProvider, setPaymentProvider] = useState("CASH");
    const [amountPayment, setAmountPayment] = useState(0);
    const [changes, setChanges] = useState(0);
    const [mountPayment, setMountPayment] = useState(true);
    const [inputCustomerName, setInputCustomerName] = useState("");
    const [customer, setCustomer] = useState<Customer | null>(null);
    const [modalCustomerOpen, setModalCustomerOpen] = useState(false);
    const [notes, setNotes] = useState("");
    const [discounts, setDiscounts] = useState<Discount[]>([]);
    const [activeDiscounts, setActiveDiscounts] = useState<Discount[]>([]);
    const [savedSales, setSavedSales] = useState<MerchantSale[]>([]);
    const [drawerSaved, setDrawerSaved] = useState(false);

    const [editedSale, setEditedSale] = useState<MerchantSale | null>(null);
    const [modalBarcodeOpen, setModalBarcodeOpen] = useState(false);
    const [modalReceiptOpen, setModalReceiptOpen] = useState(false);
    const [selectedReceipt, setSelectedReceipt] = useState<MerchantSale | null>(null);



    useEffect(() => {
        setMounted(true)
    }, []);

    useEffect(() => {
        if (search != "") {
            getPosProducts({ search })
                .then(v => v.json())
                .then(v => setSearchProducts(v.data))
        } else {
            setSearchProducts([])
        }

    }, [search]);
    useEffect(() => {
        if (selectedProduct && merchant && merchant.default_store_id && merchant.check_stock) {
            getCirculationStock(selectedProduct.uuid, merchant!.default_store_id!)
                .then(v => v.json())
                .then(v => setVarianStocks(v.data))
        }
    }, [selectedProduct]);
    useEffect(() => {
        if (categoryId == "") {
            getPosProducts({ is_pinned: true })
                .then(v => v.json())
                .then(v => setProducts(v.data))
            return
        }
        getPosProducts({ product_category_id: categoryId })
            .then(v => v.json())
            .then(v => setProducts(v.data))
    }, [categoryId]);

    useEffect(() => {
        if (merchant && merchant.tax_id) {
            getTaxDetail(merchant.tax_id)
                .then(v => v.json())
                .then(v => setTax(v.data))
        }
    }, [merchant]);

    useEffect(() => {
        if (!mounted) return
        initData()



    }, [mounted]);

    const initData = async () => {
        setProfile(await getProfileStorage())
        getMerchantDetail()
            .then(v => v.json())
            .then(v => setMerchant(v.data))
            .catch(err => errorToast(`${err}`))
            .finally(() => {
                setIsLoading(false)
            })
        getPosProductCategories()
            .then(v => v.json())
            .then(v => setProductCategories(v.data))
            .catch(err => errorToast(`${err}`))
            .finally(() => {
                setIsLoading(false)
            })
        getPosProducts({ is_pinned: true })
            .then(v => v.json())
            .then(v => setProducts(v.data))
            .catch(err => errorToast(`${err}`))
            .finally(() => {
                setIsLoading(false)
            })
        getMerchantMachines()
            .then(v => v.json())
            .then(v => setMachines(v.data))
            .catch(err => errorToast(`${err}`))
            .finally(() => {
                setIsLoading(false)
            })
        setSelectedMachine(await getStorageMachine())
        getPosDiscounts()
            .then(v => v.json())
            .then(v => setDiscounts(v.data))
            .catch(err => errorToast(`${err}`))
            .finally(() => {
                setIsLoading(false)
            })
        getSales()
            .then(v => setSavedSales(v))
            .catch(err => errorToast(`${err}`))
            .finally(() => {
                setIsLoading(false)
            })
    }

    const checkDiscounts = (item: MerchantSaleItem): Discount[] => {
        return discounts.filter(e => e.min_qty <= item.qty && e.max_qty >= item.qty && e.is_all_product)
    }
    const editItem = (item: MerchantSaleItem, qty: number, refresh?: boolean, discount?: Discount | null) => {

        let newItems = salesItems.map(e => {
            if (item.uniqueId == e.uniqueId) {
                if (item.product.prices[0].price_level_data.length > 0) {
                    let levels: PriceLevelData[] = reverseArr(item.product.prices[0].price_level_data)
                    for (const key in levels) {
                        if (Object.prototype.hasOwnProperty.call(levels, key)) {
                            const element = levels[key];
                            if (qty >= element.min) {
                                e.price = element.amount
                                // console.log(element.min)
                                break
                            }
                        }
                    }
                }
                e.qty = qty
                e = countItem(e, discount)
            }
            return e
        })
        setSalesItems([...newItems])
        if (refresh) {

            setMountQty(false)
            setTimeout(() => {
                setMountQty(true)
            }, 10);
        }
    }
    const delItem = (item: MerchantSaleItem) => {
        let newItems = salesItems.filter(e => e.uniqueId != item.uniqueId)
        setSalesItems([...newItems])
        setMountQty(false)
        setTimeout(() => {
            setMountQty(true)
        }, 10);
    }

    const calculateTax = (price: number, includeTax: boolean, selectedTax?: Tax | null): { priceBeforeTax: number, priceAfterTax: number, taxAmount: number } => {
        if (tax == null) return { priceBeforeTax: price, priceAfterTax: price, taxAmount: 0 }
        if (includeTax) {

            return {
                priceAfterTax: price,
                taxAmount: selectedTax!.amount / (100 + selectedTax!.amount) * price,
                priceBeforeTax: 100 / (100 + selectedTax!.amount) * price,
            }
        }
        return {
            priceBeforeTax: price,
            priceAfterTax: (100 + selectedTax!.amount) / 100 * price,
            taxAmount: selectedTax!.amount / 100 * price,
        }
    }

    let countItem = (item: MerchantSaleItem, discount?: Discount | null): MerchantSaleItem => {
        let discountAmount = 0
        let discountPercent = 0
        if (discount != null) {
            if (discount.type == 1) {
                discountAmount = item.price * discount.percent / 100
                discountPercent = discount.percent
            }
            if (discount.type == 0) {
                discountAmount = item.price - discount.amount
            }
        }
        let result = calculateTax((item.price - discountAmount) * item.qty, merchant?.price_tax_include ?? false, tax)
        item.price_before_tax = result.priceBeforeTax
        item.tax_amount = result.taxAmount
        item.discount_percent = discountPercent
        item.discount_amount = discountAmount
        item.discount = discount
        item.discount_id = discount?.uuid

        return item

    }

    const addItem = (item: Product, varian?: VarianStock | null, discount?: Discount | null) => {
        let itemExist = salesItems.find(e => e.product.uuid == item.uuid)
        if (item.varians.length > 0 && varian != null) {
            itemExist = salesItems.find(e => e.product.uuid == item.uuid && e.varian?.uuid == varian.uuid)
        }
        let qty = 1


        if (!itemExist) {

            let saleItem: MerchantSaleItem = {
                uniqueId: randomStr(3),
                product: item,
                qty: qty,
                discount: discount,
                discount_id: discount?.uuid,
                isService: false,
                price: item.prices[0].amount,
                varian: varian,
                tax_id: tax?.uuid,
                tax: tax,
            }
            salesItems.push(countItem(saleItem, discount))

            setSalesItems([...salesItems])
        } else {
            let newItems = salesItems.map(e => {
                if (item.varians.length > 0 && varian != null) {
                    if (e.product.uuid == item.uuid && e.varian?.uuid == varian.uuid) {
                        e.qty++
                        e = countItem(e, discount)
                    }
                } else {
                    if (e.product.uuid == item.uuid) {
                        e.qty++
                        e = countItem(e, discount)
                    }
                }

                return e
            })

            setSalesItems([...newItems])

        }

        setMountQty(false)
        setTimeout(() => {
            setMountQty(true)
        }, 10);


    }

    const countSubTotal = (): number => {
        return salesItems.map(e => {
            return e.price * e.qty
        }).reduce((a, b) => a + b, 0)
    }
    const countDiscount = (): number => {
        return salesItems.map(e => (e.discount_amount ?? 0) * e.qty).reduce((a, b) => a + b, 0)
    }
    const countGrandTotal = (): number => {
        let result = calculateTax(countSubTotal() - countDiscount(), merchant?.price_tax_include ?? false, tax)
        return result.priceAfterTax
    }
    const countTax = (): number => {
        if (merchant && tax) {
            let result = calculateTax(countSubTotal() - countDiscount(), merchant?.price_tax_include ?? false, tax)
            return result.taxAmount
        }
        return 0
    }


    const sendSale = async () => {
        let selectedProvider = merchant!.payment_providers.find(e => e.provider == paymentProvider)
        let dataSales: MerchantSale = {
            code: editedSale ? editedSale.code : randomStr(5).toUpperCase(),
            items: salesItems,
            date: moment().toISOString(),
            cashier_id: profile!.uuid,
            machine_id: selectedMachine!.uuid,
            cashier_name: profile!.full_name,
            customer_id: customer?.uuid ?? "",
            customer_name: inputCustomerName,
            notes: notes,
            subtotal: countSubTotal(),
            discount_total: countDiscount(),
            total: countSubTotal() - countDiscount(),
            tax_amount: countTax(),
            total_tax: countTax(),
            tax_name: tax?.name ?? "",
            count_item: salesItems.length,
            grand_total: countGrandTotal(),
            change: amountPayment - countGrandTotal(),
            payment_amount: amountPayment,
            payment_method: paymentMethod,
            payment_provider: paymentProvider,
            account_destination_id: selectedProvider!.account_destination_id!,
        }

        try {
            setIsLoading(true)
            var resp = await sendPosSale(merchantSaleToReq(dataSales))
            var respJson = await resp.json()
            getPosSaleDetail(respJson.data.last_id)
                .then((v) => v.json())
                .then((v) => {
                    setSelectedReceipt(v.data)
                    setModalReceiptOpen(true)
                })
            clearForm()
            if (editedSale) {
                delSale(editedSale).then((v) => setSavedSales(v))
            }
            setEditedSale(null)
        } catch (error) {
            Swal.fire("Perhatian", `${error}`, "error")
        } finally {
            setIsLoading(false)
        }


    }

    const clearForm = () => {
        setModalSearchOpen(false)
        setSalesItems([])
        setModalVarianOpen(false)
        setSelectedProduct(null)
        setSelectedVarians([])
        setVarianStocks([])
        setPaymentMethod("CASH")
        setPaymentProviderGroup("")
        setPaymentProvider("CASH")
        setAmountPayment(0)
        setInputCustomerName("")
        setCustomer(null)
        setModalCustomerOpen(false)
        setAmountPayment(0)
        setSidebarPaymentOpen(false)
    }

    return (<DashboardLayout permission='cashier_user'>
        <div className='flex h-full ' style={{ width: "calc(100vw - 8rem)" }}>
            <div className=' w-8/12 flex flex-col'>
                <div className='flex justify-between p-4'>
                    <h3 className=' text-2xl font-semibold mb-2 '>Kategori</h3>
                    <div className='flex'>
                        <MagnifyingGlassIcon className="w-6 mr-2 transition-all hover:w-8 text-gray-600 hover:text-gray-800 ml-2 cursor-pointer" onClick={() => setModalSearchOpen(true)} />
                        <Whisper followCursor speaker={<Tooltip>Scan Barcode</Tooltip>}>
                            <QrCodeIcon className="w-6 transition-all hover:w-8 text-gray-600 hover:text-gray-800 ml-2 cursor-pointer" onClick={() => setModalBarcodeOpen(true)} />
                        </Whisper>


                    </div>
                </div>
                <ul className=' overflow-y-auto  whitespace-nowrap pb-4' >
                    <li onClick={() => {
                        setCategoryId("")
                    }} className={`inline-block pos-product-cat ${categoryId == '' ? 'active' : ''}`}>Semua Produk</li>
                    {productCategories.map(e => <li onClick={() => {
                        setCategoryId(e.uuid)
                    }} key={e.uuid} className={` inline-block pos-product-cat ${categoryId == e.uuid ? 'active' : ''}`}>{e.name}</li>)}
                </ul>

                <div className=' grid grid-cols-4 gap-x-8 gap-y-4 px-6 mt-4 overflow-y-auto'>
                    {products.map(e => <div onClick={async () => {

                        if (e.varians.length > 0) {
                            if (e.varians.length == 1) {
                                getCirculationStock(e.uuid, merchant!.default_store_id!)
                                    .then(v => v.json())
                                    .then(v => {
                                        let selected = (v.data as ProductStoreStock[]).find(vr => vr.varian_id == e.varians[0].uuid)
                                        if (!selected) {
                                            toaster.push(<Message showIcon type={"warning"} closable>
                                                Total Stok 0 {e.unit_name}
                                            </Message>, { placement: "bottomStart", duration: 3000 })
                                        } else {
                                            if (selected.amount == 0) {
                                                toaster.push(<Message showIcon type={"warning"} closable>
                                                    Total Stok 0 {e.unit_name}
                                                </Message>, { placement: "bottomStart", duration: 3000 })
                                            }
                                        }

                                        addItem(e, e.varians[0])
                                    })
                                return
                            }
                            setSelectedProduct(e)
                            setModalVarianOpen(true)
                        } else {
                            let res = await getProductStocks(e.uuid)
                            let resJson = await res.json()
                            if (merchant != null && merchant!.check_stock) {
                                let productStocks: ProductStock[] = resJson.data
                                let sel = productStocks.find(e => e.uuid == merchant.default_store_id)
                                if (sel) {
                                    if (sel.stock == 0) {
                                        toaster.push(<Message showIcon type={"warning"} closable>
                                            Total Stok {sel.stock} {e.unit_name}
                                        </Message>, { placement: "bottomStart", duration: 3000 })
                                    }
                                }
                            }

                            // (resJson.data as ProductStock[]).find()
                            addItem(e)

                        }
                    }} key={e.uuid} className='w-full h-80 md:h-40 xl:h-80  rounded-lg transition-all hover:shadow-lg shadow-gray-200 bg-white '>
                        <div className='flex flex-col h-full cursor-pointer'>
                            <div className=' xl:h-3/4  md:h-2/3 relative'>
                                {e.default_picture ? <img src={e.default_picture} alt="" className='rounded-tl-lg h-full w-full rounded-tr-lg object-cover bg-gray-600' />
                                    : <img src="/images/logo-niaga.png" alt="" className='rounded-tl-lg h-full w-full rounded-tr-lg object-cover bg-gray-600' />}
                                <div className=' absolute top-2 right-2 py-1 px-2 rounded  bg-opacity-70 bg-pink-500 text-xs text-white'>{e.product_category}</div>
                            </div>


                            <div className='xl:h-1/4 md:1/3 xl:px-3 xl:py-2 md:p-1  flex flex-col'>
                                <p className='md:text-xs font-semibold'> {e.name}</p>
                                <div className='flex xl:text-xl md:text-xs font-semibold text-orange-600'>
                                    {money(e.prices[0].amount)}
                                </div>

                            </div>
                        </div>
                    </div>)}
                </div>
            </div>
            <div className=' w-4/12 h-full bg-white border-l border-l-gray-200 p-4 flex flex-col'>
                <div className=' flex justify-between items-center'>
                    <h3 className=' xl:text-2xl md:text-lg font-semibold mb-2 '>Order Detail</h3>
                    <div className='flex'>
                        <Whisper followCursor placement='left' speaker={<Tooltip>Transaksi Tersimpan</Tooltip>}>
                            <div>
                                {savedSales.length == 0 ?
                                    <FaRegFloppyDisk /// <reference path="" />
                                        size={24} className='ml-2 text-gray-400 cursor-pointer hover:text-gray-600' onClick={() => setDrawerSaved(true)} />
                                    :
                                    <Badge content={savedSales.length}>
                                        <FaRegFloppyDisk /// <reference path="" />
                                            size={24} className='ml-2 text-gray-400 cursor-pointer hover:text-gray-600' onClick={() => setDrawerSaved(true)} />
                                    </Badge>
                                }
                            </div>
                        </Whisper>
                        <Whisper followCursor placement='left' speaker={<Tooltip>Laporan Penjualan</Tooltip>}>
                            <div>
                                <FaChartLine size={24} className='ml-2 text-gray-400 cursor-pointer hover:text-gray-600' onClick={() => nav(`/cashier/report`)} />
                            </div>
                        </Whisper>
                    </div>
                </div>
                <ul className='flex-1 overflow-y-auto'>
                    {salesItems.map(e => {
                        let discItems = checkDiscounts(e)
                        return <li key={e.uniqueId} className=' flex justify-between items-center hover:bg-gray-100 transition-colors  last:border-b-0  border-b px-2 py-4 cursor-pointer'>
                            <div className='flex'>
                                <div className='xl:w-16 xl:h-16 md:w-10  xl:block md:hidden md:h-10 mr-2 '>
                                    {e.product.default_picture ? <img src={e.product.default_picture} alt="" className='rounded-md h-full w-full object-cover bg-gray-600' />
                                        : <img src="/images/logo-niaga.png" alt="" className='rounded-md  w-full object-cover bg-gray-600' />}
                                </div>
                                <div className='flex flex-col'>
                                    <p className='font-semibold md:text-base '>{e.product.name}</p>
                                    {e.varian != null ? <p className=' -mt-1 font-xs'>{parseVarian(e.varian!.data, true)}</p> : null}
                                    <div className='flex text-xl  md:text-base font-semibold text-orange-600 -mb-1'>
                                        {money((e.price - (e.discount_amount ?? 0)) * e.qty)}
                                    </div>
                                    <small className=''>{money(e.price - (e.discount_amount ?? 0))}</small>
                                    {discItems.length == 1 && e.discount == null && <small onClick={() => {
                                        editItem(e, e.qty, true, discItems[0])
                                    }} className=' text-orange-600'>Terapkan diskon {discItems[0].type == 1 ? `${discItems[0].percent}%` : discItems[0].amount}</small>}


                                </div>

                            </div>
                            <div className=''>
                                <div className='flex items-center'>
                                    <MinusCircleIcon onClick={() => {
                                        editItem(e, e.qty - 1, true);
                                        if (e.qty - 1 <= 0) {
                                            delItem(e);
                                        }
                                    }} className='w-6 text-blue-500' />
                                    {mountQty && <CurrencyInput
                                        className="bg-white mx-2 w-16 text-right appearance-none border border-gray-200 rounded-xl  py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                        groupSeparator="."
                                        decimalSeparator=","
                                        placeholder="Jumlah"
                                        defaultValue={e.qty}
                                        onSubmit={() => {
                                            setMountQty(false);
                                            setTimeout(() => {
                                                setMountQty(true);
                                            }, 10);
                                        }}
                                        onValueChange={(value, _, values) => {
                                            editItem(e, values?.float ?? e.qty, false);
                                        }} />}
                                    <PlusCircleIcon onClick={() => {
                                        editItem(e, e.qty + 1, true);
                                    }} className='w-6 text-green-500' />
                                    <TrashIcon onClick={() => {
                                        delItem(e);
                                    }} className='ml-2 w-6 text-red-500' />
                                </div>

                            </div>
                        </li>;
                    }
                    )}
                </ul>
                <div className=' bg-gray-100 rounded-lg px-3 py-2 flex items-center'>
                    <div className='flex-1'>
                        <div className='flex justify-between items-center'>
                            <p className=' text-gray-600'>Subtotal</p>
                            <h1 className=' text-lg'>{money(countSubTotal())}</h1>
                        </div>
                        {countDiscount() > 0 &&
                            <div className='flex justify-between items-center'>
                                <p className=' text-gray-600'>Total Diskon</p>
                                <h1 className=' text-lg'>{money(countDiscount())}</h1>
                            </div>
                        }

                        {merchant != null && tax &&
                            <div className='flex justify-between items-center'>
                                <p className=' text-gray-600'>{tax.name} ({tax.amount}%)</p>
                                <h1 className=' text-lg'>{money(countTax())}</h1>
                            </div>
                        }
                        <div className='flex justify-between items-center'>
                            <p className=' text-gray-600'>Total</p>
                            <h1 className=' text-2xl font-semibold text-red-600'>{money(countGrandTotal())}</h1>
                        </div>
                    </div>


                </div>
                <div className='flex items-center justify-center mt-2'>
                    <button
                        onClick={() => {
                            if (salesItems.length == 0) {
                                Swal.fire("Perhatian", `Belum ada item`, "warning")
                                return
                            }
                            setSidebarPaymentOpen(true)
                            setAmountPayment(countGrandTotal())
                        }}
                        className="h-12 flex-1 text-white flex justify-center items-center bg-orange-600 hover:bg-orange-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-base  px-5 py-2.5 text-center "
                    >
                        <FaRegMoneyBillAlt className='w-8 mr-2 ' /> Pembayaran
                    </button>
                    <button
                        onClick={() => {
                            saveSale({
                                code: randomStr(5).toUpperCase(),
                                items: salesItems,
                                date: moment().toISOString(),
                                cashier_id: profile!.uuid,
                                machine_id: selectedMachine!.uuid,
                                cashier_name: profile!.full_name,
                                customer_id: customer?.uuid ?? "",
                                customer_name: inputCustomerName,
                                notes: notes,
                                subtotal: countSubTotal(),
                                discount_total: countDiscount(),
                                total: countSubTotal() - countDiscount(),
                                tax_amount: countTax(),
                                total_tax: countTax(),
                                tax_name: tax?.name ?? "",
                                count_item: salesItems.length,
                                grand_total: countGrandTotal(),
                                change: amountPayment - countGrandTotal(),
                                payment_amount: amountPayment,
                                payment_method: paymentMethod,
                                payment_provider: paymentProvider,
                                account_destination_id: "",
                            }).then((val) => {
                                setSavedSales(val)
                                Swal.fire("Perhatian", `Transaksi Tersimpan`, "success")
                                clearForm()
                            })
                        }}
                        className="h-12 ml-2 text-white text-sm  flex flex-col justify-center items-center bg-green-600 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg px-2.5 py-4   text-center "
                    >
                        <Whisper followCursor placement='left' speaker={<Tooltip>Simpan Transaksi</Tooltip>}>
                            {/* <FolderIcon className='w-6 ' /> */}
                            <div className='flex items-center'>
                                <FaRegFloppyDisk className='w-8 ' /> Simpan
                            </div>
                        </Whisper>
                    </button>
                </div>

            </div>

        </div>
        <ModalSearch isOpen={modalSearchOpen} setIsOpen={setModalSearchOpen} onChange={(val) => setSearch(val)} >
            <ul role="list" className="divide-y divide-gray-100">
                {searchProducts.map(e =>

                    <li onClick={() => {
                        if (e.varians.length > 0) {
                            setSelectedProduct(e)
                            setModalVarianOpen(true)
                        } else {
                            addItem(e)
                        }
                        setSearchProducts([])
                        setModalSearchOpen(false)

                    }} key={e.uuid} className='flex items-center justify-between gap-x-4 px-3 py-5 hover:bg-gray-600 outline-none cursor-pointer text-gray-900 hover:text-white'>
                        <div className='flex'>
                            <div className='w-16 h-16 mr-2 '>
                                {e.default_picture ? <img src={e.default_picture} alt="" className='rounded-md h-full w-full object-cover bg-gray-600' />
                                    : <img src="/images/logo-niaga.png" alt="" className='rounded-md  w-full object-cover bg-gray-600' />}
                            </div>
                            <div>
                                <p className='font-semibold'>{e.name}</p>
                                <div className='flex text-xl font-semibold text-orange-600'>
                                    {money(e.prices[0].amount)}
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='flex items-center'>

                            </div>
                        </div>
                    </li>
                )}
            </ul>
        </ModalSearch>
        <ModalSearch footer={selectedProduct != null &&
            <div className="p-4 flex justify-between">
                <button
                    type="button"
                    className="inline-flex justify-center items-center rounded-md border border-transparent bg-orange-200 px-2 py-1 text-sm font-medium text-orange-900 hover:bg-orange-200"
                    onClick={() => {
                        if (selectedVarians.length == selectedProduct.varians.length) {
                            setSelectedVarians([])
                            return
                        }
                        setSelectedVarians([...selectedProduct.varians])
                    }}
                > <CheckCircleIcon
                        className=" h-3 w-3 mr-1"
                        aria-hidden="true" />  Pilih Semua </button>
                <button
                    type="button"
                    className="inline-flex justify-center items-center rounded-md border border-transparent bg-green-200 px-2 py-1 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                    onClick={() => {
                        for (const key in selectedVarians) {
                            if (Object.prototype.hasOwnProperty.call(selectedVarians, key)) {
                                const element = selectedVarians[key];
                                addItem(selectedProduct, element)
                            }
                        }
                        setSelectedProduct(null)
                        setModalVarianOpen(false)
                        setSelectedVarians([])
                    }}
                > <WalletIcon
                        className=" h-3 w-3 mr-1"
                        aria-hidden="true" />  Simpan </button>
            </div>
        } hideSearch={true} isOpen={modalVarianOpen} setIsOpen={setModalVarianOpen} onChange={function (arg: string): void {
        }} onClose={() => {
            setSelectedProduct(null)
            setSelectedVarians([])
        }} >
            {selectedProduct != null &&
                <ul role="list" className="divide-y divide-gray-100">
                    {selectedProduct!.varians.map(e => {
                        let stock = 0
                        let selectedStock = varianStocks.find(s => s.varian_id == e.uuid)
                        if (selectedStock) {
                            stock = selectedStock.amount
                        }
                        return <li key={e.uuid}
                            onClick={() => {
                                // addItem(selectedProduct, e)
                                if (selectedVarians.filter(v => v.uuid == e.uuid).length > 0) {
                                    setSelectedVarians([...selectedVarians.filter(v => v.uuid != e.uuid)]);
                                } else {
                                    setSelectedVarians([...selectedVarians, e]);
                                    if (merchant != null && merchant!.check_stock && stock == 0) {
                                        toaster.push(<Message showIcon type={"warning"} closable>
                                            Total Stok {stock} {selectedProduct.unit_name}
                                        </Message>, { placement: "bottomStart", duration: 3000 })
                                    }
                                }
                            }}
                            className='flex items-center justify-between gap-x-4 px-3 py-5 hover:bg-gray-600 outline-none cursor-pointer text-gray-900 hover:text-white'>
                            <div className='flex items-center mr-2'>
                                <Avatar marginRight={20} name={parseVarian(e.data)} url={""} />
                                <div className="min-w-0">
                                    <p className="text-sm font-semibold leading-6   ">{parseVarian(e.data, true)}</p>
                                    {stock > 0 &&
                                        <p className='text-xs'><strong>Total Stok:</strong> {money(stock)} {selectedProduct?.unit_name} </p>
                                    }
                                </div>
                            </div>
                            {selectedVarians.filter(v => v.uuid == e.uuid).length > 0 ? <CheckCircleIcon className='w-7 text-green-600' /> : null}
                        </li>;
                    })}
                </ul>
            }
        </ModalSearch>
        <ModalCustomer isOpen={modalCustomerOpen} setIsOpen={setModalCustomerOpen} onClick={(val) => {
            setInputCustomerName(val.name)
            setCustomer(val)
        }} />
        <Drawer size={"sm"} placement={"right"} open={drawerSaved} onClose={() => setDrawerSaved(false)}>
            <Drawer.Header>
                <Drawer.Title>Tersimpan</Drawer.Title>
                <Drawer.Actions>

                </Drawer.Actions>
            </Drawer.Header>
            <Drawer.Body className='p-6'>
                <ul>
                    {savedSales.map(e => <li className='flex  px-2  py-4 border-b last:border-b-0 hover:bg-gray-50 cursor-pointer' key={e.code}>
                        <div className='flex-1 flex justify-between' onClick={() => {
                            setSalesItems(e.items)
                            setEditedSale(e)
                            setDrawerSaved(false)
                        }}>
                            <AvatarGroup stack>
                                {e.items
                                    .filter((item, i) => i < 3)
                                    .map(item => (
                                        <Whisper followCursor speaker={<Tooltip>{`${item.product.name} ${item.varian ? parseVarian(item.varian.data, true) : ''}`}</Tooltip>}>
                                            <RsAvatar bordered circle key={item.product.name} src={item.product.default_picture ? item.product.default_picture : '/images/logo-niaga.png'} alt={item.product.name} />
                                        </Whisper>
                                    ))}
                                {e.items.length - 3 > 0 &&
                                    <RsAvatar bordered circle style={{ background: '#111' }}>
                                        +{e.items.length - 3}
                                    </RsAvatar>
                                }
                            </AvatarGroup>
                            <div className='flex flex-col  items-end'>
                                <h3 className=' text-lg text-orange-600 font-semibold'>{money(e.grand_total)}</h3>
                                <span>
                                    {e.items.length} items
                                </span>
                            </div>
                        </div>
                        <TrashIcon onClick={() => {
                            Swal.fire({
                                title: "Perhatian",
                                text: "Anda yakin akan menghapus transaksi ini ",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "Ya, Kirim!",
                                cancelButtonText: "Batal"
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    delSale(e).then(v => setSavedSales(v));
                                }
                            })

                        }} className='ml-2 w-5 text-red-500' />
                    </li>)}
                </ul>
            </Drawer.Body>
        </Drawer>

        <Drawer size={"md"} placement={"right"} open={sidebarPaymentOpen} onClose={() => setSidebarPaymentOpen(false)}>
            <Drawer.Header>
                <Drawer.Title>
                    <div className=' flex m-0 justify-between items-center'>
                        <p>
                            Proses Pembayaran
                        </p>
                        <p className='flex m-0 text-4xl font-semibold text-orange-600 cursor-pointer' onClick={() => {
                            setAmountPayment(countGrandTotal())
                            setMountPayment(false)
                            setTimeout(() => {
                                setMountPayment(true)
                            }, 10);
                        }}>{money(countGrandTotal())}</p>
                    </div>
                </Drawer.Title>
                <Drawer.Actions>
                    {/* <Button onClick={() => setSidebarPaymentOpen(false)}>Cancel</Button>
                    <Button onClick={() => setSidebarPaymentOpen(false)} appearance="primary">
                        Confirm
                    </Button> */}
                </Drawer.Actions>
            </Drawer.Header>
            <Drawer.Body className='p-6 flex flex-col '>
                <div className='flex-1'>
                    <h3 className='text-lg mb-2'>Konsumen</h3>
                    <div className='relative'>
                        <input placeholder='Nama Konsumen' value={inputCustomerName} onChange={(val) => {
                            setInputCustomerName(val.target.value)
                            if (val.target.value == "") {
                                setCustomer(null)
                            }
                        }} className='bg-white mb-4  text-lg w-full appearance-none border border-gray-200 rounded-xl  py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500' />
                        <div className="absolute right-2 top-2.5 cursor-pointer" >
                            <MagnifyingGlassIcon
                                className=" h-5 w-5 text-violet-200 hover:text-violet-100"
                                aria-hidden="true"
                                onClick={() => setModalCustomerOpen(true)}
                            />
                        </div>
                    </div>
                    <h3 className='text-lg m-0'>Pilih Cash Register</h3>
                    <div className='grid gap-x-4 gap-y-4 grid-cols-6 mb-4'>
                        {machines.map(e => <div onClick={() => {
                            setSelectedMachine(e)
                            setStorageMachine(e)
                        }} key={e.uuid} className={`rounded-md py-3 px-7 justify-center items-center flex text-sm text-center ${e.uuid == selectedMachine?.uuid ? 'bg-orange-400 hover:bg-orange-600 font-semibold text-white' : 'bg-gray-50'}  hover:bg-gray-200 cursor-pointer hover:shadow-xl`}>{e.name}</div>)}

                    </div>
                    <h3 className='text-lg m-0'>Pilih Pembayaran</h3>
                    <div className='grid gap-x-4 gap-y-4 grid-cols-6 mb-4'>
                        {paymentMethodsData.map(e => <div onClick={() => {
                            setPaymentMethod(e.value)
                            if (e.value == "CASH") {
                                setPaymentProviderGroup("")
                                setPaymentProvider("CASH")
                            }

                        }} key={e.value} className={`rounded-md py-3 px-7 justify-center items-center flex text-sm text-center ${e.value == paymentMethod ? 'bg-orange-400 hover:bg-orange-600 font-semibold text-white' : 'bg-gray-50'}  hover:bg-gray-200 cursor-pointer hover:shadow-xl`}>{e.name}</div>)}
                    </div>
                    {paymentMethod != "CASH" &&
                        <div className='grid gap-x-4 gap-y-4 grid-cols-6 mb-4'>
                            {paymentProvidersData.map(e => <div onClick={() => {
                                setPaymentProviderGroup(e.name)
                                setPaymentProvider("")
                            }} key={e.name} className={`rounded-md py-3 px-7 justify-center items-center flex text-sm text-center ${e.name == paymentProviderGroup ? 'bg-orange-400 hover:bg-orange-600 font-semibold text-white' : 'bg-gray-50'}  hover:bg-gray-200 cursor-pointer hover:shadow-xl`}>{e.name}</div>)}
                        </div>
                    }
                    {paymentMethod != "CASH" && paymentProviderGroup != "" &&
                        <div className='grid gap-x-4 gap-y-4 grid-cols-6 mb-4'>
                            {paymentProvidersData.find(e => e.name == paymentProviderGroup)!.children.map(e => <div onClick={() => {
                                setPaymentProvider(e.value)
                            }} key={e.name} className={`rounded-md py-3 px-7 justify-center items-center flex text-sm text-center ${e.value == paymentProvider ? 'bg-orange-400 hover:bg-orange-600 font-semibold text-white' : 'bg-gray-50'}  hover:bg-gray-200 cursor-pointer hover:shadow-xl`}>{e.name}</div>)}
                        </div>
                    }
                    {paymentMethod == "CASH" && <h3 className='text-lg m-0 text-right'>Jumlah</h3>}
                    {paymentMethod == "CASH" && mountPayment &&
                        <CurrencyInput
                            className="bg-white mb-4  text-4xl text-right w-full appearance-none border border-gray-200 rounded-xl  py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                            groupSeparator="."
                            decimalSeparator=","
                            placeholder="Jumlah"
                            defaultValue={Math.round(countGrandTotal())}
                            decimalsLimit={0}
                            onValueChange={(value, _, values) => {
                                setAmountPayment(values!.float!)
                            }}
                        />
                    }
                    {paymentMethod == "CASH" && (amountPayment - countGrandTotal() != 0) && <h3 className='text-lg m-0  text-right'>Kembalian</h3>}
                    {paymentMethod == "CASH" && (amountPayment - countGrandTotal() != 0) && <h3 className='text-2xl m-0 text-orange-600 text-right'>{money(amountPayment - countGrandTotal())}</h3>}
                    <h3 className='text-lg m-0 text-right'>Catatan</h3>
                    <textarea placeholder='Catatan' value={notes} rows={3} onChange={(val) => setNotes(val.target.value)} className='bg-white mb-4  text-xl text-right w-full appearance-none border border-gray-200 rounded-xl  py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500' />
                </div>
                <div className='flex justify-end'>
                    <button
                        onClick={sendSale}
                        className=" flex-1 text-white flex justify-center items-center bg-red-600 hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-base  px-5 py-2.5 text-center "
                    >
                        <FaRegMoneyBillAlt className='w-8 mr-2 ' /> Proses Pembayaran
                    </button>
                </div>
            </Drawer.Body>
        </Drawer>
        <ModalCustom isOpen={modalBarcodeOpen} setIsOpen={setModalBarcodeOpen} styles={{ padding: 0, height: 60 }}   >
            <div className=" w-full ">
                <div className="relative">
                    <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                        <QrCodeIcon
                            className=" h-5 w-5 text-violet-200 hover:text-violet-100"
                            aria-hidden="true"
                        />
                    </div>
                    <input

                        type="search"
                        id="default-search"
                        className="block outline-none w-full p-4 ps-10 text-lg text-gray-900 border-0 rounded-full bg-transparent   "
                        placeholder={'Input Barcode ...'}
                        onChange={(val) => {
                            if (val) {
                                getPosProductByBarcode(val.target.value)
                                    .then(v => v.json())
                                    .then(v => {
                                        let products: Product[] = v.data
                                        if (products.length == 1) {
                                            if (products[0].varians.length > 0) {
                                                setSelectedProduct(products[0])
                                                setModalVarianOpen(true)
                                            } else {
                                                addItem(products[0])
                                            }
                                        }
                                    })
                            }
                        }}
                    />
                </div>
            </div>
        </ModalCustom>

        <ModalReceipt isOpen={modalReceiptOpen} setIsOpen={setModalReceiptOpen} onClose={() => setModalReceiptOpen(false)} sale={selectedReceipt} merchant={merchant!} />

    </DashboardLayout>);
}
export default CashierPage;