
import { BillReq } from "../model/bill";
import { ItemReq } from "../model/item";
import { PaginationReq } from "../model/pagination";
import { PaymentReq } from "../model/transaction";
import { customFetch } from "../utils/helper";
import moment from "moment";
import { DateRange } from "rsuite/esm/DateRangePicker";


export let getBills = async (pagination: PaginationReq, dateRange?: DateRange | null, vendorIds? : string | null) => {
    var params: Record<string, string> = {
        page: pagination.page.toString(),
        limit: pagination.limit.toString(),

    };
    if (pagination.search && pagination.search != "") {
        params["search"] = pagination.search
    }

    if (pagination.merchant_id) {
        params["merchant_id"] = pagination.merchant_id
    }

    if (dateRange) {
        params["start_date"] = moment(dateRange[0]).format("YYYY-MM-DD")
        params["end_date"] = moment(dateRange[1]).format("YYYY-MM-DD")
    }
    if (vendorIds) {
        params["vendor_ids"] = vendorIds
    }

    return await customFetch(`Trading/Bill/?${new URLSearchParams(params)}`)
}
export let getBillDetail = async (uuid: string) => {
    return await customFetch(`Trading/Bill/${uuid}`)
}
export let addItemBill = async (uuid: string, req: ItemReq) => {
    return await customFetch(`Trading/Bill/${uuid}/AddItem`, {
        method: "POST",
        body: JSON.stringify(req)
    })
}
export let updateItemBill = async (uuid: string, itemId: string, req: ItemReq) => {

    return await customFetch(`Trading/Bill/${uuid}/UpdateItem/${itemId}`, {
        method: "PUT",
        body: JSON.stringify(req)
    })
}
export let deleteItemBill = async (uuid: string, itemId: string,) => {
    return await customFetch(`Trading/Bill/${uuid}/DeleteItem/${itemId}`, {
        method: "DELETE",
    })
}

export let createBill = async (req: BillReq) => {

    return await customFetch(`Trading/Bill/`, {
        method: "POST",
        body: JSON.stringify(req)
    })
}

export let updateBill = async (uuid: string, req: BillReq) => {
    return await customFetch(`Trading/Bill/${uuid}`, {
        method: "PUT",
        body: JSON.stringify(req)
    })
}

export let addBillPayment = async (uuid: string, req: PaymentReq) => {
    return await customFetch(`Trading/Bill/${uuid}/AddPayment`, {
        method: "POST",
        body: JSON.stringify(req)
    })
}

export let downloadBill = async (uuid: string, {
    hide_tax,
    hide_secondary_tax,
    hide_total_before_tax,
}: {
    hide_tax: string
    hide_secondary_tax: string
    hide_total_before_tax: string
}) => {
    return await customFetch(`Trading/Bill/${uuid}/Download?${new URLSearchParams({ hide_tax, hide_secondary_tax, hide_total_before_tax })}`)
}
export let releaseBill = async (uuid: string) => {
    return await customFetch(`Trading/Bill/${uuid}/Release`, {
        method: "PUT",
    })
}
export let unreleaseBill = async (uuid: string, password: string) => {
    return await customFetch(`Trading/Bill/${uuid}/Unrelease`, {
        method: "PUT",
        body: JSON.stringify({ password })
    })
}
export let getReceipt = async (uuid: string, transactionId: string) => {
    return await customFetch(`Trading/Bill/${uuid}/Payment/${transactionId}/Receipt`)
}

export let deleteBill = async (uuid: string) => {

    return await customFetch(`Trading/Bill/${uuid}`, {
        method: "DELETE",
    })
}

export let duplicateBill = async (uuid: string) => {
    return await customFetch(`Trading/Bill/${uuid}/Duplicate`, {
        method: "POST",
    })
}