import { Customer } from '../../model/customer';
import { Vendor } from '../../model/vendor';
import { Pagination } from '../../model/pagination';
import { LoadingContext } from '../../objects/loading_context';
import { deleteCustomer, getCustomers } from '../../repositories/customer';
import { useState, type FC, useEffect, useContext } from 'react';
import { Panel } from 'rsuite';
import Swal from 'sweetalert2';
import { deleteVendor, getVendors } from '../../repositories/vendor';
import CustomTable, { TableRecord } from '../custom_table';
import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import ModalCustom from '../modals/modal_custom';
import FormContact from '../modals/form_contact';
import { Employee } from '../../model/employee';
import { deleteEmployee, getEmployees } from '../../repositories/employee';

interface ContactProps {

}

const ContactSetting: FC<ContactProps> = ({

}) => {
  let { isLoading, setIsLoading } = useContext(LoadingContext);

  const [mounted, setMounted] = useState(false);
  const [pageCustomer, setPageCustomer] = useState(1);
  const [limitCustomer, setLimitCustomer] = useState(20);
  const [searchCustomer, setSearchCustomer] = useState("");
  const [paginationCustomer, setPaginationCustomer] = useState<Pagination | null>(null);
  const [customers, setCustomers] = useState<Customer[]>([]);

  const [pageVendor, setPageVendor] = useState(1);
  const [limitVendor, setLimitVendor] = useState(20);
  const [searchVendor, setSearchVendor] = useState("");
  const [paginationVendor, setPaginationVendor] = useState<Pagination | null>(null);
  const [vendors, setVendors] = useState<Vendor[]>([]);
  const [modalCustomerOpen, setModalCustomerOpen] = useState(false);
  const [modalVendorOpen, setModalVendorOpen] = useState(false);
  const [contactType, setContactType] = useState("");
  const [selectedContact, setSelectedContact] = useState<Customer | Vendor | Employee | null>(null);


  const [pageEmployee, setPageEmployee] = useState(1);
  const [limitEmployee, setLimitEmployee] = useState(20);
  const [searchEmployee, setSearchEmployee] = useState("");
  const [paginationEmployee, setPaginationEmployee] = useState<Pagination | null>(null);
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [modalEmployeeOpen, setModalEmployeeOpen] = useState(false);


  useEffect(() => {
    setMounted(true)

  }, []);

  useEffect(() => {
    if (!mounted) return
    getAllCustomers()
    getAllVendors()
    getAllEmployees()

  }, [mounted]);

  const getAllEmployees = async () => {
    try {
      setIsLoading(true)
      var resp = await getEmployees({ page: pageEmployee, limit: limitEmployee, search: searchEmployee })
      var respJson = await resp.json()
      setEmployees(respJson.data)
      setPaginationEmployee(respJson.meta)
    } catch (error) {
      Swal.fire("Perhatian", `${error}`, "error")
    } finally {
      setIsLoading(false)
    }
  }
  const getAllCustomers = async () => {
    try {
      setIsLoading(true)
      var resp = await getCustomers({ page: pageCustomer, limit: limitCustomer, search: searchCustomer })
      var respJson = await resp.json()
      setCustomers(respJson.data)
      setPaginationCustomer(respJson.meta)
    } catch (error) {
      Swal.fire("Perhatian", `${error}`, "error")
    } finally {
      setIsLoading(false)
    }
  }
  const getAllVendors = async () => {
    try {
      setIsLoading(true)
      var resp = await getVendors({ page: pageVendor, limit: limitVendor, search: searchVendor })
      var respJson = await resp.json()
      setVendors(respJson.data)
      setPaginationVendor(respJson.meta)
    } catch (error) {
      Swal.fire("Perhatian", `${error}`, "error")
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (contactType != "") {
      
      if (contactType == "customer") {
        setModalCustomerOpen(true)

      }
      if (contactType == "vendor") {

        setModalVendorOpen(true)
      }
      if (contactType == "employee") {
        setModalEmployeeOpen(true)
      }

    }
  }, [contactType]);

  useEffect(() => {
    getAllCustomers()
  }, [pageCustomer, limitCustomer, searchCustomer]);

  useEffect(() => {
    getAllVendors()
  }, [pageVendor, limitVendor, searchVendor]);
  
  useEffect(() => {
    getAllEmployees()
  }, [pageEmployee, limitEmployee, searchEmployee]);



  return (<div className="flex-1 mb-5 col-span-1 mt-5 rounded-xl py-6 px-6 transition-all hover:shadow-lg sm:p-4 lg:px-4 xl:px-6 bg-white cursor-pointer">
    <div className='grid gap-x-8 gap-y-4 sm:gap-y-0 xl:grid-cols-4 lg:grid-cols-4 sm:grid-cols-2 md:grid-cols-2'>
      <div className='col-span-2 mb-8'>
        <Panel className=' min-h-[300px]:' header="Konsumen" bordered>
          <CustomTable
            searchPlaceholder='Cari Kontak'
            onSearch={(val) => {
              setPageCustomer(1)
              setSearchCustomer(val)
            }}
            pagination
            total={paginationCustomer?.total_records}
            limit={limitCustomer}
            activePage={pageCustomer}
            setActivePage={(val) => {
              setPageCustomer(val)
            }}
            searchHeader={
              <button
                type="button"
                className="inline-flex justify-center items-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-xs font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                onClick={() => {
                  setContactType("customer")
                }}
              >
                <PlusIcon
                  className=" h-3 w-3 mr-1"
                  aria-hidden="true" />  Tambah Konsumen
              </button>
            }
            headers={["#", "Nama", "Alamat", ""]} headerClasses={["w-10", "", "", "w-10"]} datasets={customers.map(e => {
              let data: TableRecord = {
                cells: [
                  { data: ((pageCustomer - 1) * limitCustomer) + (customers.indexOf(e) + 1) },
                  {
                    data: <div className=' hover:font-medium ' onClick={() => {
                      setContactType("customer")
                      setSelectedContact(e)
                      setModalCustomerOpen(true)
                    }}>
                      {e.name}
                    </div>,
                  },
                  { data: e.address, },
                  {
                    data: <TrashIcon className="w-5 text-red-600" onClick={() => {
                      Swal.fire({
                        title: "Anda Yakin",
                        text: "Anda tidak akan dapat mengembalikan proses ini!",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Ya, Hapus!",
                        cancelButtonText: "Batal"
                      }).then((result) => {
                        if (result.isConfirmed) {
                          setIsLoading(true)
                          deleteCustomer(e.uuid)
                            .then(v => getAllCustomers())
                            .catch(error => Swal.fire("Perhatian", `${error}`, "error"))
                            .finally(() => setIsLoading(false))
                        }
                      });
                    }} />, className: 'w-4 text-right'
                  },
                ],
                className: "last:border-0 hover:bg-gray-50"
              }
              return data
            })} />
        </Panel>
      </div>
      <div className='col-span-2 mb-8'>
        <Panel className=' min-h-[300px]:' header="Vendor / Supplier" bordered>
          <CustomTable
            searchPlaceholder='Cari Kontak'
            onSearch={(val) => {
              setPageVendor(1)
              setSearchVendor(val)
            }}
            pagination
            total={paginationVendor?.total_records}
            limit={limitVendor}
            activePage={pageVendor}
            setActivePage={(val) => {
              setPageVendor(val)
            }}
            searchHeader={
              <button
                type="button"
                className="inline-flex justify-center items-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-xs font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                onClick={() => {
                  setContactType("vendor")
                }}
              >
                <PlusIcon
                  className=" h-3 w-3 mr-1"
                  aria-hidden="true" />  Tambah Vendor
              </button>
            }
            headers={["#", "Nama", "Alamat", ""]} headerClasses={["w-10", "", "", "w-10"]} datasets={vendors.map(e => {
              let data: TableRecord = {
                cells: [
                  { data: ((pageVendor - 1) * limitVendor) + (vendors.indexOf(e) + 1) },
                  {
                    data: <div className=' hover:font-medium ' onClick={() => {
                      setContactType("vendor")
                      setSelectedContact(e)
                      setModalVendorOpen(true)
                    }}>
                      {e.name}
                    </div>,
                  },
                  { data: e.address, },
                  {
                    data: <TrashIcon className="w-5 text-red-600" onClick={() => {
                      Swal.fire({
                        title: "Anda Yakin",
                        text: "Anda tidak akan dapat mengembalikan proses ini!",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Ya, Hapus!",
                        cancelButtonText: "Batal"
                      }).then((result) => {
                        if (result.isConfirmed) {
                          setIsLoading(true)
                          deleteVendor(e.uuid)
                            .then(v => getAllVendors())
                            .catch(error => Swal.fire("Perhatian", `${error}`, "error"))
                            .finally(() => setIsLoading(false))
                        }
                      });
                    }} />, className: 'w-4 text-right'
                  },
                ],
                className: "last:border-0 hover:bg-gray-50"
              }
              return data
            })} />
        </Panel>
      </div>
      <div className='col-span-2 mb-8'>
        <Panel className=' min-h-[300px]:' header="Karyawan " bordered>
          <CustomTable
            searchPlaceholder='Cari Kontak'
            onSearch={(val) => {
              setPageEmployee(1)
              setSearchEmployee(val)
            }}
            pagination
            total={paginationEmployee?.total_records}
            limit={limitEmployee}
            activePage={pageEmployee}
            setActivePage={(val) => {
              setPageEmployee(val)
            }}
            searchHeader={
              <button
                type="button"
                className="inline-flex justify-center items-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-xs font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                onClick={() => {
                  setContactType("employee")
                }}
              >
                <PlusIcon
                  className=" h-3 w-3 mr-1"
                  aria-hidden="true" />  Tambah Karyawan
              </button>
            }
            headers={["#", "Nama", "Alamat", ""]} headerClasses={["w-10", "", "", "w-10"]} datasets={employees.map(e => {
              let data: TableRecord = {
                cells: [
                  { data: ((pageEmployee - 1) * limitEmployee) + (employees.indexOf(e) + 1) },
                  {
                    data: <div className=' hover:font-medium ' onClick={() => {
                      setContactType("employee")
                      setSelectedContact(e)
                      setModalEmployeeOpen(true)
                    }}>
                      {e.full_name}
                    </div>,
                  },
                  { data: e.address, },
                  {
                    data: <TrashIcon className="w-5 text-red-600" onClick={() => {
                      Swal.fire({
                        title: "Anda Yakin",
                        text: "Anda tidak akan dapat mengembalikan proses ini!",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Ya, Hapus!",
                        cancelButtonText: "Batal"
                      }).then((result) => {
                        if (result.isConfirmed) {
                          setIsLoading(true)
                          deleteEmployee(e.uuid)
                            .then(v => getAllEmployees())
                            .catch(error => Swal.fire("Perhatian", `${error}`, "error"))
                            .finally(() => setIsLoading(false))
                        }
                      });
                    }} />, className: 'w-4 text-right'
                  },
                ],
                className: "last:border-0 hover:bg-gray-50"
              }
              return data
            })} />
        </Panel>
      </div>
    </div>
    <FormContact
      data={selectedContact}
      contactType={contactType}
      isOpen={contactType == "customer" ? modalCustomerOpen : contactType == "vendor" ? modalVendorOpen : modalEmployeeOpen}
      setIsOpen={contactType == "customer" ? setModalCustomerOpen : contactType == "vendor" ? setModalVendorOpen : setModalEmployeeOpen}
      onClose={() => {
        setContactType("")
        setModalCustomerOpen(false)
        setModalVendorOpen(false)
        setModalEmployeeOpen(false)
        if (contactType == "customer") {
          getAllCustomers()
        }
        if (contactType == "vendor") {
          getAllVendors()
        }
        if (contactType == "employee") {
          getAllEmployees()
        }
        setSelectedContact(null)
      }} />
  </div>);
}
export default ContactSetting;