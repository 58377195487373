import { useState, type FC, useEffect } from 'react';
import ModalSearch from './modal_search';
import { getTaxes } from '../../repositories/tax';
import { Tax } from '../../model/tax';
import { COLORS, TAX_METHODS } from '../../objects/constants';
import Avatar from '../avatar';
import { money } from '../../utils/number';
import { ChevronLeftIcon } from '@heroicons/react/16/solid';

interface ModalTaxProps {
    isOpen: boolean
    setIsOpen: (arg: boolean) => void
    onClose?: () => void
    onClick?: (a: Tax, b: string) => void
    onChange: (arg: string) => void
    taxType?: string
}

const ModalTax: FC<ModalTaxProps> = ({
    isOpen,
    setIsOpen,
    onClose,
    onChange,
    onClick,
    taxType,
}) => {
    const [mounted, setMounted] = useState(false);
    const [search, setSearch] = useState("");
    const [taxes, setTaxes] = useState<Tax[]>([]);
    const [selectedTax, setSelectedTax] = useState<Tax | null>(null);
    useEffect(() => {
        setMounted(true)
    }, []);

    useEffect(() => {
        getAllTaxes()
    }, []);

    useEffect(() => {
        getAllTaxes()
    }, [search]);

    const getAllTaxes = () => {
        getTaxes({ page: 1, limit: 10, search, type: taxType })
            .then(v => v.json())
            .then(v => {
                setTaxes(v.data)
            })
    }
    return (<ModalSearch isOpen={isOpen} setIsOpen={setIsOpen} onChange={(val) => {
        setSearch(val)
    }} onClose={() => {
        setSelectedTax(null)
        if (onClose) onClose()
    }} footer={selectedTax != null && <div className='p-4'>
         <button
                type="button"
                className="inline-flex justify-center items-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-xs font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                onClick={() => {
                    setSelectedTax(null)
                }}
            > <ChevronLeftIcon
                    className=" h-3 w-3 mr-1"
                    aria-hidden="true" />  Kembali </button>
    </div>}>
        {selectedTax == null ?
            <ul>
                {taxes.map(v => <li onClick={() => {
                    setSelectedTax(v)
                }} key={v.uuid} className="flex items-center gap-x-4 px-3 py-5 hover:bg-gray-600 outline-none cursor-pointer text-gray-900 hover:text-white"
                >
                    <Avatar marginRight={0} name={v.name} url={''} style={{ backgroundColor: COLORS[Math.floor(Math.random() * COLORS.length)].color }} />
                    <div className="min-w-0">
                        <p className="text-sm font-semibold leading-6   ">{v.name}</p>
                        <p className="-mt-0.5 truncate text-xs leading-5  " >{money(v.amount)}%</p>
                    </div>
                </li>)}
            </ul> : <ul>
                {TAX_METHODS.map(v => <li onClick={() => {
                    if (onClick) onClick(selectedTax!, v.method)
                }} key={v.method} className="flex items-center gap-x-4 px-3 py-5 hover:bg-gray-600 outline-none cursor-pointer text-gray-900 hover:text-white"
                >

                    <Avatar marginRight={0} name={v.method} url={''} style={{ backgroundColor: COLORS[Math.floor(Math.random() * COLORS.length)].color }} />
                    <div className="min-w-0">
                        <p className="text-sm font-semibold leading-6   ">{v.description}</p>
                    </div>
                </li>)}
            </ul>
        }
    </ModalSearch>);
}
export default ModalTax;

