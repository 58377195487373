import { useNavigate } from 'react-router-dom';
import { Item } from '../model/item';
import { Product } from '../model/product';
import { getProductDetail } from '../repositories/product';
import { randomStr } from '../utils/helper';
import { money } from '../utils/number';
import { ChevronDoubleDownIcon, ChevronDownIcon, PencilSquareIcon, ReceiptPercentIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useState, type FC, useEffect, ReactNode } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { Checkbox, Dropdown } from 'rsuite';
import Swal from 'sweetalert2';


interface RecordItemProps {
    parentId?: string
    item: Item
    no: number
    locked: boolean
    checked: boolean
    permissions: string[]
    onDescFocus?: () => void
    onClickDesc?: () => void
    onChange?: (arg: Item) => void
    onDelete?: () => void
    onChecked?: (arg: boolean) => void
    userPackagingStock: boolean
    addDiscount?: () => void
    addNotes?: () => void
}

const RecordItem: FC<RecordItemProps> = ({
    parentId,
    item,
    no,
    locked,
    checked,
    onDescFocus,
    onClickDesc,
    onChange,
    onDelete,
    permissions,
    onChecked,
    userPackagingStock,
    addDiscount,
    addNotes
}) => {
    const [mounted, setMounted] = useState(false);
    const [product, setProduct] = useState<Product | null>(null);
    const [inputDesc, setInputDesc] = useState("");
    const [inputQty, setInputQty] = useState(0);
    const [inputQtyPackaging, setInputQtyPackaging] = useState(0);
    const [inputUnitId, setInputUnitId] = useState("");
    const [inputPrice, setInputPrice] = useState(0);
    const [mountedPrice, setMountedPrice] = useState(false);
    const nav = useNavigate()
    useEffect(() => {
        setMounted(true)
        setMountedPrice(true)
    }, []);

    useEffect(() => {
        if (!mounted) return
        console.log(item)
    }, []);

    useEffect(() => {
        setMountedPrice(true)
    }, [inputPrice]);

    useEffect(() => {
        setInputDesc(item.description)
        setInputQty(item.qty)
        setInputUnitId(item.unit_id)
        setInputPrice(item.price)
        setInputQtyPackaging(item.qty_packaging)
        if (item.product_id != "") {
            getProductDetail(item.product_id)
                .then(v => v.json())
                .then(v => setProduct(v.data))
        }
        setMountedPrice(false)
        setTimeout(() => {
            setMountedPrice(true)
        }, 300);
    }, [item]);


    const renderDesc = () => {
        let desc: ReactNode = ""
        if (locked) {
            desc = <span onClick={() => {
                if (item.product_id) {
                    nav(`/product/${item.product_id}`)
                }
            }} className='hover:font-medium'>{item.description}</span>
        } else if (item.product_id != '') {
            desc = <div className=' hover:font-medium' onClick={() => {
                if (onClickDesc != null) {
                    onClickDesc()
                }
                nav(`/product/${item.product_id}`)
            }}>
                {item.description}
            </div>
        } else {
            desc = (<div>
                <input id={`desc-${item.uuid}`} className='block w-full' onBlur={() => {
                    if (onChange != null) onChange({ ...item, description: inputDesc })
                }} onKeyUp={(e) => {
                    if (e.key === 'Enter') {
                        document.getElementById(`desc-${item.uuid}`)!.blur();
                    }
                }} onFocus={onDescFocus} value={inputDesc} onChange={(val) => setInputDesc(val.target.value)} />
            </div>)
        }

        return <div>{desc}
            {addNotes &&
                <div>
                    {item?.notes ?
                        <div className='flex'>
                            <small className='text-xs' dangerouslySetInnerHTML={{ __html: item?.notes }}></small>
                            <PencilSquareIcon onClick={() => {
                                addNotes()
                            }} className='w-3 ml-1 text-gray-400 ' />
                        </div>
                        : <div className='flex'><div className='text-gray-300 hover:text-gray-400'>add note</div> <PencilSquareIcon onClick={() => {
                            addNotes()
                        }} className='w-3 ml-1 text-gray-400 ' /></div>}
                </div>
            }
        </div>
    }
    const renderQty = () => {
        if (locked) return money(item.qty)
        return mountedPrice && (<div>
            <CurrencyInput
                className=" max-w-24 text-right"
                id={`qty-${item.uuid}`}
                groupSeparator="."
                decimalSeparator=","
                placeholder="Qty"
                defaultValue={inputQty}
                onValueChange={(value, _, values) => setInputQty(values?.float ?? 0)}
                onKeyUp={(e) => {
                    if (e.key === 'Enter') {
                        document.getElementById(`qty-${item.uuid}`)!.blur();
                    }
                }}
                onBlur={() => {
                    if (onChange != null) onChange({ ...item, qty: inputQty })
                }}
            />

        </div>)
    }

    const renderUnit = () => (
        product == null ? null :
            <select value={inputUnitId} onChange={(val) => {
                setInputUnitId(val.target.value)
                var selectedUnit = product?.units.find(e => e.uuid == val.target.value)
                if (onChange != null && selectedUnit) onChange({ ...item, unit_id: selectedUnit?.uuid ?? "", unit_name: selectedUnit?.name ?? "", unit_value: selectedUnit?.value ?? 0 })
            }}>
                <option value="" >Pilih Unit</option>
                {(product?.units ?? []).map(e => <option key={e.uuid} onClick={() => {
                    console.log(e)
                }} value={e.uuid}>{e.name}</option>)}
            </select>
    )

    const renderPrice = () => {
        if (!mountedPrice) return <div></div>
        if (locked) return money(item.price)
        if (permissions.includes("custom_invoice_price")) {
            return <div className='flex items-center justify-end'>
                <CurrencyInput
                    className=" max-w-20 text-right"
                    id={`price-${item.uuid}`}
                    groupSeparator="."
                    decimalSeparator=","
                    placeholder="Price"
                    defaultValue={inputPrice}
                    onValueChange={(value, _, values) => setInputPrice(values?.float ?? 0)}
                    onKeyUp={(e) => {
                        if (e.key === 'Enter') {
                            document.getElementById(`price-${item.uuid}`)!.blur();
                        }
                    }}
                    onBlur={() => {
                        if (onChange != null) onChange({ ...item, price: inputPrice })
                    }}
                />
                {renderPriceSelect()}
            </div>
        }
        return <div className='flex items-center'>
            {money(item.price)}
            {renderPriceSelect()}
        </div>
    }

    const renderPriceSelect = () => (product?.prices ?? []).length == 0 ? null : (

        <Dropdown placement='bottomEnd' renderToggle={(props, ref) => <ChevronDownIcon {...props} ref={ref} className='ml-1 w-3 cursor-pointer' />}>
            {(product?.prices ?? []).map(e => <Dropdown.Item key={e.price_category_id} onSelect={(val) => {
                setMountedPrice(false)
                setInputPrice(e.amount)
                setTimeout(() => {
                    setMountedPrice(true)
                }, 300);
                if (onChange != null) onChange({ ...item, price: e.amount })
            }} >{e.category_name} {money(e.amount)}</Dropdown.Item>)}
        </Dropdown>
    )
    useEffect(() => {
        if (!product) return
    }, [product]);

    const renderQtyPackaging = () => {
        return (<div>
            <CurrencyInput
                className=" max-w-10 text-right"
                id={`packaging-${item.uuid}`}
                groupSeparator="."
                decimalSeparator=","
                placeholder="Qty Packaging"
                defaultValue={inputQtyPackaging}
                onValueChange={(value, _, values) => setInputQtyPackaging(values?.float ?? 0)}
                onKeyUp={(e) => {
                    if (e.key === 'Enter') {
                        document.getElementById(`packaging-${item.uuid}`)!.blur();
                    }
                }}
                onBlur={() => {
                    if (onChange != null) onChange({ ...item, qty_packaging: inputQtyPackaging })
                }}
            />

        </div>)
    }

    return (<tr className=' hover:bg-gray-50' >
        <td className="px-6 py-4 flex items-center">
            {!locked && <Checkbox defaultChecked={checked} onChange={(value, checked) => {
                if (onChecked != null) {
                    onChecked(checked)
                }
            }} />}  {money(no)}
        </td>
        <td className="px-6 py-4">
            {renderDesc()}
        </td>
        <td className="px-6 py-4 text-right">
            {renderQty()}
        </td>
        <td className="px-6 py-4">
            {!locked ? renderUnit() : item.unit_name}
        </td>
        {userPackagingStock &&
            <td className="px-6 py-4 text-right">
                {product && <div className='flex justify-end'>{!locked ? renderQtyPackaging() : money(item.qty_packaging)} <span className='ml-2'>{product.packaging_name}</span> </div>}
            </td>
        }

        <td className="px-6 py-4 text-right">
            {renderPrice()}
        </td>

        <td className="px-6 py-4 text-right">
            {money(item.amount)}
        </td>
        <td className="px-6 py-4 text-right ">
            <div className='flex items-center justify-center'>
                {!locked && addDiscount ? <ReceiptPercentIcon
                    className=" h-5 w-5 text-blue-400 hover:text-blue-600"
                    aria-hidden="true"
                    onClick={() => {
                        if (addDiscount) addDiscount()
                    }}
                /> : null}
                {!locked ? <TrashIcon
                    className=" h-5 w-5 text-red-400 hover:text-red-600"
                    aria-hidden="true"
                    onClick={() => {
                        if (onDelete == null) return
                        Swal.fire({
                            title: "Anda Yakin",
                            text: "Anda tidak akan dapat mengembalikan proses ini!",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Ya, Hapus!",
                            cancelButtonText: "Batal"
                        }).then((result) => {

                            if (result.isConfirmed) {
                                onDelete()
                            }
                        });
                    }}
                /> : null}
            </div>
        </td>
    </tr>);
}
export default RecordItem;