import { Company, JarayaTradingSetting, ProductAddOnSetting } from '../../model/company';
import { Pagination } from '../../model/pagination';
import { Packaging, PriceCategory, ProductCategory, Unit } from '../../model/product';
import { LoadingContext } from '../../objects/loading_context';
import { getCompanyDetail, updateProductAddOnSetting } from '../../repositories/company';
import { addPackaging, addPriceCategory, addProductCategory, addUnit, deletePackaging, deletePriceCategory, deleteProductCategory, deleteUnit, getPackagings, getPriceCategories, getProductCategories, getUnits } from '../../repositories/product';
import { FolderIcon, PencilIcon, PencilSquareIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useContext, useEffect, useState, type FC } from 'react';
import { Button, Modal, Panel, Tag, TagGroup, TagPicker } from 'rsuite';
import Swal from 'sweetalert2';
import CustomTable, { TableRecord } from '../custom_table';
import { Tax } from '../../model/tax';
import { deleteTax, getTaxes } from '../../repositories/tax';
import FormTax from '../modals/form_tax';
import InlineForm from '../inline_form';
import { AUTO_NUMERIC_FORMAL, TAX_METHODS } from '../../objects/constants';
import { Account } from '../../model/account';
import { getAccounts } from '../../repositories/account';
import { Switch } from "@headlessui/react";
import ModalEditor from '../modals/modal_editor';
import { Editor } from 'tinymce';
import { Discount } from '../../model/discount';
import { addDiscont, deleteDiscount, getDiscounts } from '../../repositories/discount';
import Moment from 'react-moment';
import { money } from '../../utils/number';
import ModalCustom from '../modals/modal_custom';
import DateRangePicker, { DateRange } from 'rsuite/esm/DateRangePicker';


interface BusinessProps {

}

const BusinessSetting: FC<BusinessProps> = ({

}) => {
    const [mounted, setMounted] = useState(false);
    const [company, setCompany] = useState<Company | null>(null);
    const [settingLoaded, setSettingLoaded] = useState(false);
    let { isLoading, setIsLoading } = useContext(LoadingContext);

    const [pageProductCat, setPageProductCat] = useState(1)
    const [limitProductCat, setLimitProductCat] = useState(5)
    const [searchProductCat, setSearchProductCat] = useState("")
    const [productCategories, setProductCategories] = useState<ProductCategory[]>([]);
    const [paginationProductCat, setPaginationProductCat] = useState<Pagination | null>(null);
    const [productCatOpen, setProductCatOpen] = useState(false);

    const [priceCatOpen, setPriceCatOpen] = useState(false);
    const [pagePriceCat, setPagePriceCat] = useState(1)
    const [limitPriceCat, setLimitPriceCat] = useState(5)
    const [searchPriceCat, setSearchPriceCat] = useState("")
    const [priceCategories, setPriceCategories] = useState<PriceCategory[]>([]);
    const [paginationPriceCat, setPaginationPriceCat] = useState<Pagination | null>(null);



    const [taxOpen, setTaxOpen] = useState(false);
    const [pageTax, setPageTax] = useState(1)
    const [limitTax, setLimitTax] = useState(5)
    const [searchTax, setSearchTax] = useState("")
    const [taxes, setTaxes] = useState<Tax[]>([]);
    const [paginationTax, setPaginationTax] = useState<Pagination | null>(null);
    const [jarayaTradingSetting, setJarayaTradingSetting] = useState<JarayaTradingSetting | null>(null);


    const [categoryName, setCategoryName] = useState("");

    const [incomeAccounts, setIncomeAccounts] = useState<Account[]>([]);
    const [invoiceReceivableAccounts, setInvoiceReceivableAccounts] = useState<Account[]>([]);
    const [expenseAccounts, setExpenseAccounts] = useState<Account[]>([]);
    const [billPayableAccounts, setBillPayableAccounts] = useState<Account[]>([]);

    const [billInvoiceTaxes, setBillInvoiceTaxes] = useState<Tax[]>([]);
    const [modalInvoiceNoteOpen, setModalInvoiceNoteOpen] = useState(false);
    const [modalBillNoteOpen, setModalBillNoteOpen] = useState(false);
    const [invoiceNotes, setInvoiceNotes] = useState("");
    const [billNotes, setBillNotes] = useState("");
    const [discounts, setDiscounts] = useState<Discount[]>([]);
    const [pageDiscount, setPageDiscount] = useState(1)
    const [limitDiscount, setLimitDiscount] = useState(5)
    const [searchDiscount, setSearchDiscount] = useState("")
    const [paginationDiscount, setPaginationDiscount] = useState<Pagination | null>(null);

    const [modalDiscountOpen, setModalDiscountOpen] = useState(false);
    const [inputName, setInputName] = useState("");
    const [inputDescription, setInputDescription] = useState("");
    const [inputType, setInputType] = useState(1);
    const [inputQty, setInputQty] = useState(1);
    const [inputMinQty, setInputMinQty] = useState(1);
    const [inputMaxQty, setInputMaxQty] = useState(1);
    const [inputAmount, setInputAmount] = useState(0);
    const [inputPercent, setInputPercent] = useState(0);
    const [inputAllCustomer, setInputAllCustomer] = useState(false);
    const [inputAllDay, setInputAllDay] = useState(false);
    const [inputAllMerchant, setInputAllMerchant] = useState(false);
    const [inputAllProduct, setInputAllProduct] = useState(false);
    const [inputSunday, setInputSunday] = useState(false);
    const [inputMonday, setInputMonday] = useState(false);
    const [inputTuesday, setInputTuesday] = useState(false);
    const [inputWednesday, setInputWednesday] = useState(false);
    const [inputThursday, setInputThursday] = useState(false);
    const [inputFriday, setInputFriday] = useState(false);
    const [inputSaturday, setInputSaturday] = useState(false);
    const [inputDateRange, setInputDateRange] = useState<DateRange>();

    const [unitOpen, setUnitOpen] = useState(false);
    const [pageUnit, setPageUnit] = useState(1)
    const [limitUnit, setLimitUnit] = useState(5)
    const [searchUnit, setSearchUnit] = useState("")
    const [units, setUnits] = useState<Unit[]>([]);
    const [paginationUnit, setPaginationUnit] = useState<Pagination | null>(null);

    const [inputUnitName, setInputUnitName] = useState("");
    const [inputUnitDescription, setInputUnitDescription] = useState("");


    const [packagingOpen, setPackagingOpen] = useState(false);
    const [pagePackaging, setPagePackaging] = useState(1)
    const [limitPackaging, setLimitPackaging] = useState(5)
    const [searchPackaging, setSearchPackaging] = useState("")
    const [packagings, setPackagings] = useState<Packaging[]>([]);
    const [paginationPackaging, setPaginationPackaging] = useState<Pagination | null>(null);

    const [inputPackagingName, setInputPackagingName] = useState("");
    const [inputPackagingDescription, setInputPackagingDescription] = useState("");




    useEffect(() => {
        setMounted(true)
    }, []);

    useEffect(() => {
        if (!mounted) return
        getCompanyData(false)
        getAllProductCategories()
        getAllPriceCategories()
        getAllTaxes()
        getAllDiscounts()


        getTaxes({ page: 1, limit: 1000, search: "" })
            .then(v => v.json())
            .then(v => {
                setBillInvoiceTaxes(v.data)
            })


        getAccounts({ page: 1, limit: 1000, search: "", type: "Income" })
            .then(v => v.json())
            .then(v => {
                setIncomeAccounts(v.data)
            })
        getAccounts({ page: 1, limit: 1000, search: "", type: "Expense,Cost,Asset", cashflowSubgroup: "fixed_asset,operational_expenses" })
            .then(v => v.json())
            .then(v => {
                setExpenseAccounts(v.data)
            })
        getAccounts({ page: 1, limit: 1000, search: "", type: "Receivable,Asset", cashflowGroup: "current_asset,operating" })
            .then(v => v.json())
            .then(v => {
                setInvoiceReceivableAccounts(v.data)
            })
        getAccounts({ page: 1, limit: 1000, search: "", type: "Liability,Asset", cashflowSubgroup: "acquisition_sale_of_assets,payment_to_vendors,cash_bank" })
            .then(v => v.json())
            .then(v => {
                setBillPayableAccounts(v.data)
            })
    }, [mounted]);

    useEffect(() => {
        setJarayaTradingSetting(company?.product_add_on_setting?.jaraya_trading_setting ?? null)
        setInvoiceNotes(company?.product_add_on_setting?.jaraya_trading_setting?.invoice?.notes ?? "")
        setBillNotes(company?.product_add_on_setting?.jaraya_trading_setting?.bill?.notes ?? "")
    }, [company]);

    useEffect(() => {
        setPageProductCat(1)
        getAllProductCategories(1)
    }, [searchProductCat]);
    useEffect(() => {
        getAllProductCategories()
    }, [limitProductCat, pageProductCat]);

    useEffect(() => {
        setPageDiscount(1)
        getAllDiscounts(1)
    }, [searchDiscount]);
    useEffect(() => {
        getAllDiscounts()
    }, [limitDiscount, pageDiscount]);

    useEffect(() => {
        setPagePriceCat(1)
        getAllPriceCategories(1)
    }, [searchPriceCat]);
    useEffect(() => {
        getAllPriceCategories()
    }, [limitPriceCat, pagePriceCat]);

    useEffect(() => {
        setPageTax(1)
        getAllTaxes(1)
    }, [searchTax]);
    useEffect(() => {
        getAllTaxes()
    }, [limitTax, pageTax]);

    useEffect(() => {
        setPageUnit(1)
        getAllUnits(1)
    }, [searchUnit]);

    useEffect(() => {
        getAllUnits()
    }, [limitUnit, pageUnit]);

    useEffect(() => {
        setPagePackaging(1)
        getAllPackagings(1)
    }, [searchPackaging]);

    useEffect(() => {
        getAllPackagings()
    }, [limitPackaging, pagePackaging]);


    const getCompanyData = async (forceUpdate: boolean) => {
        if (settingLoaded && !forceUpdate) return
        try {
            setIsLoading(true)
            var resp = await getCompanyDetail()
            var respJson = await resp.json()
            setCompany(respJson.data)
            setSettingLoaded(true)
        } catch (error) {
            Swal.fire("Perhatian", `${error}`, "error")
        } finally {
            setIsLoading(false)
        }
    }

    const getAllProductCategories = async (page?: number) => {
        try {
            setIsLoading(true)
            var resp = await getProductCategories({ page: page ?? pageProductCat, limit: limitProductCat, search: searchProductCat })
            var respJson = await resp.json()
            setProductCategories(respJson.data)
            setPaginationProductCat(respJson.meta)
        } catch (error) {
            Swal.fire("Perhatian", `${error}`, "error")
        } finally {
            setIsLoading(false)
        }
    }

    const getAllDiscounts = async (page?: number) => {
        try {
            setIsLoading(true)
            var resp = await getDiscounts({ page: page ?? pageDiscount, limit: limitDiscount, search: searchDiscount })
            var respJson = await resp.json()
            setDiscounts(respJson.data)
            setPaginationDiscount(respJson.meta)
        } catch (error) {
            Swal.fire("Perhatian", `${error}`, "error")
        } finally {
            setIsLoading(false)
        }
    }

    const getAllPriceCategories = async (page?: number) => {
        try {
            setIsLoading(true)
            var resp = await getPriceCategories({ page: page ?? pagePriceCat, limit: limitPriceCat, search: searchPriceCat })
            var respJson = await resp.json()
            setPriceCategories(respJson.data)
            setPaginationPriceCat(respJson.meta)
        } catch (error) {
            Swal.fire("Perhatian", `${error}`, "error")
        } finally {
            setIsLoading(false)
        }
    }

    const getAllTaxes = async (page?: number) => {
        try {
            setIsLoading(true)
            var resp = await getTaxes({ page: page ?? pageTax, limit: limitTax, search: searchTax })
            var respJson = await resp.json()
            setTaxes(respJson.data)
            setPaginationTax(respJson.meta)
        } catch (error) {
            Swal.fire("Perhatian", `${error}`, "error")
        } finally {
            setIsLoading(false)
        }
    }

    const getAllUnits = async (page?: number) => {
        try {
            setIsLoading(true)
            var resp = await getUnits({ page: page ?? pageUnit, limit: limitUnit, search: searchUnit })
            var respJson = await resp.json()
            setUnits(respJson.data)
            setPaginationUnit(respJson.meta)
        } catch (error) {
            Swal.fire("Perhatian", `${error}`, "error")
        } finally {
            setIsLoading(false)
        }
    }
    const getAllPackagings = async (page?: number) => {
        try {
            setIsLoading(true)
            var resp = await getPackagings({ page: page ?? pagePackaging, limit: limitPackaging, search: searchPackaging })
            var respJson = await resp.json()
            setPackagings(respJson.data)
            setPaginationPackaging(respJson.meta)
        } catch (error) {
            Swal.fire("Perhatian", `${error}`, "error")
        } finally {
            setIsLoading(false)
        }
    }



    const modalCat = (isOpen: boolean, setIsOpen: (a: boolean) => void, onSubmit: () => void) => (
        <Modal open={isOpen} onClose={() => setIsOpen(false)}>
            <Modal.Header>
                <Modal.Title>Tambah Kategori</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="flex items-center mb-6">
                    <div className="w-1/3">
                        <label className="block text-gray-900 mb-0 pr-4">Nama Kategori</label>
                    </div>
                    <div className="w-2/3">
                        <input

                            className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                            type="text"
                            placeholder={"Nama Kategori"}
                            value={categoryName}
                            onChange={(el) => setCategoryName(el.target.value)}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onSubmit} appearance="primary">
                    Simpan
                </Button>
                <Button onClick={() => setIsOpen(false)} appearance="subtle">
                    Batal
                </Button>
            </Modal.Footer>
        </Modal>
    )



    return ( <div className="flex-1 mb-5 col-span-1 mt-5 rounded-xl py-6 px-6 transition-all hover:shadow-lg sm:p-4 lg:px-4 xl:px-6 bg-white cursor-pointer">
        <div className='grid gap-x-8 gap-y-4 sm:gap-y-0 xl:grid-cols-4 lg:grid-cols-4 sm:grid-cols-2 md:grid-cols-2'>
            <div className='col-span-2'>
                <div>
                    <Panel header="Faktur Penjualan" bordered className='mb-8'>
                        <InlineForm title={"Judul"}>
                            <input
                                className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"

                                type="text"

                                value={jarayaTradingSetting?.invoice?.title ?? 0}
                                onChange={(e) => {
                                    let setting: JarayaTradingSetting = {
                                        ...jarayaTradingSetting!
                                    }
                                    setting.invoice = {
                                        ...setting.invoice!,
                                        title: e.target.value
                                    }
                                    setJarayaTradingSetting({
                                        ...setting
                                    })
                                }}
                            />
                        </InlineForm>


                        <InlineForm title={"Panjang Auto Number"}>
                            <input
                                className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"

                                type="text"

                                value={jarayaTradingSetting?.invoice?.auto_numeric_length ?? 0}
                                onChange={(e) => {
                                    let setting: JarayaTradingSetting = {
                                        ...jarayaTradingSetting!
                                    }
                                    setting.invoice = {
                                        ...setting.invoice!,
                                        auto_numeric_length: parseInt(e.target.value)
                                    }
                                    setJarayaTradingSetting({
                                        ...setting
                                    })
                                }}
                            />
                        </InlineForm>
                        <InlineForm title={"Format Nomor"}>
                            <textarea
                                className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"

                                value={jarayaTradingSetting?.invoice?.number_format ?? ""}
                                onChange={(e) => {
                                    let setting: JarayaTradingSetting = {
                                        ...jarayaTradingSetting!
                                    }
                                    setting.invoice = {
                                        ...setting.invoice!,
                                        number_format: e.target.value
                                    }
                                    setJarayaTradingSetting({
                                        ...setting
                                    })
                                }}
                            />
                            <TagGroup className='mt-2'>
                                {AUTO_NUMERIC_FORMAL.map(e => <Tag onClick={() => {
                                    let setting: JarayaTradingSetting = {
                                        ...jarayaTradingSetting!
                                    }
                                    setting.invoice = {
                                        ...setting.invoice!,
                                        number_format: `${jarayaTradingSetting?.invoice?.number_format}${e}`
                                    }
                                    setJarayaTradingSetting({
                                        ...setting
                                    })
                                }} style={{ marginTop: 5, marginLeft: 5, }} key={e} color={jarayaTradingSetting?.invoice?.number_format?.includes(e) ? 'green' : 'cyan'}>{e}</Tag>)}
                            </TagGroup>
                        </InlineForm>
                        <InlineForm title={"Panjang Random Character"}>
                            <input
                                className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"

                                type="text"

                                value={jarayaTradingSetting?.invoice?.random_character_length ?? 0}
                                onChange={(e) => {
                                    let setting: JarayaTradingSetting = {
                                        ...jarayaTradingSetting!
                                    }
                                    setting.invoice = {
                                        ...setting.invoice!,
                                        random_character_length: parseInt(e.target.value)
                                    }
                                    setJarayaTradingSetting({
                                        ...setting
                                    })
                                }}
                            />
                        </InlineForm>
                        <InlineForm title={"Panjang Random Numeric"}>
                            <input
                                className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"

                                type="text"

                                value={jarayaTradingSetting?.invoice?.random_numeric_length ?? 0}
                                onChange={(e) => {
                                    let setting: JarayaTradingSetting = {
                                        ...jarayaTradingSetting!
                                    }
                                    setting.invoice = {
                                        ...setting.invoice!,
                                        random_numeric_length: parseInt(e.target.value)
                                    }
                                    setJarayaTradingSetting({
                                        ...setting
                                    })
                                }}
                            />
                        </InlineForm>
                        <InlineForm title={"Karakter Statis"}>
                            <input
                                className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"

                                type="text"

                                value={jarayaTradingSetting?.invoice?.static_character ?? ""}
                                onChange={(e) => {
                                    let setting: JarayaTradingSetting = {
                                        ...jarayaTradingSetting!
                                    }
                                    setting.invoice = {
                                        ...setting.invoice!,
                                        static_character: e.target.value
                                    }
                                    setJarayaTradingSetting({
                                        ...setting
                                    })
                                }}
                            />
                        </InlineForm>
                        <InlineForm title={"Auto Stock"}>
                            <Switch
                                checked={jarayaTradingSetting?.invoice?.is_auto_stock ?? false}
                                onChange={(val) => {
                                    let setting: JarayaTradingSetting = {
                                        ...jarayaTradingSetting!
                                    }
                                    setting.invoice = {
                                        ...setting.invoice!,
                                        is_auto_stock: val
                                    }
                                    setJarayaTradingSetting({
                                        ...setting
                                    })
                                }}
                                className={`${jarayaTradingSetting?.invoice?.is_auto_stock ?? false ? 'bg-blue-600' : 'bg-gray-200'
                                    } relative inline-flex h-6 w-11 items-center rounded-full`}
                            >
                                <span className="sr-only">Auto Stock</span>
                                <span
                                    aria-hidden="true"
                                    className={`${jarayaTradingSetting?.invoice?.is_auto_stock ?? false ? 'translate-x-6' : 'translate-x-1'
                                        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                />
                            </Switch>
                        </InlineForm>
                        <InlineForm title={"Akun Pendapatan"}>
                            <select onChange={(val) => {
                                let setting: JarayaTradingSetting = {
                                    ...jarayaTradingSetting!
                                }
                                setting.invoice = {
                                    ...setting.invoice!,
                                    source_id: val.target.value
                                }
                                setJarayaTradingSetting({
                                    ...setting
                                })
                            }} value={jarayaTradingSetting?.invoice?.source_id ?? ""} className='bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500'>
                                <option value={""}>Pilih Akun</option>
                                {incomeAccounts.map(el => <option key={el.uuid} value={el.uuid}>{el.name}</option>)}
                            </select>
                        </InlineForm>
                        <InlineForm title={"Akun Kas/Piutang"}>
                            <select onChange={(val) => {
                                let setting: JarayaTradingSetting = {
                                    ...jarayaTradingSetting!
                                }
                                setting.invoice = {
                                    ...setting.invoice!,
                                    destination_id: val.target.value
                                }
                                setJarayaTradingSetting({
                                    ...setting
                                })
                            }} value={jarayaTradingSetting?.invoice?.destination_id ?? ""} className='bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500'>
                                <option value={""}>Pilih Akun</option>
                                {invoiceReceivableAccounts.map(el => <option key={el.uuid} value={el.uuid}>{el.name}</option>)}
                            </select>
                        </InlineForm>
                        <InlineForm title={"Pajak"}>
                            <select onChange={(val) => {
                                let setting: JarayaTradingSetting = {
                                    ...jarayaTradingSetting!
                                }
                                setting.invoice = {
                                    ...setting.invoice!,
                                    tax_id: val.target.value
                                }
                                setJarayaTradingSetting({
                                    ...setting
                                })
                            }} value={jarayaTradingSetting?.invoice?.tax_id ?? ""} className='bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500'>
                                <option value={""}>Pilih Pajak</option>
                                {billInvoiceTaxes.filter(e => e.account_payable_id != "").map(el => <option key={el.uuid} value={el.uuid}>{el.name}</option>)}
                            </select>
                        </InlineForm>
                        <InlineForm title={"Metode Pajak"}>
                            <select onChange={(val) => {
                                let setting: JarayaTradingSetting = {
                                    ...jarayaTradingSetting!
                                }
                                setting.invoice = {
                                    ...setting.invoice!,
                                    tax_method: val.target.value
                                }
                                setJarayaTradingSetting({
                                    ...setting
                                })
                            }} value={jarayaTradingSetting?.invoice?.tax_method ?? ""} className='bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500'>
                                <option value={""}>Pilih Metode Pajak</option>
                                {TAX_METHODS.map(el => <option key={el.method} value={el.method}>{el.description}</option>)}
                            </select>
                        </InlineForm>

                        <InlineForm title={"Pajak #2"}>
                            <select onChange={(val) => {
                                let setting: JarayaTradingSetting = {
                                    ...jarayaTradingSetting!
                                }
                                setting.invoice = {
                                    ...setting.invoice!,
                                    secondary_tax_id: val.target.value
                                }
                                setJarayaTradingSetting({
                                    ...setting
                                })
                            }} value={jarayaTradingSetting?.invoice?.secondary_tax_id ?? ""} className='bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500'>
                                <option value={""}>Pilih Pajak #2</option>
                                {billInvoiceTaxes.filter(e => e.account_payable_id != "").map(el => <option key={el.uuid} value={el.uuid}>{el.name}</option>)}
                            </select>
                        </InlineForm>
                        <InlineForm title={"Metode Pajak #2"}>
                            <select onChange={(val) => {
                                let setting: JarayaTradingSetting = {
                                    ...jarayaTradingSetting!
                                }
                                setting.invoice = {
                                    ...setting.invoice!,
                                    secondary_tax_method: val.target.value
                                }
                                setJarayaTradingSetting({
                                    ...setting
                                })
                            }} value={jarayaTradingSetting?.invoice?.secondary_tax_method ?? ""} className='bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500'>
                                <option value={""}>Pilih Metode Pajak #2</option>
                                {TAX_METHODS.map(el => <option key={el.method} value={el.method}>{el.description}</option>)}
                            </select>
                        </InlineForm>
                        <InlineForm title={"Catatan Faktur"} style={{ alignItems: "start" }}>
                            <div onClick={() => setModalInvoiceNoteOpen(true)}>
                                {jarayaTradingSetting?.invoice?.notes ? <div dangerouslySetInnerHTML={{ __html: jarayaTradingSetting?.invoice?.notes }}></div> : <PencilSquareIcon className='w-4 text-gray-400 ' />}
                            </div>
                        </InlineForm>

                        <button
                            type="button"
                            className="inline-flex justify-center items-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-xs font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                            onClick={() => {
                                let req: ProductAddOnSetting = {
                                    jaraya_trading_setting: { ...jarayaTradingSetting! }
                                }
                                setIsLoading(true)
                                updateProductAddOnSetting(req)
                                    .then(v => getCompanyData(true))
                                    .then((v) => {
                                        Swal.fire("Perhatian", `Setting Faktur Penjualan Berhasil diupdate`, "success")
                                    })
                                    .finally(() => setIsLoading(false))
                            }}
                        >
                            <FolderIcon
                                className=" h-3 w-3 mr-1"
                                aria-hidden="true" />  Simpan
                        </button>
                    </Panel>

                </div>
            </div>
            <div className='col-span-2'>
                <div>
                    <Panel header="Faktur Pembelian" bordered className='mb-8'>
                        <InlineForm title={"Judul"}>
                            <input
                                className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"

                                type="text"

                                value={jarayaTradingSetting?.bill?.title ?? 0}
                                onChange={(e) => {
                                    let setting: JarayaTradingSetting = {
                                        ...jarayaTradingSetting!
                                    }
                                    setting.bill = {
                                        ...setting.bill!,
                                        title: e.target.value
                                    }
                                    setJarayaTradingSetting({
                                        ...setting
                                    })
                                }}
                            />
                        </InlineForm>


                        <InlineForm title={"Panjang Auto Number"}>
                            <input
                                className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"

                                type="text"

                                value={jarayaTradingSetting?.bill?.auto_numeric_length ?? 0}
                                onChange={(e) => {
                                    let setting: JarayaTradingSetting = {
                                        ...jarayaTradingSetting!
                                    }
                                    setting.bill = {
                                        ...setting.bill!,
                                        auto_numeric_length: parseInt(e.target.value)
                                    }
                                    setJarayaTradingSetting({
                                        ...setting
                                    })
                                }}
                            />
                        </InlineForm>
                        <InlineForm title={"Format Nomor"}>
                            <textarea
                                className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"

                                value={jarayaTradingSetting?.bill?.number_format ?? ""}
                                onChange={(e) => {
                                    let setting: JarayaTradingSetting = {
                                        ...jarayaTradingSetting!
                                    }
                                    setting.bill = {
                                        ...setting.bill!,
                                        number_format: e.target.value
                                    }
                                    setJarayaTradingSetting({
                                        ...setting
                                    })
                                }}
                            />
                            <TagGroup className='mt-2'>
                                {AUTO_NUMERIC_FORMAL.map(e => <Tag onClick={() => {
                                    console.log(jarayaTradingSetting?.bill?.number_format ?? "" + e, e)
                                    let setting: JarayaTradingSetting = {
                                        ...jarayaTradingSetting!
                                    }
                                    setting.bill = {
                                        ...setting.bill!,
                                        number_format: `${jarayaTradingSetting?.bill?.number_format}${e}`
                                    }
                                    setJarayaTradingSetting({
                                        ...setting
                                    })
                                }} style={{ marginTop: 5, marginLeft: 5, }} key={e} color={jarayaTradingSetting?.bill?.number_format?.includes(e) ? 'green' : 'cyan'}>{e}</Tag>)}
                            </TagGroup>
                        </InlineForm>
                        <InlineForm title={"Panjang Random Character"}>
                            <input
                                className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"

                                type="text"

                                value={jarayaTradingSetting?.bill?.random_character_length ?? 0}
                                onChange={(e) => {
                                    let setting: JarayaTradingSetting = {
                                        ...jarayaTradingSetting!
                                    }
                                    setting.bill = {
                                        ...setting.bill!,
                                        random_character_length: parseInt(e.target.value)
                                    }
                                    setJarayaTradingSetting({
                                        ...setting
                                    })
                                }}
                            />
                        </InlineForm>
                        <InlineForm title={"Panjang Random Numeric"}>
                            <input
                                className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"

                                type="text"

                                value={jarayaTradingSetting?.bill?.random_numeric_length ?? 0}
                                onChange={(e) => {
                                    let setting: JarayaTradingSetting = {
                                        ...jarayaTradingSetting!
                                    }
                                    setting.bill = {
                                        ...setting.bill!,
                                        random_numeric_length: parseInt(e.target.value)
                                    }
                                    setJarayaTradingSetting({
                                        ...setting
                                    })
                                }}
                            />
                        </InlineForm>
                        <InlineForm title={"Karakter Statis"}>
                            <input
                                className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"

                                type="text"

                                value={jarayaTradingSetting?.bill?.static_character ?? ""}
                                onChange={(e) => {
                                    let setting: JarayaTradingSetting = {
                                        ...jarayaTradingSetting!
                                    }
                                    setting.bill = {
                                        ...setting.bill!,
                                        static_character: e.target.value
                                    }
                                    setJarayaTradingSetting({
                                        ...setting
                                    })
                                }}
                            />
                        </InlineForm>
                        <InlineForm title={"Auto Stock"}>
                            <Switch
                                checked={jarayaTradingSetting?.bill?.is_auto_stock ?? false}
                                onChange={(val) => {
                                    let setting: JarayaTradingSetting = {
                                        ...jarayaTradingSetting!
                                    }
                                    setting.bill = {
                                        ...setting.bill!,
                                        is_auto_stock: val
                                    }
                                    setJarayaTradingSetting({
                                        ...setting
                                    })
                                }}
                                className={`${jarayaTradingSetting?.bill?.is_auto_stock ?? false ? 'bg-blue-600' : 'bg-gray-200'
                                    } relative inline-flex h-6 w-11 items-center rounded-full`}
                            >
                                <span className="sr-only">Auto Stock</span>
                                <span
                                    aria-hidden="true"
                                    className={`${jarayaTradingSetting?.bill?.is_auto_stock ?? false ? 'translate-x-6' : 'translate-x-1'
                                        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                />
                            </Switch>
                        </InlineForm>
                        <InlineForm title={"Akun Pengeluaran / Biaya"}>
                            <select onChange={(val) => {
                                let setting: JarayaTradingSetting = {
                                    ...jarayaTradingSetting!
                                }
                                setting.bill = {
                                    ...setting.bill!,
                                    source_id: val.target.value
                                }
                                setJarayaTradingSetting({
                                    ...setting
                                })
                            }} value={jarayaTradingSetting?.bill?.source_id ?? ""} className='bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500'>
                                <option value={""}>Pilih Akun</option>
                                {expenseAccounts.map(el => <option key={el.uuid} value={el.uuid}>{el.name}</option>)}
                            </select>
                        </InlineForm>
                        <InlineForm title={"Akun Kas/Hutang"}>
                            <select onChange={(val) => {
                                let setting: JarayaTradingSetting = {
                                    ...jarayaTradingSetting!
                                }
                                setting.bill = {
                                    ...setting.bill!,
                                    destination_id: val.target.value
                                }
                                setJarayaTradingSetting({
                                    ...setting
                                })
                            }} value={jarayaTradingSetting?.bill?.destination_id ?? ""} className='bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500'>
                                <option value={""}>Pilih Akun</option>
                                {billPayableAccounts.map(el => <option key={el.uuid} value={el.uuid}>{el.name}</option>)}
                            </select>
                        </InlineForm>
                        <InlineForm title={"Pajak"}>
                            <select onChange={(val) => {
                                let setting: JarayaTradingSetting = {
                                    ...jarayaTradingSetting!
                                }
                                setting.bill = {
                                    ...setting.bill!,
                                    tax_id: val.target.value
                                }
                                setJarayaTradingSetting({
                                    ...setting
                                })
                            }} value={jarayaTradingSetting?.bill?.tax_id ?? ""} className='bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500'>
                                <option value={""}>Pilih Pajak</option>
                                {billInvoiceTaxes.filter(e => e.account_receivable_id != "").map(el => <option key={el.uuid} value={el.uuid}>{el.name}</option>)}
                            </select>
                        </InlineForm>
                        <InlineForm title={"Metode Pajak"}>
                            <select onChange={(val) => {
                                let setting: JarayaTradingSetting = {
                                    ...jarayaTradingSetting!
                                }
                                setting.invoice = {
                                    ...setting.invoice!,
                                    tax_method: val.target.value
                                }
                                setJarayaTradingSetting({
                                    ...setting
                                })
                            }} value={jarayaTradingSetting?.invoice?.tax_method ?? ""} className='bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500'>
                                <option value={""}>Pilih Metode Pajak</option>
                                {TAX_METHODS.map(el => <option key={el.method} value={el.method}>{el.description}</option>)}
                            </select>
                        </InlineForm>

                        <InlineForm title={"Pajak #2"}>
                            <select onChange={(val) => {
                                let setting: JarayaTradingSetting = {
                                    ...jarayaTradingSetting!
                                }
                                setting.invoice = {
                                    ...setting.invoice!,
                                    secondary_tax_id: val.target.value
                                }
                                setJarayaTradingSetting({
                                    ...setting
                                })
                            }} value={jarayaTradingSetting?.invoice?.secondary_tax_id ?? ""} className='bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500'>
                                <option value={""}>Pilih Pajak #2</option>
                                {billInvoiceTaxes.filter(e => e.account_receivable_id != "").map(el => <option key={el.uuid} value={el.uuid}>{el.name}</option>)}
                            </select>
                        </InlineForm>
                        <InlineForm title={"Metode Pajak #2"}>
                            <select onChange={(val) => {
                                let setting: JarayaTradingSetting = {
                                    ...jarayaTradingSetting!
                                }
                                setting.invoice = {
                                    ...setting.invoice!,
                                    secondary_tax_method: val.target.value
                                }
                                setJarayaTradingSetting({
                                    ...setting
                                })
                            }} value={jarayaTradingSetting?.invoice?.secondary_tax_method ?? ""} className='bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500'>
                                <option value={""}>Pilih Metode Pajak #2</option>
                                {TAX_METHODS.map(el => <option key={el.method} value={el.method}>{el.description}</option>)}
                            </select>
                        </InlineForm>
                        <InlineForm title={"Catatan Faktur"} style={{ alignItems: "start" }}>
                            <div onClick={() => setModalBillNoteOpen(true)}>
                                {jarayaTradingSetting?.bill?.notes ? <div dangerouslySetInnerHTML={{ __html: jarayaTradingSetting?.bill?.notes }}></div> : <PencilSquareIcon className='w-4 text-gray-400 ' />}
                            </div>
                        </InlineForm>

                        <button
                            type="button"
                            className="inline-flex justify-center items-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-xs font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                            onClick={() => {
                                let req: ProductAddOnSetting = {
                                    jaraya_trading_setting: { ...jarayaTradingSetting! }
                                }
                                setIsLoading(true)
                                updateProductAddOnSetting(req)
                                    .then(v => getCompanyData(true))
                                    .then((v) => {
                                        Swal.fire("Perhatian", `Setting Faktur Pembelian Berhasil diupdate`, "success")
                                    })
                                    .finally(() => setIsLoading(false))
                            }}
                        >
                            <FolderIcon
                                className=" h-3 w-3 mr-1"
                                aria-hidden="true" />  Simpan
                        </button>
                    </Panel>

                </div>
            </div>

            <div className='col-span-2'>
                <div>

                    <Panel header="Surat Jalan" bordered className='mb-8'>
                        <InlineForm title={"Templat Surat Jalan"}>
                            <select
                                value={jarayaTradingSetting?.delivery_letter_template}
                                className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                onChange={(val) => {
                                    let setting: JarayaTradingSetting = {
                                        ...jarayaTradingSetting!
                                    }
                                    setJarayaTradingSetting({
                                        ...setting,
                                        delivery_letter_template: val.target.value
                                    })
                                }}
                            >
                                <option value="regular">Regular</option>
                                <option value="packing_list">Packing List</option>
                            </select>
                        </InlineForm>
                        <InlineForm title={"Panjang Auto Number"}>
                            <input
                                className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"

                                type="text"

                                value={jarayaTradingSetting?.delivery_letter?.auto_numeric_length ?? 0}
                                onChange={(e) => {
                                    let setting: JarayaTradingSetting = {
                                        ...jarayaTradingSetting!
                                    }
                                    setting.delivery_letter = {
                                        ...setting.delivery_letter!,
                                        auto_numeric_length: parseInt(e.target.value)
                                    }
                                    setJarayaTradingSetting({
                                        ...setting
                                    })
                                }}
                            />
                        </InlineForm>
                        <InlineForm title={"Format Nomor"}>
                            <textarea
                                className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"

                                value={jarayaTradingSetting?.delivery_letter?.number_format ?? ""}
                                onChange={(e) => {
                                    let setting: JarayaTradingSetting = {
                                        ...jarayaTradingSetting!
                                    }
                                    setting.delivery_letter = {
                                        ...setting.delivery_letter!,
                                        number_format: e.target.value
                                    }
                                    setJarayaTradingSetting({
                                        ...setting
                                    })
                                }}
                            />
                            <TagGroup className='mt-2'>
                                {AUTO_NUMERIC_FORMAL.map(e => <Tag onClick={() => {
                                    let setting: JarayaTradingSetting = {
                                        ...jarayaTradingSetting!
                                    }
                                    setting.delivery_letter = {
                                        ...setting.delivery_letter!,
                                        number_format: `${jarayaTradingSetting?.delivery_letter?.number_format}${e}`
                                    }
                                    setJarayaTradingSetting({
                                        ...setting
                                    })
                                }} style={{ marginTop: 5, marginLeft: 5, }} key={e} color={jarayaTradingSetting?.delivery_letter?.number_format?.includes(e) ? 'green' : 'cyan'}>{e}</Tag>)}
                            </TagGroup>
                        </InlineForm>
                        <InlineForm title={"Panjang Random Character"}>
                            <input
                                className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"

                                type="text"

                                value={jarayaTradingSetting?.delivery_letter?.random_character_length ?? 0}
                                onChange={(e) => {
                                    let setting: JarayaTradingSetting = {
                                        ...jarayaTradingSetting!
                                    }
                                    setting.delivery_letter = {
                                        ...setting.delivery_letter!,
                                        random_character_length: parseInt(e.target.value)
                                    }
                                    setJarayaTradingSetting({
                                        ...setting
                                    })
                                }}
                            />
                        </InlineForm>
                        <InlineForm title={"Panjang Random Numeric"}>
                            <input
                                className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"

                                type="text"

                                value={jarayaTradingSetting?.delivery_letter?.random_numeric_length ?? 0}
                                onChange={(e) => {
                                    let setting: JarayaTradingSetting = {
                                        ...jarayaTradingSetting!
                                    }
                                    setting.delivery_letter = {
                                        ...setting.delivery_letter!,
                                        random_numeric_length: parseInt(e.target.value)
                                    }
                                    setJarayaTradingSetting({
                                        ...setting
                                    })
                                }}
                            />
                        </InlineForm>
                        <InlineForm title={"Karakter Statis"}>
                            <input
                                className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"

                                type="text"

                                value={jarayaTradingSetting?.delivery_letter?.static_character ?? ""}
                                onChange={(e) => {
                                    let setting: JarayaTradingSetting = {
                                        ...jarayaTradingSetting!
                                    }
                                    setting.delivery_letter = {
                                        ...setting.delivery_letter!,
                                        static_character: e.target.value
                                    }
                                    setJarayaTradingSetting({
                                        ...setting
                                    })
                                }}
                            />
                        </InlineForm>
                        <button
                            type="button"
                            className="inline-flex justify-center items-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-xs font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                            onClick={() => {
                                let req: ProductAddOnSetting = {
                                    jaraya_trading_setting: { ...jarayaTradingSetting! }
                                }
                                setIsLoading(true)
                                updateProductAddOnSetting(req)
                                    .then(v => getCompanyData(true))
                                    .then((v) => {
                                        Swal.fire("Perhatian", `Setting Produk Berhasil diupdate`, "success")
                                    })
                                    .finally(() => setIsLoading(false))
                            }}
                        >
                            <FolderIcon
                                className=" h-3 w-3 mr-1"
                                aria-hidden="true" />  Simpan
                        </button>
                    </Panel>
                </div>
            </div>
            <div className='col-span-2'>
                <div>
                    <Panel header="Produk" bordered className='mb-8'>
                        <InlineForm title={"Default Varian Parameter"}>
                            <TagPicker
                                creatable
                                data={(jarayaTradingSetting?.varian_parameters ?? []).map(
                                    item => ({
                                        label: item,
                                        value: item,
                                    })
                                )}
                                value={(jarayaTradingSetting?.varian_parameters ?? [])}
                                style={{ width: 300 }}
                                menuStyle={{ width: 300 }}
                                onCreate={(value, item) => {
                                    let setting: JarayaTradingSetting = {
                                        ...jarayaTradingSetting!
                                    }

                                    setJarayaTradingSetting({
                                        ...setting,
                                        varian_parameters: value
                                    })
                                }}
                                onChange={(value, item) => {
                                    let setting: JarayaTradingSetting = {
                                        ...jarayaTradingSetting!
                                    }
                                    setJarayaTradingSetting({
                                        ...setting,
                                        varian_parameters: value
                                    })
                                }}
                            />
                            {/* <input
                                className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                
                                type="text"
                                value={jarayaTradingSetting?.varian_parameters.join(",")}
                                onChange={(e) => {
                                    let setting: JarayaTradingSetting = {
                                        ...jarayaTradingSetting!
                                    }
                                    setting.invoice = {
                                        ...setting.invoice!,
                                        title: e.target.value
                                    }
                                    setJarayaTradingSetting({
                                        ...setting
                                    })
                                }}
                            /> */}
                        </InlineForm>
                        <InlineForm title={"Stok Kemasan"}>
                            <Switch
                                checked={jarayaTradingSetting?.user_packaging_stock ?? false}
                                onChange={(val) => {
                                    let setting: JarayaTradingSetting = {
                                        ...jarayaTradingSetting!
                                    }

                                    setJarayaTradingSetting({
                                        ...setting,
                                        user_packaging_stock: val
                                    })
                                }}
                                className={`${jarayaTradingSetting?.user_packaging_stock ?? false ? 'bg-blue-600' : 'bg-gray-200'
                                    } relative inline-flex h-6 w-11 items-center rounded-full`}
                            >
                                <span className="sr-only">Stok Kemasan</span>
                                <span
                                    aria-hidden="true"
                                    className={`${jarayaTradingSetting?.user_packaging_stock ?? false ? 'translate-x-6' : 'translate-x-1'
                                        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                />
                            </Switch>
                        </InlineForm>
                        <button
                            type="button"
                            className="inline-flex justify-center items-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-xs font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                            onClick={() => {
                                let req: ProductAddOnSetting = {
                                    jaraya_trading_setting: { ...jarayaTradingSetting! }
                                }
                                setIsLoading(true)
                                updateProductAddOnSetting(req)
                                    .then(v => getCompanyData(true))
                                    .then((v) => {
                                        Swal.fire("Perhatian", `Setting Produk Berhasil diupdate`, "success")
                                    })
                                    .finally(() => setIsLoading(false))
                            }}
                        >
                            <FolderIcon
                                className=" h-3 w-3 mr-1"
                                aria-hidden="true" />  Simpan
                        </button>
                    </Panel>
                    <Panel header="Diskon" bordered className='mb-8'>
                        <CustomTable
                            switchHeader
                            searchPlaceholder='Cari Diskon'
                            onSearch={(val) => {
                                setSearchDiscount(val)
                            }}
                            pagination
                            total={paginationDiscount?.total_records}
                            limit={limitDiscount}
                            activePage={pageDiscount}
                            setActivePage={(val) => {
                                setPageDiscount(val)
                            }}
                            searchHeader={
                                <button
                                    type="button"
                                    className="inline-flex justify-center items-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-xs font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                    onClick={() => setModalDiscountOpen(true)}
                                >
                                    <PlusIcon
                                        className=" h-3 w-3 mr-1"
                                        aria-hidden="true" />  Tambah Diskon
                                </button>
                            }
                            headers={["#", "Nama", "Berlaku", "Keterangan", "Nilai", ""]} headerClasses={[]} datasets={discounts.map(e => {
                                return {
                                    cells: [
                                        { data: ((pageDiscount - 1) * limitDiscount) + (discounts.indexOf(e) + 1) },
                                        {
                                            data: e.name
                                        },
                                        {
                                            data: <small className='flex flex-col justify-center'>
                                                <Moment format='DD/MM/YYYY'>{e.start_date}</Moment>
                                                <span className='text-center'>s/d</span>
                                                <Moment format='DD/MM/YYYY'>{e.end_date}</Moment>
                                            </small>
                                        },
                                        {
                                            data: e.description
                                        },
                                        {
                                            data: e.type == 1 ? `${e.percent}%` : money(e.amount)
                                        },
                                        {
                                            data: <TrashIcon className="w-5 text-red-600" onClick={() => {
                                                Swal.fire({
                                                    title: "Anda Yakin",
                                                    text: "Anda tidak akan dapat mengembalikan proses ini!",
                                                    icon: "warning",
                                                    showCancelButton: true,
                                                    confirmButtonColor: "#3085d6",
                                                    cancelButtonColor: "#d33",
                                                    confirmButtonText: "Ya, Hapus!",
                                                    cancelButtonText: "Batal"
                                                }).then((result) => {
                                                    if (result.isConfirmed) {
                                                        setIsLoading(true)
                                                        deleteDiscount(e.uuid!).then(v => getAllDiscounts())
                                                            .finally(() => setIsLoading(false))
                                                    }
                                                });
                                            }} />, className: 'w-4 text-right'
                                        },
                                    ],
                                    className: "last:border-0 hover:bg-gray-50"
                                }
                            })} />
                    </Panel>
                </div>
            </div>
        </div>
        <div className='grid gap-x-8 gap-y-4 sm:gap-y-0 xl:grid-cols-4 lg:grid-cols-4 sm:grid-cols-2 md:grid-cols-2'>
            <div className='col-span-2'>
                <div>
                    <Panel header="Kategori Produk" bordered className='mb-8'>
                        <CustomTable
                            switchHeader
                            searchPlaceholder='Cari Kategori'
                            onSearch={(val) => {
                                setSearchProductCat(val)
                            }}
                            pagination
                            total={paginationProductCat?.total_records}
                            limit={limitProductCat}
                            activePage={pageProductCat}
                            setActivePage={(val) => {
                                setPageProductCat(val)
                            }}
                            searchHeader={
                                <button
                                    type="button"
                                    className="inline-flex justify-center items-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-xs font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                    onClick={() => setProductCatOpen(true)}
                                >
                                    <PlusIcon
                                        className=" h-3 w-3 mr-1"
                                        aria-hidden="true" />  Tambah Kategori
                                </button>
                            }
                            headers={["#", "Nama Kategori", ""]} headerClasses={["w-10", "w-80"]}
                            datasets={productCategories.map(e => {
                                let data: TableRecord = {
                                    cells: [
                                        { data: ((pageProductCat - 1) * limitProductCat) + (productCategories.indexOf(e) + 1) },
                                        { data: e.name, },
                                        {
                                            data: <TrashIcon className="w-5 text-red-600" onClick={() => {
                                                Swal.fire({
                                                    title: "Anda Yakin",
                                                    text: "Anda tidak akan dapat mengembalikan proses ini!",
                                                    icon: "warning",
                                                    showCancelButton: true,
                                                    confirmButtonColor: "#3085d6",
                                                    cancelButtonColor: "#d33",
                                                    confirmButtonText: "Ya, Hapus!",
                                                    cancelButtonText: "Batal"
                                                }).then((result) => {
                                                    if (result.isConfirmed) {
                                                        deleteProductCategory(e.uuid)
                                                            .then(e => getAllProductCategories())
                                                    }
                                                });
                                            }} />, className: 'w-4 text-right'
                                        },
                                    ],
                                    className: "last:border-0 hover:bg-gray-50"
                                }
                                return data
                            })} />
                    </Panel>

                </div>


            </div>
            <div className='col-span-2'>
                <div>
                    <Panel header="Kategori Harga" bordered className='mb-8'>
                        <CustomTable
                            switchHeader
                            searchPlaceholder='Cari Kategori'
                            onSearch={(val) => {
                                setSearchPriceCat(val)
                            }}
                            pagination
                            total={paginationPriceCat?.total_records}
                            limit={limitPriceCat}
                            activePage={pagePriceCat}
                            setActivePage={(val) => {
                                setPagePriceCat(val)
                            }}
                            searchHeader={
                                <button
                                    type="button"
                                    className="inline-flex justify-center items-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-xs font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                    onClick={() => setPriceCatOpen(true)}
                                >
                                    <PlusIcon
                                        className=" h-3 w-3 mr-1"
                                        aria-hidden="true" />  Tambah Kategori
                                </button>
                            }
                            headers={["#", "Nama Kategori", ""]} headerClasses={["w-10", "w-80"]}
                            datasets={priceCategories.map(e => {
                                let data: TableRecord = {
                                    cells: [
                                        { data: ((pagePriceCat - 1) * limitPriceCat) + (priceCategories.indexOf(e) + 1) },
                                        { data: e.name, },
                                        {
                                            data: <TrashIcon className="w-5 text-red-600" onClick={() => {
                                                Swal.fire({
                                                    title: "Anda Yakin",
                                                    text: "Anda tidak akan dapat mengembalikan proses ini!",
                                                    icon: "warning",
                                                    showCancelButton: true,
                                                    confirmButtonColor: "#3085d6",
                                                    cancelButtonColor: "#d33",
                                                    confirmButtonText: "Ya, Hapus!",
                                                    cancelButtonText: "Batal"
                                                }).then((result) => {
                                                    if (result.isConfirmed) {
                                                        deletePriceCategory(e.uuid)
                                                            .then(e => getAllPriceCategories())
                                                    }
                                                });
                                            }}
                                            />, className: 'w-4 text-right'
                                        },
                                    ],
                                    className: "last:border-0 hover:bg-gray-50"
                                }
                                return data
                            })} />
                    </Panel>
                </div>
            </div>
        </div>
        <div className='grid gap-x-8 gap-y-4 sm:gap-y-0 xl:grid-cols-4 lg:grid-cols-4 sm:grid-cols-2 md:grid-cols-2'>
            <div className='col-span-2'>
                <Panel header="Pajak" bordered className='mb-8'>
                    <CustomTable
                        switchHeader
                        searchPlaceholder='Cari Pajak'
                        onSearch={(val) => {
                            setSearchTax(val)
                        }}
                        pagination
                        total={paginationTax?.total_records}
                        limit={limitTax}
                        activePage={pageTax}
                        setActivePage={(val) => {
                            setPageTax(val)
                        }}
                        searchHeader={
                            <button
                                type="button"
                                className="inline-flex justify-center items-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-xs font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                onClick={() => setTaxOpen(true)}
                            >
                                <PlusIcon
                                    className=" h-3 w-3 mr-1"
                                    aria-hidden="true" />  Tambah Pajak
                            </button>
                        }
                        headers={["#", "Nama Pajak", "Kode", "Piutang", "Hutang", "Nilai", ""]} headerClasses={["w-10", "w-80"]}
                        datasets={taxes.map(e => {
                            let data: TableRecord = {
                                cells: [
                                    { data: ((pageTax - 1) * limitTax) + (taxes.indexOf(e) + 1) },
                                    { data: e.name, },
                                    { data: e.code, },
                                    { data: e.account_receivable?.name ?? "", },
                                    { data: e.account_payable?.name ?? "", },
                                    { data: `${e.amount}%`, },
                                    {
                                        data: <TrashIcon className="w-5 text-red-600" onClick={() => {
                                            Swal.fire({
                                                title: "Anda Yakin",
                                                text: "Anda tidak akan dapat mengembalikan proses ini!",
                                                icon: "warning",
                                                showCancelButton: true,
                                                confirmButtonColor: "#3085d6",
                                                cancelButtonColor: "#d33",
                                                confirmButtonText: "Ya, Hapus!",
                                                cancelButtonText: "Batal"
                                            }).then((result) => {
                                                if (result.isConfirmed) {
                                                    deleteTax(e.uuid)
                                                        .then(e => getAllTaxes())
                                                }
                                            });
                                        }}
                                        />, className: 'w-4 text-right'
                                    },
                                ],
                                className: "last:border-0 hover:bg-gray-50"
                            }
                            return data
                        })} />
                </Panel>

            </div>
            <div className='col-span-2'>
                <Panel header="Unit" bordered className='mb-8'>
                    <CustomTable
                        switchHeader
                        searchPlaceholder='Cari Unit'
                        onSearch={(val) => {
                            setSearchUnit(val)
                        }}
                        pagination
                        total={paginationUnit?.total_records}
                        limit={limitUnit}
                        activePage={pageUnit}
                        setActivePage={(val) => {
                            setPageUnit(val)
                        }}
                        searchHeader={
                            <button
                                type="button"
                                className="inline-flex justify-center items-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-xs font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                onClick={() => setUnitOpen(true)}
                            >
                                <PlusIcon
                                    className=" h-3 w-3 mr-1"
                                    aria-hidden="true" />  Tambah Unit
                            </button>
                        }
                        headers={["#", "Nama", "Keterangan", ""]} headerClasses={["w-10", "w-80"]}
                        datasets={units.map(e => {
                            let data: TableRecord = {
                                cells: [
                                    { data: ((pageUnit - 1) * limitUnit) + (units.indexOf(e) + 1) },
                                    { data: e.name, },
                                    { data: e.description, },

                                    {
                                        data: <TrashIcon className="w-5 text-red-600" onClick={() => {
                                            Swal.fire({
                                                title: "Anda Yakin",
                                                text: "Anda tidak akan dapat mengembalikan proses ini!",
                                                icon: "warning",
                                                showCancelButton: true,
                                                confirmButtonColor: "#3085d6",
                                                cancelButtonColor: "#d33",
                                                confirmButtonText: "Ya, Hapus!",
                                                cancelButtonText: "Batal"
                                            }).then((result) => {
                                                if (result.isConfirmed) {
                                                    deleteUnit(e.uuid)
                                                        .then(e => getAllUnits())
                                                }
                                            });
                                        }}
                                        />, className: 'w-4 text-right'
                                    },
                                ],
                                className: "last:border-0 hover:bg-gray-50"
                            }
                            return data
                        })} />
                </Panel>

            </div>
        </div>
        <div className='grid gap-x-8 gap-y-4 sm:gap-y-0 xl:grid-cols-4 lg:grid-cols-4 sm:grid-cols-2 md:grid-cols-2'>
        <div className='col-span-2'>
                <Panel header="Packaging" bordered className='mb-8'>
                    <CustomTable
                        switchHeader
                        searchPlaceholder='Cari Packaging'
                        onSearch={(val) => {
                            setSearchPackaging(val)
                        }}
                        pagination
                        total={paginationPackaging?.total_records}
                        limit={limitPackaging}
                        activePage={pagePackaging}
                        setActivePage={(val) => {
                            setPagePackaging(val)
                        }}
                        searchHeader={
                            <button
                                type="button"
                                className="inline-flex justify-center items-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-xs font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                onClick={() => setPackagingOpen(true)}
                            >
                                <PlusIcon
                                    className=" h-3 w-3 mr-1"
                                    aria-hidden="true" />  Tambah Packaging
                            </button>
                        }
                        headers={["#", "Nama", "Keterangan", ""]} headerClasses={["w-10", "w-80"]}
                        datasets={packagings.map(e => {
                            let data: TableRecord = {
                                cells: [
                                    { data: ((pagePackaging - 1) * limitPackaging) + (packagings.indexOf(e) + 1) },
                                    { data: e.name, },
                                    { data: e.description, },

                                    {
                                        data: <TrashIcon className="w-5 text-red-600" onClick={() => {
                                            Swal.fire({
                                                title: "Anda Yakin",
                                                text: "Anda tidak akan dapat mengembalikan proses ini!",
                                                icon: "warning",
                                                showCancelButton: true,
                                                confirmButtonColor: "#3085d6",
                                                cancelButtonColor: "#d33",
                                                confirmButtonText: "Ya, Hapus!",
                                                cancelButtonText: "Batal"
                                            }).then((result) => {
                                                if (result.isConfirmed) {
                                                    deletePackaging(e.uuid)
                                                        .then(e => getAllPackagings())
                                                }
                                            });
                                        }}
                                        />, className: 'w-4 text-right'
                                    },
                                ],
                                className: "last:border-0 hover:bg-gray-50"
                            }
                            return data
                        })} />
                </Panel>

            </div>
        </div>
        {modalCat(productCatOpen, setProductCatOpen, () => {
            setIsLoading(true)
            addProductCategory({ name: categoryName })
                .then(e => {
                    setCategoryName("")
                    getAllProductCategories()
                    setProductCatOpen(false)
                })
                .catch(error => Swal.fire("Perhatian", `${error}`, "error"))
                .finally(() => setIsLoading(false))
        })}
        {modalCat(priceCatOpen, setPriceCatOpen, () => {
            setIsLoading(true)
            addPriceCategory({ name: categoryName })
                .then(e => {
                    setCategoryName("")
                    getAllPriceCategories()
                    setPriceCatOpen(false)
                })
                .catch(error => Swal.fire("Perhatian", `${error}`, "error"))
                .finally(() => setIsLoading(false))
        })}
        <FormTax isOpen={taxOpen} setIsOpen={setTaxOpen} onSubmit={() => {
            setTaxOpen(false)
            getAllTaxes()
        }} />
        <ModalEditor isOpen={modalInvoiceNoteOpen} setIsOpen={setModalInvoiceNoteOpen} value={jarayaTradingSetting?.invoice.notes ?? ""} onEditorChange={(val, editor) => {
            let setting: JarayaTradingSetting = {
                ...jarayaTradingSetting!
            }
            setting.invoice = {
                ...setting.invoice!,
                notes: val
            }
            setJarayaTradingSetting({
                ...setting
            })
        }} setContent={(content) => {
            console.log(content)
        }} onClose={(val) => {

        }} />
        <ModalEditor isOpen={modalBillNoteOpen} setIsOpen={setModalBillNoteOpen} value={jarayaTradingSetting?.bill.notes ?? ""} onEditorChange={(val, editor) => {
            let setting: JarayaTradingSetting = {
                ...jarayaTradingSetting!
            }
            setting.bill = {
                ...setting.bill!,
                notes: val
            }
            setJarayaTradingSetting({
                ...setting
            })
        }} setContent={(content) => {
        }} onClose={(val) => {

        }} />
        <ModalCustom title='Form Diskon' isOpen={modalDiscountOpen} setIsOpen={setModalDiscountOpen} onClose={() => {
            setModalDiscountOpen(false)
        }} footer={
            <div className='flex'>
                <button
                    type="button"
                    className="inline-flex justify-center items-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-xs font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={async () => {
                        if (!inputDateRange) {
                            Swal.fire("Perhatian", `Isi tanggal terlebih dahulu`, "error")
                            return
                        }
                        if (!inputName) {
                            Swal.fire("Perhatian", `Isi nama diskon terlebih dahulu`, "error")
                            return
                        }

                        try {
                            setIsLoading(true)
                            await addDiscont({
                                name: inputName,
                                description: inputDescription,
                                type: inputType,
                                amount: inputAmount,
                                percent: inputPercent,
                                qty: inputQty,
                                min_qty: inputMinQty,
                                max_qty: inputMaxQty,
                                start_date: inputDateRange[0].toISOString(),
                                end_date: inputDateRange[1].toISOString(),
                                is_sunday: inputSunday,
                                is_monday: inputMonday,
                                is_tuesday: inputTuesday,
                                is_wednesday: inputWednesday,
                                is_thursday: inputThursday,
                                is_friday: inputFriday,
                                is_saturday: inputSaturday,
                                is_allday: inputAllDay,
                                is_all_product: inputAllProduct,
                                is_all_customer: inputAllCustomer,
                                is_all_merchant: inputAllMerchant,
                                flag: true
                            })
                            getAllDiscounts()
                        } catch (error) {
                            Swal.fire("Perhatian", `${error}`, "error")
                        } finally {
                            setIsLoading(false)
                            setModalDiscountOpen(false)
                        }
                    }}
                >
                    <FolderIcon
                        className=" h-3 w-3 mr-1"
                        aria-hidden="true" />  Simpan
                </button></div>} >


            <InlineForm title={'Berlaku'}>
                <DateRangePicker placement="bottomEnd" value={inputDateRange} block format="dd/MM/yyyy" onOk={(val: DateRange) => {
                    setInputDateRange(val)
                }} onShortcutClick={(val) => {
                    setInputDateRange(val.value)
                }} />
            </InlineForm>
            <InlineForm title={'Nama Diskon'}>
                <input

                    className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                    type="text"
                    placeholder={"Nama Diskon"}
                    value={inputName}
                    onChange={(el) => setInputName(el.target.value)}
                />
            </InlineForm>
            <InlineForm title={'Keterangan'} style={{ alignItems: "start" }}>
                <textarea
                    rows={5}

                    className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                    placeholder={"Keterangan"}
                    value={inputDescription}
                    onChange={(el) => setInputDescription(el.target.value)}
                />
            </InlineForm>
            <InlineForm title={'Hari'} style={{ alignItems: "start" }}>
                <div className='grid grid-cols-3  gap-y-2'>
                    <div className=' col-span-1 flex items-center'>
                        <Switch checked={inputAllDay} onChange={(val) => {
                            setInputAllDay(val)
                            setInputSunday(val)
                            setInputMonday(val)
                            setInputTuesday(val)
                            setInputWednesday(val)
                            setInputThursday(val)
                            setInputFriday(val)
                            setInputSaturday(val)
                        }} className={`${inputAllDay ? 'bg-blue-600' : 'bg-gray-200'} relative inline-flex h-6 w-11 items-center rounded-full col-span-1 mr-2`}>
                            <span className="sr-only"></span>
                            <span aria-hidden="true" className={`${inputAllDay ? 'translate-x-6' : 'translate-x-1'} inline-block h-4 w-4 transform rounded-full bg-white transition`} />
                        </Switch> Semua Hari
                    </div>
                    <div className=' col-span-1 flex items-center'>
                        <Switch checked={inputSunday} onChange={(val) => setInputSunday(val)} className={`${inputSunday ? 'bg-blue-600' : 'bg-gray-200'} relative inline-flex h-6 w-11 items-center rounded-full col-span-1 mr-2`}>
                            <span className="sr-only"></span>
                            <span aria-hidden="true" className={`${inputSunday ? 'translate-x-6' : 'translate-x-1'} inline-block h-4 w-4 transform rounded-full bg-white transition`} />
                        </Switch> Minggu
                    </div>
                    <div className=' col-span-1 flex items-center'>
                        <Switch checked={inputMonday} onChange={(val) => setInputMonday(val)} className={`${inputMonday ? 'bg-blue-600' : 'bg-gray-200'} relative inline-flex h-6 w-11 items-center rounded-full col-span-1 mr-2`}>
                            <span className="sr-only"></span>
                            <span aria-hidden="true" className={`${inputMonday ? 'translate-x-6' : 'translate-x-1'} inline-block h-4 w-4 transform rounded-full bg-white transition`} />
                        </Switch> Senin
                    </div>
                    <div className=' col-span-1 flex items-center'>
                        <Switch checked={inputTuesday} onChange={(val) => setInputTuesday(val)} className={`${inputTuesday ? 'bg-blue-600' : 'bg-gray-200'} relative inline-flex h-6 w-11 items-center rounded-full col-span-1 mr-2`}>
                            <span className="sr-only"></span>
                            <span aria-hidden="true" className={`${inputTuesday ? 'translate-x-6' : 'translate-x-1'} inline-block h-4 w-4 transform rounded-full bg-white transition`} />
                        </Switch> Selasa
                    </div>
                    <div className=' col-span-1 flex items-center'>
                        <Switch checked={inputWednesday} onChange={(val) => setInputWednesday(val)} className={`${inputWednesday ? 'bg-blue-600' : 'bg-gray-200'} relative inline-flex h-6 w-11 items-center rounded-full col-span-1 mr-2`}>
                            <span className="sr-only"></span>
                            <span aria-hidden="true" className={`${inputWednesday ? 'translate-x-6' : 'translate-x-1'} inline-block h-4 w-4 transform rounded-full bg-white transition`} />
                        </Switch> Rabu
                    </div>
                    <div className=' col-span-1 flex items-center'>
                        <Switch checked={inputThursday} onChange={(val) => setInputThursday(val)} className={`${inputThursday ? 'bg-blue-600' : 'bg-gray-200'} relative inline-flex h-6 w-11 items-center rounded-full col-span-1 mr-2`}>
                            <span className="sr-only"></span>
                            <span aria-hidden="true" className={`${inputThursday ? 'translate-x-6' : 'translate-x-1'} inline-block h-4 w-4 transform rounded-full bg-white transition`} />
                        </Switch> Kamis
                    </div>
                    <div className=' col-span-1 flex items-center'>
                        <Switch checked={inputFriday} onChange={(val) => setInputFriday(val)} className={`${inputFriday ? 'bg-blue-600' : 'bg-gray-200'} relative inline-flex h-6 w-11 items-center rounded-full col-span-1 mr-2`}>
                            <span className="sr-only"></span>
                            <span aria-hidden="true" className={`${inputFriday ? 'translate-x-6' : 'translate-x-1'} inline-block h-4 w-4 transform rounded-full bg-white transition`} />
                        </Switch> Jumat
                    </div>
                    <div className=' col-span-1 flex items-center'>
                        <Switch checked={inputSaturday} onChange={(val) => setInputSaturday(val)} className={`${inputSaturday ? 'bg-blue-600' : 'bg-gray-200'} relative inline-flex h-6 w-11 items-center rounded-full col-span-1 mr-2`}>
                            <span className="sr-only"></span>
                            <span aria-hidden="true" className={`${inputSaturday ? 'translate-x-6' : 'translate-x-1'} inline-block h-4 w-4 transform rounded-full bg-white transition`} />
                        </Switch> Sabtu
                    </div>
                </div>

            </InlineForm>
            <InlineForm title={'Semua Konsumen'} style={{ alignItems: "start" }}>
                <Switch checked={inputAllCustomer} onChange={(val) => setInputAllCustomer(val)} className={`${inputAllCustomer ? 'bg-blue-600' : 'bg-gray-200'} relative inline-flex h-6 w-11 items-center rounded-full col-span-1 mr-2`}>
                    <span className="sr-only"></span>
                    <span aria-hidden="true" className={`${inputAllCustomer ? 'translate-x-6' : 'translate-x-1'} inline-block h-4 w-4 transform rounded-full bg-white transition`} />
                </Switch>
            </InlineForm>
            <InlineForm title={'Semua Produk'} style={{ alignItems: "start" }}>
                <Switch checked={inputAllProduct} onChange={(val) => setInputAllProduct(val)} className={`${inputAllProduct ? 'bg-blue-600' : 'bg-gray-200'} relative inline-flex h-6 w-11 items-center rounded-full col-span-1 mr-2`}>
                    <span className="sr-only"></span>
                    <span aria-hidden="true" className={`${inputAllProduct ? 'translate-x-6' : 'translate-x-1'} inline-block h-4 w-4 transform rounded-full bg-white transition`} />
                </Switch>
            </InlineForm>
            <InlineForm title={'Jenis Diskon'} style={{ alignItems: "start" }}>
                <select

                    className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                    value={inputType}
                    onChange={(el) => {
                        setInputType(parseInt(el.target.value))
                        if (el.target.value == "1") {
                            setInputAmount(0)
                        } else {
                            setInputPercent(0)
                        }
                    }}
                >
                    <option value="1">Persen</option>
                    <option value="0">Jumlah (Uang)</option>
                </select>
            </InlineForm>
            {inputType == 0 ?
                <InlineForm title={'Nilai Diskon'} style={{ alignItems: "start" }}>
                    <input

                        className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                        value={inputAmount}
                        onChange={(el) => setInputAmount(parseInt(el.target.value))}
                    />

                </InlineForm>
                :
                <InlineForm title={'Nilai Diskon (%)'} style={{ alignItems: "start" }}>
                    <input

                        className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                        value={inputPercent}
                        onChange={(el) => setInputPercent(parseInt(el.target.value))}
                    />
                </InlineForm>
            }

            <InlineForm title={'Min Qty'} >
                <input

                    className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                    value={inputMinQty}
                    onChange={(el) => setInputMinQty(parseInt(el.target.value))}
                />
            </InlineForm>
            <InlineForm title={'Max Qty'} >
                <input

                    className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                    value={inputMaxQty}
                    onChange={(el) => setInputMaxQty(parseInt(el.target.value))}
                />
            </InlineForm>
        </ModalCustom>
        <ModalCustom
            footer={
                <div>
                    <button
                        type="button"
                        className="inline-flex justify-center items-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-xs font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                        onClick={async () => {
                            try {
                                setIsLoading(true)
                                await addUnit({ name: inputUnitName, description: inputUnitDescription })
                                getAllUnits()
                                setUnitOpen(false)
                                setInputUnitName("")
                                setInputUnitDescription("")
                            } catch (error) {
                                Swal.fire("Perhatian", `${error}`, "error")
                            } finally {
                                setIsLoading(false)
                            }
                        }}
                    >
                        <FolderIcon
                            className=" h-3 w-3 mr-1"
                            aria-hidden="true" />  Simpan
                    </button>
                </div>
            }
            title='Form Unit' isOpen={unitOpen} setIsOpen={setUnitOpen}>
            <InlineForm title={"Nama Unit"} className='mt-4'>
                <input
                    className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                    value={inputUnitName}
                    onChange={(el) => setInputUnitName(el.target.value)}
                />
            </InlineForm>
            <InlineForm title={"Keterangan"} style={{ alignItems: 'sZ' }}>
                <textarea
                    rows={5}
                    className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                    value={inputUnitDescription}
                    onChange={(el) => setInputUnitDescription(el.target.value)}
                />
            </InlineForm>
        </ModalCustom>
        <ModalCustom
            footer={
                <div>
                    <button
                        type="button"
                        className="inline-flex justify-center items-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-xs font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                        onClick={async () => {
                            try {
                                setIsLoading(true)
                                await addPackaging({ name: inputPackagingName, description: inputPackagingDescription })
                                getAllPackagings()
                                setPackagingOpen(false)
                                setInputPackagingName("")
                                setInputPackagingDescription("")
                            } catch (error) {
                                Swal.fire("Perhatian", `${error}`, "error")
                            } finally {
                                setIsLoading(false)
                            }
                        }}
                    >
                        <FolderIcon
                            className=" h-3 w-3 mr-1"
                            aria-hidden="true" />  Simpan
                    </button>
                </div>
            }
            title='Form Packaging' isOpen={packagingOpen} setIsOpen={setPackagingOpen}>
            <InlineForm title={"Nama Packaging"} className='mt-4'>
                <input
                    className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                    value={inputPackagingName}
                    onChange={(el) => setInputPackagingName(el.target.value)}
                />
            </InlineForm>
            <InlineForm title={"Keterangan"} style={{ alignItems: 'start' }}>
                <textarea
                    rows={5}
                    className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                    value={inputPackagingDescription}
                    onChange={(el) => setInputPackagingDescription(el.target.value)}
                />
            </InlineForm>
        </ModalCustom>
    </div>);


}
export default BusinessSetting;