import type { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import NotFound from '../pages/NotFound';
import Home from '../pages/Home';
import ProductPage from '../pages/ProductPage';
import StorePage from '../pages/StorePage';
import SalePage from '../pages/SalePage';
import BuyPage from '../pages/BuyPage';
import CashierPage from '../pages/CashierPage';
import SettingPage from '../pages/SettingPage';
import ProductDetail from '../pages/ProductDetail';
import StoreDetail from '../pages/StoreDetail';
import SaleDetail from '../pages/SaleDetail';
import BuyDetail from '../pages/BuyDetail';
import TransactionPage from '../pages/TransactionPage';
import DeliveryLetterPage from '../pages/DeliveryLetter';
import SalePrint from '../pages/SalePrint';
import BuyPrint from '../pages/BuyPrint';
import DeliveryLetterPrint from '../pages/DeliveryLetterPrint';
import CashierReport from '../pages/CashierReport';
import CashierReportPrint from '../pages/CashierReportPrint';
import PosReportReceipt from '../pages/PosReportReceipt';
import ProductSummary from '../pages/ProductSummary';

interface PrivateRoutesProps {}

const PrivateRoutes: FC<PrivateRoutesProps> = ({}) => {
        return (<Routes>
            <Route path="/" element={<Home />} />
            <Route path="/product" element={<ProductPage />} />
            <Route path="/product/:productId" element={<ProductDetail />} />
            <Route path="/store" element={<StorePage />} />
            <Route path="/store/:storeId" element={<StoreDetail />} />
            <Route path="/sale" element={<SalePage />} />
            <Route path="/sale/:saleId" element={<SaleDetail />} />
            <Route path="/sale/:saleId/print" element={<SalePrint />} />
            <Route path="/buy" element={<BuyPage />} />
            <Route path="/buy/:buyId" element={<BuyDetail />} />
            <Route path="/buy/:buyId/print" element={<BuyPrint />} />
            <Route path="/cashier" element={<CashierPage />} />
            <Route path="/cashier/report/:cashierReportId/print" element={<CashierReportPrint />} />
            <Route path="/transaction" element={<TransactionPage />} />
            <Route path="/cashier/report" element={<CashierReport />} />
            <Route path="/product/summary" element={<ProductSummary />} />
            <Route path="/delivery_letter/:deliveryLetterId" element={<DeliveryLetterPage />} />
            <Route path="/delivery_letter/:deliveryLetterId/print" element={<DeliveryLetterPrint />} />
            <Route path="/pos/:receiptId/receipt" element={<PosReportReceipt />} />
            <Route path="/setting" element={<SettingPage />} />
            <Route path="*" element={<NotFound />} />

        </Routes>);
}
export default PrivateRoutes;