import { COLORS } from '../../objects/constants';
import { ucFirst } from '../../utils/helper';
import { Dialog, Transition } from '@headlessui/react';
import { CheckBadgeIcon } from '@heroicons/react/24/outline';
import FolderIcon from '@heroicons/react/24/outline/FolderIcon';
import { Fragment, type FC, useState, useEffect } from 'react';
import { Tooltip, Whisper } from 'rsuite';

interface FormVarianProps {
    varianParameters: string[]
    modalVarianOpen: boolean
    setModalVarianOpen: (arg: boolean) => void
    onSubmit?: (a: string[]) => void
    onChange?: (a: string[], key: string) => void
    inputNewVarian: Record<string, string>
    setInputNewVarian: (arg: Record<string, string>) => void
}

const FormVarian: FC<FormVarianProps> = ({ varianParameters, modalVarianOpen, setModalVarianOpen, onSubmit, inputNewVarian, setInputNewVarian, onChange }) => {
    const [showPallete, setShowPallete] = useState(false);
    const [colorsSelected, setColorsSelected] = useState<string[]>([]);

    useEffect(() => {

    }, [colorsSelected]);

    return (<Transition appear show={modalVarianOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => {
            setModalVarianOpen(false)
            setColorsSelected([])
        }}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="fixed inset-0 bg-black/25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Dialog.Panel className="w-full max-w-2xl  max-h-screen transform overflow-hidden rounded-2xl bg-form-dialog p-6 text-left align-middle shadow-xl transition-all">
                            <Dialog.Title
                                as="h3"
                                className="text-xl font-medium leading-6 text-gray-900"
                            >
                                Tambah Varian
                            </Dialog.Title>
                            <div className="mt-4">
                                {varianParameters.map(e => <div key={e} className="flex  mb-6">
                                    <div className="w-1/3">
                                        <label
                                            className="block text-gray-900 mb-0 pr-4"

                                        >
                                            {ucFirst(e)}
                                        </label>
                                    </div>
                                    <div className="w-2/3 ">
                                        <div className='flex items-center'>
                                            <input
                                                className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"

                                                type="text"
                                                placeholder={e}
                                                value={inputNewVarian[e] ?? ""}
                                                onChange={(el) => {
                                                    let newVarian: Record<string, string> = { ...inputNewVarian }
                                                    newVarian[e] = el.target.value
                                                    setInputNewVarian({ ...newVarian })
                                                }}
                                            />

                                            {e.toLowerCase() == "warna" || e.toLowerCase() == "color" || e.toLowerCase() == "colour" ? <img
                                                onClick={() => setShowPallete(!showPallete)}
                                                src='/images/color-palette.png' className='w-5 h-5 ml-2 cursor-pointer' /> : null}
                                        </div>
                                        {showPallete && e.toLowerCase() == "warna" || e.toLowerCase() == "color" || e.toLowerCase() == "colour" ? <div className='flex flex-wrap max-h-60 overflow-y-auto mt-2'> {COLORS.map(c =>
                                            <Whisper key={c.name} followCursor speaker={<Tooltip>{c.name}</Tooltip>}>
                                                <div onClick={() => {
                                                    if (!colorsSelected.includes(c.name)) {
                                                        setColorsSelected([...colorsSelected, c.name])
                                                        if (onChange) onChange([...colorsSelected, c.name], e)
                                                    } else {
                                                        setColorsSelected([...colorsSelected.filter(d => d != c.name)])
                                                        if (onChange) onChange([...colorsSelected.filter(d => d != c.name)], e)
                                                    }

                                                }} className='w-10 h-10 m-1 cursor-pointer flex justify-center items-center ' style={{ backgroundColor: c.color }}>
                                                    {colorsSelected.includes(c.name) ? <CheckBadgeIcon className='w-5 h-5' /> : null}
                                                </div>
                                            </Whisper>

                                        )}  </div> : null}
                                    </div>
                                </div>)}




                            </div>

                            <div className="mt-4">
                                <button
                                    type="button"
                                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                    onClick={() => {
                                        if (onSubmit) onSubmit(colorsSelected)
                                    }}
                                >
                                    <FolderIcon
                                        className=" h-5 w-5 mr-2"
                                        aria-hidden="true" />  Simpan
                                </button>
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </div>
        </Dialog>
    </Transition>);
}
export default FormVarian;