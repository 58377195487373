import { Account } from '../../model/account';
import { LoadingContext } from '../../objects/loading_context';
import { getAccounts } from '../../repositories/account';
import { addTax } from '../../repositories/tax';
import { setNullString } from '../../utils/helper';
import { SelectOption, colourStyles } from '../../utils/style';
import { Dialog, Switch, Transition } from '@headlessui/react';
import FolderIcon from '@heroicons/react/24/outline/FolderIcon';
import { Fragment, useEffect, useState, type FC, useContext } from 'react';
import CurrencyInput from 'react-currency-input-field';
import Select, { SingleValue } from 'react-select';
import Swal from 'sweetalert2';


interface FormTaxProps {
    isOpen: boolean
    setIsOpen: (arg: boolean) => void
    onSubmit: () => void
}

const FormTax: FC<FormTaxProps> = ({
    isOpen,
    setIsOpen,
    onSubmit }) => {
    let { isLoading, setIsLoading } = useContext(LoadingContext);

    const [mounted, setMounted] = useState(false);
    const [inputName, setInputName] = useState("");
    const [inputCode, setInputCode] = useState("");
    const [inputAmount, setInputAmount] = useState(0);
    const [accountReceivables, setAccountReceivables] = useState<Account[]>([]);
    const [accountReceivableId, setAccountReceivableId] = useState("");
    const [accountReceivable, setAccountReceivable] = useState<SingleValue<SelectOption> | null>(null);
    const [accountReceivableSelect, setAccountReceivableSelect] = useState<SelectOption[]>([]);

    const [accountPayables, setAccountPayables] = useState<Account[]>([]);
    const [accountPayableId, setAccountPayableId] = useState("");
    const [accountPayable, setAccountPayable] = useState<SingleValue<SelectOption> | null>(null);
    const [accountPayableSelect, setAccountPayableSelect] = useState<SelectOption[]>([]);
    const [isIncomeTax, setIsIncomeTax] = useState(false);


    useEffect(() => {
        setMounted(true)

    }, []);

    useEffect(() => {
        if (!mounted) return
        getAllAccounts("Receivable", "")
        getAllAccounts("Liability", "")
    }, [mounted]);

    useEffect(() => {
        if (isIncomeTax) {
            setAccountPayableId("")
            setAccountPayable(null)
        } else {
            setAccountReceivableId("")
            setAccountReceivable(null)
        }

    }, [isIncomeTax]);




    const getAllAccounts = (type: string, search: string) => {
        getAccounts({ page: 1, limit: 5, type, search, isTax: true })
            .then(v => v.json())
            .then(v => {
                if (type == "Receivable") {
                    setAccountReceivables(v.data)
                } else {
                    setAccountPayables(v.data)
                }
            })
    }

    const clearForm = () => {
        setInputName("")
        setInputCode("")
        setInputAmount(0)
        setAccountReceivableId("")
        setAccountReceivable(null)
        setAccountPayableId("")
        setAccountPayable(null)
        setIsIncomeTax(false)
    }

    useEffect(() => {
        let cat: { value: string; label: string; }[] = []
        for (let index = 0; index < accountReceivables.length; index++) {
            const element = accountReceivables[index];
            cat.push({ value: element.uuid, label: element.name })
        }
        setAccountReceivableSelect(cat)
    }, [accountReceivables]);

    useEffect(() => {
        let cat: { value: string; label: string; }[] = []
        for (let index = 0; index < accountPayables.length; index++) {
            const element = accountPayables[index];
            cat.push({ value: element.uuid, label: element.name })
        }
        setAccountPayableSelect(cat)
    }, [accountPayables]);

    const save = async () => {
        try {
            setIsLoading(true)
            var resp = await addTax({
                name: inputName,
                code: inputCode,
                amount: inputAmount,
                account_receivable_id: setNullString(accountReceivableId),
                account_payable_id: setNullString(accountPayableId),
                is_inlet: isIncomeTax
            })

            if (onSubmit != null) onSubmit()
            clearForm()
            
        } catch (error) {
            Swal.fire("Perhatian", `${error}`, "error")
        } finally {
            setIsLoading(false)
        }

    }

    return (<Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setIsOpen(false)}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="fixed inset-0 bg-black/25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Dialog.Panel className="w-full flex flex-col max-w-2xl transform overflow-hidden rounded-2xl bg-form-dialog p-6 text-left align-middle shadow-xl transition-all" style={{ height: 'calc(100vh - 300px)' }}>
                            <Dialog.Title
                                as="h3"
                                className="text-xl font-medium leading-6 text-gray-900"
                            >
                                Tambah Pajak
                            </Dialog.Title>
                            <div className="flex-1">
                                <div className="flex items-center mb-6">
                                    <div className="w-1/3">
                                        <label
                                            className="block text-gray-900 mb-0 pr-4"
                                            htmlFor="product-name"
                                        >
                                            Nama
                                        </label>
                                    </div>
                                    <div className="w-2/3">
                                        <input
                                            className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                            id="product-name"
                                            type="text"
                                            placeholder="Nama"
                                            value={inputName}
                                            onChange={(e) => setInputName(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="flex items-center mb-6">
                                    <div className="w-1/3">
                                        <label
                                            className="block text-gray-900 mb-0 pr-4"
                                            htmlFor="product-name"
                                        >
                                            Kode
                                        </label>
                                    </div>
                                    <div className="w-2/3">
                                        <input
                                            className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                            id="product-name"
                                            type="text"
                                            placeholder="Kode"
                                            value={inputCode}
                                            onChange={(e) => setInputCode(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="flex items-center mb-6">
                                    <div className="w-1/3">
                                        <label
                                            className="block text-gray-900 mb-0 pr-4"
                                            htmlFor="product-name"
                                        >
                                            Pajak Masukan
                                        </label>
                                    </div>
                                    <div className="w-2/3">
                                        <Switch
                                            checked={isIncomeTax}
                                            onChange={setIsIncomeTax}
                                            className={`${isIncomeTax ? 'bg-blue-600' : 'bg-gray-200'
                                                } relative inline-flex h-6 w-11 items-center rounded-full`}
                                        >
                                            <span className="sr-only">Pajak Masukan</span>
                                            <span
                                                aria-hidden="true"
                                                className={`${isIncomeTax ? 'translate-x-6' : 'translate-x-1'
                                                    } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                            />
                                        </Switch>
                                    </div>
                                </div>
                                {isIncomeTax ?
                                    <div className="mt-4 ">
                                        <div className="flex items-center mb-6">
                                            <div className="w-1/3">
                                                <label className="block text-gray-900 mb-0 pr-4">
                                                    Akun Piutang
                                                </label>
                                            </div>
                                            <div className="w-2/3">
                                                <Select< SelectOption, false> styles={colourStyles} options={accountReceivableSelect} value={accountReceivable}
                                                    onChange={(option: SingleValue<SelectOption>): void => {
                                                        setAccountReceivableId(option?.value ?? "")
                                                        setAccountReceivable(option)
                                                    }}
                                                    onInputChange={(val) => getAllAccounts("Receivable", val)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="mt-4 ">
                                        <div className="flex items-center mb-6">
                                            <div className="w-1/3">
                                                <label className="block text-gray-900 mb-0 pr-4">
                                                    Akun Hutang
                                                </label>
                                            </div>
                                            <div className="w-2/3">
                                                <Select< SelectOption, false> styles={colourStyles} options={accountPayableSelect} value={accountPayable}
                                                    onChange={(option: SingleValue<SelectOption>): void => {
                                                        setAccountPayableId(option?.value ?? "")
                                                        setAccountPayable(option)
                                                        
                                                    }}
                                                    onInputChange={(val) => getAllAccounts("Liability", val)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="flex items-center mb-6">
                                    <div className="w-1/3">
                                        <label
                                            className="block text-gray-900 mb-0 pr-4"
                                            htmlFor="base-price"
                                        >
                                            Nilai (%)
                                        </label>
                                    </div>
                                    <div className="w-2/3">
                                        <CurrencyInput
                                            className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                            id="base-price"
                                            groupSeparator="."
                                            decimalSeparator=","
                                            placeholder="ex: 10%"
                                            onValueChange={(value, _, values) => setInputAmount(values?.float ?? 0)}
                                        />
                                    </div>
                                </div>

                            </div>

                            <div className="mt-4">
                                <button
                                    type="button"
                                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                    onClick={save}
                                >
                                    <FolderIcon
                                        className=" h-5 w-5 mr-2"
                                        aria-hidden="true" />  Simpan
                                </button>
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </div>
        </Dialog>
    </Transition>);
}
export default FormTax;