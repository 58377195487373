import { PriceCategory, PriceLevelData, Product, Unit } from '../../model/product';
import { addPriceCategory, getPriceCategories } from '../../repositories/product';
import { ucFirst } from '../../utils/helper';
import { money } from '../../utils/number';
import { SelectOption, colourStyles } from '../../utils/style';
import { Dialog, Switch, Transition } from '@headlessui/react';
import FolderIcon from '@heroicons/react/24/outline/FolderIcon';
import { Fragment, type FC, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { SingleValue } from 'react-select';
import Creatable from 'react-select/creatable';

interface FormPriceCategoryProps {
    product: Product | null
    modalPriceCategoryOpen: boolean
    setModalPriceCategoryOpen: (arg: boolean) => void
    priceCategorySelect: SelectOption[]
    priceSelectedIds: string[]
    setPriceCategorySelect: (arg: SelectOption[]) => void
    inputPriceCategory: SelectOption | null | undefined
    inputPriceCategoryId: string
    setInputPriceCategoryId: (arg: string) => void
    setInputPriceCategory: (arg: SingleValue<SelectOption>) => void
    setPriceCategories: (arg: PriceCategory[]) => void
    priceLevel: boolean
    setPriceLevel: (arg: boolean) => void
    inputAmountValue: number
    setInputAmountValue: (arg: number) => void
    priceLevelValues: PriceLevelData[]
    setPriceLevelValues: (arg: PriceLevelData[]) => void
    onSubmit: () => void
}

const FormPriceCategory: FC<FormPriceCategoryProps> = ({
    product,
    priceSelectedIds,
    modalPriceCategoryOpen,
    setModalPriceCategoryOpen,
    priceCategorySelect,
    inputPriceCategory,
    inputPriceCategoryId,
    setInputPriceCategoryId,
    setInputPriceCategory,
    setPriceCategories,
    priceLevel,
    setPriceLevel,
    inputAmountValue,
    setInputAmountValue,
    priceLevelValues,
    setPriceLevelValues,
    onSubmit,
    setPriceCategorySelect
}) => {
    return (<Transition appear show={modalPriceCategoryOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setModalPriceCategoryOpen(false)}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="fixed inset-0 bg-black/25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Dialog.Panel className="w-full  flex flex-col max-w-2xl transform overflow-hidden rounded-2xl bg-form-dialog p-6 text-left align-middle shadow-xl transition-all" style={{ height: 'calc(100vh - 300px)' }}>
                            <Dialog.Title
                                as="h3"
                                className="text-xl font-medium leading-6 text-gray-900"
                            >
                                Tambah Harga
                            </Dialog.Title>
                            <div className="flex-1 overflow-y-auto">
                                <div className="mt-4 ">
                                    <div className="flex items-center mb-6">
                                        <div className="w-1/3">
                                            <label className="block text-gray-900 mb-0 pr-4">
                                                Harga Dasar
                                            </label>
                                        </div>
                                        <div className="w-2/3">
                                            {money(product?.price ?? 0)}
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-4 ">
                                    <div className="flex items-center mb-6">
                                        <div className="w-1/3">
                                            <label className="block text-gray-900 mb-0 pr-4">
                                                Kategori 
                                            </label>
                                        </div>
                                        <div className="w-2/3">
                                            <Creatable< SelectOption, false> styles={colourStyles} options={priceCategorySelect.filter(e => !priceSelectedIds.includes(e.value))} value={inputPriceCategory}
                                                onChange={(option: SingleValue<SelectOption>): void => {
                                                    setInputPriceCategoryId(option?.value ?? "")
                                                    setInputPriceCategory(option)
                                                }}
                                                onCreateOption={(val) => {
                                                    addPriceCategory({ name: val }).then(v => getPriceCategories({ page: 1, limit: 1000 })
                                                        .then(v => v.json())
                                                        .then(v => {
                                                            setPriceCategories(v.data)
                                                        }))
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {inputPriceCategoryId ? <div>

                                    <div className="mt-4 mb-6 ">
                                        <div className="flex items-center">
                                            <div className="w-1/3">
                                                <label className="block text-gray-900 mb-0 pr-4">
                                                    Harga
                                                </label>
                                            </div>
                                            <div className="w-2/3">
                                                <CurrencyInput
                                                    className="bg-white appearance-none disabled:bg-gray-100 border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                                    id="price"
                                                    groupSeparator="."
                                                    decimalSeparator=","
                                                    placeholder="Harga"
                                                    disabled={priceLevel}
                                                    onValueChange={(value, _, values) => setInputAmountValue(values?.float ?? 0)}
                                                />
                                            </div>
                                        </div>
                                        <div className="flex items-center ">
                                            <div className="w-1/3"></div>
                                            <div className="w-2/3">
                                                {inputAmountValue > 0 ? <span className="text-xs text-gray-400">margin {money(inputAmountValue - (product?.price ?? 0))}</span> : null}

                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-4 mb-6 ">
                                        <div className="flex items-center">
                                            <div className="w-1/3">
                                                <label className="block text-gray-900 mb-0 pr-4">
                                                    Harga Bertingkat
                                                </label>
                                            </div>
                                            <div className="w-2/3">
                                                <Switch
                                                    checked={priceLevel}
                                                    onChange={setPriceLevel}
                                                    className={`${priceLevel ? 'bg-blue-600' : 'bg-gray-200'
                                                        } relative inline-flex h-6 w-11 items-center rounded-full`}
                                                >
                                                    <span className="sr-only">Harga Bertingkat</span>
                                                    <span
                                                        aria-hidden="true"
                                                        className={`${priceLevel ? 'translate-x-6' : 'translate-x-1'
                                                            } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                                    />
                                                </Switch>

                                            </div>
                                        </div>
                                        {priceLevel ? <div className="flex items-center mt-2">
                                            <div className="w-1/3">
                                            </div>
                                            <div className="w-2/3">
                                                <ul>
                                                    {priceLevelValues.map(e =>
                                                        <li className="flex mb-2" key={`price-level-${priceLevelValues.indexOf(e)}`}>
                                                            <div className="w-1/2 mr-2">
                                                                <small className="mb-1 ml-1 block">Qty min</small>
                                                                <CurrencyInput
                                                                    className="bg-white appearance-none disabled:bg-gray-100 border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                                                    groupSeparator="."
                                                                    decimalSeparator=","
                                                                    placeholder="min"
                                                                    readOnly={priceLevelValues.indexOf(e) == 0}
                                                                    onValueChange={(value, _, values) => {
                                                                        let priceLevelData = [...priceLevelValues]
                                                                        priceLevelData[priceLevelValues.indexOf(e)].min = values?.float ?? 0
                                                                        setPriceLevelValues(priceLevelData)
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="w-1/2">
                                                                <small className="mb-1 ml-1 block">Harga</small>
                                                                <CurrencyInput
                                                                    className="bg-white appearance-none disabled:bg-gray-100 border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                                                    groupSeparator="."
                                                                    decimalSeparator=","
                                                                    placeholder="Harga"

                                                                    onValueChange={(value, _, values) => {
                                                                        let priceLevelData = [...priceLevelValues]
                                                                        priceLevelData[priceLevelValues.indexOf(e)].amount = values?.float ?? 0
                                                                        setPriceLevelValues(priceLevelData)
                                                                    }}
                                                                />
                                                                {priceLevelValues[priceLevelValues.indexOf(e)].amount > 0 ? <span className="text-xs text-gray-400">margin {money(priceLevelValues[priceLevelValues.indexOf(e)].amount - (product?.price ?? 0))}</span> : null}
                                                            </div>

                                                        </li>
                                                    )}
                                                    <li className="flex justify-center mt-4 text-xs text-blue-500 hover:text-blue-800 cursor-pointer" onClick={() => {
                                                        let priceLevelData = [...priceLevelValues]
                                                        let newMin = priceLevelData[priceLevelData.length - 1].min + 1
                                                        let newAmount = priceLevelData[priceLevelData.length - 1].amount - 1
                                                        priceLevelData.push({ min: newMin, amount: newAmount })
                                                        setPriceLevelValues(priceLevelData)
                                                    }}>
                                                        + Tambah Harga
                                                    </li>
                                                </ul>
                                            </div>
                                        </div> : null}

                                    </div>
                                </div> : null}
                            </div>

                            <div className="mt-4">
                                <button
                                    type="button"
                                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                    onClick={onSubmit}
                                >
                                    <FolderIcon
                                        className=" h-5 w-5 mr-2"
                                        aria-hidden="true" />  Simpan
                                </button>
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </div>
        </Dialog>
    </Transition>);
}
export default FormPriceCategory;