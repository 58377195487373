import CustomTable from '../components/custom_table';
import DashboardLayout from '../components/dashboard';
import InlineForm from '../components/inline_form';
import ModalCustom from '../components/modals/modal_custom';
import { Account } from '../model/account';
import { Pagination } from '../model/pagination';
import { MerchantSaleReport } from '../model/pos';
import { Transaction } from '../model/transaction';
import { getAccounts } from '../repositories/account';
import { getReportDetail, getReportDownload } from '../repositories/pos';
import { addFileTransaction, addTransaction, deleteTransaction, editTransaction, getTransactions } from '../repositories/transaction';
import { confirmDelete, setNullString } from '../utils/helper';
import { money } from '../utils/number';
import { FunnelIcon, TrashIcon, XMarkIcon } from '@heroicons/react/24/outline';
import saveAs from 'file-saver';
import { useContext, useEffect, useRef, useState, type FC } from 'react';
import { FaCloudDownloadAlt, FaFileUpload, FaPencilAlt } from 'react-icons/fa';
import Moment from 'react-moment';
import { Avatar, AvatarGroup, Badge, Button, DatePicker, DateRangePicker, Drawer, Placeholder } from 'rsuite';
import Select, { SingleValue } from 'react-select';
import { SelectOption, colourStyles } from '../utils/style';
import CurrencyInput from 'react-currency-input-field';
import { FaPaperclip, FaPenClip, FaRegFloppyDisk } from 'react-icons/fa6';
import { LoadingContext } from '../objects/loading_context';
import Swal from 'sweetalert2';
import moment from 'moment';
import { fileUpload } from '../utils/file';
import { DateRange } from 'rsuite/esm/DateRangePicker';
import { useNavigate } from 'react-router-dom';

interface TransactionPageProps {}

const TransactionPage: FC<TransactionPageProps> = ({}) => {
    let { isLoading, setIsLoading } = useContext(LoadingContext);
    const [selectedMenu, setSelectedMenu] = useState("company");

    const [openWithHeader, setOpenWithHeader] = useState(false);
    const [mounted, setMounted] = useState(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(20);
    const [search, setSearch] = useState("");
    const [pagination, setPagination] = useState<Pagination | null>(null);
    const [transactions, setTransactions] = useState<Transaction[]>([]);
    const [filterSidebarOpen, setFilterSidebarOpen] = useState(false);
    const nav = useNavigate()
    const [selectReport, setSelectReport] = useState<MerchantSaleReport | null>(null);
    const [modalReportDetail, setModalReportDetail] = useState(false);
    const [isIncome, setIsIncome] = useState(false);
    const [accountCategories, setAccountCategories] = useState<Account[]>([]);
    const [accountAssets, setAccountAssets] = useState<Account[]>([]);
    const [amountMounted, setAmountMounted] = useState(true);
    const [amount, setAmount] = useState(0);
    const [accountCat, setAccountCat] = useState<Account | null>(null);
    const [accountAsset, setAccountAsset] = useState<Account | null>(null);
    const [inputDescription, setInputDescription] = useState("");
    const [date, setDate] = useState<Date>(moment().toDate());
    const fileRef = useRef<HTMLInputElement>(null)
    
    const [selectedTransaction, setSelectedTransaction] = useState<Transaction | null>(null);
    const [dateRange, setDateRange] = useState<DateRange | null>(null);
    const [allAccounts, setAllAccounts] = useState<Account[]>([]);
    const [selectAccount, setSelectAccount] = useState<Account | null>(null);
    const [editingTransaction , setEditingTransaction] = useState<Transaction|null>(null);
    useEffect(() => {
        setMounted(true)
    }, []);

    useEffect(() => {
        if (!mounted) return
        getAllTransactions()
        getCatAccounts("Expense", "")
        getAccounts({ page: 1, limit: 5, type: "Asset", cashflowSubgroup: "cash_bank" })
            .then(v => v.json()).then(v => setAccountAssets(v.data))

    }, [mounted]);

    useEffect(() => {
        getAllTransactions()

    }, [page, search, limit, dateRange, selectAccount]);

    const getCatAccounts = (accountType: string, search: string) => {
        getAccounts({ page: 1, limit: 5, type: accountType, search })
            .then(v => v.json()).then(v => setAccountCategories(v.data))
    }



    const getAllTransactions = () => {
        getTransactions({ page, limit, search }, dateRange, selectAccount?.uuid)
            .then(v => v.json())
            .then(v => {
                setTransactions(v.data)
                setPagination(v.meta)
            })
    }
    useEffect(() => {
        if (selectReport) {
            setModalReportDetail(true)
        }
    }, [selectReport]);

    const clearForm = () => {
        setEditingTransaction(null)
        setAmountMounted(false)
        setAmount(0)
        setAccountCat(null)
        setAccountAsset(null)
        setInputDescription("")
        setDate(moment().toDate())
        setTimeout(() => {
            setAmountMounted(true)
        }, 50);
    }

    const saveTransaction = async () => {

        try {
            if (!accountCat || !accountAsset) {
                throw new Error("Pilih akun / kategori terlebih dahulu")
            }
            if (amount == 0) {
                throw new Error("isi nominal transaksi")
            }
            setIsLoading(true)
            
            if (editingTransaction) {
                await editTransaction( editingTransaction.uuid, {
                    description: inputDescription,
                    notes: editingTransaction.notes,
                    credit: isIncome ? amount : 0,
                    debit: !isIncome ? amount : 0,
                    amount: amount,
                    date: date.toISOString(),
                    is_income: isIncome,
                    is_expense: !isIncome,
                    is_journal: editingTransaction.is_journal,
                    is_account_receivable: editingTransaction.is_account_receivable,
                    is_account_payable: editingTransaction.is_account_payable,
                    account_source_id: setNullString(accountCat?.uuid),
                    account_destination_id: setNullString(accountAsset?.uuid),
                    journal_id: setNullString(editingTransaction.journal_id),
                    invoice_id: setNullString(editingTransaction.invoice_id),
                    process_id: setNullString(editingTransaction.process_id),
                    process_item_id: setNullString(editingTransaction.process_item_id),
                    process_output_id: setNullString(editingTransaction.process_output_id),
                    bill_id: setNullString(editingTransaction.bill_id),
                    is_invoice_payment: editingTransaction.is_invoice_payment,
                    is_bill_payment: editingTransaction.is_bill_payment,
                    employee_id: setNullString(editingTransaction.employee_id),
                    account_source_type: editingTransaction.account_source_type,
                    account_destination_type: editingTransaction.account_destination_type,
                    account_payment_debt_id: setNullString(editingTransaction.account_payment_debt_id),
                    merchant_sale_report_id: setNullString(editingTransaction.merchant_sale_report_id),
                    tax_payment_id: setNullString(editingTransaction.tax_payment_id),
                    pay_roll_id: setNullString(editingTransaction.pay_roll_id),
                    reimbursement_id: setNullString(editingTransaction.reimbursement_id),
                    is_source_tax: editingTransaction.is_source_tax,
                    is_destination_tax: editingTransaction.is_destination_tax,
                    pay_roll_payable_id: setNullString(editingTransaction.pay_roll_payable_id),
                    product_serial_number_id: setNullString(editingTransaction.product_serial_number_id),
                    is_secondary_tax: editingTransaction.is_secondary_tax
                })
            } else {
                await addTransaction( {
                    description: inputDescription,
                    notes: '',
                    credit: isIncome ? amount : 0,
                    debit: !isIncome ? amount : 0,
                    amount: amount,
                    date: date.toISOString(),
                    is_income: isIncome,
                    is_expense: !isIncome,
                    is_journal: false,
                    is_account_receivable: false,
                    is_account_payable: false,
                    account_source_id: setNullString(accountCat?.uuid),
                    account_destination_id: setNullString(accountAsset?.uuid),
                    journal_id: setNullString(""),
                    invoice_id: setNullString(""),
                    process_id: setNullString(""),
                    process_item_id: setNullString(""),
                    process_output_id: setNullString(""),
                    bill_id: setNullString(""),
                    is_invoice_payment: false,
                    is_bill_payment: false,
                    employee_id: setNullString(""),
                    account_source_type: '',
                    account_destination_type: '',
                    account_payment_debt_id: setNullString(""),
                    merchant_sale_report_id: setNullString(""),
                    tax_payment_id: setNullString(""),
                    pay_roll_id: setNullString(""),
                    reimbursement_id: setNullString(""),
                    is_source_tax: false,
                    is_destination_tax: false,
                    pay_roll_payable_id: setNullString(""),
                    product_serial_number_id: setNullString(""),
                    is_secondary_tax: false
                })
            }
             
            Swal.fire("Perhatian", editingTransaction ? `Transaksi telah diupdate` : `Transaksi telah ditambahkan`, "success")
            clearForm()
            getAllTransactions()
            
        } catch (error) {
            Swal.fire("Perhatian", `${error}`, "error")
        } finally {
            setIsLoading(false)
        }
    }

    const transactionTable = () => (<div className='col-span-3'>
        <div className="mb-5  rounded-xl py-6 px-6 transition-all hover:shadow-lg sm:p-4 lg:px-4 xl:px-6 bg-white cursor-pointer" >
            <CustomTable
                searchPlaceholder='Cari Transaksi'
                onSearch={(val) => {
                    setSearch(val)
                }}
                switchHeader
                pagination
                total={pagination?.total_records}
                limit={limit}
                activePage={page}
                setActivePage={(val) => {
                    setPage(val)
                }}
                changeLimit={(val) => setLimit(val)}
                limitPaginations={[10, 20, 50, 100, 1000]}
                searchHeader={
                    <div></div>
                }
                headers={["#", "Tgl", "Keterangan", "Kategori", "Akun", "Jumlah", ""]}
                headerClasses={["w-10", "w-24", "", "", "", "text-right", "w-10 text-right"]}
                datasets={transactions.map(e => {
                    return {
                        cells: [
                            { data: ((page - 1) * limit) + (transactions.indexOf(e) + 1) },
                            {
                                data: <div className='flex flex-col'>
                                    <Moment className="text-sm" format="DD/MM/YYYY">{e.date}</Moment>
                                    <Moment className="text-xs" format="HH:mm">{e.date}</Moment>
                                </div>
                            },
                            {
                                data: <div className='flex justify-between items-center'> <span className='flex flex-col hover:font-semibold' onClick={() => {
                                    if (e.bill_id) {
                                        nav(`/buy/${e.bill_id}`)
                                    }
                                    if (e.invoice_id) {
                                        nav(`/sale/${e.invoice_id}`)
                                    }
                                    if (e.merchant_sale_report_id && e.merchant_id) {
                                        console.log(e.merchant_id)
                                        getReportDetail(e.merchant_sale_report_id, e.merchant_id)
                                            .then(v => v.json())
                                            .then(v => setSelectReport(v.data))
                                    }

                                }}>

                                    {e.description}
                                    {e.bill_id ? <span> <Badge color='red' content="Pembelian"></Badge> </span> : null}
                                    {e.invoice_id ? <span> <Badge color='green' content="Penjualan"></Badge> </span> : null}
                                </span>

                                    <AvatarGroup stack>
                                        {e.images
                                            .filter((item, i) => i < 3)
                                            .map(item => (

                                                <Avatar size={'sm'} bordered circle key={item.uuid} src={item.url} alt={item.description} />

                                            ))}
                                        {e.images.length - 3 > 0 &&
                                            <Avatar size={'sm'} bordered circle style={{ background: '#111' }}>
                                                +{e.images.length - 3}
                                            </Avatar>
                                        }
                                    </AvatarGroup>

                                </div>
                            },
                            {
                                data: <span className=' hover:font-semibold' onClick={() => {
                                    if (e.account_source_id) {
                                      nav(`/report/account?account_id=${e.account_source_id}`)
                                    }
                                }}>

                                    {e.account_source_name}
                                </span>
                            },
                            { data: <span className=' hover:font-semibold' onClick={() => {
                                if (e.account_destination_id) {
                                    nav(`/report/account?account_id=${e.account_destination_id}`)
                                  }
                            }}>

                                {e.account_destination_name}
                            </span> },
                            { data: <div>{money(e.amount)}</div>, className: "text-right" },
                            {
                                data: <div >

                                    {e.is_editable && <div className='flex'>
                                        
                                        <FaPencilAlt onClick={() => {
                                            document.getElementById('dashboard-content-wrapper')?.scrollTo({top: 0,  behavior: 'smooth'})
                                            setEditingTransaction(e)
                                            setDate(moment(e.date).toDate())
                                            setInputDescription(e.description)
                                            setAmount(e.is_income ? e.credit : e.debit)
                                            setIsIncome(e.is_income) 
                                            setAccountCat({
                                                uuid: e.account_source_id,
                                                name: e.account_source_name,
                                                type: e.account_source_type ,
                                                category: "",
                                                cashflow_group: "",
                                                cashflow_subgroup:"" ,
                                                code: "",
                                                credit: 0 ,
                                                debit: 0,
                                                balance: 0,
                                                is_tax: false,
                                                is_deletable: false,
                                                cashflow_group_label:"" ,
                                                cashflow_subgroup_label: "" ,
                                            })
                                            setAccountAsset({
                                                uuid: e.account_destination_id,
                                                name: e.account_destination_name,
                                                type: e.account_destination_type ,
                                                category: "",
                                                cashflow_group: "",
                                                cashflow_subgroup:"" ,
                                                code: "",
                                                credit: 0 ,
                                                debit: 0,
                                                balance: 0,
                                                is_tax: false,
                                                is_deletable: false,
                                                cashflow_group_label:"" ,
                                                cashflow_subgroup_label: "" ,
                                            })
                                            setAmountMounted(false)
                                            setTimeout(() => {
                                                setAmountMounted(true)
                                            }, 300);
                                            
                                        }} className=" cursor-pointer w-4 h-4 text-blue-400 hover:text-blue-600 ml-2" />
                                        <FaPaperclip onClick={() => {
                                            setSelectedTransaction(e)
                                            setTimeout(() => {
                                                fileRef.current?.click()
                                            }, 300);
                                        }} className=" cursor-pointer w-4 h-4 text-blue-400 hover:text-blue-600 ml-2" />
                                        <TrashIcon className=" cursor-pointer w-4 h-4 text-red-400 hover:text-red-600 ml-2" onClick={() => confirmDelete(() => {
                                            deleteTransaction(e.uuid).then(v => getAllTransactions())
                                        })} />
                                    </div>}
                                </div>, className: "text-right"
                            },
                        ],
                        className: `last:border-0 hover:bg-gray-50 ${e.is_editable && e.is_income ? ' bg-green-50':""} ${e.is_editable && e.is_expense ? ' bg-red-50':""}`
                    }
                })} />
        </div>
    </div>)

    const rightSide = () => (
        <div className='col-span-1'>
            <div className="mb-5  rounded-xl py-6 px-6 transition-all hover:shadow-lg sm:p-4 lg:px-4 xl:px-6 bg-white cursor-pointer" >
                <h3 className=" xl:text-2xl md:text-lg ">Form Transaksi</h3>
                <input value={""} type='file' id='file' accept='image/*' ref={fileRef} style={{ display: 'none' }} onChange={async (e) => {
                    if (!selectedTransaction) return
                    try {
                        setIsLoading(true)
                        var resp = await fileUpload(`File/ImageUpload`, {
                            image: e.target.files![0],
                            dir: 'product',
                        })

                        let respJson = await resp.json()
                        await addFileTransaction(selectedTransaction?.uuid, respJson.data.path)
                        getAllTransactions()
                    } catch (error) {
                        Swal.fire("Perhatian", `${error}`, "error")
                    } finally {
                        setIsLoading(false)
                    }
                }} />
                <InlineForm title={'Tgl'} className='mt-4'>
                    <DatePicker value={date} block format="dd/MM/yyyy HH:mm" onChangeCalendarDate={(date) => {
                        setDate(date)
                    }} />
                </InlineForm>
                <InlineForm title={'Tipe'} className='mt-4'>
                    <Select< SelectOption, false> styles={colourStyles}
                        options={[{ value: "1", label: "Pemasukan" }, { value: "0", label: "Pengeluaran" }]}
                        value={isIncome ? { value: "1", label: "Pemasukan" } : { value: "0", label: "Pengeluaran" }}
                        onChange={(option: SingleValue<SelectOption>): void => {
                            setIsIncome(option?.value == "1" ? true : false)
                            getCatAccounts(option?.value == "1" ? "Income" : "Expense", "")
                            setAccountCat(null)
                        }} />
                </InlineForm>
                <InlineForm title={'Kategori'}>
                    <Select< SelectOption, false> styles={colourStyles}
                        options={accountCategories.map(e => ({ value: e.uuid, label: e.name }))}
                        value={accountCat ? { value: accountCat.uuid, label: accountCat.name } : null}
                        onChange={(option: SingleValue<SelectOption>): void => {
                            setAccountCat(accountCategories.find(e => e.uuid == option?.value) ?? null)
                        }} onInputChange={(val) => {
                            if (val) getCatAccounts(isIncome ? "Income" : "Expense", val)
                        }} />
                </InlineForm>
                <InlineForm title={'Akun'}>
                    <Select< SelectOption, false> styles={colourStyles}
                        options={accountAssets.map(e => ({ value: e.uuid, label: e.name }))}
                        value={accountAsset ? { value: accountAsset.uuid, label: accountAsset.name } : null}
                        onChange={(option: SingleValue<SelectOption>): void => {
                            setAccountAsset(accountAssets.find(e => e.uuid == option?.value) ?? null)
                            // console.log(option)
                        }} onInputChange={(val) => {
                            if (val) getAccounts({ page: 1, limit: 5, type: "Asset", cashflowSubgroup: "cash_bank", search: val })
                                .then(v => v.json()).then(v => setAccountAssets(v.data))
                        }} />
                </InlineForm>
                <InlineForm title={'Jumlah'}>
                    {amountMounted &&
                        <CurrencyInput
                            className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                            groupSeparator="."
                            decimalSeparator=","
                            placeholder="Jumlah"
                            defaultValue={amount}
                            onValueChange={(value, _, values) => {
                                setAmount(values?.float ?? 0)
                            }}
                        />
                    }
                </InlineForm>
                <InlineForm title={'Keterangan'} style={{ alignItems: 'start' }}>
                    <textarea
                        className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                        id="sku"
                        rows={5}
                        placeholder="Keterangan"
                        value={inputDescription}
                        onChange={(e) => setInputDescription(e.target.value)}
                    />
                </InlineForm>
                <div className='mt-4 flex justify-end'>
                    <button
                        type="button"
                        className="inline-flex justify-center items-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-sm font-medium  text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                        onClick={saveTransaction}
                    >
                        <FaRegFloppyDisk
                            className=" h-3 w-3 mr-1"
                            aria-hidden="true" />  Simpan
                    </button>
                </div>
            </div>
            {/* <div className="mb-5  rounded-xl py-6 px-6 transition-all hover:shadow-lg sm:p-4 lg:px-4 xl:px-6 bg-white cursor-pointer" >

            </div> */}
        </div>

    )

    return ( <DashboardLayout  permission='read_transaction'>
        <div className="p-6">
            <div className="flex justify-between items-center">
                <h1 className="text-4xl">Transaksi</h1>
                <div className="flex  items-center ">
                    <Button className="flex  bg-white px-4 py-2 text-sm font-medium ml-2" onClick={() => setFilterSidebarOpen(true)}>
                        <FunnelIcon
                            className="cursor-pointer z-50  h-5 w-5"
                        />
                        Filter
                    </Button>
                </div>
            </div>
            <div className="mt-6 grid gap-x-8 gap-y-4 sm:gap-y-0 xl:grid-cols-4 lg:grid-cols-4 sm:grid-cols-2 md:grid-cols-2 ">
                {transactionTable()}
                {rightSide()}
            </div>
        </div>
        <Drawer open={openWithHeader} onClose={() => setOpenWithHeader(false)}>
            <Drawer.Header>
                <Drawer.Title>Form Transaksi</Drawer.Title>
                <Drawer.Actions>
                    {/* <Button onClick={() => setOpenWithHeader(false)}>Cancel</Button>
                    <Button onClick={() => setOpenWithHeader(false)} appearance="primary">
                        Confirm
                    </Button> */}
                </Drawer.Actions>
            </Drawer.Header>
            <Drawer.Body>
                <Placeholder.Paragraph />
            </Drawer.Body>
        </Drawer>
        <Drawer open={filterSidebarOpen} onClose={() => setFilterSidebarOpen(false)}>
            <Drawer.Header>
                <Drawer.Title>Filter Transaksi</Drawer.Title>
                <Drawer.Actions>
                    <Button onClick={() => {
                        setDateRange(null)
                        setSelectAccount(null)
                        //   setVendorIds(null)
                        //   setSelectedVendors([])

                    }}><XMarkIcon className="w-5" /> Clear Filter</Button>
                </Drawer.Actions>
            </Drawer.Header>
            <Drawer.Body>
                <InlineForm title="Rentang Tanggal">
                    <DateRangePicker placement="bottomEnd" value={dateRange} block format="dd/MM/yyyy" onOk={(val: DateRange) => {
                        setPage(1)
                        setTimeout(() => {
                            setDateRange(val)
                        }, 300);
                    }} />
                </InlineForm>
                <InlineForm title="Akun">
                    <Select< SelectOption, false> styles={colourStyles}
                        options={allAccounts.map(e => ({ value: e.uuid, label: e.name }))}
                        value={selectAccount ? { value: selectAccount.uuid, label: selectAccount.name } : null}
                        onChange={(option: SingleValue<SelectOption>): void => {
                            setSelectAccount(allAccounts.find(e => e.uuid == option?.value) ?? null)
                        }} onInputChange={(val) => {
                            if (val) getAccounts({ page: 1, limit: 5, search: val })
                                .then(v => v.json()).then(v => setAllAccounts(v.data))
                        }}/>
                </InlineForm>
            </Drawer.Body>
        </Drawer>
        <ModalCustom
            footer={selectReport != null &&
                <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={() => getReportDownload(selectReport!.uuid)
                        .then(v => v.blob())
                        .then(v => {
                            saveAs(v, `${selectReport!.title}.xlsx`)
                        })}>
                    <FaCloudDownloadAlt
                        className=" h-5 w-5 mr-2"
                        aria-hidden="true" />  Simpan
                </button>
            }
            title={selectReport?.title ?? "Detail Laporan"} isOpen={modalReportDetail} setIsOpen={setModalReportDetail} onClose={() => setSelectReport(null)}  >
            <CustomTable className='mb-4 mt-4' headers={["No", "Kode", "Total", "Pembayaran"]} headerClasses={["", "", "text-right"]}
                footer={[
                    {
                        cells: [{ data: "" },
                        { data: <div className=' font-semibold'>Total Penjualan CASH</div> },
                        { data: money(selectReport?.sales.filter(e => e.payment_method == "CASH").map(e => e.grand_total).reduce((a, b) => a + b, 0)), className: "text-right" },
                        { data: "" }]
                    },
                    {
                        cells: [{ data: "" },
                        { data: <div className=' font-semibold'>Total Penjualan NON CASH</div> },
                        { data: money(selectReport?.sales.filter(e => e.payment_method != "CASH").map(e => e.grand_total).reduce((a, b) => a + b, 0)), className: "text-right" },
                        { data: "" }]
                    },
                    {
                        cells: [{ data: "" },
                        { data: <div className=' font-semibold'>Total Penjualan</div> },
                        { data: money(selectReport?.sales.map(e => e.grand_total).reduce((a, b) => a + b, 0)), className: "text-right" },
                        { data: "" }]
                    },
                ]}
                datasets={(selectReport?.sales ?? []).map(e => {
                    return {
                        cells: [
                            { data: (selectReport?.sales ?? []).indexOf(e) + 1 },
                            {
                                data: <div className=' hover:font-semibold' onClick={() => {
                                    window.open(`/pos/${e.uuid}/receipt`)
                                }}>{e.code}</div>, className: " cursor-pointer"
                            },
                            { data: money(e.grand_total), className: "text-right" },
                            { data: e.payment_method }
                        ],
                        className: "hover:bg-gray-50"
                    }
                })} />
        </ModalCustom>
    </DashboardLayout>);
}
export default TransactionPage;