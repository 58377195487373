import { NullString } from "../objects/null_string"
import { Discount } from "./discount"
import { Merchant } from "./merchant"
import { Product, VarianStock } from "./product"
import { parseVarian, setNullString } from "../utils/helper"
import { Tax } from "./tax"

export interface MerchantSale {
  uuid?: string
  cashier_id: string
  machine_id: string
  merchant_id?: string | null
  merchant_sale_report_id?: string
  cashier_name?: string
  code: string
  order_number?: number
  customer_id?: string
  customer_name?: string
  flag?: boolean
  reported?: boolean
  notes?: string
  table_number?: string
  date: string
  subtotal: number
  discount_total: number
  total: number
  tax_amount: number
  total_tax: number
  tax_name: string
  count_item: number
  grand_total: number
  change: number
  payment_amount: number
  payment_method: string
  account_destination_name?: string
  payment_provider: string
  account_destination_id: string
  items: MerchantSaleItem[]
}


export interface MerchantSaleItem {
  uuid?: string | null
  uniqueId: string
  product: Product
  isService: boolean
  varian?: VarianStock | null
  discount?: Discount | null
  item_id?: string
  qty: number
  description?: string
  price: number
  price_before_tax?: number
  sku?: string
  barcode?: string
  discount_name?: string
  discount_percent?: number
  discount_amount?: number
  discount_qty?: number
  discount_min_qty?: number
  discount_max_qty?: number
  discount_type?: number
  discount_id?: string
  tax_id?: string
  tax?: Tax | null
  tax_amount?: number
  tax_percent?: number
  product_id?: string
  service_id?: string
  product_serial_number_id?: string
  varian_id?: string
  unit_id?: string
  unit_name?: string
  unit_value?: number
  total_discount?: number
  total_bruto?: number
  amount?: number
}


export interface MerchantSaleReq {
  cashier_id: NullString
  machine_id: NullString
  cashier_name: string
  code: string
  customer_id: NullString
  customer_name: string
  notes: string
  date: string
  subtotal: number
  discount_total: number
  total: number
  tax_amount: number
  total_tax: number
  tax_name: string
  count_item: number
  grand_total: number
  change: number
  payment_amount: number
  payment_method: string
  payment_provider: string
  account_destination_id: NullString
  items: MerchantSaleItemReq[]
}



export interface MerchantSaleItemReq {
  item_id: NullString
  qty: number
  description: string
  sku?: string
  barcode?: string
  price: number
  discount_percent: number
  discount_amount: number
  discount_id: NullString
  tax_id: NullString
  tax_amount: number
  tax_percent: number
  product_id: NullString
  service_id: NullString
  product_serial_number_id: NullString
  varian_id: NullString
  unit_id: NullString
  total_discount: number
  total_bruto: number
  amount: number
}

export const merchantSaleToReq = (req: MerchantSale): MerchantSaleReq => ({
  cashier_id: setNullString(req.cashier_id),
  machine_id: setNullString(req.machine_id),
  cashier_name: req.cashier_name!,
  code: req.code,
  customer_id: setNullString(req.customer_id),
  customer_name: req.customer_name ?? "",
  notes: req.notes ?? "",
  date: req.date,
  subtotal: req.subtotal,
  discount_total: req.discount_total,
  total: req.total,
  tax_amount: req.tax_amount,
  total_tax: req.total_tax,
  tax_name: req.tax_name,
  count_item: req.count_item,
  grand_total: req.grand_total,
  change: req.change,
  payment_amount: req.payment_amount,
  payment_method: req.payment_method,
  payment_provider: req.payment_provider,
  account_destination_id: setNullString(req.account_destination_id),
  items: req.items.map(e => ({
    item_id: setNullString(e.item_id),
    qty: e.qty,
    description: `${e.product.name}${e.varian ? ' ' + parseVarian(e.varian.data, true) : ''}`,
    price: e.price,
    price_before_tax: e.price_before_tax,
    sku: e.product.sku,
    barcode: e.product.barcode,
    discount_percent: e.discount?.percent ?? 0,
    discount_amount: e.discount?.amount ?? 0,
    discount_name: e.discount?.name ?? "",
    discount_id: setNullString(e.discount?.uuid),
    tax_id: setNullString(e.tax_id),
    tax_amount: e.tax_amount ?? 0,
    tax_percent: e.tax?.amount ?? 0,
    product_id: setNullString(e.product!.uuid),
    service_id: setNullString(null),
    product_serial_number_id: setNullString(e.product_serial_number_id),
    varian_id: setNullString(e.varian?.uuid),
    unit_id: setNullString(e.product.unit_id),
    unit_name: e.product.unit_name,
    unit_value: e.product.unit_value,
    total_discount: (e.discount_amount ?? 0) * e.qty,
    total_bruto: e.price * e.qty,
    amount: e.price * e.qty,
  }))
})

export interface MerchantSaleReportReq {
  account_source_id: NullString
  store_id: NullString
  title: string
  notes: string
  date: string
  amount_cash: number
  amount_card: number
  grand_total: number
  sales: {
    uuid: NullString
    payment_method: string
    grand_total: number
    account_destination_id: NullString
    machine_id: NullString
  }[]
}




export interface MerchantSaleReport {
  uuid: string
  customer_id: string
  account_source_id: string
  account_destination_cash_id: string
  account_destination_card_id: string
  account_source_name: string
  store_name: string
  store_id: string
  flag: boolean
  title: string
  notes: string
  date: string
  amount_cash: number
  amount_card: number
  sales: MerchantSale[]
  grand_total: number
}


