import { Packaging, Product, Unit } from '../../model/product';
import { Store, StoreProduct, StoreProductVarian } from '../../model/store';
import { addPackaging, addUnit, getPackagings, getProducts, getUnits } from '../../repositories/product';
import { addStore, getStoreProductDetail, getStores } from '../../repositories/store';
import { parseVarian, setNullString, ucFirst } from '../../utils/helper';
import { SelectOption, colourStyles } from '../../utils/style';
import { Dialog, Transition } from '@headlessui/react';
import FolderIcon from '@heroicons/react/24/outline/FolderIcon';
import { Fragment, type FC, useState, useEffect, useContext } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { SingleValue } from 'react-select';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import FormProduct from './form_product';
import { LoadingContext } from "../../objects/loading_context";
import Swal from 'sweetalert2';
import { CirculationReq } from '../../model/circulation';
import { DatePicker } from 'rsuite';
import { addCirculation } from '../../repositories/circulation';

interface FormCirculationProps {
    modalCirculationOpen: boolean
    setModalCirculationOpen: (arg: boolean) => void
    initialProduct: Product | null
    initialStore?: Store | null
    onProductModalOpen: () => void
    onProductModalSubmit?: () => void
    onSubmit?: () => void
}

const FormCirculation: FC<FormCirculationProps> = ({
    modalCirculationOpen,
    setModalCirculationOpen,
    initialStore,
    initialProduct,
    onProductModalOpen,
    onProductModalSubmit,
    onSubmit
}) => {
    const [product, setProduct] = useState<Product | null>(null);
    const [stores, setStores] = useState<Store[]>([]);
    const [mounted, setMounted] = useState(false);
    const [storeSelect, setStoreSelect] = useState<SelectOption[]>([]);
    const [productSelect, setProductSelect] = useState<SelectOption[]>([]);
    const [inputSourceId, setInputSourceId] = useState("");
    let { isLoading, setIsLoading } = useContext(LoadingContext);
    const [inputSource, setInputSource] = useState<SelectOption | null>();
    const [inputDestinationId, setInputDestinationId] = useState("");
    const [inputDestination, setInputDestination] = useState<SelectOption | null>();
    const [products, setProducts] = useState<Product[]>([]);

    const [inputProductId, setInputProductId] = useState("");
    const [inputProduct, setInputProduct] = useState<SelectOption | null>();
    const [productOpen, setProductOpen] = useState(false);
    const [productName, setProductName] = useState("");
    const [inputAmountIn, setInputAmountIn] = useState(0);
    const [inputAmountOut, setInputAmountOut] = useState(0);

    const [unitSelect, setUnitSelect] = useState<SelectOption[]>([]);
    const [units, setUnits] = useState<Unit[]>([]);
    const [inputUnitId, setInputUnitId] = useState("");
    const [inputUnit, setInputUnit] = useState<SelectOption | null>();
    const [inputDescription, setInputDescription] = useState("");

    const [varianSelect, setVarianSelect] = useState<SelectOption[]>([]);
    const [inputVarianId, setInputVarianId] = useState("");
    const [inputVarian, setInputVarian] = useState<SelectOption | null>();
    const [date, setDate] = useState<Date | null>(null);
    const [storeProduct, setStoreProduct] = useState<StoreProduct | null>(null);
    const [selectedSourceVarian, setSelectedSourceVarian] = useState<StoreProductVarian | null>(null);

    useEffect(() => {
        setMounted(true)
    }, []);

    useEffect(() => {
        if (!mounted) return
        setDate(new Date())
        getStores({ page: 1, limit: 1000 })
            .then(v => v.json())
            .then(v => {
                setStores(v.data)
            })
        getProducts({ page: 1, limit: 1000 })
            .then(v => v.json())
            .then(v => {
                setProducts(v.data)
            })
        // getUnits({ page: 1, limit: 1000 })
        //     .then(v => v.json())
        //     .then(v => {
        //         setUnits(v.data)
        //     })



    }, [mounted]);

    const getProductStore = async (productId: string, storeId: string) => {
        try {
            setIsLoading(true)
            var resp = await getStoreProductDetail(storeId, productId)
            var respJson = await resp.json()
            setStoreProduct(respJson.data)
        } catch (error) {
            Swal.fire("Perhatian", `${error}`, "error")
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (initialProduct != null) {
            setInputProductId(initialProduct!.uuid!)
            setInputProduct({ value: initialProduct!.uuid!, label: initialProduct.name })

            let v: { value: string; label: string; }[] = [{ value: "", label: "Tanpa Varian" }]
            for (let index = 0; index < initialProduct.varians.length; index++) {
                const element = initialProduct.varians[index];
                v.push({ value: element.uuid!, label: parseVarian(element.data) })
            }

            setVarianSelect(v)
            setUnits(initialProduct.units)
        }
    }, [initialProduct]);

    useEffect(() => {
        if (initialStore != null) {
            setInputDestinationId(initialStore!.uuid!)
            setInputDestination({ value: initialStore!.uuid!, label: initialStore.name })
        }
    }, [initialStore]);

    useEffect(() => {
        let selectedProduct = products.filter(e => e.uuid == inputProductId)
        if (selectedProduct.length) {
            let v: { value: string; label: string; }[] = [{ value: "", label: "Tanpa Varian" }]
            for (let index = 0; index < (selectedProduct[0].varians ?? []).length; index++) {
                const element = (selectedProduct[0].varians ?? [])[index];
                v.push({ value: element.uuid!, label: parseVarian(element.data) })
            }
            setVarianSelect(v)
            setUnits(selectedProduct[0].units)
        }
    }, [inputProductId]);

    useEffect(() => {
        let u: { value: string; label: string; }[] = []
        for (let index = 0; index < stores.length; index++) {
            const element = stores[index];
            u.push({ value: element.uuid!, label: element.name })
        }
        setStoreSelect(u)
    }, [stores]);

    useEffect(() => {
        let u: { value: string; label: string; }[] = []
        for (let index = 0; index < products.length; index++) {
            const element = products[index];
            u.push({ value: element.uuid!, label: element.name })
        }
        setProductSelect(u)
    }, [products]);

    useEffect(() => {
        let u: { value: string; label: string; }[] = []
        for (let index = 0; index < units.length; index++) {
            const element = units[index];
            u.push({ value: element.uuid, label: element.name })
        }
        setUnitSelect(u)
        let defaultUnit = units.filter(e => e.is_default)
        if (defaultUnit) {
            setInputUnitId(defaultUnit[0]?.uuid)
            setInputUnit({ value: defaultUnit[0]?.uuid, label: defaultUnit[0]?.name })
        }

    }, [units]);

    useEffect(() => {

    }, [productName]);

    const save = async () => {
        try {
            setIsLoading(true)

            if (date == null) {
                Swal.fire("Perhatian", `Tgl tidak boleh kosong`, "error")
                return
            }
            if (inputDescription.length == 0) {
                Swal.fire("Perhatian", `Keterangan tidak boleh kosong`, "error")
                return
            }
            if (inputAmountIn == 0 && inputAmountOut == 0) {
                Swal.fire("Perhatian", `isi stok terlebih dahulu`, "error")
                return
            }
            if (inputSourceId.length == 0 && inputDestinationId.length == 0) {
                Swal.fire("Perhatian", `isi gudang terlebih dahulu`, "error")
                return
            }

            if (inputSourceId == inputDestinationId) {
                Swal.fire("Perhatian", `Gudang tidak boleh sama`, "error")
                return
            }

            let circulation: CirculationReq = {
                in: inputAmountIn,
                out: inputAmountOut,
                description: inputDescription,
                product_id: setNullString(inputProductId),
                source_id: setNullString(inputSourceId),
                destination_id: setNullString(inputDestinationId),
                date: date!.toISOString(),
                varian_id: setNullString(inputVarianId),
                unit_id: setNullString(inputUnitId),
                packaging_in: 0,
                packaging_out: 0
            }

            await addCirculation(circulation)
            clearForm()
            if (onSubmit != null) {
                onSubmit()
            }

        } catch (error) {
            Swal.fire("Perhatian", `${error}`, "error")
        } finally {
            setIsLoading(false)

        }

    }

    const clearForm = () => {
        setDate(null)
        setInputSourceId("")
        setInputSource(null)
        setInputDestinationId("")
        setInputDestination(null)
        setInputProductId("")
        setInputProduct(null)
        setProductName("")
        setProductOpen(false)
        setInputVarianId("")
        setInputVarian(null)
        setInputUnitId("")
        setInputUnit(null)
        setInputAmountIn(0)
        setInputAmountOut(0)
        setInputDescription("")
        setStoreProduct(null)
        setSelectedSourceVarian(null)
    }

    return (
        <>
            <Transition appear show={modalCirculationOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => {
                    setModalCirculationOpen(false)
                    clearForm()
                }} >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full flex flex-col max-w-2xl transform overflow-hidden rounded-2xl bg-form-dialog p-6 text-left align-middle shadow-xl transition-all" style={{ height: 'calc(100vh - 200px)' }}>
                                    <Dialog.Title
                                        as="h3"
                                        className="text-xl font-medium leading-6 text-gray-900"
                                    >
                                        {initialStore != null ? `Sirkulasi ${initialStore.name}` : 'Tambah Sirkulasi'}
                                    </Dialog.Title>
                                    <div className="flex-1 overflow-y-auto">
                                        <div className="mt-4 ">
                                            <div className="flex items-center mb-6">
                                                <div className="w-1/3">
                                                    <label
                                                        className="block text-gray-900 mb-0 pr-4"
                                                        htmlFor="date"
                                                    >
                                                        Tanggal
                                                    </label>
                                                </div>
                                                <div className="w-2/3">
                                                    <DatePicker value={date} block format="dd/MM/yyyy HH:mm" onChangeCalendarDate={(date) => {
                                                        setDate(date)
                                                    }} />
                                                </div>
                                            </div>
                                            <div className="flex items-center mb-6">
                                                <div className="w-1/3">
                                                    <label className="block text-gray-900 mb-0 pr-4">
                                                        Gudang Asal
                                                    </label>
                                                </div>
                                                <div className="w-2/3">
                                                    <Creatable< SelectOption, false> styles={colourStyles} options={storeSelect} value={inputSource}
                                                        onChange={(option: SingleValue<SelectOption>): void => {
                                                            setInputVarianId("")
                                                            setInputVarian(null)
                                                            setInputSourceId(option?.value ?? "")
                                                            setInputSource(option)

                                                            if (inputProductId != "") {
                                                                getProductStore(inputProductId, option?.value ?? "")
                                                            }
                                                        }}
                                                        onCreateOption={(val) => {
                                                            addStore({ name: val }).then(v => getStores({ page: 1, limit: 1000 })
                                                                .then(v => v.json())
                                                                .then(v => {
                                                                    setStores(v.data)
                                                                }))
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex items-center mb-6">
                                                <div className="w-1/3">
                                                    <label className="block text-gray-900 mb-0 pr-4">
                                                        Gudang Tujuan
                                                    </label>
                                                </div>
                                                <div className="w-2/3">
                                                    {initialStore != null ? `${initialStore.name}` : <Creatable< SelectOption, false> styles={colourStyles} options={storeSelect} value={inputDestination}
                                                        onChange={(option: SingleValue<SelectOption>): void => {
                                                            setInputVarianId("")
                                                            setInputVarian(null)
                                                            setInputDestinationId(option?.value ?? "")
                                                            setInputDestination(option)
                                                        }}
                                                        onCreateOption={(val) => {
                                                            addStore({ name: val }).then(v => getStores({ page: 1, limit: 1000 })
                                                                .then(v => v.json())
                                                                .then(v => {
                                                                    setStores(v.data)
                                                                }))
                                                        }}
                                                    />}

                                                </div>
                                            </div>
                                            <div className="flex items-center mb-6">
                                                <div className="w-1/3">
                                                    <label className="block text-gray-900 mb-0 pr-4">
                                                        Produk
                                                    </label>
                                                </div>
                                                <div className="w-2/3">
                                                    {initialProduct != null ? `${initialProduct.name}` : <Creatable< SelectOption, false> styles={colourStyles} options={productSelect} value={inputProduct}
                                                        onChange={(option: SingleValue<SelectOption>): void => {
                                                            setInputProductId(option?.value ?? "")
                                                            setInputProduct(option)
                                                            if (inputSourceId != "") {
                                                                getProductStore(option?.value ?? "", inputSourceId)
                                                            }
                                                        }}
                                                        onInputChange={(val) => {
                                                            setProductName(val)
                                                        }}
                                                        onCreateOption={(val) => {
                                                            setProductOpen(true)
                                                            setTimeout(() => {
                                                                onProductModalOpen()
                                                            }, 300);
                                                        }} />}
                                                </div>
                                            </div>
                                            <div className="flex items-center mb-6">
                                                <div className="w-1/3">
                                                    <label className="block text-gray-900 mb-0 pr-4">
                                                        Varian
                                                    </label>
                                                </div>
                                                <div className="w-2/3">
                                                    <Select< SelectOption, false> styles={colourStyles} options={varianSelect} value={inputVarian}
                                                        onChange={(option: SingleValue<SelectOption>): void => {
                                                            setInputVarianId(option?.value ?? "")
                                                            setInputVarian(option)
                                                            let selectedVar = storeProduct?.varians.filter(e => e.uuid == option?.value ?? "")
                                                            if (selectedVar) {
                                                                setSelectedSourceVarian(selectedVar[0])
                                                            }
                                                        }}
                                                    />
                                                    <small className='text-gray-400'>{selectedSourceVarian != null ? `Stok tersedia ${selectedSourceVarian?.stock}` : null}</small>
                                                </div>
                                            </div>

                                            <div className="flex items-center mb-6">
                                                <div className="w-1/3">
                                                    <label className="block text-gray-900 mb-0 pr-4">
                                                        Unit
                                                    </label>
                                                </div>
                                                <div className="w-2/3">
                                                    <Select< SelectOption, false> styles={colourStyles} options={unitSelect} value={inputUnit}
                                                        onChange={(option: SingleValue<SelectOption>): void => {
                                                            setInputUnitId(option?.value ?? "")
                                                            setInputUnit(option)
                                                        }}
                                                    // onCreateOption={(val) => {
                                                    //     addUnit({ name: val }).then(v => getUnits({ page: 1, limit: 1000 })
                                                    //         .then(v => v.json())
                                                    //         .then(v => {
                                                    //             setUnits(v.data)
                                                    //         }))
                                                    // }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="flex items-center mb-6">
                                                <div className="w-1/3">
                                                    <label
                                                        className="block text-gray-900 mb-0 pr-4"
                                                        htmlFor="amount-in"
                                                    >
                                                        Stok Masuk
                                                    </label>
                                                </div>
                                                <div className="w-2/3">
                                                    <CurrencyInput
                                                        className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                                        id="amount-in"
                                                        groupSeparator="."
                                                        decimalSeparator=","
                                                        placeholder="Stok Masuk"
                                                        onValueChange={(value, _, values) => {
                                                            setInputAmountIn(values?.float ?? 0)
                                                            setInputAmountOut(0)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            {initialStore == null ? <div className="flex items-center mb-6">
                                                <div className="w-1/3">
                                                    <label
                                                        className="block text-gray-900 mb-0 pr-4"
                                                        htmlFor="amount-in"
                                                    >
                                                        Stok Keluar
                                                    </label>
                                                </div>
                                                <div className="w-2/3">
                                                    <CurrencyInput
                                                        className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                                        id="amount-in"
                                                        groupSeparator="."
                                                        decimalSeparator=","
                                                        placeholder="Stok Keluar"
                                                        onValueChange={(value, _, values) => {
                                                            setInputAmountIn(0)
                                                            setInputAmountOut(values?.float ?? 0)
                                                        }}
                                                    />
                                                </div>
                                            </div> : null}

                                            <div className="flex items-start mb-6">
                                                <div className="w-1/3">
                                                    <label
                                                        className="block text-gray-900 mb-0 pr-4"
                                                        htmlFor="desc"
                                                    >
                                                        Keterangan
                                                    </label>
                                                </div>
                                                <div className="w-2/3">
                                                    <textarea
                                                        className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                                        id="desc"
                                                        rows={5}
                                                        placeholder="Keterangan"
                                                        value={inputDescription}
                                                        onChange={(e) => setInputDescription(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="my-0">
                                        <button
                                            type="button"
                                            className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                            onClick={save}
                                        >
                                            <FolderIcon
                                                className=" h-5 w-5 mr-2"
                                                aria-hidden="true" />  Simpan
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
            <FormProduct initialProductName={productName} isOpen={productOpen} setIsOpen={setProductOpen} onSubmit={(uuid) => {
                setProductOpen(false)
                if (onProductModalSubmit != null) {
                    onProductModalSubmit()
                }
            }} />
        </>);
}
export default FormCirculation;