import { DeliveryLetterItemReq, DeliveryLetterReq, UpdateDeliveryLetterItemReq } from "../model/delivery_letter";
import { PaginationReq } from "../model/pagination";
import { customFetch } from "../utils/helper";


export let getDeliveryLetters = async (pagination: PaginationReq) => {
    var params: Record<string, string> = {
        page: pagination.page.toString(),
        limit: pagination.limit.toString(),
        search: pagination.search ?? "",

    };

    if (pagination.type) {
        params["type"] = pagination.type
    }

    if (pagination.merchant_id) {
        params["merchant_id"] = pagination.merchant_id
    }

    return await customFetch(`Trading/DeliveryLetter/?${new URLSearchParams(params)}`)
}

export let getDeliveryLetterDetail = async (uuid: string) => {
    return await customFetch(`Trading/DeliveryLetter/${uuid}`)
}


export let deleteDeliveryLetter = async (uuid: string) => {
    return await customFetch(`Trading/DeliveryLetter/${uuid}`, {
        method: "DELETE",
    })
}
export let addDeliveryLetter = async (req: DeliveryLetterReq) => {
    return await customFetch(`Trading/DeliveryLetter/`, {
        method: "POST",
        body: JSON.stringify(req)
    })
}

export let updateDeliveryLetter = async (uuid:string, req: DeliveryLetterReq) => {
    return await customFetch(`Trading/DeliveryLetter/${uuid}`, {
        method: "PUT",
        body: JSON.stringify(req)
    })
}


export let addItemDeliveryLetter = async (uuid: string, req: DeliveryLetterItemReq) => {
    return await customFetch(`Trading/DeliveryLetter/${uuid}/Item`, {
        method: "POST",
        body: JSON.stringify(req)
    })
}
export let editItemDeliveryLetter = async (uuid: string, itemId: string, req: UpdateDeliveryLetterItemReq) => {
    return await customFetch(`Trading/DeliveryLetter/${uuid}/EditItem/${itemId}`, {
        method: "PUT",
        body: JSON.stringify(req)
    })
}
export let deleteItemDeliveryLetter = async (uuid: string, itemId: string) => {
    return await customFetch(`Trading/DeliveryLetter/${uuid}/DeleteItem/${itemId}`, {
        method: "DELETE",
    })
}