import { Button, Menu, Transition } from "@headlessui/react";
import {
  ChevronDownIcon,
  EyeIcon,
  FunnelIcon,
  PlusIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import moment from "moment";
import { Fragment, useContext, useEffect, useState, type FC } from "react";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import Select, { MultiValue } from "react-select";
import { Badge, Drawer, Tooltip, Whisper } from "rsuite";
import DateRangePicker, { DateRange } from "rsuite/esm/DateRangePicker";
import Swal from "sweetalert2";
import CustomTable, { TableRecord } from "../components/custom_table";
import DashboardLayout from "../components/dashboard";
import InlineForm from "../components/inline_form";
import {
  BusinessSetting,
  Company,
  ProductAddOnSetting,
} from "../model/company";
import { Customer } from "../model/customer";
import { DeliveryLetter, DeliveryLetterReq } from "../model/delivery_letter";
import { Invoice, InvoiceReq } from "../model/invoice";
import { Pagination } from "../model/pagination";
import { LoadingContext } from "../objects/loading_context";
import { generateNumber, getCompanyDetail } from "../repositories/company";
import { getCustomers } from "../repositories/customer";
import {
  addDeliveryLetter,
  deleteDeliveryLetter,
  getDeliveryLetters,
} from "../repositories/delivery_letter";
import {
  createInvoice,
  deleteInvoice,
  duplicateInvoice,
  getInvoices,
} from "../repositories/invoice";
import {
  getPermissions,
  getSelectedMerchantID,
  setNullString,
} from "../utils/helper";
import { money } from "../utils/number";
import { SelectOption, multiColourStyles } from "../utils/style";
import { errorToast } from "../utils/helper-ui";
import { HiOutlineDuplicate } from "react-icons/hi";
interface SalePageProps {}

const SalePage: FC<SalePageProps> = ({}) => {
  const [mounted, setMounted] = useState(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [pagination, setPagination] = useState<Pagination | null>(null);
  let { isLoading, setIsLoading } = useContext(LoadingContext);
  const [permissions, setPermissions] = useState<string[]>([]);
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const nav = useNavigate();
  const [businessSetting, setBusinessSetting] =
    useState<BusinessSetting | null>(null);
  const [addOnSetting, setAddOnSetting] = useState<ProductAddOnSetting | null>(
    null
  );
  const [autoNumber, setAutoNumber] = useState("");
  const [deliveryLetterAutoNumber, setDeliveryLetterAutoNumber] = useState("");
  const [settingLoaded, setSettingLoaded] = useState(false);

  const [pageDeliveryLetter, setPageDeliveryLetter] = useState(1);
  const [limitDeliveryLetter, setLimitDeliveryLetter] = useState(20);
  const [paginationDeliveryLetter, setPaginationDeliveryLetter] =
    useState<Pagination | null>(null);
  const [searchDeliveryLetter, setSearchDeliveryLetter] = useState("");
  const [deliveryLetters, setDeliveryLetters] = useState<DeliveryLetter[]>([]);
  const [merchantId, setMerchantId] = useState<string | null>(null);
  const [openWithHeader, setOpenWithHeader] = useState(false);
  const [dateRange, setDateRange] = useState<DateRange | null>(null);
  const [customerIds, setCustomerIds] = useState<string | null>(null);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [selectedCustomers, setSelectedCustomers] = useState<Customer[]>([]);

  useEffect(() => {
    getPermissions().then((v) => {
      setPermissions(v);
    });

    setMounted(true);
  }, []);

  useEffect(() => {
    if (!mounted) return;
    getAllInvoices();
    getAllDeliveryLetters();
  }, [mounted]);

  useEffect(() => {
    getAllInvoices();
    getAllDeliveryLetters();
  }, [merchantId]);

  useEffect(() => {
    setCustomerIds(
      selectedCustomers.length > 0
        ? selectedCustomers.map((e) => e.uuid).join(",")
        : null
    );
  }, [selectedCustomers]);

  const getAllInvoices = async () => {
    try {
      setIsLoading(true);
      var resp = await getInvoices(
        { page, limit, search, merchant_id: merchantId },
        dateRange,
        customerIds
      );
      var respJson = await resp.json();
      setInvoices(respJson.data);
      setPagination(respJson.meta);
      getSetting();
    } catch (error) {
      Swal.fire("Perhatian", `${error}`, "error");
    } finally {
      setIsLoading(false);
    }
  };

  const getAllDeliveryLetters = async () => {
    try {
      setIsLoading(true);
      var resp = await getDeliveryLetters({
        page,
        limit,
        search,
        type: "letter_out",
        merchant_id: merchantId,
      });
      var respJson = await resp.json();
      setDeliveryLetters(respJson.data);
      setPaginationDeliveryLetter(respJson.meta);
      getSetting();
    } catch (error) {
      Swal.fire("Perhatian", `${error}`, "error");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllInvoices();
  }, [limit, page, search, dateRange, customerIds]);

  const getSetting = async () => {
    if (settingLoaded) return false;
    try {
      setIsLoading(true);
      var resp = await getCompanyDetail();
      var respJson = await resp.json();
      var company: Company = { ...respJson.data };
      if (company?.product_add_on_setting != null) {
        setBusinessSetting(
          company?.product_add_on_setting?.jaraya_trading_setting?.invoice
        );
        setAddOnSetting(company?.product_add_on_setting);
        var resp2 = await generateNumber(
          company!.product_add_on_setting!.jaraya_trading_setting!.invoice,
          "invoice"
        );
        var respJson2 = await resp2.json();
        setAutoNumber(respJson2.data["auto_number"]);
        var resp3 = await generateNumber(
          company!.product_add_on_setting!.jaraya_trading_setting!
            .delivery_letter,
          "delivery_letter"
        );
        var respJson3 = await resp3.json();
        setDeliveryLetterAutoNumber(respJson3.data["auto_number"]);
      }

      setSettingLoaded(true);
    } catch (error) {
      Swal.fire("Perhatian", `${error}`, "error");
    } finally {
      setIsLoading(false);
    }
  };

  const addInvoice = async () => {
    let input: InvoiceReq = {
      customer_id: setNullString(""),
      invoice_date: moment().toISOString(),
      payment_due: moment().add(30, "days").toISOString(),
      invoice_title: businessSetting?.title ?? "",
      invoice_number: autoNumber ?? "",
      account_receivable_id: setNullString(
        businessSetting?.destination_id ?? ""
      ),
      account_income_id: setNullString(businessSetting?.source_id ?? ""),
      is_auto_stock: businessSetting?.is_auto_stock ?? true,
      tax_id: setNullString(businessSetting?.tax_id ?? ""),
      tax_method: setNullString(businessSetting?.tax_method ?? ""),
      secondary_tax_id: setNullString(businessSetting?.secondary_tax_id ?? ""),
      secondary_tax_method: setNullString(
        businessSetting?.secondary_tax_method ?? ""
      ),
      notes: businessSetting?.notes ?? "",
      ref_number: "",
    };

    try {
      setIsLoading(true);
      var resp = await createInvoice(input);
      var respJson = await resp.json();
      nav(`/sale/${respJson.data["invoice_id"]}`);
    } catch (error) {
      Swal.fire("Perhatian", `${error}`, "error");
    } finally {
      setIsLoading(false);
    }
  };

  const menuDataAll = () => {
    return (
      <div className="text-right">
        <Menu as="div" className="relative inline-block text-left">
          <div>
            <Menu.Button className="inline-flex w-full justify-center rounded-md bg-white px-4 py-2 text-sm font-medium  hover:bg-black/30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75">
              {merchantId == null ? "Semua Data" : "Data Merchant"}
              <ChevronDownIcon
                className="-mr-1 ml-2 h-5 w-5 text-violet-200 hover:text-violet-100"
                aria-hidden="true"
              />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
              <div className="px-1 py-1 ">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => setMerchantId(null)}
                      className={`${
                        merchantId == null
                          ? "bg-pink-500 text-white"
                          : "text-gray-900"
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    >
                      Semua Data
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={async () =>
                        setMerchantId(await getSelectedMerchantID())
                      }
                      className={`${
                        merchantId != null
                          ? "bg-pink-500 text-white"
                          : "text-gray-900"
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    >
                      Data Merchant
                    </button>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    );
  };

  const addLetter = async () => {
    var req: DeliveryLetterReq = {
      invoice_id: "",
      customer_id: "",
      vendor_id: "",
      employee_id: "",
      employee_name: "",
      number: deliveryLetterAutoNumber,
      contact_name: "",
      contact_address: "",
      contact_phone: "",
      contact_fax: "",
      contact_email: "",
      contact_contact_person: "",
      send_date: moment().format("YYYY-MM-DD"),
      invoice_date: "",
      invoice_due_date: "",
      invoice_number: "",
      assignor: "",
      assignor_name: "",
      status: "CREATED",
      notes: "",
      number_plate: "",
      transportation_type: "",
      type:
        addOnSetting?.jaraya_trading_setting?.delivery_letter_template ??
        "regular",
      letter_flow: "letter_out",
    };

    try {
      setIsLoading(true);
      var resp = await addDeliveryLetter(req);
      var respJson = await resp.json();
      nav(`/delivery_letter/${respJson.data["last_id"]}`);
    } catch (error) {
      Swal.fire("Perhatian", `${error}`, "error");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <DashboardLayout permission="read_invoice">
      <div className="p-6">
        <div className="flex justify-between items-center">
          <h1 className="text-4xl">Penjualan</h1>
          <div className="flex  items-center ">
            {menuDataAll()}
            <Button
              className="flex  bg-white px-4 py-2 text-sm font-medium ml-2"
              onClick={() => setOpenWithHeader(true)}
            >
              <FunnelIcon className="cursor-pointer z-50  h-5 w-5" />
              Filter
            </Button>
          </div>
        </div>
        <div className="mt-6 grid gap-x-8 gap-y-4 sm:gap-y-0 xl:grid-cols-8 lg:grid-cols-8 sm:grid-cols-4 md:grid-cols-4 ">
          <div className="mb-5 col-span-6 rounded-xl py-6 px-6 transition-all hover:shadow-lg sm:p-4 lg:px-4 xl:px-6 bg-white cursor-pointer">
            <CustomTable
              searchPlaceholder="Cari Faktur"
              onSearch={(val) => {
                setSearch(val);
              }}
              switchHeader
              pagination
              total={pagination?.total_records}
              limit={limit}
              activePage={page}
              setActivePage={(val) => {
                setPage(val);
              }}
              searchHeader={
                <button
                  onClick={addInvoice}
                  className=" text-white bg-orange-600 hover:bg-orange-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-full text-sm px-5 py-2.5 text-center "
                >
                  Tambah Faktur
                </button>
              }
              changeLimit={(val) => setLimit(val)}
              limitPaginations={[10, 20, 50, 100, 1000]}
              footer={[
                {
                  cells: [
                    { data: "" },
                    { data: "" },
                    { data: "" },
                    { data: "" },
                    { data: <strong>GRAND TOTAL</strong> },
                    {
                      data: money(
                        invoices
                          .map((e) => e.grand_total)
                          .reduce((a, b) => a + b, 0)
                      ),
                      className: "text-right",
                    },
                    {
                      data: money(
                        invoices.map((e) => e.paid).reduce((a, b) => a + b, 0)
                      ),
                      className: "text-right",
                    },
                    {
                      data: money(
                        invoices
                          .map((e) => e.balance)
                          .reduce((a, b) => a + b, 0)
                      ),
                      className: "text-right",
                    },
                    { data: "" },
                  ],
                },
              ]}
              headers={[
                "#",
                "Status",
                "Tgl",
                "No Faktur",
                "Konsumen",
                "Total",
                "Terbayar",
                "Sisa",
                "",
              ]}
              headerClasses={[
                "w-10",
                "w-20",
                "w-10",
                "w-64",
                "w-64",
                "text-right",
                "text-right",
                "text-right",
                "w-10",
              ]}
              datasets={invoices.map((e) => {
                let data: TableRecord = {
                  cells: [
                    { data: (page - 1) * limit + (invoices.indexOf(e) + 1) },
                    {
                      data: (
                        <div className="">
                          {new Date() > new Date(e.payment_due) &&
                          e.balance > 0 ? (
                            <Badge
                              className="py-1 px-4 text-center"
                              content="Terlambat"
                              color="red"
                            />
                          ) : null}
                          {new Date() < new Date(e.payment_due) &&
                          e.balance > 0 &&
                          e.balance == e.grand_total ? (
                            <Badge
                              className="py-1 px-4 text-center"
                              content="Belum Dibayar"
                              color="orange"
                            />
                          ) : null}
                          {new Date() < new Date(e.payment_due) &&
                          e.balance > 0 &&
                          e.balance != e.grand_total ? (
                            <Badge
                              className="py-1 px-4 text-center"
                              content="Dibayar Sebagian"
                              color="violet"
                            />
                          ) : null}
                          {e.paid && e.balance == 0 ? (
                            <Badge
                              className="py-1 px-4 text-center"
                              content="Lunas"
                              color="green"
                            />
                          ) : null}
                        </div>
                      ),
                    },
                    {
                      data: (
                        <div className="flex flex-col">
                          <Moment className="text-xs" format="DD/MM/YYYY">
                            {e.invoice_date}
                          </Moment>
                          <Moment
                            className="text-xs"
                            format="HH:mm"
                            style={{ fontSize: 10 }}
                          >
                            {e.invoice_date}
                          </Moment>
                        </div>
                      ),
                    },
                    {
                      data: (
                        <div className="flex flex-wrap flex-col hover:font-medium">
                          <p className="mb-0 text-normal hover:font-medium">
                            {e.invoice_title}
                          </p>
                          <dl onClick={() => nav(`/sale/${e.uuid}`)}>
                            <dt>
                              <strong style={{ fontSize: 10 }}>
                                No. Faktur:{" "}
                              </strong>
                            </dt>
                            <dd>{e.invoice_number}</dd>
                            {e.po_number ? (
                              <dt>
                                <strong style={{ fontSize: 10 }}>
                                  No. PO:{" "}
                                </strong>
                              </dt>
                            ) : null}
                            {e.po_number ? <dd>{e.po_number}</dd> : null}
                          </dl>
                        </div>
                      ),
                    },
                    {
                      data: e.customer_name,
                    },
                    {
                      data: money(e.grand_total),
                      className: "text-right",
                    },
                    {
                      data: money(e.paid),
                      className: "text-right",
                    },
                    {
                      data: money(e.balance),
                      className: "text-right",
                    },
                    {
                      data: (
                        <div className="flex justify-end">
                          <Whisper
                            placement="left"
                            followCursor
                            speaker={<Tooltip>Duplikat Faktur</Tooltip>}
                          >
                            <div>
                              <HiOutlineDuplicate
                                onClick={async () => {
                                  try {
                                    setIsLoading(true);
                                    await duplicateInvoice(e.uuid);
                                    getAllInvoices();
                                  } catch (error) {
                                    errorToast(`${error}`);
                                  } finally {
                                    setIsLoading(false);
                                  }
                                }}
                                size={16}
                                className="cursor-pointer text-orange-400 hover:text-orange-600 mr-1"
                              />
                            </div>
                          </Whisper>
                          <EyeIcon
                          onClick={() => nav(`/sale/${e.uuid}`)}
                          className="cursor-pointer h-5 w-5 text-blue-400 hover:text-blue-600"
                        />
                          <TrashIcon
                            className=" h-5 w-5 text-red-400 hover:text-red-600"
                            aria-hidden="true"
                            onClick={() => {
                              Swal.fire({
                                title: "Anda Yakin",
                                text: "Anda tidak akan dapat mengembalikan proses ini!",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "Ya, Hapus!",
                                cancelButtonText: "Batal",
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  // delProduct(row)
                                  setIsLoading(true);
                                  deleteInvoice(e.uuid)
                                    .then((v) => getAllInvoices())
                                    .catch((error) =>
                                      Swal.fire(
                                        "Perhatian",
                                        `${error}`,
                                        "error"
                                      )
                                    )
                                    .finally(() => setIsLoading(false));
                                }
                              });
                            }}
                          />
                        </div>
                      ),
                      className: "w-4 text-right",
                    },
                  ],
                  className: "last:border-0 hover:bg-gray-50",
                };
                return data;
              })}
            />
            {/* <div className="flex justify-between items-center pb-5 pt-3">
              <form className=" w-60 ">
                <div className="relative">
                  <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                    <MagnifyingGlassIcon
                      className=" h-5 w-5 text-violet-200 hover:text-violet-100"
                      aria-hidden="true"
                    />
                  </div>
                  <input
                    type="search"
                    id="default-search"
                    className="block w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-full bg-white focus:ring-blue-500 focus:border-blue-500   "
                    placeholder="Search Faktur..."
                    onChange={(val) => {
                      // setPage(1)
                      // setSearch(val.target.value)
                    }}
                  />
                </div>
              </form>
              <button
                onClick={addInvoice}
                className=" text-white bg-orange-600 hover:bg-orange-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-full text-sm px-5 py-2.5 text-center "
              >
                Tambah Faktur
              </button>
            </div>
            <DataTable
              pagination
              paginationServer
              paginationTotalRows={pagination?.total_records ?? 0}
              dense
              striped
              columns={columns}
              data={invoices}
              onRowClicked={(val) => {
                // nav(`/product/${val.uuid}`)
              }}
              paginationPerPage={limit}
              paginationRowsPerPageOptions={[5, 10, 20, 50, 100, 500, 1000]}

              pointerOnHover={true}
              customStyles={{
                headCells: {
                  style: {
                    paddingTop: '20px', // override the cell padding for head cells
                    paddingBottom: '20px', // override the cell padding for head cells
                    fontWeight: "600",
                    fontSize: "12pt"
                  },
                },
                cells: {
                  style: {
                    padding: '16px', // override the cell padding for head cells
                  },
                },
              }}
              onChangeRowsPerPage={(v) => {
                setLimit(v)
              }}
              onChangePage={(v) => {
                if (v != page) {

                  setPage(v)
                }
              }}
            /> */}
          </div>
          <div className="mb-5 col-span-2 rounded-xl py-6 px-6 transition-all hover:shadow-lg sm:p-4 lg:px-4 xl:px-6 bg-white cursor-pointer">
            <div className=" flex justify-between items-center">
              <h3 className=" xl:text-2xl md:text-lg ">Surat Jalan</h3>
              <button
                onClick={addLetter}
                className="flex items-center text-white bg-orange-600 hover:bg-orange-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-full text-sm xl:px-3 xl:py-1.5 md:py-1 md:px-2 md:h-8 xl:h-10 text-center "
              >
                <PlusIcon className="text-white w-4 mr-2" />
                Tambah
              </button>
            </div>
            <ul className="mt-4">
              {deliveryLetters.map((e) => (
                <li
                  className="p-3 mb-2  flex justify-between border rounded-lg  hover:bg-gray-50 "
                  key={e.uuid}
                >
                  <div
                    className="flex-1"
                    onClick={() => nav(`/delivery_letter/${e.uuid}`)}
                  >
                    <Moment format="DD/MM/YYYY">{e.send_date}</Moment>
                    <p className=" text-xl font-medium">{e.number}</p>
                    <p className=" mt-0 text-base font-medium">
                      {e.contact_name}
                    </p>
                    <p className=" mt-0 text-xs ">{e.contact_address}</p>
                  </div>
                  {permissions.includes("delete_delivery_letter") && (
                    <TrashIcon
                      className="w-4 h-4 text-red-400 hover:text-red-600"
                      onClick={() => {
                        Swal.fire({
                          title: "Anda Yakin",
                          text: "Anda tidak akan dapat mengembalikan proses ini!",
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: "#3085d6",
                          cancelButtonColor: "#d33",
                          confirmButtonText: "Ya, Hapus!",
                          cancelButtonText: "Batal",
                        }).then((result) => {
                          if (result.isConfirmed) {
                            // delProduct(row)
                            setIsLoading(true);
                            deleteDeliveryLetter(e.uuid)
                              .then((v) => getAllDeliveryLetters())
                              .catch((error) =>
                                Swal.fire("Perhatian", `${error}`, "error")
                              )
                              .finally(() => setIsLoading(false));
                          }
                        });
                      }}
                    />
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <Drawer open={openWithHeader} onClose={() => setOpenWithHeader(false)}>
        <Drawer.Header>
          <Drawer.Title>Filter Penjualan</Drawer.Title>
          <Drawer.Actions>
            <Button
              onClick={() => {
                setDateRange(null);
                setCustomerIds(null);
                setSelectedCustomers([]);
              }}
            >
              <XMarkIcon className="w-5" /> Clear Filter
            </Button>
          </Drawer.Actions>
        </Drawer.Header>
        <Drawer.Body>
          <InlineForm title="Rentang Tanggal">
            <DateRangePicker
              placement="bottomEnd"
              value={dateRange}
              block
              format="dd/MM/yyyy"
              onOk={(val: DateRange) => {
                setPage(1);
                setTimeout(() => {
                  setDateRange(val);
                }, 300);
              }}
            />
          </InlineForm>
          <InlineForm title="Konsumen">
            <Select<SelectOption, true>
              isMulti
              styles={multiColourStyles}
              options={customers.map((e) => {
                return {
                  value: e.uuid,
                  label: e.name,
                };
              })}
              value={selectedCustomers.map((e) => {
                return {
                  value: e.uuid,
                  label: e.name,
                };
              })}
              onChange={(option: MultiValue<SelectOption>): void => {
                console.log(option);
                setSelectedCustomers(
                  option.map((e) => {
                    return {
                      uuid: e.value,
                      name: e.label,
                      logo: "",
                      email: "",
                      phone: "",
                      fax: "",
                      address: "",
                      contact_person: "",
                      contact_person_position: "",
                    };
                  })
                );
              }}
              onInputChange={(val) => {
                getCustomers({ page: 1, limit: 10, search: val })
                  .then((v) => v.json())
                  .then((v) => setCustomers(v.data));
              }}
            />
          </InlineForm>
        </Drawer.Body>
      </Drawer>
    </DashboardLayout>
  );
};
export default SalePage;
