import { useNavigate, useParams } from 'react-router-dom';
import Back from '../components/back';
import DashboardLayout from '../components/dashboard';
import DeliveryLetterItemRecord from '../components/delivery_letter_item';
import InlineForm from '../components/inline_form';
import ModalEditor from '../components/modals/modal_editor';
import ModalEmployee from '../components/modals/modal_employee';
import ModalProduct from '../components/modals/modal_product';
import ModalVendor from '../components/modals/modal_vendor';
import ModalCustomer from '../components/modals/model_customer';
import { BillReq } from '../model/bill';
import { BusinessSetting, Company, ProductAddOnSetting } from '../model/company';
import { DeliveryLetter, DeliveryLetterItem, UpdateDeliveryLetterItemReq } from '../model/delivery_letter';
import { InvoiceReq } from '../model/invoice';
import { Product, ProductStoreStock } from '../model/product';
import { Store } from '../model/store';
import { Vendor } from '../model/vendor';
import { VEHICLES } from '../objects/constants';
import { LoadingContext } from '../objects/loading_context';
import { addItemBill, createBill, releaseBill } from '../repositories/bill';
import { generateNumber, getCompanyDetail } from '../repositories/company';
import { addItemDeliveryLetter, deleteItemDeliveryLetter, editItemDeliveryLetter, getDeliveryLetterDetail, getDeliveryLetters, updateDeliveryLetter } from '../repositories/delivery_letter';
import { addItemInvoice, createInvoice, releaseInvoice } from '../repositories/invoice';
import { getProductDetail } from '../repositories/product';
import { deliveryLetterStatus, parseVarian, setNullString } from '../utils/helper';
import { Switch } from '@headlessui/react';
import { Bars3BottomRightIcon, CheckBadgeIcon, ClipboardDocumentIcon, FolderIcon, PlusIcon, PrinterIcon, TrashIcon, TruckIcon, WalletIcon, XMarkIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { useContext, useEffect, useState, type FC } from 'react';
import Moment from 'react-moment';
import { Avatar, Checkbox, DatePicker, Drawer, Tooltip, Whisper } from 'rsuite';
import Swal from 'sweetalert2';

interface DeliveryLetterPageProps { }

const DeliveryLetterPage: FC<DeliveryLetterPageProps> = ({ }) => {
	const { deliveryLetterId } = useParams()

	const [editing, setEditing] = useState(false)
	const nav = useNavigate()
	const [mounted, setMounted] = useState(false);
	let { isLoading, setIsLoading } = useContext(LoadingContext);
	const [deliveryLetter, setDeliveryLetter] = useState<DeliveryLetter | null>(null);
	const [openWithHeader, setOpenWithHeader] = useState(false);
	const [editable, setEditable] = useState(true);
	const [editorOpen, setEditorOpen] = useState(false);
	const [selectedItems, setSelectedItems] = useState<DeliveryLetterItem[]>([]);
	const [modalProductOpen, setModalProductOpen] = useState(false);
	const [selectAll, setSelectAll] = useState(false);
	const [mountedItems, setMountedItems] = useState(true);
	const [modalVendorOpen, setModalVendorOpen] = useState(false);
	const [modalCustomerOpen, setModalCustomerOpen] = useState(false);
	const [modalEmployeeOpen, setModalEmployeeOpen] = useState(false);
	const [modalSenderOpen, setModalSenderOpen] = useState(false);
	const [content, setContent] = useState("");
	const [addOnSetting, setAddOnSetting] = useState<ProductAddOnSetting | null>(null);
	const [settingLoaded, setSettingLoaded] = useState(false);
	const [company, setCompany] = useState<Company | null>(null);
	const [printHeader, setPrintHeader] = useState("");
	const [printShowPayment, setPrintShowPayment] = useState(false);
	const [printShowAddress, setPrintShowAddress] = useState(false);
	const [printShowNotes, setPrintShowNotes] = useState(false);
	const [printShowMaker, setPrintShowMaker] = useState(false);
	const [printShowSender, setPrintShowSender] = useState(false);
	const [printDownload, setPrintDownload] = useState(false);

	useEffect(() => {
		setMounted(true)

	}, []);
	useEffect(() => {
		if (!mounted) return
		getDetail()
		getSetting()
	}, [mounted]);

	const getDetail = async () => {
		try {
			setIsLoading(true)
			const resp = await getDeliveryLetterDetail(deliveryLetterId!)
			const respJson = await resp.json()
			setDeliveryLetter(respJson.data)
			setEditable(respJson.data.status == "CREATED")
		} catch (error) {
			Swal.fire("Perhatian", `${error}`, "error")
		} finally {
			setIsLoading(false)
		}

	}

	const getSetting = async () => {
		if (settingLoaded) return false
		try {

			setIsLoading(true)
			var resp = await getCompanyDetail()
			var respJson = await resp.json()
			var company: Company = { ...respJson.data }
			setCompany(respJson.data)
			if (company?.product_add_on_setting != null) {
				setAddOnSetting(company?.product_add_on_setting)
			}

			setSettingLoaded(true)

		} catch (error) {
			Swal.fire("Perhatian", `${error}`, "error")
		} finally {
			setIsLoading(false)
		}


	}

	const selectProduct = async (product: Product, store?: Store, varians?: ProductStoreStock[]) => {
		try {
			setIsLoading(true)
			if (varians != null && varians.length > 0) {
				for (const key in varians) {
					if (Object.prototype.hasOwnProperty.call(varians, key)) {
						const element = varians[key];
						await addItem(product, store!, element)
					}
				}
			} else {
				await addItem(product, store!)
			}
			getDetail()
		} catch (error) {
			Swal.fire("Perhatian", `${error}`, "error")
		} finally {
			setIsLoading(false)
		}
	}

	const addItem = async (product: Product, store?: Store, varian?: ProductStoreStock) => {
		let desc = product.name
		if (varian) {
			desc += " " + parseVarian(varian.varian_data, true)
		}

		// console.log(parseVarian(varian!.varian_data, true))
		return await addItemDeliveryLetter(deliveryLetter!.uuid, {
			qty: 1,
			description: desc,
			product_id: product.uuid,
			varian_id: varian?.varian_id ?? "",
			unit_id: product.unit_id,
			unit_name: product.unit_name,
			unit_value: product.unit_value,
			store_source_id: deliveryLetter?.letter_flow == "letter_out" ? store?.uuid ?? "" : "",
			store_destination_id: deliveryLetter?.letter_flow == "letter_in" ? store?.uuid ?? "" : "",
			qty_packaging: 1,
			packaging_id: product.packaging_id,
			packaging_name: product.packaging_name,
			packing_list: [1]
		})
	}

	const editItem = async (item: DeliveryLetterItem) => {
		const req: UpdateDeliveryLetterItemReq = {
			travel_permit_id: item.travel_permit_id,
			qty: item.qty,
			description: item.description,
			product_id: setNullString(item.product_id),
			product_name: item.product_name,
			varian_id: setNullString(item.varian_id),
			store_source_id: setNullString(item.store_source_id),
			store_source_name: item.store_source_name,
			store_destination_id: setNullString(item.store_destination_id),
			store_destination_name: item.store_destination_name,
			unit_id: setNullString(item.unit_id),
			unit_name: item.unit_name,
			unit_value: item.unit_value,
			qty_packaging: item.qty_packaging,
			packaging_id: setNullString(item.packaging_id),
			packaging_name: item.packaging_name,
			packing_list: JSON.stringify(item.packing_list)
		}


		try {
			setIsLoading(true)
			await editItemDeliveryLetter(deliveryLetter!.uuid, item.uuid, req)
			getDetail()
		} catch (error) {
			Swal.fire("Perhatian", `${error}`, "error")
		} finally {
			setIsLoading(false)
		}
	}


	const update = () => {

		updateDeliveryLetter(deliveryLetter!.uuid, {
			...deliveryLetter!,
			send_date: moment(deliveryLetter!.send_date).format("YYYY-MM-DD"),
		})
			.then(v => {
				getDetail()
				setEditing(false)
			});
	}

	const detail = () => (<div className='flex flex-col col-span-3'>
		<div className="mb-5  flex flex-col col-span-3 rounded-xl py-6 px-6 transition-all hover:shadow-lg sm:p-4 lg:px-4 xl:px-6 bg-white cursor-pointer">
			<div className='flex mb-4'>
				<div className='w-1/2 flex-col flex mr-2'>
					<p className=' text-gray-500  '>No. Surat</p>
					<input disabled={!editable} className='editable  text-2xl w-full ' value={deliveryLetter?.number ?? ""} onChange={(val) => {
						let change = {
							...deliveryLetter!,
							number: val.target.value
						}
						setDeliveryLetter(change)
						setEditing(true)
					}} />
				</div>
				<div className='w-1/2 flex-col flex mr-2'>
					<p className=' text-gray-500  '>Status</p>
					<span className='text-2xl w-full'>{deliveryLetterStatus(deliveryLetter?.status)}</span>
				</div>
			</div>
			<div className='flex mb-4'>
				<div className='w-1/2 flex-col flex mr-2'>
					<p className=' text-gray-500  '>Tgl. Kirim</p>
					{!editable ? <Moment className="text-lg" format="DD MMM YYYY">{new Date(deliveryLetter?.send_date ?? new Date())}</Moment> : <DatePicker disabled={!editable} as={"div"} className='input-flat' value={new Date(deliveryLetter?.send_date ?? new Date())} format="dd MMM yyyy" onChangeCalendarDate={(date) => {
						let change = {
							...deliveryLetter!,
							send_date: moment(date).format("YYYY-MM-DD")
						}
						setDeliveryLetter(change)

					}} />}

				</div>
				<div className='w-1/2 flex-col flex'>

				</div>
			</div>

			<div className='flex mb-2 items-center mt-8 justify-between border-b py-2'>
				<p className='text-xl'>Detail Surat Jalan</p>
				<div>
					{editable &&
						<button
							type="button"
							className="inline-flex items-center justify-center rounded-md border bg-white  px-4 py-2 text-sm font-medium text-gray-400 hover:text-gray-900 hover:bg-gray-100 "
							onClick={() => setModalProductOpen(true)}>
							<PlusIcon
								className=" h-3 w-3 mr-2"
								aria-hidden="true" />  Tambah
						</button>
					}
					{selectedItems.length > 0 &&
						<button
							type="button"
							className="inline-flex ml-2 items-center justify-center rounded-md border bg-white  px-4 py-2 text-sm font-medium text-red-400 hover:text-red-900 hover:bg-red-100 "
							onClick={async () => {
								Swal.fire({
									title: "Anda Yakin",
									text: "Anda tidak akan dapat mengembalikan proses ini!",
									icon: "warning",
									showCancelButton: true,
									confirmButtonColor: "#3085d6",
									cancelButtonColor: "#d33",
									confirmButtonText: "Ya, Hapus!",
									cancelButtonText: "Batal"
								}).then(async (result) => {

									if (result.isConfirmed) {
										for (const key in selectedItems) {
											if (Object.prototype.hasOwnProperty.call(selectedItems, key)) {
												const element = selectedItems[key];
												await deleteItemDeliveryLetter(deliveryLetter!.uuid, element.uuid)
											}
										}
										getDetail()
										setSelectedItems([])
									}
								});

							}}>
							<TrashIcon
								className=" h-3 w-3 mr-2"
								aria-hidden="true" />  Hapus
						</button>
					}
				</div>
			</div>
			{deliveryLetter?.items.length == 0 ? <div className='flex justify-center text-base text-center py-5'>
				Belum ada item
			</div> :
				<div className=" overflow-x-auto">
					<table className="w-full text-sm text-left rtl:text-right text-gray-900 ">
						<thead className="text-xs text-gray-700 uppercase border-t border-b ">
							<tr>
								<th scope="col" className="px-6 py-3 w-3 flex items-center">
									{editable &&
										<Checkbox defaultChecked={selectAll} onChange={(value, checked) => {
											setSelectAll(checked)
											if (checked) {
												setSelectedItems([...deliveryLetter!.items])
											} else {
												setSelectedItems([])
											}
											setMountedItems(false)
											setTimeout(() => {
												setMountedItems(true)
											}, 50);
										}} />
									}
									<span>#</span>
								</th>
								<th scope="col" className="px-6 py-3">
									Item
								</th>
								<th scope="col" className="px-6 py-3 text-right">
									Pkg
								</th>
								<th scope="col" className="px-6 py-3 text-right">
									Qty
								</th>

								<th scope="col" className="px-6 py-3 ">
									Keterangan
								</th>
								<th scope="col" className="px-6 py-3 text-right">
								</th>
							</tr>
						</thead>
						{mountedItems &&
							<tbody>
								{deliveryLetter?.items?.map(e =>
									<DeliveryLetterItemRecord
										key={e.uuid}
										deliveryLetter={deliveryLetter}
										item={e}
										checked={selectedItems.includes(e)}
										editable={editable}
										orderNumber={deliveryLetter?.items.indexOf(e) + 1}
										onDelete={() => {
											setIsLoading(true);
											deleteItemDeliveryLetter(deliveryLetter!.uuid, e.uuid)
												.then(v => getDetail())
												.catch(error => Swal.fire("Perhatian", `${error}`, "error"))
												.finally(() => setIsLoading(false));
										}}
										onChange={(val) => {
											editItem(val);
										}}
										onAddPackingList={(val) => {
											editItem(val);
										}}
										onChecked={(val) => {
											if (!val) {
												setSelectedItems([...selectedItems.filter((i) => i != e)])
											} else {
												setSelectedItems([...selectedItems, e])
											}
										}}
									/>
								)}
							</tbody>
						}
					</table>
				</div>

			}
		</div>

	</div>
	)
	const createInvoiceBill = async () => {
		if (deliveryLetter) {
			
			if (deliveryLetter?.letter_flow == "letter_in") {
				var resp2 = await generateNumber(addOnSetting!.jaraya_trading_setting!.bill, "bill")
				var respJson2 = await resp2.json()
				let input: BillReq = {
					vendor_id: setNullString(deliveryLetter.vendor_id),
					bill_date: moment().toISOString(),
					payment_due: moment().add(30, "days").toISOString(),
					bill_title: addOnSetting?.jaraya_trading_setting!.bill.title ?? "",
					bill_number: respJson2.data["auto_number"] ?? "",
					account_payable_id: setNullString(addOnSetting?.jaraya_trading_setting!.bill.destination_id ?? ""),
					account_expense_id: setNullString(addOnSetting?.jaraya_trading_setting!.bill.source_id ?? ""),
					is_auto_stock: addOnSetting?.jaraya_trading_setting!.bill.is_auto_stock ?? true,
					tax_id: setNullString(addOnSetting?.jaraya_trading_setting!.bill.tax_id ?? ""),
					tax_method: setNullString(addOnSetting?.jaraya_trading_setting!.bill.tax_method ?? ""),
					secondary_tax_id: setNullString(addOnSetting?.jaraya_trading_setting!.bill.secondary_tax_id ?? ""),
					secondary_tax_method: setNullString(addOnSetting?.jaraya_trading_setting!.bill.secondary_tax_method ?? ""),
					notes: addOnSetting?.jaraya_trading_setting!.bill.notes ?? "",
					ref_number: deliveryLetter.number,
					po_number: '',
				}

				try {
					setIsLoading(true)
					var resp = await createBill(input)
					var respJson = await resp.json()
					let billId = respJson.data['bill_id']
					for (let index = 0; index < deliveryLetter.items.length; index++) {
						const item = deliveryLetter.items[index];
						var respProd = await getProductDetail(item.product_id)
						var respProdJson = await respProd.json()
						var product: Product = respProdJson.data
						await addItemBill(billId, {
							price: product.price,
							qty: item.qty,
							qty_packaging: item.qty_packaging,
							discount_percent: 0,
							discount_amount: 0,
							description: item.description,
							notes: '',
							product_id: setNullString(item.product_id),
							varian_id: setNullString(item.varian_id),
							store_id: setNullString(item.store_destination_id),
							service_id: setNullString(""),
							product_serial_number_id: setNullString(""),
							unit_id: setNullString(item.unit_id),
							is_product: true,
							is_service: false
						})
					}
					await releaseBill(billId)
					nav(`/buy/${billId}`)
				} catch (error) {
					Swal.fire("Perhatian", `${error}`, "error")
				} finally {
					setIsLoading(false)

				}

			}

			if (deliveryLetter?.letter_flow == "letter_out") {
				var resp2 = await generateNumber(addOnSetting!.jaraya_trading_setting!.invoice, "invoice")
				var respJson2 = await resp2.json()
				let input: InvoiceReq = {
					customer_id: setNullString(deliveryLetter.customer_id),
					invoice_date: moment().toISOString(),
					payment_due: moment().add(30, "days").toISOString(),
					invoice_title: addOnSetting?.jaraya_trading_setting!.invoice.title ?? "",
					invoice_number: respJson2.data["auto_number"] ?? "",
					account_receivable_id: setNullString(addOnSetting?.jaraya_trading_setting!.invoice.destination_id ?? ""),
					account_income_id: setNullString(addOnSetting?.jaraya_trading_setting!.invoice.source_id ?? ""),
					is_auto_stock: addOnSetting?.jaraya_trading_setting!.invoice.is_auto_stock ?? true,
					tax_id: setNullString(addOnSetting?.jaraya_trading_setting!.invoice.tax_id ?? ""),
					tax_method: setNullString(addOnSetting?.jaraya_trading_setting!.invoice.tax_method ?? ""),
					secondary_tax_id: setNullString(addOnSetting?.jaraya_trading_setting!.invoice.secondary_tax_id ?? ""),
					secondary_tax_method: setNullString(addOnSetting?.jaraya_trading_setting!.invoice.secondary_tax_method ?? ""),
					notes: addOnSetting?.jaraya_trading_setting!.invoice.notes ?? "",
					ref_number: deliveryLetter.number,
					po_number: ''
				}

				try {
					setIsLoading(true)
					var resp = await createInvoice(input)
					var respJson = await resp.json()
					let invoiceId = respJson.data['invoice_id']
					for (let index = 0; index < deliveryLetter.items.length; index++) {
						const item = deliveryLetter.items[index];
						var respProd = await getProductDetail(item.product_id)
						var respProdJson = await respProd.json()
						var product: Product = respProdJson.data
						await addItemInvoice(invoiceId, {
							price: product.price,
							qty: item.qty,
							qty_packaging: item.qty_packaging,
							discount_percent: 0,
							discount_amount: 0,
							description: item.description,
							notes: '',
							product_id: setNullString(item.product_id),
							varian_id: setNullString(item.varian_id),
							store_id: setNullString(item.store_source_id),
							service_id: setNullString(""),
							product_serial_number_id: setNullString(""),
							unit_id: setNullString(item.unit_id),
							is_product: true,
							is_service: false
						})
					}
					await releaseInvoice(invoiceId)
					nav(`/sale/${invoiceId}`)
				} catch (error) {
					Swal.fire("Perhatian", `${error}`, "error")
				} finally {
					setIsLoading(false)

				}

			}
		}

	}
	const info = () => {
		return (
			<div>
				{deliveryLetter?.status != "CREATED" &&
					<div className="mb-5 col-span-1 rounded-xl py-6 px-6 transition-all hover:shadow-lg sm:p-4 lg:px-4 xl:px-6 bg-white cursor-pointer">
						<button
							type="button"
							className="  w-full flex items-center  transition-all duration-500 justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-sm font-medium text-white hover:bg-green-900 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
							onClick={() => createInvoiceBill()}> <ClipboardDocumentIcon
								className=" h-5 w-5 mr-2"
								aria-hidden="true" />  BUAT FAKTUR</button>

					</div>
				}
				{deliveryLetter?.status != "FINISHED" && deliveryLetter?.status != "CANCELED" &&
					<div className="mb-5 col-span-1 rounded-xl py-6 px-6 transition-all hover:shadow-lg sm:p-4 lg:px-4 xl:px-6 bg-white cursor-pointer">
						{deliveryLetter?.status == "CREATED" &&
							<button
								type="button"
								className="  w-full flex items-center  transition-all duration-500 justify-center rounded-md border border-transparent bg-orange-600 px-4 py-2 text-sm font-medium text-white hover:bg-orange-900 focus:outline-none focus-visible:ring-2 focus-visible:ring-orange-500 focus-visible:ring-offset-2"
								onClick={() => {
									updateDeliveryLetter(deliveryLetter!.uuid, {
										...deliveryLetter!,
										send_date: moment(deliveryLetter!.send_date).format("YYYY-MM-DD"),
										status: "ONPROGRESS",
									})
										.then(v => getDetail());
								}}>
								<TruckIcon
									className=" h-5 w-5 mr-2"
									aria-hidden="true" />  PROSES SURAT JALAN
							</button>
						}
						{deliveryLetter?.status == "ONPROGRESS" &&
							<div>
								<button
									type="button"
									className="  w-full flex items-center  transition-all duration-500 justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-sm font-medium text-white hover:bg-green-900 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
									onClick={() => {
										Swal.fire({
											title: "Anda Yakin",
											text: "Pastikan anda telah memeriksa semua proses pengiriman, Anda tidak akan dapat mengembalikan proses ini!",
											icon: "warning",
											showCancelButton: true,
											confirmButtonColor: "#3085d6",
											cancelButtonColor: "#d33",
											confirmButtonText: "Ya, Selesaikan!",
											cancelButtonText: "Batal"
										}).then(async (result) => {

											if (result.isConfirmed) {
												updateDeliveryLetter(deliveryLetter!.uuid, {
													...deliveryLetter!,
													send_date: moment(deliveryLetter!.send_date).format("YYYY-MM-DD"),
													status: "FINISHED",
												})
													.then(v => getDetail());
											}
										});

									}}>
									<CheckBadgeIcon
										className=" h-5 w-5 mr-2"
										aria-hidden="true" />  SELESAI
								</button>
								<button
									type="button"
									className="  w-full flex items-center  transition-all mt-4 duration-500 justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2"
									onClick={() => {
										Swal.fire({
											title: "Anda Yakin",
											text: "Anda tidak akan dapat mengembalikan proses ini!",
											icon: "warning",
											showCancelButton: true,
											confirmButtonColor: "#3085d6",
											cancelButtonColor: "#d33",
											confirmButtonText: "Ya, Batalkan Surat Jalan!",
											cancelButtonText: "Batal"
										}).then(async (result) => {

											if (result.isConfirmed) {
												updateDeliveryLetter(deliveryLetter!.uuid, {
													...deliveryLetter!,
													send_date: moment(deliveryLetter!.send_date).format("YYYY-MM-DD"),
													status: "CANCELED",
												})
													.then(v => getDetail());
											}
										});

									}}>
									<XMarkIcon
										className=" h-5 w-5 mr-2"
										aria-hidden="true" />  BATAL
								</button>
							</div>

						}
					</div>
				}

				<div className="mb-5 col-span-1 rounded-xl py-6 px-6 transition-all hover:shadow-lg sm:p-4 lg:px-4 xl:px-6 bg-white cursor-pointer">
					<div className=' flex-col'>
						<div className='mb-3 flex  flex-col' onClick={!editable ? () => { } : () => {
							if (deliveryLetter?.letter_flow == "letter_in") {
								setModalVendorOpen(true);
							}
							if (deliveryLetter?.letter_flow == "letter_out") {
								setModalCustomerOpen(true);
							}
						}}>
							<p className=' text-gray-500'>
								{deliveryLetter?.letter_flow == "letter_in" ? "Vendor" : ""}
								{deliveryLetter?.letter_flow == "letter_out" ? "Konsumen" : ""}
							</p>
							<div className=' text-lg  w-full  -mt-1'>
								{deliveryLetter?.contact_name ?? "-"}
							</div>
							<p className=' w-5/6 leading-5'>{deliveryLetter?.contact_address} {deliveryLetter?.contact_phone ? `Telp. ${deliveryLetter?.contact_phone}` : ''}</p>
							{deliveryLetter?.contact_email ? <p className=' -mt-0.5 w-5/6 leading-4'>  email: {deliveryLetter?.contact_email}</p> : null}
							{deliveryLetter?.contact_fax ? <p className=' -mt-0.5 w-5/6 leading-4'>  fax: {deliveryLetter?.contact_fax}</p> : null}
							{deliveryLetter?.contact_name.length == 0 && <div className='block h-5'>No Data</div>}
						</div>


					</div>
				</div>
				<div className='flex flex-col col-span-1'>
					<div className="mb-5  flex flex-col col-span-3 rounded-xl py-6 px-6 transition-all hover:shadow-lg sm:p-4 lg:px-4 xl:px-6 bg-white cursor-pointer">
						<div className='flex flex-col mb-4'>
							<p className=' text-gray-500'>Info</p>
							<p className=' text-gray-700 text-lg font-medium' onClick={() => {
								if (!editable) return;
								setModalEmployeeOpen(true);
							}}>Dibuat :</p>
							<p className=' text-gray-700 mt-0 text-base'>{deliveryLetter?.assignor_name}</p>
							<p className=' text-gray-700 text-lg font-medium' onClick={() => {
								if (!editable) return;
								setModalSenderOpen(true);
							}}>Dikirim :</p>
							<p className=' text-gray-700 mt-0 text-base'>{deliveryLetter?.employee_name}</p>
							<p className=' text-gray-700 text-lg font-medium mt-4'>Kendaraan :</p>
							{editable ?
								<select

									value={deliveryLetter?.transportation_type ?? ""}
									onChange={(val) => {
										updateDeliveryLetter(deliveryLetter!.uuid, {
											...deliveryLetter!,
											send_date: moment(deliveryLetter!.send_date).format("YYYY-MM-DD"),
											transportation_type: val.target.value,
										})
											.then(v => getDetail());
									}}
								>

									<option value="Pilih Kendaraan">Pilih Kendaraan</option>
									{VEHICLES.map(e => <option key={e} value={e}>{e}</option>)}
								</select>
								: <p className='text-gray-700 mt-0 text-base'>{deliveryLetter!.transportation_type}</p>}
							<p className=' text-gray-700 text-lg font-medium mt-4'>Plat No :</p>
							{editable ?
								<input
									id='number_plate'

									value={deliveryLetter?.number_plate ?? ""}
									onKeyUp={(e) => {
										if (e.key === 'Enter') {
											document.getElementById(`number_plate`)!.blur();
										}
									}}
									onBlur={() => {
										updateDeliveryLetter(deliveryLetter!.uuid, {
											...deliveryLetter!,
											send_date: moment(deliveryLetter!.send_date).format("YYYY-MM-DD"),
										})
											.then(v => getDetail());
									}}
									onChange={(val) => {
										setDeliveryLetter({
											...deliveryLetter!,
											number_plate: val.target.value,
										});

									}} />
								: <p className='text-gray-700 mt-0 text-base'>{deliveryLetter!.number_plate}</p>}
							<div className='mb-3 flex  flex-col'>

							</div>
						</div>
					</div>
				</div>
				<div className='flex flex-col col-span-1'>
					<div className="mb-5  flex flex-col col-span-3 rounded-xl py-6 px-6 transition-all hover:shadow-lg sm:p-4 lg:px-4 xl:px-6 bg-white cursor-pointer">
						<div className='flex flex-col mb-4'>
							<p className=' text-gray-500 ' onClick={!editable ? () => { } : () => {
								setEditorOpen(true)
							}}>Catatan</p>
							<div dangerouslySetInnerHTML={{ __html: deliveryLetter?.notes ?? "-" }}></div>
						</div>

					</div>
				</div>
				<div className='flex flex-col col-span-1'>
					<div className="mb-5  flex flex-col col-span-3 rounded-xl py-6 px-6 transition-all hover:shadow-lg sm:p-4 lg:px-4 xl:px-6 bg-white cursor-pointer">
						<div className='flex flex-col mb-4'>
							<p className=' text-gray-500 '>Faktur</p>
							{(deliveryLetter?.invoices ?? []).map(e =>
								<li className=" mb-2  flex justify-between  rounded-lg  hover:bg-gray-50 " key={e.uuid}>
									<div className="flex-1" onClick={() => nav(`/sale/${e.uuid}`)} >
										<Moment format="DD/MM/YYYY">{e.invoice_date}</Moment>
										<p className=" text-lg font-medium">{e.invoice_number}</p>
										<p className=" mt-0 text-base font-medium">{e.customer_name}</p>
										<p className=" mt-0 text-xs ">{e.customer_address}</p>
									</div>
								</li>)}
							{(deliveryLetter?.bills ?? []).map(e =>
								<li className=" mb-2  flex justify-between  rounded-lg  hover:bg-gray-50 " key={e.uuid}>
									<div className="flex-1" onClick={() => nav(`/buy/${e.uuid}`)} >
										<Moment format="DD/MM/YYYY">{e.bill_date}</Moment>
										<p className=" text-lg font-medium">{e.bill_number}</p>
										<p className=" mt-0 text-base font-medium">{e.vendor_name}</p>
										<p className=" mt-0 text-xs ">{e.vendor_address}</p>
									</div>
								</li>)}
						</div>

					</div>
				</div>
			</div>
		);
	}



	return (<DashboardLayout permission='read_delivery_letter'>
		<div className="p-6">
			<div className='flex justify-between items-center'>
				<h1 className="text-4xl flex"> <Back className="mr-2" onClick={() => {
					if (deliveryLetter?.letter_flow == "letter_in") {
						nav('/buy')
					}
					if (deliveryLetter?.letter_flow == "letter_out") {
						nav('/sale')
					}
				}} /> Surat Jalan</h1>
				<div className='flex'>
					{editing && <div className="">
						<button
							type="button"
							className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
							onClick={update}>
							<FolderIcon
								className=" h-5 w-5 mr-2"
								aria-hidden="true" />  Simpan
						</button>
					</div>}
					<Bars3BottomRightIcon className='w-10 ml-2 text-gray-400 cursor-pointer hover:text-gray-600' onClick={() => setOpenWithHeader(true)} />
				</div>
			</div>
			<div className="mt-6 grid gap-x-8 gap-y-4 sm:gap-y-0 xl:grid-cols-4 lg:grid-cols-4 sm:grid-cols-2 md:grid-cols-2 ">
				{detail()}
				{info()}
			</div>
		</div>
		<ModalProduct
			onClose={() => {
				setEditing(true)
				// setSelectedItem(null)
			}}
			isOpen={modalProductOpen} selectStore={true} isSell={deliveryLetter?.letter_flow == "letter_out"} isBuy={deliveryLetter?.letter_flow == "letter_in"} setIsOpen={setModalProductOpen}
			onClick={selectProduct} />
		<ModalVendor isOpen={modalVendorOpen} setIsOpen={setModalVendorOpen} onClick={async (val) => {
			try {
				setIsLoading(true)

				await updateDeliveryLetter(deliveryLetter!.uuid, {
					...deliveryLetter!,
					vendor_id: val.uuid,
					contact_name: val.name,
					contact_address: val.address,
					contact_phone: val.phone,
					contact_fax: val.fax,
					contact_email: val.email,
					contact_contact_person: val.contact_person,
					send_date: moment(deliveryLetter!.send_date).format("YYYY-MM-DD"),
				})

				getDetail()
			} catch (error) {
				Swal.fire("Perhatian", `${error}`, "error")
			} finally {
				setIsLoading(false)
			}

		}} />
		<ModalCustomer isOpen={modalCustomerOpen} setIsOpen={setModalCustomerOpen} onClick={async (val) => {
			try {
				setIsLoading(true)

				await updateDeliveryLetter(deliveryLetter!.uuid, {
					...deliveryLetter!,
					customer_id: val.uuid,
					contact_name: val.name,
					contact_address: val.address,
					contact_phone: val.phone,
					contact_fax: val.fax,
					contact_email: val.email,
					contact_contact_person: val.contact_person,
					send_date: moment(deliveryLetter!.send_date).format("YYYY-MM-DD"),
				})

				getDetail()
			} catch (error) {
				Swal.fire("Perhatian", `${error}`, "error")
			} finally {
				setIsLoading(false)
			}
		}} />
		<ModalEmployee isOpen={modalEmployeeOpen} setIsOpen={setModalEmployeeOpen} onClick={async (val) => {
			try {
				setIsLoading(true)

				await updateDeliveryLetter(deliveryLetter!.uuid, {
					...deliveryLetter!,
					send_date: moment(deliveryLetter!.send_date).format("YYYY-MM-DD"),
					assignor: val.uuid,
					assignor_name: val!.full_name,
				})

				getDetail()
			} catch (error) {
				Swal.fire("Perhatian", `${error}`, "error")
			} finally {
				setIsLoading(false)
			}
		}} />
		<ModalEmployee isOpen={modalSenderOpen} setIsOpen={setModalSenderOpen} onClick={async (val) => {
			try {
				setIsLoading(true)

				await updateDeliveryLetter(deliveryLetter!.uuid, {
					...deliveryLetter!,
					employee_id: val.uuid,
					employee_name: val.full_name,
					send_date: moment(deliveryLetter!.send_date).format("YYYY-MM-DD"),
				})

				getDetail()
			} catch (error) {
				Swal.fire("Perhatian", `${error}`, "error")
			} finally {
				setIsLoading(false)
			}
		}} />
		<ModalEditor isOpen={editorOpen} setIsOpen={setEditorOpen} value={deliveryLetter?.notes ?? ""} onEditorChange={(val) => {
			let change = {
				...deliveryLetter!,
				notes: val,
			}
			setDeliveryLetter(change)
			setEditing(true)

		}}
			setContent={setContent}
			onClose={(val) => {
				console.log(val)
			}}

		/>
		<Drawer open={openWithHeader} onClose={() => setOpenWithHeader(false)}>
			<Drawer.Header>
				<Drawer.Title>Menu Faktur</Drawer.Title>
				<Drawer.Actions>
					{/* <Button onClick={() => {
                        //   setDateRange(null)
                        //   setInputProduct(null)
                        //   setInputDestination(null)
                        //   setOpenWithHeader(false)
                        //   setProduct(null)
                        //   setStore(null)
                        //   setProducts([])
                    }}><XMarkIcon className="w-5" /> Clear Filter</Button> */}
				</Drawer.Actions>
			</Drawer.Header>
			<Drawer.Body>
				<div className='flex flex-col h-full'>
					<div className='flex-1'>

						<h3 className=' font-medium text-2xl mb-4'>Pengaturan Print</h3>
						<div className="mb-6">
							<InlineForm title="Header">
								<select className='bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500'
									value={printHeader} onChange={(val) => setPrintHeader(val.target.value)} >
									<option value={""}>Tanpa Header</option>
									<option value={"merchant"}>Merchant</option>
									<option value={"company"}>Perusahaan</option>
								</select>
							</InlineForm>

							<InlineForm title="Alamat">
								<Switch
									checked={printShowAddress}
									onChange={async (val) => {
										setPrintShowAddress(val)
									}}
									className={`${printShowAddress ? 'bg-blue-600' : 'bg-gray-200'
										} relative inline-flex h-6 w-11 items-center rounded-full`}
								>
									<span className="sr-only"> Alamat </span>
									<span
										aria-hidden="true"
										className={`${printShowAddress ? 'translate-x-6' : 'translate-x-1'
											} inline-block h-4 w-4 transform rounded-full bg-white transition`}
									/>
								</Switch>
							</InlineForm>
							<InlineForm title="Pembuat">
								<Switch
									checked={printShowMaker}
									onChange={async (val) => {
										setPrintShowMaker(val)
									}}
									className={`${printShowMaker ? 'bg-blue-600' : 'bg-gray-200'
										} relative inline-flex h-6 w-11 items-center rounded-full`}
								>
									<span className="sr-only"> Pembuat </span>
									<span
										aria-hidden="true"
										className={`${printShowMaker ? 'translate-x-6' : 'translate-x-1'
											} inline-block h-4 w-4 transform rounded-full bg-white transition`}
									/>
								</Switch>
							</InlineForm>
							<InlineForm title="Pengirim">
								<Switch
									checked={printShowSender}
									onChange={async (val) => {
										setPrintShowSender(val)
									}}
									className={`${printShowSender ? 'bg-blue-600' : 'bg-gray-200'
										} relative inline-flex h-6 w-11 items-center rounded-full`}
								>
									<span className="sr-only"> Pengirim </span>
									<span
										aria-hidden="true"
										className={`${printShowSender ? 'translate-x-6' : 'translate-x-1'
											} inline-block h-4 w-4 transform rounded-full bg-white transition`}
									/>
								</Switch>
							</InlineForm>
							<InlineForm title="Catatan">
								<Switch
									checked={printShowNotes}
									onChange={async (val) => {
										setPrintShowNotes(val)
									}}
									className={`${printShowNotes ? 'bg-blue-600' : 'bg-gray-200'
										} relative inline-flex h-6 w-11 items-center rounded-full`}
								>
									<span className="sr-only"> Catatan </span>
									<span
										aria-hidden="true"
										className={`${printShowNotes ? 'translate-x-6' : 'translate-x-1'
											} inline-block h-4 w-4 transform rounded-full bg-white transition`}
									/>
								</Switch>
							</InlineForm>
							<InlineForm title="Download">
								<Switch
									checked={printDownload}
									onChange={async (val) => {
										setPrintDownload(val)
									}}
									className={`${printDownload ? 'bg-blue-600' : 'bg-gray-200'
										} relative inline-flex h-6 w-11 items-center rounded-full`}
								>
									<span className="sr-only"> Download </span>
									<span
										aria-hidden="true"
										className={`${printDownload ? 'translate-x-6' : 'translate-x-1'
											} inline-block h-4 w-4 transform rounded-full bg-white transition`}
									/>
								</Switch>
							</InlineForm>
							<button
								type="button"
								className="flex w-full transition-all mt-4 duration-500 justify-center rounded-md border border-transparent bg-orange-600 px-4 py-2 text-sm font-medium text-white hover:bg-orange-900 focus:outline-none focus-visible:ring-2 focus-visible:ring-orange-500 focus-visible:ring-offset-2"
								onClick={() => {
									let params: Record<string, string> = {}
									if (printHeader) {
										params['header'] = printHeader
									}
									if (printShowPayment) {
										params['payment'] = printShowPayment ? "1" : "0"
									}
									if (printShowAddress) {
										params['address'] = printShowAddress ? "1" : "0"
									}
									if (printShowMaker) {
										params['maker'] = printShowMaker ? "1" : "0"
									}
									if (printShowSender) {
										params['sender'] = printShowSender ? "1" : "0"
									}
									if (printShowNotes) {
										params['notes'] = printShowNotes ? "1" : "0"
									}
									if (printDownload) {
										params['download'] = printDownload ? "1" : "0"
									}
									let url = `/delivery_letter/${deliveryLetter?.uuid}/print?${new URLSearchParams(params)}`
									window.open(url)


								}}>
								<PrinterIcon
									className=" h-5 w-5 mr-2"
									aria-hidden="true" />  Print
							</button>
						</div>
					</div>


					{/* <div>
                        <Whisper followCursor speaker={<Tooltip>Download Faktur</Tooltip>}>
                            <AvatarRS circle color='orange' className=' cursor-pointer' onClick={() => {
                                setModalDownloadOpen(true)
                                setOpenWithHeader(false)
                            }} >
                                <DocumentArrowDownIcon className='w-6' />
                            </AvatarRS>
                        </Whisper>
                    </div> */}

				</div>

			</Drawer.Body>
		</Drawer>
	</DashboardLayout>);
}
export default DeliveryLetterPage;