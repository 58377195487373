import { useContext, useEffect, useState, type FC } from 'react';
import { Store } from '../model/store';
import { Pagination } from '../model/pagination';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LoadingContext } from '../objects/loading_context';
import { Product } from '../model/product';
import { Circulation } from '../model/circulation';
import DateRangePicker, { DateRange } from 'rsuite/esm/DateRangePicker';
import { SelectOption, colourStyles } from '../utils/style';
import { Return } from '../model/return';
import { Account } from '../model/account';
import { Vendor } from '../model/vendor';
import { Customer } from '../model/customer';
import { deleteStore, getStores } from '../repositories/store';
import { getVendors } from '../repositories/vendor';
import { getCustomers } from '../repositories/customer';
import { addReturn, deleteReturn, getReturns } from '../repositories/return';
import Swal from 'sweetalert2';
import { deleteCirculation, getCirculations } from '../repositories/circulation';
import { getProductDetail, getProducts } from '../repositories/product';
import CustomTable, { TableRecord } from '../components/custom_table';
import { EyeIcon, FolderIcon, FunnelIcon, TrashIcon, XMarkIcon } from '@heroicons/react/24/outline';
import Moment from 'react-moment';
import { money } from '../utils/number';
import { Badge, Button, DatePicker, Drawer } from 'rsuite';
import { confirmDelete, parseVarian } from '../utils/helper';
import DashboardLayout from '../components/dashboard';
import FormStore from '../components/modals/form_store';
import FormCirculation from '../components/modals/form_circulation';
import Select, { MultiValue, SingleValue } from 'react-select';
import ModalCustom from '../components/modals/modal_custom';
import InlineForm from '../components/inline_form';
interface StorePageProps { }

const StorePage: FC<StorePageProps> = ({ }) => {
    const [mounted, setMounted] = useState(false);
    let { isLoading, setIsLoading } = useContext(LoadingContext);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(20);
    const [search, setSearch] = useState("");
    const [pageCirculation, setPageCirculation] = useState(1);
    const [searchCirculation, setSearchCirculation] = useState("");
    const [limitCirculation, setLimitCirculation] = useState(20);
    const [modalStoreOpen, setModalStoreOpen] = useState(false);

    const [stores, setStores] = useState<Store[]>([]);
    const [allStores, setAllStores] = useState<Store[]>([]);
    const [pagination, setPagination] = useState<Pagination | null>(null);
    const [paginationCirculation, setPaginationCirculation] = useState<Pagination | null>(null);

    const [searchParams] = useSearchParams()
    const [product, setProduct] = useState<Product | null>(null);
    const [circulations, setCirculations] = useState<Circulation[]>([]);
    const [modalCirculationOpen, setModalCirculationOpen] = useState(false);

    const nav = useNavigate()
    const [openWithHeader, setOpenWithHeader] = useState(false);
    const [dateRange, setDateRange] = useState<DateRange | null>(null);
    const [productSelect, setProductSelect] = useState<SelectOption[]>([]);
    const [inputProductId, setInputProductId] = useState("");
    const [inputProduct, setInputProduct] = useState<SelectOption | null>();
    const [products, setProducts] = useState<Product[]>([]);

    const [inputDestinationId, setInputDestinationId] = useState("");
    const [inputDestination, setInputDestination] = useState<SelectOption | null>();
    const [storeSelect, setStoreSelect] = useState<SelectOption[]>([]);
    const [store, setStore] = useState<Store | null>(null);
    const [pageReturn, setPageReturn] = useState(1)
    const [limitReturn, setLimitReturn] = useState(20)
    const [searchReturn, setSearchReturn] = useState("")
    const [returns, setReturns] = useState<Return[]>([]);
    const [paginationReturn, setPaginationReturn] = useState<Pagination | null>(null);
    const [modalReturnOpen, setModalReturnOpen] = useState(false);
    const [inputReturnType, setInputReturnType] = useState("PURCHASE_RETURN");
    const [date, setDate] = useState<Date>(new Date());
    const [assetAccounts, setAssetAccounts] = useState<Account[]>([]);
    const [selectAssetAccount, setSelectAssetAccount] = useState<SelectOption>({ value: "", label: "Pilih Akun" });
    const [returnAccounts, setReturnAccounts] = useState<Account[]>([]);
    const [selectReturnAccount, setSelectReturnAccount] = useState<SelectOption>({ value: "", label: "Pilih Akun" });
    const [vendors, setVendors] = useState<Vendor[]>([]);
    const [selectVendor, setSelectVendor] = useState<SelectOption>({ value: "", label: "Pilih Vendor" });
    const [customers, setCustomers] = useState<Customer[]>([]);
    const [selectCustomer, setSelectCustomer] = useState<SelectOption>({ value: "", label: "Pilih Customer" });
    const [returnNumber, setReturnNumber] = useState("");
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");

    useEffect(() => {
        setMounted(true)
    }, []);

    useEffect(() => {
        getAllStores()
        productDetail()
        getAllCirculations()
        getAllReturns()
        getContacts("")
        getStores({ limit: 10, page: 1 })
            .then(v => v.json())
            .then(v => {
                setAllStores(v.data)
            })

    }, [mounted]);




    useEffect(() => {
        setPageReturn(1)
        getAllReturns(1)
    }, [searchReturn]);

    useEffect(() => {
        getContacts("")
    }, [selectCustomer, selectVendor]);
    useEffect(() => {
        getAllReturns()
    }, [limitReturn, pageReturn]);

    const getContacts = (search: string) => {
        if (inputReturnType == "PURCHASE_RETURN") {
            getVendors({ page: 1, limit: 5, search })
                .then(v => v.json())
                .then(v => setVendors(v.data))
        } else {
            getCustomers({ page: 1, limit: 5, search })
                .then(v => v.json())
                .then(v => setCustomers(v.data))
        }
    }


    const getAllReturns = async (page?: number) => {
        try {
            setIsLoading(true)
            var resp = await getReturns({ page: page ?? pageReturn, limit: limitReturn, search: searchReturn })
            var respJson = await resp.json()
            setReturns(respJson.data)
            setPaginationReturn(respJson.meta)
        } catch (error) {
            Swal.fire("Perhatian", `${error}`, "error")
        } finally {
            setIsLoading(false)
        }
    }

    const getAllStores = async () => {
        try {
            setIsLoading(true)
            var resp = await getStores({ page, limit, search })
            var respJson = await resp.json()
            setStores(respJson.data)
            setPagination(respJson.meta)
        } catch (error) {
            Swal.fire("Perhatian", `${error}`, "error")
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (!mounted) return
        getAllStores()
    }, [search, page, limit]);

    useEffect(() => {
        let u: { value: string; label: string; }[] = []
        for (let index = 0; index < products.length; index++) {
            const element = products[index];
            u.push({ value: element.uuid!, label: element.name })
        }
        setProductSelect(u)
    }, [products]);

    useEffect(() => {
        if (!mounted) return
        getAllCirculations()
    }, [searchCirculation, pageCirculation, limitCirculation, dateRange, product, store]);

    const getAllCirculations = async () => {
        getCirculations({
            page: pageCirculation,
            limit: limitCirculation,
            search: searchCirculation,
            dateRange,
            product_id: product?.uuid ?? searchParams.get('product_id') ?? null,
            store_id: store?.uuid ?? null,
        })
            .then(v => v.json())
            .then(v => {
                setCirculations(v.data)
                setPaginationCirculation(v.meta)
            })
    }

    const productDetail = async () => {
        if (searchParams.get('product_id')) {
            getProductDetail(searchParams.get('product_id')!)
                .then(v => v.json())
                .then(v => {
                    setProduct(v.data)
                })

        }
    }

    useEffect(() => {
        let u: { value: string; label: string; }[] = []
        for (let index = 0; index < allStores.length; index++) {
            const element = allStores[index];
            u.push({ value: element.uuid!, label: element.name })
        }
        setStoreSelect(u)
    }, [allStores]);


    const renderStore = () => (
        <div className="mb-5 col-span-2 rounded-xl py-6 px-6 transition-all hover:shadow-lg sm:p-4 lg:px-4 xl:px-6 bg-white cursor-pointer" >
            <h1 className="text-2xl">Gudang</h1>

            <CustomTable className="mt-4"
                onSearch={(val) => {
                    setSearch(val)
                }}
                switchHeader
                pagination
                total={pagination?.total_records}
                limit={limit}
                activePage={page}
                setActivePage={(val) => {
                    setPage(val)
                }}
                searchHeader={
                    <button
                        onClick={() => { setModalStoreOpen(true) }}
                        className=" text-white bg-orange-600 hover:bg-orange-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-full text-sm px-5 py-2.5 text-center "
                    >
                        Tambah Gudang
                    </button>
                }
                changeLimit={(val) => setLimit(val)}
                limitPaginations={[10, 20, 50, 100, 1000]}
                headers={["No", "Nama Gudang", "Alamat", ""]}
                headerClasses={[]} datasets={stores.map(e => {
                    let data: TableRecord = {
                        cells: [
                            { data: ((page - 1) * limit) + (stores.indexOf(e) + 1) },

                            {
                                data: <div className=" hover:font-medium" onClick={() => {
                                    nav(`/store/${e.uuid}`)
                                }}>
                                    {e.name}
                                </div>
                            },
                            { data: e.address },

                            {
                                data: <div className="flex justify-end">
                                    <TrashIcon
                                        className=" h-5 w-5 text-red-400 hover:text-red-600"
                                        aria-hidden="true"
                                        onClick={() => {
                                            Swal.fire({
                                                title: "Anda Yakin",
                                                text: "Anda tidak akan dapat mengembalikan proses ini!",
                                                icon: "warning",
                                                showCancelButton: true,
                                                confirmButtonColor: "#3085d6",
                                                cancelButtonColor: "#d33",
                                                confirmButtonText: "Ya, Hapus!",
                                                cancelButtonText: "Batal"
                                            }).then((result) => {
                                                if (result.isConfirmed) {
                                                    deleteStore(e.uuid!).then(v => getAllStores())
                                                }
                                            });
                                        }}
                                    />

                                </div>, className: 'w-4 text-right'
                            },
                        ],
                        className: "last:border-0 hover:bg-gray-50"
                    }
                    return data
                })} />

        </div>
    )
    const renderReturn = () => (
        <div className="mb-5 col-span-2 rounded-xl py-6 px-6 transition-all hover:shadow-lg sm:p-4 lg:px-4 xl:px-6 bg-white cursor-pointer" >
            <h1 className="text-2xl">Retur</h1>
            <CustomTable
                className='mt-2'
                searchPlaceholder='Cari Return'
                onSearch={(val) => {
                    setSearchReturn(val)
                }}
                pagination
                switchHeader
                total={paginationReturn?.total_records}
                limit={limitReturn}
                activePage={pageReturn}
                setActivePage={(val) => {
                    setPageReturn(val)
                }}

                searchHeader={
                    <button
                        onClick={() => { setModalReturnOpen(true) }}
                        className=" text-white bg-orange-600 hover:bg-orange-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-full text-sm px-5 py-2.5 text-center "
                    >
                        Tambah Retur
                    </button>
                }

                headers={["#", "Tgl", "No. Retur", "Jenis", "Total", "Status", ""]} headerClasses={["w-10", "w-80", "", "", "text-right"]}
                datasets={returns.map(e => {
                    let data: TableRecord = {
                        cells: [
                            { data: ((pageReturn - 1) * limitReturn) + (returns.indexOf(e) + 1) },
                            { data: <Moment format='DD MMM YYYY'>{e.return_date}</Moment>, },
                            {
                                data: <div onClick={() => {
                                    nav(`/return/${e.id}`)
                                }} className=' cursor-pointer hover:font-semibold'>
                                    {e.return_number}</div>, className: "cursor-pointer"
                            },
                            { data: e.return_type == "PURCHASE_RETURN" ? "Pembelian" : "Penjualan" },
                            { data: (money(e.total)), className: "text-right" },
                            {
                                data: <div>
                                    {e.status == "DRAFT" && <Badge color='yellow' content={e.status} />}
                                    {e.status == "PROCESSING" && <Badge color='green' content={e.status} />}
                                    {e.status == "PAID" && <Badge color='orange' content={e.status} />}
                                    {e.status == "DELIVERED" && <Badge color='cyan' content={e.status} />}
                                    {e.status == "FINISHED" && <Badge color='blue' content={e.status} />}


                                </div>
                            },
                            {
                                data: <div className='flex'>
                                    <EyeIcon className="w-5 text-blue-600" onClick={() => {
                                        nav(`/return/${e.id}`)

                                    }} />
                                    <TrashIcon className="w-5 text-red-600" onClick={() => {
                                        confirmDelete(() => {
                                            deleteReturn(e.id)
                                                .then(e => getAllReturns())
                                        })

                                    }} />
                                </div>, className: 'w-4 text-right'
                            },
                        ],
                        className: "last:border-0 hover:bg-gray-50"
                    }
                    return data
                })} />
        </div>
    )
    const renderCirculation = () => (
        <div className="mb-5 col-span-4 rounded-xl py-6 px-6 transition-all hover:shadow-lg sm:p-4 lg:px-4 xl:px-6 bg-white cursor-pointer" >
            <div className="flex justify-between">
                <h1 className="text-2xl">Sirkulasi</h1>
                <div className="flex text-violet-200 hover:text-violet-400" onClick={() => setOpenWithHeader(true)}>
                    <FunnelIcon
                        className="cursor-pointer z-50  h-5 w-5"
                    />
                    Filter
                </div>

            </div>

            <CustomTable
                className="mt-4"
                onSearch={(val) => {
                    setSearchCirculation(val)
                }}
                switchHeader
                pagination
                total={paginationCirculation?.total_records}
                limit={limitCirculation}
                activePage={pageCirculation}
                setActivePage={(val) => {
                    setPageCirculation(val)
                }}
                searchHeader={
                    <button
                        onClick={() => { setModalCirculationOpen(true) }}
                        className=" text-white bg-orange-600 hover:bg-orange-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-full text-sm px-5 py-2.5 text-center "
                    >
                        Tambah Sirkulasi
                    </button>
                }
                changeLimit={(val) => setLimitCirculation(val)}
                limitPaginations={[10, 20, 50, 100, 1000]}
                headers={["No.", "Tgl", "Keterangan", "Produk", "Jumlah", "Mutasi", ""]} headerClasses={[]} datasets={circulations.map(e => {
                    let data: TableRecord = {
                        cells: [
                            { data: ((pageCirculation - 1) * limitCirculation) + (circulations.indexOf(e) + 1) },

                            {
                                data: <div className="flex flex-col">
                                    <Moment className="text-xs" format="DD/MM/YYYY">{e.date}</Moment>
                                    <Moment className="text-xs" format="HH:mm" style={{ fontSize: 10 }}>{e.date}</Moment>
                                </div>,
                            },
                            {
                                data: <div className=" hover:font-medium" onClick={() => {
                                    if (e.invoice_id) {
                                        nav(`/sale/${e.invoice_id}`)
                                        return
                                    }
                                    if (e.bill_id) {
                                        nav(`/buy/${e.bill_id}`)
                                        return
                                    }
                                }}>
                                    {e.description}
                                </div>,
                            },
                            {
                                data: <div>
                                    <div className=" hover:font-medium" onClick={() => {
                                        nav(`/product/${e.product_id}`)
                                    }}>
                                        {e.product_name}
                                    </div>
                                    {e.varian_id ? <small>{parseVarian(e.varian_data)}</small> : null}
                                </div>,
                            },
                            {
                                data: <div className="flex flex-col hover:font-medium" onClick={() => {
                                }}>
                                    <span>
                                        {`${money(e.amount_in - e.amount_out)} ${e.unit_name}`}
                                    </span>
                                    {e.packaging_id ? <small>{money(e.packaging_in - e.packaging_out)} {e.packaging_name}</small> : null}

                                </div>, className: "text-right",
                            },
                            {
                                data: <div className=" hover:font-medium" onClick={() => {

                                }}>
                                    {e.destination_name != "" ? <div className="flex" onClick={() => {
                                        nav(`/store/${e.destination_id}`)
                                    }}>ke {e.destination_name} </div> : null}
                                    {e.source_name != "" ? <div className="flex" onClick={() => {
                                        nav(`/store/${e.source_id}`)
                                    }}> dari {e.source_name} </div> : null}
                                </div>,
                            },

                            {
                                data: <div className="flex justify-end">
                                    <TrashIcon
                                        className=" h-5 w-5 text-red-400 hover:text-red-600"
                                        aria-hidden="true"
                                        onClick={() => {
                                            Swal.fire({
                                                title: "Anda Yakin",
                                                text: "Anda tidak akan dapat mengembalikan proses ini!",
                                                icon: "warning",
                                                showCancelButton: true,
                                                confirmButtonColor: "#3085d6",
                                                cancelButtonColor: "#d33",
                                                confirmButtonText: "Ya, Hapus!",
                                                cancelButtonText: "Batal"
                                            }).then((result) => {
                                                if (result.isConfirmed) {
                                                    deleteCirculation(e.uuid).then(v => {
                                                        getAllCirculations()
                                                    }).catch(error => Swal.fire("Perhatian", `${error}`, "error"))
                                                }
                                            });
                                        }}
                                    />

                                </div>, className: 'w-4 text-right'
                            },
                        ],
                        className: "last:border-0 hover:bg-gray-50"
                    }
                    return data
                })} />

        </div>
    )
    return (
        <DashboardLayout  >
            <div className="p-6">
                <h1 className="text-4xl">Gudang & Sirkulasi</h1>
                <div className="mt-6 grid gap-x-8 gap-y-4 sm:gap-y-0 xl:grid-cols-4 lg:grid-cols-4 sm:grid-cols-2 md:grid-cols-2 ">
                    {renderStore()}
                    {renderReturn()}
                    {renderCirculation()}
                </div>
            </div>
            <FormStore isOpen={modalStoreOpen} setIsOpen={setModalStoreOpen} onSubmit={() => {
                setModalStoreOpen(false)
                getAllStores()
            }} />
            <FormCirculation modalCirculationOpen={modalCirculationOpen} setModalCirculationOpen={setModalCirculationOpen} initialProduct={product} onProductModalOpen={() => {

            }} onSubmit={() => {
                setModalCirculationOpen(false)
                getAllCirculations()
            }} />

            <Drawer open={openWithHeader} onClose={() => setOpenWithHeader(false)}>
                <Drawer.Header>
                    <Drawer.Title>Filter Sirkulasi</Drawer.Title>
                    <Drawer.Actions>
                        <Button onClick={() => {
                            setDateRange(null)
                            setInputProduct(null)
                            setInputDestination(null)
                            setOpenWithHeader(false)
                            setProduct(null)
                            setStore(null)
                            setProducts([])
                        }}><XMarkIcon className="w-5" /> Clear Filter</Button>
                    </Drawer.Actions>
                </Drawer.Header>
                <Drawer.Body>
                    <div className="flex items-center mb-6">
                        <div className="w-1/3">
                            <label className="block text-gray-900 mb-0 pr-4">
                                Rentang Tanggal
                            </label>
                        </div>
                        <div className="w-2/3">
                            <DateRangePicker placement="bottomEnd" value={dateRange} block format="dd/MM/yyyy" onOk={(val: DateRange) => {
                                setPageCirculation(1)
                                setTimeout(() => {
                                    setDateRange(val)
                                }, 300);
                            }} />
                        </div>
                    </div>
                    <div className="flex items-center mb-6">
                        <div className="w-1/3">
                            <label className="block text-gray-900 mb-0 pr-4">
                                Produk
                            </label>
                        </div>
                        <div className="w-2/3">
                            <Select< SelectOption, false> placeholder="Cari Produk ..." styles={colourStyles} options={productSelect} value={inputProduct}
                                onChange={(option: SingleValue<SelectOption>): void => {
                                    setInputProductId(option?.value ?? "")
                                    setInputProduct(option)
                                    let selectedProduct = products.filter(e => e.uuid == option?.value)
                                    if (selectedProduct) {
                                        setProduct(selectedProduct[0])
                                    }
                                }}
                                onInputChange={(val) => {
                                    getProducts({ limit: 20, page: 1, search: val })
                                        .then(v => v.json())
                                        .then(v => {
                                            setProducts(v.data)
                                        })
                                }}
                            />
                        </div>
                    </div>
                    <div className="flex items-center mb-6">
                        <div className="w-1/3">
                            <label className="block text-gray-900 mb-0 pr-4">
                                Gudang
                            </label>
                        </div>
                        <div className="w-2/3">
                            <Select< SelectOption, false> placeholder="Cari Gudang ..." styles={colourStyles} options={storeSelect} value={inputDestination}
                                onChange={(option: SingleValue<SelectOption>): void => {
                                    setInputDestinationId(option?.value ?? "")
                                    setInputDestination(option)
                                    let selectedStore = allStores.filter(e => e.uuid == option?.value)
                                    if (selectedStore) {
                                        setStore(selectedStore[0])
                                    }
                                }}
                                onInputChange={(val) => {
                                    getStores({ limit: 10, page: 1, search: val })
                                        .then(v => v.json())
                                        .then(v => {
                                            setAllStores(v.data)
                                        })
                                }}
                            />
                        </div>
                    </div>
                </Drawer.Body>
            </Drawer>
            <ModalCustom
                footer={<div className="mt-4">
                    <button
                        type="button"
                        className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                        onClick={async () => {
                            try {
                                setIsLoading(true)
                                var resp = await addReturn({
                                    return_date: date.toISOString(),
                                    return_number: returnNumber,
                                    return_type: inputReturnType,
                                    notes: description,
                                    customer_id: inputReturnType == "PURCHASE_RETURN" ? "" : selectCustomer.value,
                                    vendor_id: inputReturnType == "PURCHASE_RETURN" ? selectVendor?.value : ""
                                })
                                var respJson = await resp.json()
                                nav(`/return/${respJson.data.last_id}`)
                                getAllReturns()
                                setSelectCustomer({ value: "", label: "Pilih Konsumen" })
                                setSelectVendor({ value: "", label: "Pilih Vendor" })
                                setDescription("")
                                setDate(new Date())
                                setInputReturnType("PURCHASE_RETURN")
                                setModalReturnOpen(false)
                                setReturnNumber("")
                            } catch (error) {
                                Swal.fire("Perhatian", `${error}`, "error")
                            } finally {
                                setIsLoading(false)
                            }
                        }}
                    >
                        <FolderIcon
                            className=" h-5 w-5 mr-2"
                            aria-hidden="true" />  Simpan
                    </button>
                </div>}
                title="Form Retur" isOpen={modalReturnOpen} setIsOpen={setModalReturnOpen} >
                <InlineForm title="Tipe">
                    <select
                        className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                        value={inputReturnType}
                        onChange={(el) => setInputReturnType(el.target.value)}
                    >
                        <option value="PURCHASE_RETURN">PEMBELIAN</option>
                        <option value="SALES_RETURN">PENJUALAN</option>
                    </select>
                </InlineForm>
                <InlineForm title="No Retur" >
                    <input
                        className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                        type="text"
                        placeholder={"No Retur"}
                        value={returnNumber}
                        onChange={(el) => setReturnNumber(el.target.value)}
                    />
                </InlineForm>
                <InlineForm title='Tgl'>
                    <DatePicker value={date} block format="dd/MM/yyyy HH:mm" onChangeCalendarDate={(date) => {
                        setDate(date)
                    }} />
                </InlineForm>
                <InlineForm title="Keterangan" style={{ alignItems: 'start' }}>
                    <textarea

                        className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                        placeholder={"Keterangan"}
                        value={description}
                        rows={5}
                        onChange={(el) => setDescription(el.target.value)}
                    />
                </InlineForm>
                {inputReturnType == "PURCHASE_RETURN" ?
                    <InlineForm title="Vendor/Supplier">
                        <Select< SelectOption, false> styles={colourStyles} options={vendors.map(e => ({
                            value: e.uuid!,
                            label: e.name!
                        }))} value={selectVendor}
                            onChange={(option: SingleValue<SelectOption>): void => {
                                setSelectVendor(option!)
                            }}
                            onInputChange={(val) => {
                                getContacts(val)
                            }}
                        />
                    </InlineForm>
                    : <InlineForm title="Konsumen">
                        <Select< SelectOption, false> styles={colourStyles} options={customers.map(e => ({
                            value: e.uuid!,
                            label: e.name!
                        }))} value={selectCustomer}
                            onChange={(option: SingleValue<SelectOption>): void => {
                                setSelectCustomer(option!)
                            }}
                            onInputChange={(val) => {
                                getContacts(val)
                            }}
                        />
                    </InlineForm>}


            </ModalCustom>
        </DashboardLayout>
    )
}
export default StorePage;