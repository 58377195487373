import { useEffect, useState, type FC } from 'react';
import { getPriceCategories, getProductCategories, getProducts } from '../repositories/product';
import { GroupedStock, Product, ProductCategory } from '../model/product';
import PDFView from '../components/pdf_view';
import { Font, StyleSheet, View, Text } from '@react-pdf/renderer';
import { getStoreDetail } from '../repositories/store';
import { Store } from '../model/store';
import { parseVarian, randomStr, sumMatrixStock } from '../utils/helper';
import { money } from '../utils/number';
import { useParams, useSearchParams } from 'react-router-dom';

interface ProductSummaryProps {}

const ProductSummary: FC<ProductSummaryProps> = ({}) => {
    const { buyId } = useParams()
    const [searchParams] = useSearchParams()
    const [mounted, setMounted] = useState(false);
    const [products, setProducts] = useState<Product[]>([]);
    const [categories, setCategories] = useState<ProductCategory[]>([]);
    const [store, setStore] = useState<Store | null>(null);
    const page = searchParams.get('page') ?? "1"
    const limit = searchParams.get('limit') ?? "20"
    const categoryIds = searchParams.get('categoryIds') ?? ""
    const storeId = searchParams.get('storeId') ?? ""
    const [groupedStock, setGroupedStock] = useState<GroupedStock[]>([]);

    useEffect(() => {
        getProducts({ page: parseInt(page), limit: parseInt(limit) }, categoryIds?.split(","), storeId, true)
            .then(v => v.json())
            .then(v => {
                setProducts(v.data)
                
                setMounted(true)
            })
        getProductCategories({ page: 1, limit: 1000 }, categoryIds?.split(","))
            .then(v => v.json())
            .then(v => {
                setCategories(v.data)
            })
        if (storeId) {
            getStoreDetail(storeId)
                .then(v => v.json())
                .then(v => {
                    setStore(v.data)
                })
        }
    }, [searchParams]);


    useEffect(() => {
        if (products.length && categories.length)
        setGroupedStock(sumMatrixStock(categories.map(e => ({value:  e.uuid, label: e.name})) , products) as GroupedStock[])
    }, [categories, products]);

    useEffect(() => {
    }, [groupedStock]);

    const styles = StyleSheet.create({
        defaultFont: {
            fontFamily: 'dot',
            letterSpacing: 0.8,
            fontSize: 9,
        },
        cellStyle: {
            border: 'solid',
            borderWidth: 0.4,
            paddingVertical: 4,
            paddingHorizontal: 8,
            borderColor: "black"
        }
    });

    let i = 0
    return (
         <PDFView title={"Product Summary"} print={false} download={false} >
            <View style={{ display: 'flex', flexDirection: 'row', paddingHorizontal: 20, paddingBottom: 5 }}>
                <View style={{ width: '15%' }}><Text style={{ ...styles.defaultFont, fontSize: 10 }}>Kategori : </Text></View>
                <View ><Text style={{ ...styles.defaultFont, }}>{categories.map(e => e.name).join(", ")}</Text></View>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row', paddingHorizontal: 20, paddingBottom: 5 }}>
                <View style={{ width: '15%' }}><Text style={{ ...styles.defaultFont, fontSize: 10 }}>Gudang : </Text></View>
                <View ><Text style={{ ...styles.defaultFont, }}>{store?.name}</Text></View>
            </View>
            <View style={{ display: 'flex', flexDirection: 'column', paddingHorizontal: 20, marginBottom: 1, }}>
                <View style={{ borderBottomStyle: "solid", borderBottomWidth: 0.4, borderBottomColor: "black", marginTop: 4, paddingBottom: 5, marginBottom: 2 }}></View>
            </View>
            <View style={{ display: 'flex', flexDirection: 'column', paddingHorizontal: 20, marginBottom: 10 }}>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <View style={{ ...styles.cellStyle, width: '5%', borderLeft: "none" }}><Text style={{ ...styles.defaultFont, }}>No</Text></View>
                    <View style={{ ...styles.cellStyle, width: '35%' }}><Text style={{ ...styles.defaultFont, }}>Keterangan</Text></View>
                    <View style={{ ...styles.cellStyle, width: '20%' }}><Text style={{ ...styles.defaultFont, textAlign: 'right' }}>Pkg</Text></View>
                    <View style={{ ...styles.cellStyle, width: '20%' }}><Text style={{ ...styles.defaultFont, textAlign: 'right' }}>Qty</Text></View>
                    <View style={{ ...styles.cellStyle, width: '20%', borderRight: "none" }}><Text style={{ ...styles.defaultFont, textAlign: 'right' }}>Jumlah</Text></View>
                </View>
                {products.map(e =>
                    {
                        return <View key={e.uuid}>
                            {e.varians.map(v => {
                                i++
                                return (<View key={v.uuid} style={{ display: 'flex', flexDirection: 'row', }}>
                                    <View style={{ ...styles.cellStyle, width: '5%', borderLeft: "none" }}><Text style={{ ...styles.defaultFont, }}>{i}</Text></View>
                                    <View style={{ ...styles.cellStyle, width: '35%' }}><Text style={{ ...styles.defaultFont, }}>{e.name} {v.uuid != "" && parseVarian(v.data, true)}</Text></View>
                                    <View style={{ ...styles.cellStyle, width: '20%' }}><Text style={{ ...styles.defaultFont, textAlign: 'right' }}></Text></View>
                                    <View style={{ ...styles.cellStyle, width: '20%' }}><Text style={{ ...styles.defaultFont, textAlign: 'right' }}>{money(v.stock)} {e.unit_name}</Text></View>
                                    <View style={{ ...styles.cellStyle, width: '20%', borderRight: "none" }}><Text style={{ ...styles.defaultFont, textAlign: 'right' }}>{money(v.stock * e.price)}</Text></View>
                                </View>);
                            })}
                            <View style={{ display: 'flex', flexDirection: 'row', }}>
                                <View style={{ ...styles.cellStyle, width: '40%' , borderLeft: "none" }}><Text style={{ ...styles.defaultFont, textAlign: "right" }}>Total</Text></View>
                                <View style={{ ...styles.cellStyle, width: '20%' }}><Text style={{ ...styles.defaultFont, textAlign: 'right' }}>{money(e.total_packaging_stock)}</Text></View>
                                <View style={{ ...styles.cellStyle, width: '20%' }}><Text style={{ ...styles.defaultFont, textAlign: 'right' }}>{money(e.total_stock)} {e.unit_name}</Text></View>
                                <View style={{ ...styles.cellStyle, width: '20%', borderRight: "none" }}><Text style={{ ...styles.defaultFont, textAlign: 'right' }}>{money(e.total_stock * e.price)}</Text></View>
                            </View>
                        </View>;
                    }
                )}
                {groupedStock.map(e => ( <View key={randomStr(3)} style={{ display: 'flex', flexDirection: 'row' }}>
                    <View style={{ ...styles.cellStyle, width: '5%', borderLeft: "none" }}>
                        <Text style={{ ...styles.defaultFont, }}></Text></View>
                    <View style={{ ...styles.cellStyle, width: '35%' , display: "flex", flexDirection: "column", justifyContent: "center" }}><Text style={{ ...styles.defaultFont, }}>Total {e.label}</Text></View>
                    <View style={{ ...styles.cellStyle, width: '20%' }}><Text style={{ ...styles.defaultFont, textAlign: 'right' }}></Text></View>
                    <View style={{ ...styles.cellStyle, width: '20%' }}>
                        <View>
                            {e.stocks.map(s => (<Text key={randomStr(3)} style={{ ...styles.defaultFont, textAlign: 'right' }}>{money(s)}</Text>))}
                        </View>
                        
                    </View>
                    <View style={{ ...styles.cellStyle, width: '20%', borderRight: "none" }}><Text style={{ ...styles.defaultFont, textAlign: 'right' }}></Text></View>
                </View>))}
            </View>
        </PDFView>
    );
}
export default ProductSummary;