import { customFetch } from "./helper";


export let fileUpload = async (url:string, { image, dir, method } :  { image: File, dir: string, method?: string }) : Promise<Record<string, any>> => {

    let formData = new FormData();

    formData.append("image", image);
    formData.append("dir", dir);
    return customFetch(url, {
        method: method ?? 'POST',
        body: formData
    }, true)
    // let response = await resp.json()
    // if (resp.ok) {
    //     return Promise.resolve({
    //         "message": response.message,
    //         "url": response.data.url,
    //         "path": response.data.path,
    //     })
    // }
    // return Promise.reject({ "error": response.error })
}
